import styled from 'styled-components';
import { StyledUiContainerProps } from 'components';

export const ViewContainer = styled.div<StyledUiContainerProps>`
  grid-gap: 1em;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-auto-rows: auto;
  grid-auto-flow: row;
  grid-template-areas: 'changeover-step-categories  changeover-step-duration';

  @media (max-width: 1300px) {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }

  .changeover-step-categories-widget {
    grid-area: changeover-step-categories;
  }

  .changeover-step-duration-widget {
    grid-area: changeover-step-duration;
  }
`;
