import React from 'react';
import { ViewContainer } from './View.elements';
import { MachineInformationForm } from './components/MachineInformationForm/MachineInformationForm';
import { RecipesForm } from './components/RecipesForm/RecipesForm';
import { ConfigurationForm } from './components/ConfigurationForm';

export const AdminView = (): JSX.Element => {
  return (
    <ViewContainer className="admin view-container">
      <MachineInformationForm />
      <RecipesForm />
      <ConfigurationForm />
    </ViewContainer>
  );
};
