import React from 'react';
import { useChartGenerator, useChartGeneratorData } from '../../hooks';
import { useChartSelections } from '../../hooks/useChartSelections';

export const LinePaths = (): JSX.Element => {
  const { getData, indexedData } = useChartGeneratorData();
  const { groupKey, getScale, colors } = useChartGenerator();

  const lineGroups = getData('groupByKey', { data: indexedData, key: groupKey });

  return (
    <>
      {Object.entries(lineGroups || {})?.map(([group, data]) => {
        const dataTS = data as Record<string, unknown>[] | undefined;
        const color = colors?.[group] || dataTS?.[0]?.color;
        const path = getScale('linePath', { data: data as Record<string, unknown>[] });
        return path && <LinePath key={group} {...{ group, data: dataTS, color }} />;
      })}
    </>
  );
};

export const LinePath = ({ data, group, color }: Record<string, unknown>): JSX.Element => {
  const selectHandler = useChartSelections()[1];

  const { groupKey, getScale, colors, colorKey, size, ...chartGeneratorProps } =
    useChartGenerator();

  const dataTS = data as Record<string, unknown>[] | undefined;
  const groupKeyToUse = (groupKey || 'group') as string;
  const groupIdToUse = (group || (dataTS?.[0]?.[groupKeyToUse] as string)) as string;
  const formattedGroupId = groupIdToUse ? String(groupIdToUse).split(' ').join('-') : undefined;

  const path = getScale('linePath', { data: data as Record<string, unknown>[] });

  color =
    color ||
    chartGeneratorProps?.color ||
    ((dataTS?.[0]?.[(colorKey as string) || 'color'] || colors?.[groupIdToUse] || 'red') as string);

  let className = `generated-line-path line-path group--${formattedGroupId}`;

  const isSelected = selectHandler('checkIfFiltered', { [groupKeyToUse]: groupIdToUse });
  if (isSelected) className += ' is-filtered';

  return (
    <path
      className={className as string}
      d={path as string}
      stroke={(color as string) || 'red'}
      fill="none"
      strokeWidth={size}
    />
  );
};

export const StackedBarChart = (): JSX.Element => {
  //const { getScale } = useChartGenerator();

  const className = `genrated-chart stacked-bar-chart-svg generated-stacked-bar-chart`;

  return (
    <>
      <div {...{ className }}>stacked bar chart</div>
    </>
  );
};
/*
<SVGContainer {...{ className, width, height }}>
    {chartType === 'lines' ? <LinePaths /> : <LinePath />}
  </SVGContainer>
      */
