import { useGetMachineHealthByBuKpiQuery } from 'api';
import { useLiveTimeFrame } from 'common/ui';
import { find } from 'lodash';
import { ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import { DSIKPIType, MachineHealthInterval, DSIKpiId } from 'types/dsi';
import { MachineAccountInfoQueryParams } from 'types/protein';

const showLiveLogs = true;

export const useProductTypeWidget = (): {
  isLoading?: boolean;
  hasError?: ReactNode;
  hasMessage?: ReactNode;
  data?: Record<string, unknown>[];
  [key: string]: unknown;
} => {
  const { machineId } = useParams<MachineAccountInfoQueryParams>();

  const { data, error, isLoading } = useGetMachineHealthByBuKpiQuery({
    machineId,
    kpiDataInterval: MachineHealthInterval.LastHour,
    widgetType: DSIKPIType.DsiCurrentKpi,
    limit: 100000,
    includeHistoricData: false,
    businessUnit: 'dsi'
  });

  // this hook automaically refreshes this api call every 60 seconds
  const live = useLiveTimeFrame(60000, { minutes: 60 });

  if (showLiveLogs) console.log({ live });

  const productType = find(data, (o) => o.id === DSIKpiId.ProductType);

  return {
    isLoading: isLoading && true,
    hasError: error && true,
    data: productType?.values as Record<string, unknown>[]
  };
};
