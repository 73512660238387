import styled from 'styled-components';

export const ViewContainer = styled.section`
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 1em;
  padding: 1em;

  .widget-ui {
    min-height: 350px;
  }

  .lane-alarms-widget {
    height: 300px;
  }

  .lane-state-widget {
    position: inherit;
  }
`;
