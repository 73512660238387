import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DebounceInput, Typography } from 'components';
import React, { KeyboardEvent, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BtnContainer,
  Highlight,
  SearchContainer,
  SearchItem,
  SearchList,
  SearchText
} from 'pages/Documentation/documentation.elements';
import { useComponentVisible } from 'common/components/DropdownTab';
import { IcoLink } from 'icons/IcoLink';
import CopyTextComponent from 'common/components/CopyLink';
import { useLazyGetFpsMachineDocFileQuery } from 'api';
import CustomLoader from 'components/CustomLoader';
import { CenterAlign } from 'common/components/common-styled';
export type SearchResult = {
  label?: string;
  value?: string;
};
type Props = {
  handleSearch: (value: string | number) => void;
  handleClick: (value: SearchResult) => void;
  searchResult?: SearchResult[];
  inputValue: string | number;
  isSearching: boolean;
  handleKeyPress?: (event: KeyboardEvent<HTMLInputElement>) => void;
};

export const highlightFirstMatch = (text: string, highlight: string): JSX.Element | string => {
  if (!highlight) return text;

  const index = text.toLowerCase().indexOf(highlight.toLowerCase());
  if (index === -1) return text;

  return (
    <>
      {text.substring(0, index)}
      <Highlight>{text.substring(index, index + highlight.length)}</Highlight>
      {text.substring(index + highlight.length)}
    </>
  );
};

const AsyncSearch = ({
  handleSearch,
  searchResult,
  handleClick,
  inputValue,
  isSearching,
  handleKeyPress
}: Props): ReactElement => {
  const { t } = useTranslation(['common']);

  const [showSuggesstions, setShowSuggesstions] = useState<boolean>();
  const [showLink, setShowLink] = useState<string | undefined>('');
  const [getSignedUrl, { isLoading, isFetching }] = useLazyGetFpsMachineDocFileQuery();

  const handleCopyText = async (doc_id: string) => {
    try {
      const resp = await getSignedUrl({ document_id: doc_id });
      if (document.hasFocus()) {
        navigator.clipboard.writeText(resp.data?.signedUrl || '');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const { ref } = useComponentVisible(setShowSuggesstions);
  return (
    <SearchContainer ref={ref}>
      <FontAwesomeIcon className="searchIcon" icon={faSearch} />
      <DebounceInput
        value={inputValue}
        onChange={handleSearch}
        placeholder={t('doc_search') as string}
        style={{
          paddingLeft: '1.5rem',
          marginBottom: '-.3rem',
          width: '14rem'
        }}
        className="globalSearchInput"
        onClick={() => setShowSuggesstions(true)}
        onKeyDown={handleKeyPress}
      />
      {showSuggesstions && (
        <SearchList padding="1rem 0">
          {isSearching ? (
            <CenterAlign>
              <CustomLoader size="2rem" thickness=".2rem" />
            </CenterAlign>
          ) : searchResult?.length || !inputValue ? (
            searchResult?.map((item) => {
              return (
                <SearchItem
                  onMouseEnter={() => setShowLink(item.value)}
                  onMouseLeave={() => setShowLink('')}
                >
                  <SearchText
                    onClick={() => {
                      handleClick(item);
                      setShowSuggesstions(false);
                    }}
                  >
                    {highlightFirstMatch(item.label || '', String(inputValue))}
                  </SearchText>
                  {showLink === item.value && (
                    <BtnContainer justifyContent="end">
                      <CopyTextComponent
                        icon={<IcoLink width="20" />}
                        isLoading={isLoading || isFetching}
                        handleCopyText={() => handleCopyText(item.value || '')}
                      />
                    </BtnContainer>
                  )}
                </SearchItem>
              );
            })
          ) : (
            <Typography weight="medium" className="emptyText">
              {t('data_not_found', { ns: 'fpns' })}
            </Typography>
          )}
        </SearchList>
      )}
    </SearchContainer>
  );
};

export default AsyncSearch;
