import React, { ReactNode, useMemo } from 'react';
import { UseApiDataProvider } from 'components/StyledUi/hooks';
import { useGetThresholdAlertsQuery } from 'api';
import { useParams } from 'react-router';
import { AlertAcknowledgeStatus, AlertStatus } from 'types/machine-health/alerts';
import { skipToken } from '@reduxjs/toolkit/dist/query';

interface Props {
  children?: ReactNode | ReactNode[];
  status?: string;
  start?: string;
  end?: string;
}

export const UseProteinMachineAlertsProvider = ({
  children,
  status,
  start,
  end
}: Props): JSX.Element => {
  const { machineId } = useParams<{ machineId: string }>();

  const ApiEndPoints = useMemo(() => {
    if (status === 'active') {
      return {
        apiStatus: AlertStatus.ACTIVE,
        secondApiStatus: AlertStatus.INACTIVE,
        acknowledgeStatus: undefined,
        secondAcknowledgeStatus: AlertAcknowledgeStatus.NOT_ACKNOWLEDGED,
        needsAcknowledge: undefined,
        secondNeedsAcknowledge: 'true'
      };
    } else if (status === 'historical') {
      return {
        apiStatus: AlertStatus.INACTIVE,
        secondApiStatus: AlertStatus.INACTIVE,
        acknowledgeStatus: AlertAcknowledgeStatus.ACKNOWLEDGED,
        secondAcknowledgeStatus: undefined,
        needsAcknowledge: undefined,
        secondNeedsAcknowledge: 'false'
      };
    }

    return {
      apiStatus: undefined,
      secondApiStatus: undefined,
      acknowledgeStatus: undefined,
      secondAcknowledgeStatus: undefined,
      needAcknowledge: undefined,
      secondNeedAcknowledge: undefined
    };
  }, [status]);

  // Fetch alerts from api
  const { data, isFetching, error, isLoading } = useGetThresholdAlertsQuery({
    machineId: machineId,
    status: ApiEndPoints.apiStatus,
    acknowledgmentStatus: ApiEndPoints.acknowledgeStatus,
    needsAcknowledgment: ApiEndPoints.needsAcknowledge,
    startDate: start,
    endDate: end
  });

  const {
    data: secondaryApi,
    isFetching: secondaryApiFetching,
    error: secondaryApiError,
    isLoading: secondaryApiLoading
  } = useGetThresholdAlertsQuery(
    status === undefined
      ? skipToken
      : {
          machineId: machineId,
          status: ApiEndPoints.secondApiStatus,
          acknowledgmentStatus: ApiEndPoints.secondAcknowledgeStatus,
          needsAcknowledgment: ApiEndPoints.secondNeedsAcknowledge,
          startDate: start,
          endDate: end
        }
  );

  const sortedByActiveAndStartTime = useMemo(() => {
    // checking if both API calling => status = active || status = historical
    if (data && secondaryApi) {
      const f = status === undefined ? [...data] : [...data, ...secondaryApi];
      return f.sort((a, b) => {
        // First, compare by status (ACTIVE before INACTIVE)
        if (a.status === 'ACTIVE' && b.status === 'INACTIVE') {
          return 1; // a (ACTIVE) comes before b (INACTIVE)
        }
        if (a.status === 'INACTIVE' && b.status === 'ACTIVE') {
          return -1; // b (ACTIVE) comes before a (INACTIVE)
        }

        // If statuses are the same or both are ACTIVE or both are INACTIVE, compare by start date
        return new Date(a.start).getTime() - new Date(b.start).getTime();
      });
    }

    // checking if status = all then don't include secondary api call for merge and sorting data.
    if (status === undefined) {
      if (data) {
        const f = [...data];
        return f.sort((a, b) => {
          // First, compare by status (ACTIVE before INACTIVE)
          if (a.status === 'ACTIVE' && b.status === 'INACTIVE') {
            return 1; // a (ACTIVE) comes before b (INACTIVE)
          }
          if (a.status === 'INACTIVE' && b.status === 'ACTIVE') {
            return -1; // b (ACTIVE) comes before a (INACTIVE)
          }

          // If statuses are the same or both are ACTIVE or both are INACTIVE, compare by start date
          return new Date(a.start).getTime() - new Date(b.start).getTime();
        });
      }
    }
  }, [data, secondaryApi, status]);

  const returnValue = {
    isLoading:
      isLoading || isFetching || secondaryApiFetching || secondaryApiLoading ? true : undefined,
    hasMessage: error || secondaryApiError ? 'there was an error getting data' : undefined,
    data: sortedByActiveAndStartTime
  };

  if (error) console.log('useProteinMachineAlerts', { error });

  return <UseApiDataProvider {...returnValue}>{children}</UseApiDataProvider>;
};
