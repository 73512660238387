export const generateLegendItems = ({
  data,
  colors,
  groupKey
}: {
  data?: Record<string, unknown>[];
  colors?: Record<string, string>;
  groupKey?: string;
}): Record<string, unknown>[] | undefined => {
  if (!data || !groupKey) return undefined;

  const legendItems = data.reduce(
    (acc: Record<string, Record<string, unknown>>, item: Record<string, unknown>) => {
      const groupKeyToUse = (item.groupKey || groupKey) as string;
      if (!groupKeyToUse) return acc;
      const group = item?.[groupKeyToUse] as string;
      const color = item?.color || colors?.[group as string];

      if (!acc?.[group]) {
        acc[group] = {
          [groupKey]: group,
          groupKey,
          color
        };
      }

      return acc;
    },
    {}
  );

  return Object.values(legendItems);
};
