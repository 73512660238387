import { ReactNode, useEffect, useState } from 'react';
import { useGetAsepticMachineHealthKpiQuery } from 'api';
import { useRouter } from 'common/hooks/useRouter';
import { fleetPageRoute } from 'common/pages/fleetV2/settings/fleetPageRoutes';
import { AsepticMachineHealthInterval, WidgetType } from 'types/machine-health';

let startRun: string | number | undefined = undefined;

const GetData = () => {
  const { id } = useRouter(fleetPageRoute) as { id: string };
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newTime = refresh + 1;
      setRefresh(newTime); // update the state every 30 seconds
    }, 30000); // 30000 milliseconds = 30 seconds
    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array means this effect runs once on mount and clean up on unmount

  const api = useGetAsepticMachineHealthKpiQuery({
    machineId: id,
    kpiDataInterval: AsepticMachineHealthInterval.Last24Hours,
    widgetType: WidgetType.MACHINE_INFO,
    limit: 100000,
    includeHistoricData: false
  });

  return api;
};

export const useMachineOverviewWidget = (): {
  isLoading?: boolean;
  hasError?: ReactNode;
  hasMessage?: ReactNode;
  isRunning?: boolean;
  recipe_name?: string;
  approvedbottles?: string | number;
  run_length_in_seconds?: number;
  [key: string]: unknown;
} => {
  // we get the first run length from the api call

  const { currentData, error, isLoading } = GetData();

  // check if this is in demo mode
  const dataToUse = currentData;

  if (error) console.log('overview', { error });

  const hasError = error ? true : undefined;
  const hasMessage = !isLoading && !hasError && !currentData ? true : undefined;

  // generate object based on return values to spread over the return
  const machineInfo = dataToUse?.reduce((acc: Record<string, unknown>, item) => {
    const id = item?.id;
    const value = item?.value?.value || `0`;

    if (id && value) {
      if (!acc?.[id]) acc = { ...acc, [id]: `${String(value).toLowerCase()}` };
    }

    return acc;
  }, {});

  let isRunning = undefined;

  if (machineInfo?.run_length_in_seconds) {
    const start = machineInfo?.run_length_in_seconds as string;
    if (!startRun) startRun = start;
    else {
      if (startRun !== start) {
        isRunning = true;
        startRun = start;
      } else {
        isRunning = false;
      }
    }
  }

  return {
    hasError,
    hasMessage,
    isRunning,
    isLoading,
    ...machineInfo
  };
};
