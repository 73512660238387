import React from 'react';
import { useMachineStateAsepticContext } from './provider';
import { useMachineInfo } from 'common/pages/fleetV2/providers';
import { ActualDetails, MiddleProps, TargetDetails } from './types';
import { formatDataForChart } from './utils';
import { LaneStatesViewContainer } from './elements';
import { WidgetUi } from 'common/ui';
import { MemoizedWrapper } from './Wrapper';
import { NoData } from 'common/ui/StatesHorizontalBarChart/NoData';

export const Provided = (): JSX.Element => {
  const { data, isLoading, hasError, hasMessage, currentSession } = useMachineStateAsepticContext();

  const middleSettings = {
    data,
    isLoading,
    hasError,
    hasMessage,
    currentSession
  };

  return (
    <WidgetUi
      title="Lane status"
      className="lane-state-widget"
      isLoading={isLoading}
      hasError={hasError}
      hasMessage={hasMessage}
      Main={<Middle middleData={middleSettings} />}
    />
  );
};

const Middle = ({ middleData }: MiddleProps): JSX.Element => {
  const { data, isLoading, hasError, hasMessage, currentSession } = middleData;

  const { timeZone } = useMachineInfo();
  const timezone = timeZone || 'UTC';

  if (isLoading) return <>Loading...</>;
  if (hasError) return <>There has been an error loading data</>;
  if (hasMessage) return <>{hasMessage}</>;

  if (!currentSession) return <NoData />;

  const {
    actualValues,
    targetValues,
    redLinedData,
    targetDurationSumByMode,
    actualDurationSumByMode
  } = formatDataForChart(
    data as { actual_details: ActualDetails[]; target_details: TargetDetails[] },
    timezone
  );

  const settings = {
    data: {
      ...data,
      actual_details: actualValues,
      target_details: targetValues,
      actualAndTargetValues: redLinedData,
      targetDataSummary: targetDurationSumByMode,
      actualDataSummary: actualDurationSumByMode
    },
    currentSession
  };

  return (
    <LaneStatesViewContainer className="lane-states-view">
      <div
        className={`lane-states-main ${
          !data || data?.actual_details.length === 0 ? `no_data` : undefined
        }`}
      >
        <MemoizedWrapper {...settings} />
      </div>
    </LaneStatesViewContainer>
  );
};
