import React from 'react';
import { useChartGenerator } from '../../hooks';
import { PieChart } from './PieChart';
import { LineChart } from './LineChart/LineChart';
import { StackedBarChart } from '../../components/BarChart/StackedBarChart';

export const ChartArea = (): JSX.Element => {
  const { chartType } = useChartGenerator();

  switch (chartType) {
    case 'lines':
    case 'line':
      return <LineChart />;

    case 'pie':
      return <PieChart hasHoverLayer />;

    case 'stacked-bar':
      return <StackedBarChart />;

    default:
      return <div>invalide chart type in ChartArea</div>;
  }
};
