import React from 'react';
import { StepsTable } from './StepsTable';
import { WidgetUi } from 'common/ui';
import { useChangeoverSessionDetailsAPI } from '../../../../providers/useChangeoverSessionDetailsAPIPovider';
import { toCleaningStepWithKPI } from './utils';
import { AsepticChangeoverType } from 'types/machine-health/aseptic';

export const SignelSessionProgramStepsTable = (): JSX.Element => {
  const {
    data,
    hasError,
    hasMessage,
    isLoading: isLoadingSession
  } = useChangeoverSessionDetailsAPI();

  const tableData = toCleaningStepWithKPI(data as AsepticChangeoverType | undefined);
  const widgetSettings = {
    className: 'changeover-table-widget',
    title: 'Changeover Steps',
    isLoading: isLoadingSession,
    hasError,
    hasMessage
  };

  return <WidgetUi {...widgetSettings} Main={<StepsTable data={tableData} />} />;
};
