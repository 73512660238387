import React from 'react';

import { StyledUiContainerProps } from 'components';
import { BarChartWidget } from 'common/components';
import { formatZonedTimestamp } from 'common/helpers/dateAndTimeHelpersV2';

import { useMachineUtilizationWidget } from './hooks/useMachineUtilizationWidget';
import { ChartTooltipItem } from '../OEEWidget/OEEWidget.elements';
import { WidgetDateRangeProvider } from 'common/ui';
import { useMachineInfo } from '../../../../../../providers';

export const MachineUtilizationWidget = (props: StyledUiContainerProps): JSX.Element => {
  const { timeZone } = useMachineInfo();
  return (
    <WidgetDateRangeProvider {...{ timeZone }} subtractDays={7} defaultLabel="Last 7 Days">
      <Provided {...props} />
    </WidgetDateRangeProvider>
  );
};

const Provided = (props: StyledUiContainerProps): JSX.Element => {
  const {
    chartData,
    isLoading,
    hasMessage,
    hasError,
    legendItems,
    groupedDataForTooltips,
    colors,
    timeZone
  } = useMachineUtilizationWidget();

  const Tooltip = (props?: Record<string, unknown>) => {
    if (!props) return <></>;

    const formattedDate =
      timeZone && formatZonedTimestamp(props?.category as string, timeZone, 'MMM d, yyyy');

    return (
      <>
        <div className="tooltip__title">{formattedDate || ''}</div>
        {groupedDataForTooltips?.[props?.category as string]
          ?.map((item?: Record<string, unknown>) => (
            <ChartTooltipItem
              className="item"
              key={item?.group as string}
              color={colors?.[item?.group as string]}
            >
              <span>
                {item?.group} - <strong>{item?.value}%</strong>
              </span>
            </ChartTooltipItem>
          ))
          .sort((a, b) => Number(a.value) < Number(b.value))}
      </>
    );
  };

  const widgetSettings = {
    className: 'machine-utilization-widget',
    title: 'Machine Utilization',
    hasDateFooter: true,
    hasDateRanges: {
      'Last 3 Days': {
        subtractDays: 2
      },
      'Last 7 Days': {
        subtractDays: 6
      },
      'Last 14 Days': {
        subtractDays: 13
      }
    },
    isLoading: isLoading,
    hasError,
    hasMessage,
    legendItems: legendItems as { label?: string; value?: string; color?: string }[] | undefined,
    ...props
  };

  const chartSettings = {
    colors,
    chartData,
    format: {
      bottomTicks: (label?: string | number) =>
        label ? formatZonedTimestamp(label as string, timeZone, 'MMM dd') : '',
      leftTicks: (label?: number | string) => (label ? `${label === 0 ? '0' : label}%` : '')
    },
    domains: {
      y: [0, 25, 50, 75, 100]
    },
    Tooltip
  };

  return <BarChartWidget {...widgetSettings} {...chartSettings} />;
};
