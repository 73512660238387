import React, { useMemo } from 'react';
import { useMachineInfo } from 'common/pages/fleetV2/providers';
import { DatepickerBar } from 'common/pages/fleet/machine/proseal/MachineProduction/components/DatepickerBar/DatepickerBar';
import { useDataManagementProvider } from '../../hooks/useDataManagement';

export const DatepickerBarWrapper = (): JSX.Element => {
  const { timeZone } = useMachineInfo();
  const { handleResetButton } = useDataManagementProvider();

  const datepickerMemo = useMemo(() => {
    const settingsDaterange = {
      showDaterange: true,
      timeZone,
      handleReset: handleResetButton
    };

    return <DatepickerBar {...settingsDaterange} />;
  }, [handleResetButton]);

  return <>{datepickerMemo}</>;
};
