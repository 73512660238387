//import * as d3  from "d3"
import * as d3 from 'd3';
import { AxisItemProps } from '../';
import { generateChartKeys } from './generateChartKeys';

export interface GeneratePointChartLineProps {
  d?: string;
  color?: string;
  [key: string]: unknown;
}

export const generatePointChart = (
  data: Record<string, unknown>[],
  {
    width,
    height,
    categoryKey,
    groupKey,
    valueKey,
    marginLeft,
    marginBottom,
    marginTop,
    marginRight,
    colors,
    domainX,
    domainY
  }: {
    width?: number;
    height?: number;
    categoryKey?: string;
    groupKey?: string;
    valueKey?: string;
    marginLeft?: number;
    marginRight?: number;
    marginTop?: number;
    marginBottom?: number;
    colors?: Record<string, string>;
    domainY?: string[] | number[];
    domainX?: string[] | number[];
  }
): [
  AxisItemProps[],
  AxisItemProps[],
  AxisItemProps[],
  { x?: number[]; y?: number[] },
  GeneratePointChartLineProps[] | undefined
] => {
  width = width || 0;
  height = height || 0;

  if (width === 0 || height === 0) return [[], [], [], {}, []];

  marginLeft = marginLeft || 0;
  marginBottom = marginBottom || 0;
  marginTop = marginTop || 0;
  marginRight = marginRight || 0;

  groupKey = groupKey || 'group';
  categoryKey = categoryKey || 'category';
  valueKey = valueKey || 'value';

  const chartHeight = height - (marginBottom + marginTop);
  const chartWidth = width - (marginLeft + marginRight);

  const categories = generateChartKeys(data, categoryKey);

  const x = d3
    .scalePoint()
    .domain((domainX as string[]) || categories)
    .rangeRound([0, chartWidth]);

  const y = d3
    .scaleLinear()
    .domain([
      0,
      domainY
        ? (domainY[domainY.length - 1] as number)
        : d3.max(data, (d) => d?.[valueKey as string] as number) || 0
    ])
    .rangeRound([chartHeight, 0]);

  let lineGroups = {};
  data?.forEach((d) => {
    if (!lineGroups?.[d?.[groupKey as string] as string]) {
      lineGroups = { ...lineGroups, [d?.[groupKey as string] as string]: [] };
    }
    lineGroups[d?.[groupKey as string] as string].push(d);
  });

  const lines: string[] = [];

  Object.values(lineGroups).forEach((d) => {
    const group = d[0]?.group;
    const cat = d[0]?.category;
    const color = d[0]?.color || colors?.[group as string] || 'black';

    const path = d3
      .line()
      .x((d) => x(d?.category as string) as number)
      .y((d) => y(d?.value as number) as number)(d);

    const line = path;

    lines.push({
      d: line as string,
      category: cat,
      group: group,
      color
    });
  });

  const leftTicks = domainY || y.ticks();
  const bottomTicks = x.domain();

  const gridX: number[] = [];
  const gridY: number[] = [];

  const axisLeft: AxisItemProps[] = leftTicks.map((val) => {
    gridY.push(y(val as number));
    return {
      x: marginLeft,
      y: y(val as number),
      label: `${val}`,
      width: marginLeft
    };
  });

  const axisBottom: AxisItemProps[] = bottomTicks.map((val) => {
    gridX.push(x(val) as number);
    return {
      x: x(val as string) as number,
      y: 0,
      label: `${val}`,
      width: x.bandwidth()
    };
  });

  const points = data?.reduce((acc: AxisItemProps[], d) => {
    acc = [
      ...acc,
      {
        x: (x(d?.[categoryKey as string] as string) as number) || 0,
        y: (y(d?.[valueKey as string] as number) as number) || 0,
        ...d
      }
    ];
    return acc;
  }, []);

  return [points, axisLeft, axisBottom, { x: gridX, y: gridY }, lines];
};
