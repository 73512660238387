export const useKPIApi = (): Record<string, unknown> => {
  const data = {
    current: 99,
    target: 92,
    unit: '%',
    averages: {
      last_minute: 100,
      current_day: 94,
      last_hour: 96
    }
  };

  const { current, target, unit, averages } = data as Record<string, unknown>;
  const { last_minute, current_day } = averages as Record<string, unknown>;

  const kpiRows = [
    {
      label: 'average last minute',
      value: last_minute,
      unit
    },
    {
      label: 'average current day',
      value: current_day,
      unit
    },
    {
      label: 'target',
      value: target,
      unit
    }
  ];

  return { current, target, unit, kpiRows };
};
