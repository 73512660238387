// 3rd party libs
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components
import {
  MachineUtilization,
  CleaningKpi,
  MachineKpi,
  ExtendedMachineKpi,
  MachineIndicatorsGraph
} from 'components/machine-health';
import { AccumulatedValues, DateRangeProvider, Flyout } from 'components';

import { DateProvider, useTimeZone } from 'providers';

import { SUB_ROUTES } from '..';
import moment from 'moment';
import { toISO8601 } from 'helpers';
import { NewMachineActiveIssues } from 'components/machine-health/MachineActiveIssues';
import { MachineHealthSubTabs, ProteinMachineRouteQueryParams } from 'types/protein';
import { useGetMachineConfiguratorDataQuery } from 'api';

// Split the overview page into 2 containers <Kpis /> and <IndicatorsHistory />
const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.375rem 3.25rem;
`;

// Contains <MachineKpi /> and <MachineMetrics />
const Kpis = styled.div`
  display: flex;
`;

// Contains 2 <SubMatrics />
const MachineMetrics = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

// Contains 2 <Cards />
const SubMatrics = styled.div`
  height: 24rem;
  display: flex;
  gap: 1rem;
  & > div {
    flex: 1;
  }
`;

const Metrics = styled.div`
  height: 24rem;
  display: flex;
  gap: 1rem;
  & > div {
    flex: 1;
  }
  & > div:first-child {
    flex: 0 0 43%;
  }
`;

const IndicatorsHistory = styled.div`
  margin: 1rem 0;
`;

const Overview = (): JSX.Element => {
  const history = useHistory();
  const { timeZone } = useTimeZone();
  const [flyout, setFlyout] = useState(false);
  const { t } = useTranslation(['mh']);
  const startDatetime = useMemo(() => moment().subtract(13, 'days').endOf('day').toDate(), []);
  const endDatetime = useMemo(() => moment().toDate(), []);
  const { machineId } = useParams<ProteinMachineRouteQueryParams>();

  const graph = useMemo(() => {
    return (
      <DateProvider context={{ startTime: startDatetime, endTime: endDatetime }}>
        <MachineIndicatorsGraph />
      </DateProvider>
    );
  }, []);

  const { data: thresholdAlertWidgetDetails } = useGetMachineConfiguratorDataQuery({
    machineId,
    labels: [MachineHealthSubTabs.OverviewAlerts]
  });

  return (
    <>
      <Container>
        <Kpis>
          <MachineMetrics>
            <Metrics>
              <DateRangeProvider subtractDaysCount={1} timeZone={timeZone}>
                <MachineKpi
                  onClick={() => setFlyout(true)}
                  startTime={toISO8601(startDatetime)}
                  endTime={toISO8601(endDatetime)}
                />
              </DateRangeProvider>
              <NewMachineActiveIssues
                linksToPath={`${SUB_ROUTES.alarms}`}
                tooltipContent={t('go_to_alarms') as string}
                location={
                  thresholdAlertWidgetDetails && thresholdAlertWidgetDetails.length !== 0
                    ? thresholdAlertWidgetDetails[0]?.id
                    : ''
                }
              />
            </Metrics>
            <Metrics>
              <MachineUtilization />
              <SubMatrics>
                <DateRangeProvider subtractDaysCount={1} timeZone={timeZone}>
                  <AccumulatedValues />
                </DateRangeProvider>
                <CleaningKpi
                  onClick={() => history.push(`${SUB_ROUTES.cleaning}`)}
                  linksToPath={`${SUB_ROUTES.cleaning}`}
                  tooltipContent={t('go_to_cleaning') as string}
                />
              </SubMatrics>
            </Metrics>
          </MachineMetrics>
        </Kpis>
        <IndicatorsHistory>{graph}</IndicatorsHistory>
      </Container>
      <Flyout noFocusTrap width="28.125rem" visible={flyout} onClose={() => setFlyout(false)}>
        <ExtendedMachineKpi close={() => setFlyout(false)} />
      </Flyout>
    </>
  );
};

export default Overview;
