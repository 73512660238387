import styled from 'styled-components';
import theme from 'common/theme';

export const MachineOverviewWidgetContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-template-rows: 1fr auto;
  column-gap: 2em;
  row-gap: 3em;
  min-height: initial;

  .image-col {
    align-self: center;
    grid-column: 1;
    grid-row: 1 / span 2;
  }

  .split-list {
    padding-right: 1em;
    &.is-scrollable {
      max-height: 200px;
      padding-right: 1em;
    }
  }

  img.full {
    width: 100%;
    max-height: 100%;
    height: auto;
  }

  .widget-group {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 1em;
    font-size: 0.9em;

    .icon-area {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.5em;
      width: 2em;
      height: 2em;
      border-radius: 50%;

      &.calibration-health {
        background: ${theme.colors.Telemetry700};
      }

      &.cumulative-finished-goods {
        background: ${theme.colors.Lila};
      }

      &.product-setup {
        background: ${theme.colors.jbtBlue};
      }
    }
  }

  .machine-image {
    grid-column: 1;
    grid-row: 1;
    align-self: center;
  }

  .widget-group-1 {
    grid-template-rows: auto;
    grid-column: 2;
    grid-row: 1;
  }

  .widget-group-2 {
    grid-column: 2;
    grid-row: 2;
  }

  .progress-bar-group {
    grid-column: 1;
    grid-row: 2;
  }

  .split-list {
    .split-list-value {
      color: ${theme.colors.label};
      font-size: 0.9em;
    }
    dd {
      display: flex;
      gap: 0.35em;
    }
  }

  .group-header {
    font-size: 1.1em;
    margin-bottom: 0.5em;
    font-weight: 600;
    display: flex;
    align-self: end;
    border-bottom: none;
    padding: 0;
    text-transform: capitalize;

    &.group-title {
      font-size: 1.1em;
    }
  }

  .colored-label {
    color: ${theme.colors.jbtBlue};
  }

  .finished-goods-title {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 1em;
  }

  .grid {
    display: grid;
    grid-template-columns: auto 1fr 1fr auto;
    grid-auto-rows: auto;
    grid-auto-flow: row;
    grid-row-gap: 0.5em;
    font-weight: 500;

    .value,
    .title {
      color: ${theme.colors.label};
    }

    .title {
      border-bottom: solid 1px ${theme.colors.border.main};
      padding-bottom: 0.5em;
    }

    .label {
      padding-right: 1em;
    }

    .label,
    .title {
      text-transform: capitalize;
    }

    .total {
      color: ${theme.colors.main};
    }
  }
`;
