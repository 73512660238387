// 3rd party libs
import React, { useLayoutEffect, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { find } from 'lodash';
import { format, parseISO } from 'date-fns';

// Components
import { PageTabView } from 'components';
import { AdminCardEditButton } from 'components/machine-health';
import { HistoricRecipeTable } from '../HistoricRecipeTable/HistoricRecipeTable';
import { HistoricRecipesProvider } from '../useHistoricRecipes';
// Types
import { PermissionScopeName } from 'types/user-management';
import { defaultLabelStyles } from 'components/StyledUi/StyledChartsV2/StyledCharts.styles';
import { UseStyledChartProps } from 'components/StyledUi/StyledChartsV2/hooks';
import { UiChartsAndFiltersPage } from 'components/StyledUi/StyledChartsV2/UIChartsAndFiltersPage/UiChartsAndFiltersPage';

//Providers
import { DateProvider } from 'providers';

// Hooks & Providers
import { usePermissions } from 'hooks';
import { useHistoricRecipes } from '../useHistoricRecipes';
import { useDateRange } from 'components';
import { useTranslation } from 'react-i18next';
import { chartValueRGBA } from 'common/components';

// Styling
const HistoricRecipesContainer = styled.div`
  padding: 1rem 2rem;
  grid-gap: 1em;
  display: grid;
  grid-template-rows: auto 1fr;

  .alarms-view__date-picker {
    display: flex;
    justify-content: flex-end;
  }
`;

export const HistoricRecipes = (): JSX.Element => {
  return (
    <HistoricRecipesProvider>
      <HistoricRecipesProvided />
    </HistoricRecipesProvider>
  );
};

//------------------------------------------------//----/
// CHARTS
//------------------------------------------------//----/
const HistoricRecipesProvided = (): JSX.Element => {
  const { t } = useTranslation(['mh']);

  // bar chart over time - grouped by `type` and stacked by `date`
  const chartAlarmsOverTime: UseStyledChartProps = {
    type: 'stacked-bar-over-time',
    groupKey: 'activerecipe',
    categoryKey: 'date',
    title: t('Recipes by day') as string,
    filteredByKeys: ['activerecipe'],
    format: {
      tooltip: (d: Record<string, unknown>) => {
        const datum = d?.datum as Record<string, unknown>;
        const dateKey = (d.dateKey as string) || 'date';
        const date = datum[dateKey];
        const group = datum[(d.groupKey as string) || 'type'];
        return [`${date}`, ` \n `, `${group}: ${datum.count}`];
      }
    },
    tooltipStyles: [
      { ...defaultLabelStyles, fontSize: 13, fillOpacity: 0.7 },
      { fontSize: 5 },
      { ...defaultLabelStyles, fontSize: 16, fontWeight: 500 }
    ]
  };

  // pie chart with legend - grouped by `type`
  const chartAlarmsByType: UseStyledChartProps = {
    //colors: alarmsColors,
    type: 'pie',
    groupKey: 'activerecipe',
    title: t('Recipes Distribution') as string,
    format: {
      label: (d: Record<string, unknown>) => {
        const datum = d?.datum as Record<string, unknown>;
        return `${Math.round(Number(datum.y))}%`;
      },
      tooltip: (d: Record<string, unknown>) => {
        const datum = d?.datum as Record<string, unknown>;

        return [
          `${datum.x === 'undefined' ? 'Undefined' : datum.x}: ${datum.count}`,
          ` \n `,
          `${Math.round(Number(datum.y))}%`
        ];
      }
    },
    tooltipStyles: [
      defaultLabelStyles,
      { fontSize: 5 },
      { ...defaultLabelStyles, fontSize: 16, fontWeight: 500 }
    ]
  };

  const permission = usePermissions();

  const scopePermission = find(
    permission?.scopes,
    (scopeItem) => scopeItem.name === PermissionScopeName.FLEET
  );
  const [isEditAdminPopupOpen, setIsEditAdminModalOpen] = useState(false);

  const handleAdminModalToggle = () => setIsEditAdminModalOpen((prev) => !prev);

  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    ref.current && setWidth(ref.current?.clientWidth);
  }, []);

  useLayoutEffect(() => {
    ref.current && setWidth(ref.current?.clientWidth);
  }, []);

  const { widgetId, machineId, tableValues, isLoading, hasError, hasMessage } =
    useHistoricRecipes();

  const { startTime, endTime } = useDateRange().dateRange;

  const isAdmin = widgetId && (scopePermission?.write || permission.isAdmin);

  const { isoDateRange, dateRange } = useDateRange();

  const start = format(parseISO(isoDateRange.startTime), 'P');
  const end = format(parseISO(isoDateRange.endTime), 'P');

  const csvFileSettings = {
    data: tableValues,
    fileName: `recipes--${start}-to-${end}.csv`
  };

  const newTableValues = tableValues?.map((x) => {
    const date = x.date as string;
    const [m, d, y] = date.split('/');
    return { ...x, date: `${y}-${m}-${d}` };
  });

  const tableProps = {
    outerTableWidth: width,
    isAdmin,
    handleAdminModalToggle: () => handleAdminModalToggle()
  };

  const formattedTableValues = newTableValues?.map((item) => ({
    ...item,
    type: item?.activerecipe || ''
  }));

  const uniqueLegendItems =
    newTableValues && new Set(newTableValues.map((item) => item?.activerecipe));

  const customLegends =
    uniqueLegendItems &&
    [...uniqueLegendItems]?.map((item) => ({
      label: item,
      color: chartValueRGBA(item) || '#FF0000'
    }));

  const tooltip = (record: Record<string, unknown>) => {
    return (
      <>
        <div className="tooltip__label">{`${t(String(record.label))}: ${record.count}`}</div>
        <div className="pie-chart-tooltip__value">
          {Math.round(record.value as number)} <span className="small-text">%</span>
        </div>
      </>
    );
  };

  const centerSubText = (text: string) => `${text}`;

  return (
    <DateProvider context={{ startTime, endTime }}>
      <PageTabView>
        <HistoricRecipesContainer>
          {newTableValues && (
            <UiChartsAndFiltersPage
              data={formattedTableValues}
              charts={[chartAlarmsOverTime, chartAlarmsByType]}
              dateRange={dateRange || undefined}
              csvSettings={csvFileSettings}
              usesFilteredData
              {...{ isLoading, hasError, hasMessage }}
              isProteinMachine={true}
              hideLegend={false}
              tooltip={tooltip}
              centerSubText={centerSubText}
              customLegends={customLegends}
            >
              <HistoricRecipeTable {...tableProps} />
            </UiChartsAndFiltersPage>
          )}
        </HistoricRecipesContainer>

        {widgetId && machineId && (
          <AdminCardEditButton
            isEditAdminPopupOpen={isEditAdminPopupOpen}
            setIsEditAdminPopupOpen={handleAdminModalToggle}
            widgetId={widgetId}
            machineId={machineId}
          />
        )}
      </PageTabView>
    </DateProvider>
  );
};

export default HistoricRecipes;
