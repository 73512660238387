import React, { useMemo } from 'react';
import { ViewContainer } from './View.elements';
import {
  fleetPageRoute,
  fleetPageVersionPath
} from 'common/pages/fleetV2/settings/fleetPageRoutes';
import { OverviewView } from './views/Overview/OverviewView';
import { ChangeoverView } from './views/Changeover/ChangeoverView';
import { useHistory } from 'react-router-dom';
import { RouterTabsNav } from 'common/components';
import { useFleetRouter } from 'common/pages/fleetV2/hooks/useFleetRouter';

const TABS = {
  overview: <OverviewView />,
  changeover: <ChangeoverView />
};

const ViewRoutes = (): JSX.Element => {
  const [{ id, tab }] = useFleetRouter();
  const history = useHistory();

  // load overview as default tab, this should be done higher up in the component tree though
  if (!tab && id) history.push(`/${fleetPageVersionPath}/${id}/machine-health/overview`);

  const CachedRoute = useMemo(() => {
    if (tab === 'overview') return TABS.overview;
    if (tab === 'changeover') return TABS.changeover;
    return <div>Changover View 404</div>;
  }, [tab, id]);

  return CachedRoute;
};

export const ViewTabs = (): JSX.Element => {
  const { id } = useFleetRouter()[0];

  const tabsNavSettings: Record<string, unknown> = {
    tabs: [
      {
        label: 'Overview',
        // we're just sending the full path
        path: `/${fleetPageVersionPath}/${id}/machine-health/overview`,
        // this is value of the pathKey in the url, it it used to check if current
        slug: 'overview'
      },
      {
        label: 'Changeover',
        // we're just sending the full path
        path: `/${fleetPageVersionPath}/${id}/machine-health/changeover/single-session`,
        // this is value of the pathKey in the url, it it used to check if current
        slug: 'changeover'
      }
    ],
    // the route for this page, you can use custom as long as you're the value
    // of the current tab from the url
    pageRoute: fleetPageRoute,
    // this is the key to get the position of the tab value from the url to compare if current tab is active
    // and for the link
    pathKey: 'tab'
    // basePath
  };

  const CachedTabs = useMemo(() => <RouterTabsNav {...tabsNavSettings} />, [id]);

  return CachedTabs;
};

export const MachineHealthView = (): JSX.Element => {
  return (
    <ViewContainer className="machine-health-view view-container">
      <ViewTabs />
      <ViewRoutes />
    </ViewContainer>
  );
};

/*
<MachineUtilizationAPIProvider>
  <ViewRoutes />
</MachineUtilizationAPIProvider>
*/
