import styled from 'styled-components';

export const PieChartTooltipContainer = styled.div<{ color?: string }>`
  .totals-container {
    text-transform: capitalize;
    font-size: 1.2em;
  }

  .totals-container-row {
    display: flex;
    gap: 0.5em;
    justify-content: space-between;
  }
`;

export const ColoredLabelContainer = styled.div<{ color?: string }>`
  ${({ color }) =>
    color
      ? `
    
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: .3em;
    align-items: center;

    &:before {
      content: '';
      display: block;
      width: .7em;
      height: .7em;
      border-radius: 50%;
      background-color: ${color};
    }`
      : `
      display: block;
    `}
`;
