import { format } from 'date-fns';
import { secondsToHms } from '../../ActualDurationWidget/utils';
import { getUserTimeZone, isUSTimeZone } from 'helpers';
import {
  ActualDetails,
  ActualDetailsTransformed,
  DurationSumByMode,
  Redlines,
  RedlinesData,
  TargetDetails,
  TargetDetailsTransformed
} from '../types';
import { utcToZonedTime } from 'date-fns-tz';
import { colors } from 'common/pages/fleetV2/settings/aseptic';

export const COLORMAP = {
  Idle: colors['idle'],
  Sterilization: colors['sterilization'],
  Sterilisation: colors['sterilization'],
  Cleaning: colors['cleaning'],
  Waittime: colors['wait time'],
  Maintenance: colors['maintenance'],
  Mainteance: colors['maintenance'] //misspelled version coming from BE
} as Record<string, string>;

//This color map is for hover colors for bar chart
export const HOVERCOLORMAP = {
  Idle: '#737270',
  Sterilization: '#018798',
  Sterilisation: '#018798',
  Cleaning: '#0164ac',
  Waittime: '#9c260e',
  Maintenance: '#cd6031',
  Mainteance: '#cd6031' //misspelled version coming from BE
} as Record<string, string>;

export const calculateTotalDuration = (subset: Redlines[]): number => {
  return subset.reduce(
    (total: number, { start_time, end_time }: { start_time: Date; end_time: Date }) => {
      const startTime = new Date(start_time).getTime();
      const endTime = new Date(end_time).getTime();
      return total + (endTime - startTime);
    },
    0
  );
};

export function timeToSeconds(time: string): number {
  // Split the time string into [hours, minutes, seconds]
  const parts = time.split(':');

  // Convert each part to seconds and add them together
  // Note: parts[0] is hours, parts[1] is minutes, parts[2] is seconds
  const seconds =
    parseInt(parts[0], 10) * 3600 + parseInt(parts[1], 10) * 60 + parseInt(parts[2], 10);

  return seconds;
}

export const displayTooltip = (tooltipData: Record<string, unknown>): string => {
  const data = tooltipData.data;

  const { start_time, end_time, mode_descr, recipe_descr, duration } = data as {
    start_time: Date;
    end_time: Date;
    mode_descr: string;
    recipe_descr: string;
    duration: number;
  };

  const isUSA = isUSTimeZone(getUserTimeZone()); //Affects time formatting AM/PM vs 14:00

  const startDate = format(start_time, 'MMM do, y');
  const endDate = format(end_time, 'MMM do, y');

  const formattedDate =
    startDate === endDate
      ? startDate
      : `${startDate.split(',')[0]} - ${endDate.split(',')[0]}, ${startDate.split(',')[1]}`;

  const startTime = format(start_time, isUSA ? 'hh:mm a' : 'HH:mm');
  const endTime = format(end_time, isUSA ? 'hh:mm a' : 'HH:mm');

  return `<div class="tooltip--inner">
            <div class='tooltip--header'>${formattedDate}</div>
            <div class='tooltip--subheader'>
              <div class='tooltip--subheader time'>Time - &nbsp;<b>${startTime}-${endTime}</b></div>
              <div class='tooltip--subheader duration'>Duration - &nbsp;<b>${secondsToHms(
                duration / 1000,
                false
              )}</b></div>
            </div>
            <div class='tooltip--line-item'>
              <span class='color-indicator' style='background-color: ${
                COLORMAP[mode_descr]
              }'></span>
              ${mode_descr} - <b>${recipe_descr}</b>
            </div>
          </div>`;
};

export const formatDataForChart = (
  data: {
    actual_details: ActualDetails[];
    target_details: TargetDetails[];
  },
  timezone: string
): {
  actualValues?: ActualDetailsTransformed[];
  targetValues?: TargetDetailsTransformed[];
  redLinedData?: RedlinesData[];
  targetDurationSumByMode?: Record<string, DurationSumByMode>;
  actualDurationSumByMode?: Record<string, DurationSumByMode>;
} => {
  const { actual_details, target_details } = data as {
    actual_details: ActualDetails[];
    target_details: TargetDetails[];
  };

  // 1. DATA TRANSFORMATION FOR CHART
  //-----
  //Transofrms dates into Date object
  const actualValues = actual_details?.reduce(
    (accumulator: ActualDetailsTransformed[], currentValue: ActualDetails) => {
      const zonedStart = utcToZonedTime(new Date(currentValue.start_time as string), timezone);
      const zonedEnd = utcToZonedTime(new Date(currentValue.end_time as string), timezone);
      const curr = {
        ...currentValue,
        start_time: zonedStart,
        end_time: zonedEnd,
        data_type: 'actual_details',
        duration: zonedEnd.getTime() - zonedStart.getTime(), // duration is in MILLISECONDS
        target_duration: currentValue.target_duration * 1000, //converts seconds to milliseconds
        color: COLORMAP[currentValue.mode_descr],
        colorHover: HOVERCOLORMAP[currentValue.mode_descr]
      };
      accumulator.push(curr);
      return accumulator;
    },
    []
  );
  //Transofrms dates into Date object
  const targetValues = target_details?.reduce(
    (accumulator: TargetDetailsTransformed[], currentValue: TargetDetails) => {
      const zonedStart = utcToZonedTime(new Date(currentValue.start_time as string), timezone);
      const zonedEnd = utcToZonedTime(new Date(currentValue.end_time as string), timezone);
      const curr = {
        ...currentValue,
        start_time: zonedStart,
        end_time: zonedEnd,
        data_type: 'target_details',
        duration: zonedEnd.getTime() - zonedStart.getTime(), // duration is in MILLISECONDS
        color: COLORMAP[currentValue.mode_descr],
        colorHover: HOVERCOLORMAP[currentValue.mode_descr]
      } as TargetDetailsTransformed;
      accumulator.push(curr);
      return accumulator;
    },
    []
  );
  //-----

  // 2.A SUM UP targetValues duration by mode_descr
  const targetDurationSumByMode = targetValues?.reduce((acc, curr) => {
    if (acc[curr.mode_descr]) {
      acc[curr.mode_descr].duration += curr.duration; // duration is in MILLISECONDS
    } else {
      acc[curr.mode_descr] = {
        duration: curr.duration, // duration is in MILLISECONDS
        mode_descr: curr.mode_descr
      } as unknown as DurationSumByMode;
    }
    return acc;
  }, {} as Record<string, DurationSumByMode>);

  // 2.B SUM UP actualValues duration by mode_descr
  const actualDurationSumByMode = actualValues?.reduce((acc, curr) => {
    if (acc[curr.mode_descr]) {
      acc[curr.mode_descr].duration += curr.duration;
    } else {
      acc[curr.mode_descr] = {
        duration: curr.duration,
        mode_descr: curr.mode_descr
      };
    }
    return acc;
  }, {} as Record<string, DurationSumByMode>);

  // 03/05/24 UPDATE this logic needs to be updated to use 'value_2' column value(api needs to be updated)
  // RED LINES LOGIC:
  // Due to business logic we need to find a first occurence of "CIP Alkaline.." cleaning logic
  // and calculate time difference up until this event and difference after this event
  const SEARCHVALUE = 'CIP Alkaline';
  const valueIndexAD = actual_details?.findIndex((obj: { recipe_descr: string }) =>
    obj.recipe_descr.indexOf(SEARCHVALUE) > -1 ? true : false
  );
  const valueIndexTD = target_details?.findIndex((obj: { recipe_descr: string }) =>
    obj.recipe_descr.indexOf(SEARCHVALUE) > -1 ? true : false
  );

  //We calculate total duration from start until CIP and from CIP until the end
  const redLinesTargetData = targetValues?.reduce((accumulator: Redlines[], curr, index) => {
    const lastIndex = targetValues.length - 1;

    if (index < valueIndexTD) return accumulator;

    if (index === valueIndexTD) {
      const item = {
        start_time: targetValues[0].start_time,
        end_time: targetValues[valueIndexTD].end_time,
        duration: calculateTotalDuration(targetValues.slice(0, valueIndexTD + 1)), // duration is in MILLISECONDS
        data_type: 'target_details'
      } as Redlines;

      accumulator.push(item);
      return accumulator;
    }

    if (index === lastIndex) {
      const item = {
        start_time: targetValues[valueIndexTD + 1].start_time,
        end_time: targetValues[lastIndex].end_time,
        duration: calculateTotalDuration(targetValues.slice(valueIndexTD + 1)), // duration is in MILLISECONDS
        data_type: 'target_details'
      } as Redlines;

      accumulator.push(item);
      return accumulator;
    }

    return accumulator;
  }, []);

  //We calculate total duration from start until CIP and from CIP until the end
  const redLinesActualData = actualValues?.reduce((accumulator: Redlines[], curr, index) => {
    const lastIndex = actualValues.length - 1;

    if (index < valueIndexAD) return accumulator;

    if (index === valueIndexAD) {
      const item = {
        start_time: actualValues[0].start_time,
        end_time: actualValues[valueIndexAD].end_time,
        duration: calculateTotalDuration(actualValues.slice(0, valueIndexAD + 1)), // duration is in MILLISECONDS
        data_type: 'actual_details'
      } as Redlines;

      accumulator.push(item);
      return accumulator;
    }

    if (index === lastIndex) {
      const item = {
        start_time: actualValues[valueIndexAD + 1].start_time,
        end_time: actualValues[lastIndex].end_time,
        duration: calculateTotalDuration(actualValues.slice(valueIndexAD + 1)), // duration is in MILLISECONDS
        data_type: 'actual_details'
      } as Redlines;

      accumulator.push(item);
      return accumulator;
    }

    return accumulator;
  }, []);

  // we comparing how Actual values are different from Target values,
  // If actual value more than target value - it's positive, if less than targetthen negative
  const redLinedData = redLinesActualData?.reduce((acc: RedlinesData[], curr, index) => {
    const isNegative = curr.duration > redLinesTargetData[index].duration ? false : true;
    const item = {
      end_time: curr.end_time, //actual end time
      other_end_time: redLinesTargetData[index].end_time, //target end time
      duration_difference: curr.duration - redLinesTargetData[index].duration, //relative to target duration, in MILLISECONDS
      target_diff_in_minutes: isNegative
        ? `-${secondsToHms(curr.duration / 1000 - redLinesTargetData[index].duration / 1000)}` // printable string
        : `+${secondsToHms(curr.duration / 1000 - redLinesTargetData[index].duration / 1000)}`
    } as RedlinesData;

    acc.push(item);
    return acc;
  }, []);
  //END

  return {
    actualValues,
    targetValues,
    redLinedData,
    targetDurationSumByMode,
    actualDurationSumByMode
  };
};
