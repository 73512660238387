import {
  //ChangeEvent,
  useEffect,
  useState
} from 'react';
import { EditableField } from './EditableField';
import { DisplayField } from './DisplayField';
import { CoreColumn } from '@tanstack/react-table';

interface TableCellProps<TData, TValue> {
  getValue: () => string | number;
  column: CoreColumn<TData, TValue>;
  row: Record<string, unknown>;
  table: {
    options: {
      meta: {
        setEditedRows: (old: []) => void;
        revertData: (index: number, cancel: boolean) => void;
        editedRows: Record<string, boolean>;
      };
    };
  }[];
}

export const TableCell = <TData, TValue>({
  getValue,
  row,
  column,
  table
}: TableCellProps<TData, TValue>): JSX.Element => {
  const initialValue = getValue();
  const columnMeta = column.columnDef.meta;
  const tableMeta = table.options.meta;
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onBlur = () => {
    tableMeta?.updateData(row.index, column.id, value);
  };

  // const onSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
  //   setValue(e.target.value);
  //   tableMeta?.updateData(row.index, column.id, e.target.value);
  // };

  if (tableMeta?.editedRows[row.id])
    return EditableField(
      value,
      //onSelectChange,
      onBlur,
      columnMeta?.type
    );
  return DisplayField(value, columnMeta?.type);
};
