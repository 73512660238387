import React from 'react';
import theme from 'themes';

interface IcoUpgraphProps {
  color?: string;
}
export const IcoUpgraph = ({ color }: IcoUpgraphProps): JSX.Element => {
  color = color || theme.colors.primaryBlue5;
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4225_2400)">
        <path
          d="M2.27148 11.3334L6.27148 7.33341L8.93815 10.0001L14.2715 4.66675"
          stroke={color}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.60449 4.66675H14.2712V9.33341"
          stroke={color}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4225_2400">
          <rect width="16" height="16" fill="white" transform="translate(0.271484)" />
        </clipPath>
      </defs>
    </svg>
  );
};
