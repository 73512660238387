import { format } from 'date-fns';
import { getUserTimeZone, isUSTimeZone } from 'helpers';
import { secondsToHms } from '../../ActualDurationWidget/utils';
import { colors } from 'common/pages/fleetV2/settings/aseptic';

export const API_INTERVAL = 1 * 60 * 1000; // 1 minute interval

export const ZOOMED_RANGE = 168; //in hours  168 - 7 days, 72 - 3days
export const ZOOMED_RANGE_MINNUTES = ZOOMED_RANGE * 60;
export const ZOOMED_RANGE_MILLISECONDS = ZOOMED_RANGE * 60 * 60 * 1000;

//Based on mode_descr because mode_number is not relizable - 2 modes have the same mode number
export const COLORMAPPING = {
  Idle: colors['idle'], //idle
  Maintenance: colors['maintenance'], //maint
  Production: colors['Production'], // production
  Cleaning: colors['cleaning'], //cleaning
  Sterilization: colors['sterilization'], //sterilization
  Waittime: colors['wait time'] //production downtime
} as Record<string, unknown>;

export interface LegendItem {
  label: string;
  color: string;
  descr: string;
}

export const legendSettings = [
  {
    label: 'idle',
    color: COLORMAPPING.Idle,
    descr: 'Idle'
  },
  {
    label: 'maintenance',
    color: COLORMAPPING.Maintenance,
    descr: 'Maintenance'
  },
  {
    label: 'production',
    color: COLORMAPPING.Production,
    descr: 'Production'
  },
  {
    label: 'cleaning',
    color: COLORMAPPING.Cleaning,
    descr: 'Cleaning'
  },
  {
    label: 'sterilization',
    color: COLORMAPPING.Sterilization,
    descr: 'Sterilization'
  },
  {
    label: 'wait time',
    color: COLORMAPPING.Waittime,
    descr: 'Wait time'
  }
] as LegendItem[];

export function getCurrentUTCDate(): Date {
  const now = new Date();
  return new Date(
    Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
      now.getUTCMilliseconds()
    )
  );
}

export const toUTCDate = (date: Date): Date => {
  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    )
  );
};

export const displayTooltip = (tooltipData: Record<string, unknown>): string => {
  const data = tooltipData.data;

  const { start_time, end_time, mode_descr, recipeDescr, duration } = data as {
    start_time: Date;
    end_time: Date;
    mode_descr: string;
    recipeDescr: string;
    duration: number;
  };

  const isUSA = isUSTimeZone(getUserTimeZone()); //Affects time formatting AM/PM vs 14:00

  const startDate = format(start_time, 'MMM do, y');
  const endDate = format(end_time, 'MMM do, y');

  const formattedDate =
    startDate === endDate
      ? startDate
      : `${startDate.split(',')[0]} - ${endDate.split(',')[0]}, ${startDate.split(',')[1]}`;

  const startTime = format(start_time, isUSA ? 'hh:mm a' : 'HH:mm');
  const endTime = format(end_time, isUSA ? 'hh:mm a' : 'HH:mm');

  return `<div class="tooltip--inner">
            <div class='tooltip--header'>${formattedDate}</div>
            <div class='tooltip--subheader'>
              <div class='tooltip--subheader time'>Time - &nbsp;<b>${startTime}-${endTime}</b></div>
              <div class='tooltip--subheader duration'>Duration - &nbsp;<b>${secondsToHms(
                duration / 1000,
                false
              )}</b></div>
            </div>
            <div class='tooltip--line-item'>
              <span class='color-indicator' style='background-color: ${
                COLORMAPPING[mode_descr]
              }'></span>
              ${mode_descr} - <b>${recipeDescr}</b>
            </div>
          </div>`;
};
