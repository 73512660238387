import styled from 'styled-components';

export const DataPointsAreaContainer = styled.div`
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;

  &.hover-layer {
    z-index: 3;
  }
`;

export const DataPointContainer = styled.div<{
  className?: string;
  left?: string;
  top?: string;
  bottom?: string;
  right?: string;
  width?: string;
  height?: string;
  size?: number;
  color?: string;
  hoverItem?: boolean;
}>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  border-style: solid;
  width: 0.5em;
  height: 0.5em;
  z-index: 2;

  left: ${({ left }) => left};
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  bottom: ${({ bottom }) => bottom};
  top: ${({ top }) => top};
  border-color: ${({ color }) => color || 'red'};
  border-width: ${({ size }) => size || 1}px;
  background-color: ${({ color }) => (color === 'transparent' ? color : `white`)};

  &.hover-item {
    border-color: transparent;
    background-color: transparent;
    cursor: pointer;
    width: 0.7em;
    height: 0.7em;
    z-index: 4;

    &.is-filtered {
      display: none;
    }
  }

  .generator-tooltip-wrapper {
    left: 50%;
    bottom: 100%;
  }
`;

export const DefaultDataPointTooltipInnerArea = styled.div<{ color?: string }>`
  text-align: center;
  font-size: 1em;

  .tooltip__group {
    align-items: center;
    text-transform: capitalize;
    font-size: 1.1em;
    font-weight: 500;
  }

  .tooltip__value {
    font-size: 1.5em;
    font-weight: 600;
  }

  ${({ color }) =>
    !color
      ? ``
      : `
    .tooltip__group {
      display: flex;
      flex-direction: column; 
      gap: .5em;
      justify-content: center;

      &:before {
        content: '';
        width: 0.7em;
        height: 0.7em;
        border-radius: 50%;
        background-color: ${color};
      }
    }
  `}
`;
