import styled from 'styled-components';
import { StyledUiContainerProps } from 'components';

export const ViewContainer = styled.div<StyledUiContainerProps>`
  grid-gap: 1em;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: auto;
  grid-auto-flow: row;
  grid-template-areas:
    'machine-utilization-tiles machine-utilization-tiles machine-utilization-tiles'
    'changeover  wait-time   changeover-machine-modes'
    'lane-status lane-status lane-status'
    'table-row   table-row   table-row';

  @media (max-width: 1300px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'machine-utilization-tiles machine-utilization-tiles'
      'changeover         wait-time'
      'session-issues     changeover-machine-modes'
      'lane-status        lane-status'
      'table-row          table-row';
  }

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }

  .machine-utilization-tiles {
    grid-area: machine-utilization-tiles;
  }

  .machine-info-widget {
    grid-area: machin-info;
  }

  .throughput-efficiency-widget {
    grid-area: throughput-efficiency;
  }

  .oee-widget {
    grid-area: oee;
  }

  .active-issues-widget {
    grid-area: active-issues;
  }

  .machine-utilization-widget {
    grid-area: machine-utilization;
  }

  .changeover-widget {
    grid-area: changeover;
  }

  .lane-alarms-widget {
    grid-area: lane-alarms;
  }

  .changeover-machine-modes-widget {
    grid-area: changeover-machine-modes;
  }

  .session-issues-widget {
    grid-area: session-issues;
  }

  .wait-time-widget {
    grid-area: wait-time;
  }

  .lane-state-widget {
    grid-area: lane-status;
    min-height: 690px;
    max-width: 100%;
    box-sizing: border-box;

    .container_no_data {
      padding: 1rem;
      width: 100%;
      margin: 0 auto;
      border-width: 0 1px 1px 1px;
      border-color: inherit;
      border-bottom-left-radius: 0.625rem;
      border-bottom-right-radius: 0.625rem;
      border-style: solid;

      img {
        display: block;
        margin: 0 auto;
        width: 100%;
        max-width: 200px;
      }
    }
  }

  .changeover-table-widget {
    grid-area: table-row;
    margin-bottom: 2rem;
  }
`;
