import { useEffect, useState } from 'react';
import { generateData } from '../mockData';

interface Props {
  data: Record<string, unknown>[];
  error?: string;
  isLoading?: boolean;
}

export const useMockGetDataQuery = (
  key: number,
  startDate: string,
  numOfNewItems: number,
  isFirstRequest: boolean
): Props => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate a network request with a delay
    const fetchData = async () => {
      try {
        setIsLoading(true);
        // Simulate a delay
        await new Promise((resolve) => setTimeout(resolve, 3000));
        const newItem = generateData(
          isFirstRequest ? '2024-07-11T16:50:00.000Z' : startDate,
          isFirstRequest ? 60 : numOfNewItems
        );
        setData(newItem);
        setIsLoading(false);
      } catch (err) {
        setError(err);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [key]);

  return { data, error, isLoading };
};
