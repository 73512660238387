export const sortSessionsByDateRangeData = (
  data: Record<string, unknown>[],
  order: string
): Record<string, unknown>[] =>
  data?.sort((a, b) => {
    if (order === 'date by ascending') {
      const startA = a?.startTimestamp as string;
      const startB = b?.startTimestamp as string;
      if (startA && startB) return new Date(startA).getTime() - new Date(startB).getTime();
    }

    if (order === 'date by descending') {
      const startA = a?.startTimestamp as string;
      const startB = b?.startTimestamp as string;
      if (startA && startB) return new Date(startB).getTime() - new Date(startA).getTime();
    }

    if (order === 'duration by ascending') {
      const durationA = a?.duration as number;
      const durationB = b?.duration as number;
      if (durationA && durationB) return durationA - durationB;
    }

    if (order === 'duration by descending') {
      const durationA = a?.duration as number;
      const durationB = b?.duration as number;
      if (durationA && durationB) return durationB - durationA;
    }
  });
