import React from 'react';
import { DimensionsContainer } from 'components';
import { StatesComponent } from './ui/StatesComponent';
import { WrapperData } from './types';

interface Props {
  data?: WrapperData;
  className?: string;
  chartDaterange?: Date[];
  zoomDaterange: Date[];
  handelZoomInDaterangeUpdate?: (range: Date[]) => void;
  chartData?: Record<string, unknown>[];
  linesData?: Record<string, unknown>[];
}

export const LaneStatesContainer = ({
  data,
  className,
  zoomDaterange,
  chartData,
  linesData
}: Props): JSX.Element => {
  return (
    <div className={`lane-states-zoomed ${className}`}>
      <DimensionsContainer
        Component={({ width }) => {
          const settings = {
            height: 400,
            width,
            data,
            zoomedRangeX: zoomDaterange,
            chartData,
            linesData
          };

          return <StatesComponent {...settings} />;
        }}
      />
    </div>
  );
};
