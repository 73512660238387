import { useState, useLayoutEffect, useCallback, useRef } from 'react';

const debounce = (func: (...args: unknown[]) => void, wait: number, immediate = false) => {
  let timeout: ReturnType<typeof setTimeout> | null = null;
  return (...args: unknown[]) => {
    const later = () => {
      timeout = null;
      if (!immediate) func.apply(this, args);
    };
    const callNow = immediate && !timeout;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(this, args);
  };
};

export interface UseContainerSizeReturnProps {
  containerRef: React.RefObject<HTMLDivElement>;
  width: number;
  height: number;
}

export const useContainerSize = (delay = 250): UseContainerSizeReturnProps => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [dimensions, set] = useState<{ width: number; height: number }>({ width: 0, height: 0 });

  const setDimensions = useCallback(() => {
    if (containerRef?.current) {
      const newWidth = containerRef.current.offsetWidth;
      const newHeight = containerRef.current.offsetHeight;
      // Only update if dimensions have actually changed
      set((prevDimensions) => {
        if (prevDimensions.width !== newWidth || prevDimensions.height !== newHeight) {
          return { width: newWidth, height: newHeight };
        }
        return prevDimensions;
      });
    }
  }, [containerRef]);

  const onResize = debounce(() => {
    setDimensions();
  }, delay);

  useLayoutEffect(() => {
    window.addEventListener('resize', onResize);
    if (containerRef.current) setDimensions(); // Initial dimensions set
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize, setDimensions]);

  return {
    containerRef,
    ...dimensions
  };
};

export default useContainerSize;
