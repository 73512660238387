import React from 'react';
import { MachineStateAsepticProvider } from './provider';
import { Provided } from './Provided';

export const LaneStatesView = (): JSX.Element => {
  return (
    <MachineStateAsepticProvider>
      <Provided />
    </MachineStateAsepticProvider>
  );
};
