import * as d3 from 'd3';
import { renderToString } from 'react-dom/server';
import { styledTheme } from 'common/theme';
import { recipeMapping } from '../../utils';
import { chartColors } from '../../../../../../../../settings/proseal/colors';
import { Icons } from '../../../../../../../../settings/proseal/Icons';
import { cloneDeep } from 'lodash';
import _ from 'lodash';

const TRANSITION_TIME = 500;
const MACHINE_LABEL_WIDTH = 146;
const MACHINE_LABEL_HEIGHT = 28;

// Function to check if there is any data within the time range
function isDataInRange(data, start, end) {
  return data.some((d) => d.timestamp >= start && d.timestamp <= end);
}

function calcualteNewLabelsPosition(data, x, y) {
  if (!data || data.length === 0) return;

  const bottomLimit = y.range()[0]; //490
  const dataCopy = cloneDeep(data);
  const labelsToLookAtBack = 30;

  for (let index = 0; index < dataCopy.length; index++) {
    const item = dataCopy[index];
    //If first item
    if (index === 0) {
      dataCopy[index] = {
        ...item,
        xCoordOriginal: x(item['timestamp']),
        yCoordOriginal: y(item['ppm']),
        xCoordUpdatedInPX: x(item['timestamp']),
        yCoordUpdatedInPx:
          y(item['ppm']) === bottomLimit ? y(item['ppm']) - MACHINE_LABEL_HEIGHT : y(item['ppm'])
      };
      continue;
    }

    const currX = x(item['timestamp']); //pixel value on svg
    const currY =
      y(item['ppm']) === bottomLimit ? y(item['ppm']) - MACHINE_LABEL_HEIGHT : y(item['ppm']); //pixel value on svg

    const sliceStart =
      labelsToLookAtBack - 1 > index ? 0 : dataCopy.length - labelsToLookAtBack - 1;
    const sliceEnd = index;
    const sliceFromStartUntilNow = dataCopy.slice(sliceStart, sliceEnd);

    let yCoordinate = currY;
    sliceFromStartUntilNow.map((prevItemPos) => {
      if (prevItemPos.xCoordOriginal + MACHINE_LABEL_WIDTH >= currX) {
        yCoordinate -= MACHINE_LABEL_HEIGHT; // "-" is because 0 is at the top of the axis
      }
    });

    dataCopy[index] = {
      ...item,
      xCoordOriginal: x(item['timestamp']),
      yCoordOriginal: y(item['ppm']),
      xCoordUpdatedInPX: currX,
      yCoordUpdatedInPx: yCoordinate
    };
  }

  return dataCopy;
}

export default class D3Chart {
  constructor(
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    element, // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    chartWidth, // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    chartHeight, // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    {
      chartMargins,
      xMainAxisSelector,
      yMainAxisSelector,
      xDomain,
      yDomain,
      isLabelVisible,
      numberofLabels,
      dataLines,
      tooltip
    }: {
      chartMargins: {
        top: number;
        bottom: number;
        left: number;
        right: number;
      };
      xMainAxisSelector: string;
      yMainAxisSelector: string;
      xDomain?: Date[];
      yDomain?: number[];
      isLabelVisible: boolean;
      lablesData?: Record<string, unknown>[];
      dataLines: Record<string, unknown>[];
      tooltip: (tooltipData: Record<string, unknown>) => string;
    }
  ) {
    this.element = element;
    this.chartWidth = chartWidth;
    this.chartHeight = chartHeight;

    this.numberofLabels = numberofLabels;

    this.xMainAxisSelector = xMainAxisSelector;
    this.yMainAxisSelector = yMainAxisSelector;
    this.xDomain = xDomain;
    this.yDomain = yDomain;
    this.tooltip = tooltip;

    const { top, bottom, left, right } = chartMargins;

    this.top = top;
    this.left = left;
    this.bottom = bottom;
    this.right = right;

    // Calc width and height of SVG
    this.width = this.chartWidth - this.left - this.right;
    this.height = this.chartHeight - this.top - this.bottom;

    const line1Settings = dataLines[0];
    const line2Settings = dataLines[1];
    const line3Settings = dataLines[2];

    const mainData = line1Settings.lineSettings.data;

    // Delete svg if already exists
    d3.select(this.element).selectAll('.live-chart').remove();

    // Create the SVG container.
    this.svg = d3
      .select(this.element)
      .append('svg')
      .attr('width', this.width + this.left + this.right)
      .attr('height', this.height + this.top + this.bottom)
      .attr('class', 'live-chart');

    //Define tooltip red markers
    this.defineLineMarkers(this.svg);

    const xdomain = this.xDomain;

    // Create X axis
    this.x = d3
      .scaleTime()
      .domain(xdomain ? xdomain : d3.extent(mainData || [], (d) => d[this.xMainAxisSelector]))
      .range([this.left, this.width - this.right]);

    // Create Y axis
    this.y = d3
      .scaleLinear()
      .domain(
        this.yDomain ? this.yDomain : [0, d3.max(mainData || [], (d) => d[this.yMainAxisSelector])]
      )
      .range([this.chartHeight - this.bottom, this.top]);

    // Call X axis
    this.xAxisCall = d3
      .axisBottom(this.x)
      .ticks(this.width / 80)
      .tickSizeOuter(0);

    // Create group for X axis
    this.xAxisGroup = this.svg
      .append('g')
      .attr('transform', `translate(0,${this.chartHeight - this.bottom})`)
      .attr('class', 'x-axis-group')
      .call(this.xAxisCall);

    // Call Y axis
    this.yAxisCall = d3.axisLeft(this.y).ticks(6);

    // Create group for Y axis
    this.yAxisGroup = this.svg
      .append('g')
      .attr('transform', `translate(${this.left},0)`)
      .attr('class', 'y-axis-group');

    //this.yAxisGroup.call(this.yAxisCall);

    // Style X axis
    this.xAxisGroup
      .call(this.xAxisCall)
      .call((g) => g.select('.domain').remove())
      .call((g) => g.selectAll('.tick line').remove())
      .call((g) =>
        g
          .selectAll('.tick text')
          .attr('fill', styledTheme.colors.gray)
          .attr('transform', `translate(0, 10)`)
      );

    // Style Y axis
    this.yAxisGroup
      .call(this.yAxisCall)
      .call((g) =>
        g
          .selectAll('.tick line')
          .clone()
          .attr('x2', this.width - this.left - this.right)
          .attr('stroke-opacity', 0.1)
      )
      .attr('fill', styledTheme.colors.gray)
      .call((g) => g.select('.domain').remove())
      .call((g) =>
        g
          .append('text')
          .attr('x', -(this.height / 2) + 10)
          .attr('y', -this.left + -10)
          .attr('fill', styledTheme.colors.gray)
          .attr('text-anchor', 'middle')
          .attr('transform', 'rotate(-90)')
          .text('Packs Per minute')
      )
      .call((g) => g.selectAll('.tick text').attr('fill', styledTheme.colors.gray))
      .call((g) => g.selectAll('.tick line').attr('stroke', styledTheme.colors.gray));
    //end styling

    this.drawArea = this.generateZoomContainer(this.svg);

    // Declare Line generator #1 Main Blue line
    this.main_line = d3
      .line()
      .x((d) => this.x(d[line1Settings.lineSettings.xSelector]))
      .y((d) => this.y(d[line1Settings.lineSettings.ySelector]))
      //.curve(d3.curveBumpX);
      .curve(d3.curveMonotoneX);

    // Declare Line generator #2  Dashed line
    this.ideal_ppm_line = d3
      .line()
      .x((d) => this.x(d[line2Settings.lineSettings.xSelector]))
      .y((d) => this.y(d[line2Settings.lineSettings.ySelector]));

    // Declare Line generator #3 Recipe line
    this.recipe_line = d3
      .line()
      .x((d) => this.x(d[line3Settings.lineSettings.xSelector]))
      .y(this.chartHeight - this.bottom + line3Settings.lineSettings.ySelector);

    // Polpulate chart with data
    dataLines[0].lineSettings.iconsData = calcualteNewLabelsPosition(
      dataLines[0].lineSettings.iconsData,
      this.x,
      this.y
    );
    this.drawData(dataLines, isLabelVisible);

    // Add tooltip
    mainData && mainData.length && this.addTooltip(mainData);

    // Show/hide labels on click
    d3.selectAll('.label-container').on('click', (event) => {
      if (d3.select(event.currentTarget).attr('class').indexOf('visible') > -1) {
        d3.select(event.currentTarget).classed('visible', false);
        d3.select(event.currentTarget).lower(); // Bring layer to the back
      } else {
        d3.select(event.currentTarget).classed('visible', true);
        d3.select(event.currentTarget).raise(); // Bring lable to the top
      }
      event.preventDefault();
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  generateZoomContainer(svg) {
    // Add a clipPath: everything out of this area won't be drawn.
    const clip = svg
      .append('defs')
      .append('svg:clipPath')
      .attr('id', 'clip')
      .append('svg:rect')
      .attr('width', () => {
        const width = this.chartWidth - this.right;
        if (width < 0) {
          //console.log('Negative width found', d);
        }
        return Math.abs(width);
      })
      .attr('height', this.chartHeight)
      .attr('x', this.left)
      .attr('y', 0);

    // to make TS happy
    const showMSG = false;
    if (showMSG) console.log(clip);
    const scatter = svg.append('g').attr('clip-path', 'url(#clip)').attr('class', 'zoom-container');
    return scatter;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  drawData(dataLines, isLabelVisible) {
    const line1Settings = dataLines[0];
    const line2Settings = dataLines[1];
    const line3Settings = dataLines[2];

    const main_line = this.main_line;
    const ideal_ppm_line = this.ideal_ppm_line;
    const recipe_line = this.recipe_line;

    //Remove old elements
    d3.selectAll(
      '.live-graph-widget .widget-ui__main .d3-tooltip, .ruler, .tooltip--point'
    ).remove();
    this.drawArea.selectAll('.line1').exit().transition().duration(500).remove();
    this.drawArea
      .selectAll('.line2')
      .exit()
      .transition()
      .duration(500)
      .attr('y', this.chartHeight - this.bottom)
      .remove();
    this.drawArea
      .selectAll('.line3')
      .exit()
      .transition()
      .duration(500)
      .attr('x', this.width)
      .remove();

    //Show no Data msg
    const result = isDataInRange(line1Settings.lineSettings.data, this.xDomain[0], this.xDomain[1]);
    if (!result) {
      this.svg
        .append('text')
        .attr('class', 'no-data-message')
        .attr('fill', styledTheme.colors.gray)
        .attr('x', this.chartWidth / 2)
        .attr('y', this.chartHeight / 2)
        .attr('text-anchor', 'middle')
        .attr('dominant-baseline', 'middle')
        .style('font-size', '16px')
        .text('No Data in Zoomed Range');
    }

    //Ideal PPM line
    line2Settings.lineSettings.data.forEach((lineData) => {
      const lastItem = [lineData[lineData.length - 1]];
      //Line
      this.drawArea
        .append('path')
        .datum(lineData)
        .attr('class', `line2`)
        .attr('fill', 'none')
        .attr('stroke', recipeMapping[lastItem[0].recipe])
        .attr('stroke-dasharray', '5 3')
        .attr('stroke-width', 2)
        .attr('d', ideal_ppm_line(lineData));
    });

    //Label at the end of a line
    const lastArray = line2Settings.lineSettings.data[line2Settings.lineSettings.data.length - 1];
    const lastArrayItem = [lastArray[lastArray.length - 1]];
    this.drawArea
      .append('g')
      .attr('class', 'container-ideal-ppm--label')
      .attr('font-family', 'sans-serif')
      .attr('font-size', 10)
      .selectAll()
      .data(lastArrayItem) // attach label only to the last item
      .join('text')
      .attr('class', 'ideal-ppm--label')
      .attr('text-anchor', (d) => d.ideal_ppm)
      .attr('x', (d) => this.x(d[line2Settings.lineSettings.xSelector]))
      .attr('y', (d) => this.y(d[line2Settings.lineSettings.ySelector]))
      .attr('dy', '0.35em')
      .text((d) => d.ideal_ppm)
      .attr('fill', recipeMapping[lastArrayItem[0].recipe]);

    //Recipe line
    line3Settings.lineSettings.data.forEach((array) => {
      if (array.length === 0) return;
      const lastItem = [array[array.length - 1]];
      this.drawArea
        .append('path')
        .attr('class', `line3`)
        .attr('fill', 'none')
        .attr('stroke', recipeMapping[lastItem[0]?.recipe])
        .attr('stroke-width', 8)
        .attr('d', recipe_line(array));
    });

    // Appending last because it needs to be a layer on top
    // Append MAIN line path
    this.drawArea
      .append('path')
      .datum(line1Settings.lineSettings.data)
      .attr('class', 'line1')
      .attr('fill', 'none')
      .attr('stroke', styledTheme.colors.mainHover)
      .attr('stroke-width', 2.5)
      .attr('d', main_line(line1Settings.lineSettings.data));

    //Append Machine state Icons
    const foreignObjectsGroup = this.drawArea.append('g').attr('class', 'group--label-container');

    const foreignObjects = foreignObjectsGroup
      .selectAll('foreignObject')
      .data(line1Settings.lineSettings.iconsData);

    // Enter
    foreignObjects
      .enter()
      .append('foreignObject')
      .attr('x', (d) => this.x(d.timestamp))
      .attr('y', (d) => d.yCoordUpdatedInPx)
      .attr('class', () => {
        return isLabelVisible ? 'label-container visible' : 'label-container';
      })
      .attr('width', 28)
      .attr('height', 28)
      .append('xhtml:div')
      .attr('class', 'label--inner-wrapper')
      .style('position', 'absolute')
      .html((d) => {
        const icon = renderToString(Icons[d.status_category.toLowerCase()]);
        return `<div class="label--inner">${icon && icon} <span class='label--description'>${
          d.status
        }</span></div>`;
      });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  update(data1, data2, data3, domain, isLabelVisible) {
    const line1Settings = data1;
    const line2Settings = data2;
    const line3Settings = data3;

    ///Remove old elements
    d3.selectAll(
      '.live-graph-widget .widget-ui__main .d3-tooltip, .tooltip-container-ruler, .tooltip--contanier-dot, .no-data-message'
    ).remove();

    const line = this.main_line;
    const ideal_ppm_line = this.ideal_ppm_line;
    const recipe_line = this.recipe_line;

    const path = this.drawArea.selectAll('.line1');

    //Update X domain with new values
    this.x.domain(domain);
    this.xAxisGroup
      .call(d3.axisBottom(this.x))
      .call((g) => g.select('.domain').remove())
      .call((g) => g.selectAll('.tick line').remove())
      .call((g) =>
        g
          .selectAll('.tick text')
          .attr('fill', styledTheme.colors.gray)
          .attr('transform', `translate(0, 10)`)
      );

    //Show no Data msg
    const result = isDataInRange(line1Settings.data, domain[0], domain[1]);
    if (!result) {
      d3.selectAll('line:not(.tick line)').remove();
      this.svg
        .append('text')
        .attr('class', 'no-data-message')
        .attr('fill', styledTheme.colors.gray)
        .attr('x', this.chartWidth / 2)
        .attr('y', this.chartHeight / 2)
        .attr('text-anchor', 'middle')
        .attr('dominant-baseline', 'middle')
        .style('font-size', '16px')
        .text('No Data in Zoomed Range');
    }

    //Calc icon position after we updated the axisi with new values
    const machineStates = calcualteNewLabelsPosition(line1Settings.iconsData, this.x, this.y);

    //--------------------------------------------------------------------------------
    // Ideal PPM line setup
    // Bind the new data to the Ideal PPM line
    const updatedIdealPPMLine = this.svg.selectAll('.line2').data(line2Settings.data);
    // Update the existing Ideal PPM line
    updatedIdealPPMLine.attr('d', ideal_ppm_line);

    // Handle the enter selection
    updatedIdealPPMLine.enter().append('path').attr('class', 'line2').attr('d', ideal_ppm_line);
    // Handle the exit selection
    updatedIdealPPMLine.exit().remove();

    // Bind the new data to the Ideal PPM label
    const lastArray = line2Settings.data[line2Settings.data.length - 1];
    const lastArrayItem = [lastArray[lastArray.length - 1]];
    const updatedIdealPPMLineLabel = this.svg.selectAll('.ideal-ppm--label').data(lastArrayItem);

    // Update the existing Ideal PPM label
    updatedIdealPPMLineLabel
      .attr('x', (d) => this.x(d[line2Settings.xSelector]))
      .attr('y', (d) => this.y(d[line2Settings.ySelector]));

    // Handle the enter selection
    updatedIdealPPMLineLabel
      .enter()
      .append('text')
      .attr('x', (d) => this.x(d[line2Settings.xSelector]))
      .attr('y', (d) => this.y(d[line2Settings.ySelector]))
      .attr('class', 'ideal-ppm--label')
      .attr('text-anchor', (d) => d.ideal_ppm)
      .text((d) => d.ideal_ppm)
      .attr('dy', '0.35em')
      .attr('fill', recipeMapping[lastArrayItem[0].recipe]);
    // Handle the exit selection
    updatedIdealPPMLineLabel.exit().remove();
    //-------------------------------------------------

    //Recipe line
    // line3Settings.data.map((array, index) => {
    //   //Update recipe line
    //   this.drawArea.selectAll(`.line3-${index}`).datum(array).attr('d', recipe_line);
    // });

    //--------------------------------------------------------------------------------
    // Recipe line setup
    // Bind the new data to the Recipe line
    const updatedRecipeLine = this.svg.selectAll('.line3').data(line3Settings.data);
    // Update the existing Recipe line
    updatedRecipeLine.attr('d', recipe_line);

    // Handle the enter selection
    updatedRecipeLine
      .enter()
      .append('path')
      .attr('class', 'line3')
      .attr('d', recipe_line)
      .attr('stroke-width', 8)
      .attr('stroke', '#0076CC');

    //fill="none" stroke="#0076CC" stroke-width="8"

    // Handle the exit selection
    updatedRecipeLine.exit().remove();
    //--------------------------------------------------------------------------------

    // MAIN path update
    path.datum(line1Settings.data).attr('d', line);

    //Machine states ICONS
    const foreignObjects = this.drawArea.selectAll('.label-container').data(machineStates);

    // EXIT phase: remove old elements not present in new data
    foreignObjects.exit().attr('width', 0).attr('height', 0).remove();

    // UPDATE phase: update existing elements
    foreignObjects
      .transition()
      .duration(TRANSITION_TIME)
      .attr('x', (d) => this.x(d['timestamp']))
      .attr('y', (d) => d.yCoordUpdatedInPx)
      .attr('width', 28)
      .attr('height', 28);

    // ENTER phase: create new elements
    const foreignObjectEnter = foreignObjects
      .enter()
      .append('foreignObject')
      .attr('class', () => {
        return isLabelVisible ? 'label-container visible' : 'label-container';
      })
      .attr('x', (d) => {
        return this.x(d['timestamp']);
      })
      .attr('y', (d) => d.yCoordUpdatedInPx)
      .attr('width', 0)
      .attr('height', 0);

    foreignObjectEnter.transition().duration(TRANSITION_TIME).attr('width', 28).attr('height', 28);

    // Create inner html for machine states icons
    foreignObjectEnter
      .append('xhtml:div')
      .style('position', 'absolute')
      .attr('class', 'label--inner-wrapper')
      .style('width', '100%')
      .style('height', '100%')
      .html((d) => {
        const icon = renderToString(Icons[d.status_category.toLowerCase()]);
        return `<div class="label--inner">${icon && icon} <span class='label--description'>${
          d.status
        }</span></div>`;
      });

    //Add tooltip
    this.addTooltip(line1Settings.data);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  toggleLabels(isLabelVisible) {
    const classToSearchFor = '.label-container';
    d3.selectAll(`${classToSearchFor}`).classed('visible', isLabelVisible);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  defineLineMarkers(svg) {
    //These markers are red triangles at the top and the bottom of a tooltip ruler
    svg
      .append('defs')
      .append('marker')
      .attr('id', 'triangle-start')
      .attr('viewBox', '0 0 10 10')
      .attr('refX', '5')
      .attr('refY', '2')
      .attr('markerWidth', '6')
      .attr('markerHeight', '6')
      .attr('orient', '0')
      .append('path')
      .attr('d', 'M 0 8.66 L 5 0 L 10 8.66 Z')
      .attr('fill', chartColors.red);

    svg
      .append('defs')
      .append('marker')
      .attr('id', 'triangle-end')
      .attr('viewBox', '0 0 10 10')
      .attr('refX', '5')
      .attr('refY', '78')
      .attr('markerWidth', '6')
      .attr('markerHeight', '6')
      .attr('orient', '180')
      .append('path')
      .attr('d', 'M 0 8.66 L 5 0 L 10 8.66 Z')
      .attr('fill', chartColors.red);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  addTooltip(blueData) {
    const mainData = blueData;

    // Vertical Ruler
    const tooltipRule = this.drawArea
      .append('g')
      .attr('class', 'tooltip-container-ruler')
      .append('line')
      .attr('class', 'ruler')
      .attr('y1', 0)
      .attr('y2', 20)
      .attr('stroke', chartColors.red)
      .attr('stroke-width', 2)
      .attr('stroke-dasharray', '10,5')
      .attr('marker-start', 'url(#triangle-start)')
      .attr('marker-end', 'url(#triangle-end)');

    // Create the tooltip container.
    const tooltipDot = this.drawArea.append('g').attr('class', 'tooltip--contanier-dot'); // Create the tooltip container.

    const g = d3.select('.live-graph-widget__line-chart .chart-area');
    const tooltipInfo = g
      .append('div')
      .attr('class', 'd3-tooltip')
      .style('position', 'absolute')
      .style('z-index', '10')
      .style('visibility', 'visible')
      .style('background', '#ffffff')
      .style('border-radius', '4px')
      .style('color', `${styledTheme.colors.black}`)
      .style('top', '-5px')
      .style('left', 0);

    const bisector = d3.bisector((d) => d.timestamp).left;

    const updateRulerPosition = (e) => {
      tooltipRule.style('visibility', 'visible');
      tooltipRule.attr('y1', this.chartHeight - this.bottom);

      const xPoint = d3.pointer(e)[0];

      const i = bisector(mainData, this.x.invert(d3.pointer(e)[0]));

      const pointBefore = mainData[i];
      const pointAfter = mainData[i - 1];

      if (!pointBefore || !pointAfter) return;

      tooltipRule.attr('transform', `translate(${xPoint}, 0)`);
    };

    const hideRuler = () => {
      tooltipRule.style('visibility', 'hidden');
    };

    const showTooltip = (e) => {
      const i = bisector(mainData, this.x.invert(d3.pointer(e)[0]));

      const pointBefore = mainData[i];
      const pointAfter = mainData[i - 1];

      if (!pointBefore || !pointAfter) return;

      const xMouse = d3.pointer(e)[0];
      const yMouse = d3.pointer(e)[1];

      const x1 = this.x(pointBefore.timestamp);
      const x2 = this.x(pointAfter.timestamp);
      const y1 = this.y(pointBefore.ppm);
      const y2 = this.y(pointAfter.ppm);

      let yPosition = y1;
      let yValue = pointBefore.ppm;
      const tooltipContent = _.cloneDeep(mainData[i]);
      const tolerance = 5;

      if (
        (x2 + tolerance <= xMouse + tolerance <= x1 &&
          y2 + tolerance <= yMouse <= y1 - tolerance) ||
        (x2 - tolerance >= xMouse >= x1 + tolerance && y2 - tolerance >= yMouse >= y1 + tolerance)
      ) {
        yPosition = y1 + ((xMouse - x1) * (y2 - y1)) / (x2 - x1);
        yValue = Math.floor(
          Math.abs(
            pointBefore.ppm + ((xMouse - x1) * (pointAfter.ppm - pointBefore.ppm)) / (x2 - x1)
          )
        );
        tooltipContent.timestamp = this.x.invert(xMouse);
        tooltipContent.ppm = yValue;
      }

      tooltipDot.style('display', 'block');

      // Remove old point
      tooltipDot.selectAll('.tooltip--point').remove();
      tooltipInfo.selectAll('.tooltip-container').remove();

      //Add new one as cursor moves
      tooltipDot
        .append('circle')
        .attr('cx', xMouse)
        .attr('cy', yPosition)
        .attr('r', 15)
        .attr('class', 'tooltip--point')
        .style('fill', chartColors.red)
        .style('opacity', 0.5);

      tooltipInfo
        .append('div')
        .attr('class', 'tooltip-container')
        .style('position', 'absolute')
        .style('z-index', '10')
        .style('visibility', 'visible')
        .html(() => this.tooltip(tooltipContent));

      tooltipInfo.style('left', `${xMouse - 120}px`);
    };

    const hideTooltip = () => {
      // Remove old point
      tooltipDot.selectAll('.tooltip--point').remove();
      tooltipInfo.selectAll('.tooltip-container').remove();
    };

    d3.selectAll('.live-graph-widget__line-chart .tooltip--container')
      .on('mousemove', function (e) {
        showTooltip(e);
        updateRulerPosition(e);
        e.preventDefault();
      })
      .on('mouseleave', function (e) {
        hideTooltip();
        hideRuler();
        e.preventDefault();
      });
  }
}
