import React, { MouseEvent } from 'react';
import { PencilIcon } from 'icons/PencilIcon';
import styled from 'styled-components';

interface EditCellProps {
  row: Record<string, unknown>;
  table: {
    options: {
      meta: {
        setEditedRows: (old: []) => void;
        revertData: (index: number, cancel: boolean) => void;
        editedRows: Record<string, boolean>;
      };
    };
  }[];
}

const EditCellContainer = styled.div`
  button {
    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

export const EditCell = ({ row, table }: EditCellProps): JSX.Element => {
  const meta = table.options.meta;

  const setEditedRows = (e: MouseEvent<HTMLButtonElement>) => {
    const elName = e.currentTarget.name;
    meta?.setEditedRows((old: []) => ({
      ...old,
      [row.id]: !old[row.id]
    }));
    if (elName !== 'edit') {
      meta?.revertData(row.index, e.currentTarget.name === 'cancel');
    }
  };

  return (
    <EditCellContainer className="edit-cell-container">
      {meta?.editedRows[row.id] ? (
        <div className="edit-cell">
          {/* TODO replace with proper icon in the next user story */}
          <button onClick={setEditedRows} name="cancel">
            X
          </button>
          {/* TODO replace with proper icon in the next user story */}
          <button onClick={setEditedRows} name="done">
            ✔
          </button>
        </div>
      ) : (
        <button onClick={setEditedRows} name="edit">
          <PencilIcon />
        </button>
      )}
    </EditCellContainer>
  );
};
