import React, { useMemo } from 'react';
import { useMachineInfo } from 'common/pages/fleetV2/providers';
import { DateRangeProviderV2 } from 'components/StyledUi/DateRange/hooks/useDateRangeV2';
import { MachineModesAPIProvider } from './provider/useMachineModesProvider';
import { DataManagement } from './provider/useDataManagement';
import { MachineModesWidget } from './MachineModesWidget';
import { APITimerProvider } from 'common/pages/fleetV2/hooks/useAPITimer';
import { API_INTERVAL } from './utils';

export const MachineModes = (): JSX.Element => {
  const { id, timeZone } = useMachineInfo() as { id: string; timeZone: string };

  const widgetContent = useMemo(() => {
    return (
      <DateRangeProviderV2 timeZone={timeZone} subtractDaysCount={3}>
        <APITimerProvider API_INTERVAL={API_INTERVAL}>
          <MachineModesAPIProvider timeZone={timeZone} machineId={id}>
            <DataManagement>
              <MachineModesWidget />
            </DataManagement>
          </MachineModesAPIProvider>
        </APITimerProvider>
      </DateRangeProviderV2>
    );
  }, [id]);

  return <>{widgetContent}</>;
};
