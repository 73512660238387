import React, { useEffect, useRef, useState } from 'react';
import D3Chart from './LiveChartD3-V2';
import { D3WrapperContainer } from '../elements';
import { displayTooltip } from '../../utils';
import { EyeIcon } from 'icons/EyeIcon';
import { LiveChartActionButtonsContainer } from 'common/pages/fleet/machine/proseal/MachineProduction/components/LiveChartActionButtons/LiveChartActionButtons.elements';
import styledTheme from 'common/theme';
import { useDataManagementProvider } from '../../hooks/useDataManagement';
import { NoData } from 'common/ui/StatesHorizontalBarChart/NoData';

interface D3WrapperProps {
  width: number;
  height?: number;
  className?: string;
}

export const CHART_MARGINS = {
  top: 110,
  bottom: 30,
  left: 25,
  right: 15
};

export const D3Wrapper = ({ width, height, className }: D3WrapperProps): JSX.Element => {
  const chartArea = useRef<HTMLDivElement | null>(null);
  const chart = useRef<D3Chart>();

  const { data, zoomDaterange } = useDataManagementProvider(); //data.machine_states_icons_data is sorted in acsending order
  const [chartWidth, setChartWidth] = useState<number>(width);
  const [showLabels, setShowLabels] = useState<boolean>(true);

  const handleLabelsShow = () => setShowLabels(!showLabels);

  const line1 = {
    data: data?.ppm_data,
    xSelector: 'timestamp',
    ySelector: 'ppm',
    iconsData: data?.machine_states_icons_data
  };

  const line2 = {
    data: data?.ideal_ppm_data,
    xSelector: 'timestamp',
    ySelector: 'ideal_ppm'
  };

  const line3 = {
    data: data?.recipe_line_data,
    xSelector: 'timestamp',
    ySelector: 4
  };

  const chartSettings = {
    chartMargins: CHART_MARGINS,
    xMainAxisSelector: 'timestamp',
    yMainAxisSelector: 'ideal_ppm',
    xDomain: zoomDaterange,
    yDomain: [0, 120],
    isLabelVisible: showLabels,
    lablesData: data?.machine_states_icons_data,
    numberofLabels: 5,
    dataLines: [
      {
        lineName: 'line1',
        lineSettings: line1
      },
      {
        lineName: 'line2',
        lineSettings: line2
      },
      {
        lineName: 'line3',
        lineSettings: line3
      }
    ],
    tooltip: displayTooltip
  };

  //Width changes first
  useEffect(() => {
    if (!zoomDaterange || !data?.ppm_data) return;
    chart.current = new D3Chart(chartArea.current, chartWidth, height, { ...chartSettings });
  }, [chartWidth]);

  //Width state changes second
  useEffect(() => {
    setChartWidth(width);
  }, [width]);

  //Zoom range changes third
  useEffect(() => {
    //we cannot update the chart without data, or can we, should we show No Data message?
    if (!data?.ppm_data) return;

    if (chart.current) {
      chart.current.update(line1, line2, line3, zoomDaterange, showLabels);
    } else {
      chart.current = new D3Chart(chartArea.current, chartWidth, height, { ...chartSettings });
    }
  }, [zoomDaterange, data]);

  useEffect(() => {
    if (!zoomDaterange || !data?.ppm_data) return;
    if (chart.current) {
      chart.current.toggleLabels(showLabels);
    }
  }, [showLabels]);

  return (
    <div className={className}>
      <LiveChartActionButtonsContainer>
        <button className="livechart--button" onClick={() => handleLabelsShow()}>
          <EyeIcon color={styledTheme.colors.secondary} />{' '}
          {showLabels ? 'Hide Labels' : 'Reveal labels'}
        </button>
      </LiveChartActionButtonsContainer>
      <D3WrapperContainer>
        {(!data?.ppm_data || data?.ppm_data.length === 0) && <NoData />}
        <div
          className={`chart-area ${!data?.ppm_data ? `hidden` : undefined}`}
          style={{ height: height }}
          ref={chartArea}
        ></div>
      </D3WrapperContainer>
      <div className="tooltip--container"></div>
    </div>
  );
};
