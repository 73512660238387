import styled from 'styled-components';
import { DateRangeButtonContainer } from 'components/StyledUi/DateRange/DateRangeButton/DateRangeButton.elements';
import { WidgetUiBorderStyles } from 'common/ui/WidgetUi/WidgetUi.elements';

export const SessionSelectButtonArea = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 1em;
  align-items: center;
`;

export const SessionSelectButton = styled(DateRangeButtonContainer)`
  font-size: 1em;
  display: grid;
  gap: 0.7em;
  justify-content: center;
  grid-template-columns: auto 1fr;
  padding: 0.7em;

  .icon-calendar {
    transform: translateY(-0.09em);
  }
`;

export const SelectSessionFlyoutContainer = styled.div`
  height: 100%;

  .ui-date-button-w-dropdown {
    margin: 0 auto;
  }

  .scroll-area {
    overflow: hidden;
  }

  .main-padder {
    padding: 1em;
    overflow: hidden;
    max-height: 100%;
    max-width: 100%;
    background: pink;
  }

  display: flex;
  flex-direction: column;
  gap: 1em;
  width: max-content;

  .cta-cell {
    display: flex;
    align-items: flex-end;
    grid-column: 2;
    justify-content: flex-end;
    color: rgb(0, 118, 204);
  }
`;

export const SessionSelectPopupContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 1em;
  height: 100%;
  width: max-content;

  .ui-date-button-w-dropdown {
    margin: 0 auto;
  }

  .scroll-area {
    overflow: hidden;
  }

  .main-padder {
    padding: 1em;
    overflow: hidden;
    max-height: 100%;
    max-width: 100%;
    background: pink;
  }

  .session-list {
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: max-content;

    a {
      ${WidgetUiBorderStyles};

      color: inherit;
      text-decoration: none;
      padding: 1em;
      gap: 0.5em;

      .col-group {
        display: grid;
        grid-gap: 1em;

        &.underlined {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          padding-bottom: 0.5em;
          margin-bottom: 0.5em;
        }

        &.col-group--2-col {
          grid-template-columns: 1fr 1fr;

          &.alternate-alignment .item-group:nth-child(even) {
            text-align: right;
          }
        }

        &.col-group--3-col {
          grid-template-columns: 1fr 1fr 1fr;
        }
      }

      .split {
        grid-row: 1;
        &:first-child {
          grid-column: 1;
        }
        &:last-child {
          grid-column: 2;
        }
      }

      .item-group {
        font-weight: 500;
        span {
          display: block;
          text-transform: capitalize;
          opacity: 0.7;
        }
      }
    }

    .cta-cell {
      display: flex;
      align-items: flex-end;
      grid-column: 2;
      justify-content: flex-end;
      color: rgb(0, 118, 204);
    }
  }
`;

export const SelectSessionFlyoutHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 0.5em;

  > strong {
    display: block;
    transform: translateY(0.05em);
  }
`;
