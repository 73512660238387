import React from 'react';
import { calculateTimeDisplay } from '../../utils';
import { IcoDateHistory } from 'icons/IcoDateHistory';
import { useTranslation } from 'react-i18next';
import { FooterContainer } from './Footer.elements';

export const Footer = ({ start, end }: { start: Date; end: Date }): JSX.Element => {
  const { t } = useTranslation(['mh']);
  const timeDisplay = calculateTimeDisplay(new Date(start), new Date(end)) || '';

  return (
    <FooterContainer className="footer--timerange-stamp">
      <IcoDateHistory />
      {t('Viewing')}: <strong>{timeDisplay}</strong>
    </FooterContainer>
  );
};
