import { useRouter } from 'common/hooks/useRouter';
import { fleetPageRoute } from 'common/pages/fleetV2/settings/fleetPageRoutes';
import { useGetAsepticMachineUtilizationQuery } from 'api';
import { useWidgetDateRange } from 'common/ui/WidgetUi/providers/useWidgetDateRange';

import {
  convertKeyValuesToChartDataArray,
  convertObjectArrayToLegendItems
} from 'common/components';
import { groupByKey } from 'common/helpers/dataGroupers';
import { useBuSettings } from 'common/pages/fleetV2/providers/useBuSettings';

export const useMachineUtilizationWidget = (): {
  isLoading?: boolean;
  hasError?: string;
  hasMessage?: string;
  chartData?: Record<string, unknown>[];
  noData?: boolean;
  timeZone: string;
  colors?: Record<string, string>;
  mapKeys?: Record<string, string>;
  [key: string]: unknown;
} => {
  const { id } = useRouter(fleetPageRoute) as { id: string };
  const { utcDateRange, timeZone } = useWidgetDateRange();
  const { startTime } = utcDateRange;
  const { mapKeys, colors } = useBuSettings() || {};

  const { data, error, isLoading } = useGetAsepticMachineUtilizationQuery({
    machineId: id,
    utc_start_datetime: startTime
  });

  // const dataToUse = data; //demoDataObjectIdKeyValues;
  const hasError = error ? 'error getting data' : undefined;

  const chartData =
    data &&
    convertKeyValuesToChartDataArray(
      // array of key id objects where the key is the group and id is the value
      data,
      // the key used for the bottom axis axis column
      `dateUtc`,
      {
        ignoreKeys: ['denominator', 'dateMachineTime'],
        mapKeys,
        colors
      }
    );

  const groupedDataForTooltips = groupByKey({ data: chartData, key: 'category' });

  const legendItems =
    chartData &&
    convertObjectArrayToLegendItems(chartData, {
      labelKey: 'group',
      colors: colors as Record<string, string>,
      valueKey: 'value'
    });

  return {
    isLoading,
    hasError,
    chartData,
    legendItems,
    groupedDataForTooltips,
    colors,
    timeZone,
    mapKeys
  };
};
