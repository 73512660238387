import styled from 'styled-components';
import theme from 'common/theme';

export const SVGContainer = styled.svg`
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  overflow: visible;
  z-index: 1;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: ${theme.colors.gridLine};

  path {
    cursor: pointer;
  }
`;

export const Path = styled.path<{ fill?: string; stroke?: string; size?: number; color?: string }>`
  fill: none;
  stroke: ${({ stroke, color }) => stroke || color || 'black'};
  stroke-width: ${({ size }) => size || 1};
  stroke-linecap: round;
  stroke-linejoin: round;
  cursor: pointer;
`;
