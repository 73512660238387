import React, { useMemo } from 'react';
import { MachineModesContainer } from './elements';
import { InlineChartLegend } from 'common/components';
import { legendSettings } from './utils';
import { MachineModeContainer } from './ui/Chart/MachineModeContainer';
import { ActualDurationWrapper } from './ui/ActualDuration/ActualDurationWrapper';
import { useTranslation } from 'react-i18next';
import { DatepickerBarWrapper } from './ui/Datepicker/DatepickerBarWrappr';
import { useMachineModesAPIProvider } from './provider/useMachineModesProvider';
import { DataLoaderIcons } from 'common/components/DataLoader/DataLoaderIcons';
import { ZoomBrushContainer } from './ui/Zoom/ZoomBrushContainer';

export const MachineModesWidget = (): JSX.Element => {
  const { t } = useTranslation(['mh']);

  const { hasError } = useMachineModesAPIProvider();

  //Convert to different type
  const legend = legendSettings as unknown as Record<string, unknown>[];

  const widget = useMemo(() => {
    return (
      <>
        <div className={`machine-modes-main`}>
          <InlineChartLegend items={legend} className="legend" />
          <DatepickerBarWrapper />
          <hr className="border-line"></hr>
          <ZoomBrushContainer className="machine-states--zoom" />
          <ActualDurationWrapper className="machine-states-duration" />
          <MachineModeContainer className="new-machine-states-zoomed" />
        </div>
      </>
    );
  }, [hasError]);

  const displayError = (
    <div className="widget-ui__main">
      {DataLoaderIcons.hasError}
      <div className="loader-message">{hasError}</div>
    </div>
  );

  return (
    <MachineModesContainer className="widget_ui machine-mode-container">
      <header className={`widget-ui__header ${hasError && 'has-error'}`}>
        <h2>{t('Machine Modes')}</h2>
      </header>
      {hasError && displayError}
      {!hasError && widget}
    </MachineModesContainer>
  );
};
