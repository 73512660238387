import { ReactNode } from 'react';
import { useWidgetDateRange } from 'common/ui/WidgetUi/providers/useWidgetDateRange';

export const useConfigurationForm = (): {
  isLoading?: boolean;
  hasError?: ReactNode;
  hasMessage?: ReactNode;
  data?: Record<string, unknown>[];
  [key: string]: unknown;
} => {
  const { startTime, endTime } = useWidgetDateRange();
  if (!startTime) console.log({ startTime, endTime });
  // make the api call
  const demoData = [
    { lable: 'one', value: 1 },
    { lable: 'two', value: 2 },
    { lable: 'three', value: 3 }
  ];

  const demoApi = {
    isLoading: false,
    hasError: false,
    hasMesssage: false,
    data: demoData
  };

  return demoApi;
};
