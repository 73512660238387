import React, { useEffect, useState } from 'react';
import * as d3 from 'd3';
import {
  ActualDetailsTransformed,
  LabelSettings,
  TargetDetailsTransformed,
  WrapperData,
  WrapperProps
} from './types';
import { isEqual, cloneDeep } from 'lodash';
import { COLORMAP } from './utils';
import { DatepickerBar } from 'common/pages/fleet/machine/proseal/MachineProduction/components/DatepickerBar/DatepickerBar';
import { InlineChartLegend } from 'common/components';
import { ActualDurationWrapper } from './ActualDurationWrapper';
import { LaneStatesContainer } from './LaneStatesContainer';
import { ZoomBrushContainer } from 'common/components/WheelZoomChart/ZoomBrushContainer';
import { NoData } from 'common/ui/StatesHorizontalBarChart/NoData';
import { utcToZonedTime } from 'date-fns-tz';
import { useMachineInfo } from 'common/pages/fleetV2/providers';

const Wrapper = ({ data, currentSession }: WrapperProps): JSX.Element => {
  const [zoomDaterange, setZoomDaterange] = useState<Date[] | undefined>(undefined);
  const [filteredData, setFilteredData] = useState<WrapperData | undefined>(data);
  const { timeZone } = useMachineInfo();
  const timezone = timeZone || 'UTC';

  useEffect(() => {
    //Find min and max timestamp for actual and target values
    const Astart = d3.extent(
      data?.actual_details as ActualDetailsTransformed[],
      (d) => d.start_time
    );
    const Aend = d3.extent(data?.actual_details as ActualDetailsTransformed[], (d) => d.end_time);
    const Tstart = d3.extent(
      data?.target_details as TargetDetailsTransformed[],
      (d) => d.start_time
    );
    const Tend = d3.extent(data?.target_details as TargetDetailsTransformed[], (d) => d.end_time);

    const alldates = [...Astart, ...Aend, ...Tstart, ...Tend] as unknown as Date[];
    const dateRange = d3.extent(alldates) as Date[];
    dateRange && setZoomDaterange(dateRange);
  }, [currentSession]);

  const handleResetButton = (): void => {
    // Format the date in the specified time zone
    const zonedStartDate = utcToZonedTime(currentSession.startTimestamp as string, timezone);
    const zonedEndDate = utcToZonedTime(currentSession?.endTimestamp as string, timezone);

    const endTime = zonedEndDate ? zonedEndDate : new Date();

    //Reset zoomed range to date range
    setZoomDaterange([zonedStartDate, endTime]);
    setFilteredData(data);
  };

  const handelZoomInDaterangeUpdate = (daterange: Date[]): void => {
    const start = daterange[0];
    const end = daterange[1];

    const copyData = cloneDeep(data?.actual_details);

    const filteredEvents = copyData?.reduce((acc, item) => {
      //if item start date is less than zoom start and if item end date more than zoom start
      if (item.start_time <= start && item.end_time >= start && item.end_time <= end) {
        const duration = item.end_time.getTime() - start.getTime();
        item.start_time = start;
        item.duration = duration;
        acc.push(item);

        //if item end date is more than zoom end and if item start date is less than zoom end
      } else if (item.end_time >= end && item.start_time <= end && item.start_time >= start) {
        const duration = end.getTime() - item.start_time.getTime();
        item.end_time = end;
        item.duration = duration;
        acc.push(item);

        //if item start date is more less than zoom start and if item end date is more than zoom end
      } else if (item.start_time <= start && item.end_time >= end) {
        const duration = end.getTime() - start.getTime();
        item.start_time = start;
        item.end_time = end;
        item.duration = duration;
        acc.push(item);
      } else if (item.start_time >= start && item.end_time <= end) {
        //do nothing, keep original start and end date
        acc.push(item);
      }

      return acc;
    }, []);

    setFilteredData({ ...filteredData, actual_details: filteredEvents });
    setZoomDaterange([start, end]);
  };

  //Find min and max timestamp for actual and target values
  const Astart = d3.extent(data?.actual_details as ActualDetailsTransformed[], (d) => d.start_time);
  const Aend = d3.extent(data?.actual_details as ActualDetailsTransformed[], (d) => d.end_time);
  const Tstart = d3.extent(data?.target_details as TargetDetailsTransformed[], (d) => d.start_time);
  const Tend = d3.extent(data?.target_details as TargetDetailsTransformed[], (d) => d.end_time);
  const alldates = [...Astart, ...Aend, ...Tstart, ...Tend] as Date[];

  let chartDateRange = undefined;
  if (data) chartDateRange = d3.extent(alldates) as Date[];

  const legendSettings =
    filteredData?.actual_details &&
    (Object.values(
      [
        ...(filteredData?.actual_details as ActualDetailsTransformed[]),
        ...(filteredData?.target_details as TargetDetailsTransformed[])
      ].reduce((acc: Record<string, LabelSettings>, curr) => {
        if (!acc[curr.mode_descr]) {
          acc[curr.mode_descr] = {
            label: curr.mode_descr,
            color: COLORMAP[curr.mode_descr]
          };
        }
        return acc;
      }, {})
    ) as LabelSettings[]);

  const chartData = [
    {
      data: data?.actual_details,
      label: 'Actual Details',
      parentProperty: 'actual_details'
    },
    {
      data: data?.target_details,
      label: 'Target Details',
      parentProperty: 'target_details'
    }
  ];

  const settings = {
    data: data,
    filteredData: filteredData,
    chartDaterange: chartDateRange,
    zoomDaterange,
    handelZoomInDaterangeUpdate,
    chartData,
    linesData: data.actualAndTargetValues
  };

  const settingsDaterange = {
    showDaterange: false,
    handleReset: handleResetButton,
    currentSession
  };

  const machineStateData = {
    data: data?.actual_details
  };

  const zoomSettings = {
    data: machineStateData as unknown as Record<string, unknown>,
    filteredData: filteredData as unknown as Record<string, unknown>[],
    chartDaterange: chartDateRange,
    zoomDaterange,
    handelZoomInDaterangeUpdate
  };

  const widgetContent =
    data && data?.actual_details.length > 0 ? (
      <>
        <MemoizedInlineChartLegend
          className="legend"
          items={legendSettings as unknown as Record<string, unknown>[]}
        />
        <hr className="border-line"></hr>
        <MemoizedZoomBrushContainer className="lane-states--zoom" {...zoomSettings} />
        <ActualDurationWrapper className="lane-states-duration" {...settings} />
        <LaneStatesContainer {...settings} className="lane-states-barchart" />
      </>
    ) : (
      <>
        <NoData />
      </>
    );

  return (
    <>
      <DatepickerBar {...settingsDaterange} />
      {widgetContent}
    </>
  );
};

const customComparator = (
  prevProps: { data: { actual_details?: ActualDetailsTransformed[] } },
  nextProps: { data: { actual_details?: ActualDetailsTransformed[] } }
) => {
  const isTrue = isEqual(
    prevProps.data?.actual_details as ActualDetailsTransformed[],
    nextProps.data?.actual_details as ActualDetailsTransformed[]
  );
  return isTrue;
};
export const MemoizedWrapper = React.memo(Wrapper, customComparator);

const ZoomBrushContainerCustomComparator = (prevProps, nextProps) => {
  const oldDataVsNewData = isEqual(prevProps?.data.data, nextProps?.data.data);
  const dataVsFilteredData = isEqual(prevProps?.data.data, nextProps?.filteredData.actual_details);
  //if old data is the same as new data and filtered data the same as data -> reload, return false
  if (oldDataVsNewData && dataVsFilteredData) return false;
  //If data is the same as filtered data that means zoom range has been reset and we need to rerender
  if (oldDataVsNewData && !dataVsFilteredData) return true;
  return false;
};
const MemoizedZoomBrushContainer = React.memo(
  ZoomBrushContainer,
  ZoomBrushContainerCustomComparator
);

const InlineChartLegendComparator = (prevProps, nextProps) => {
  const oldDataVsNewData = isEqual(prevProps.items, nextProps.items);
  return oldDataVsNewData;
};
const MemoizedInlineChartLegend = React.memo(InlineChartLegend, InlineChartLegendComparator);
