import styled from 'styled-components';
import { StyledUiContainerProps, styledTheme } from 'components';

import { WidgetUiBorderStyles } from '../WidgetUi/WidgetUi.elements';

export const IconTileContainer = styled.div<StyledUiContainerProps>`
  ${WidgetUiBorderStyles}

  font-size: clamp(12px, 1vw, 14px);
  display: grid;
  padding: 1em;
  row-gap: 0.5em;
  column-gap: 10%;
  transition: border-color 1s linear both;

  grid-auto-flow: column;
  grid-template-rows: auto 1fr;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    'title title'
    'left right';

  color: ${styledTheme.color.main};

  .icon-tile__title {
    font-size: 1em;
    min-height: 1em;
    grid-area: title;
    text-transform: capitalize;
  }

  h3 {
    margin: 0;
    padding: 0;
  }

  .icon-tile__value {
    min-height: 1em;
    font-size: 2.5em;
    font-weight: 300;
    color: rgba(102, 102, 102, 1);
    transition: opacity 1s ease-in-out both;
    display: flex;
    align-items: center;

    &.left {
      grid-area: left;
    }
    &.right {
      grid-area: right;
    }
  }

  .icon {
    height: 1em;
    path {
      transition: stroke 1s ease-in-out both;
    }

    &.icon-wrench {
      height: 0.9em;
    }
  }

  .data-loader {
    min-height: 1em;
    font-size: 2.8em;
    font-weight: 300;
    grid-row: 2;
    grid-column: 1 / span 2;
  }

  &:not(.is-loading) {
    border-color: ${({ colr }) => colr};
    opacity: 1;
  }

  &.is-loading {
    .icon-tile__value {
      opacity: 0.5;
    }
    .icon {
      svg {
        path {
          stroke: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }

  &.icon-right {
    grid-template-columns: 1fr auto;
  }
`;
