import styledTheme from 'common/theme';
import { space } from 'common/theme/standard.theme';
import styled from 'styled-components';

export const RecipesTableContainer = styled.div`
  .customcss {
    margin: 0;
    padding: 0;
  }

  table {
    border-top: 1px solid ${styledTheme.colors.gray300};
    tr {
      th {
        padding: 12px;

        .select-none {
          margin: 0;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 12px;
          span {
            margin: 0;
          }
        }
        td:first-child {
          width: 25%;
        }
        td:nth-child(2) {
          width: 25%;
        }
        td:nth-child(3) {
          width: 25%;

          input {
            display: block;
          }
        }
        td:nth-child(4) {
          text-align:left;
          width: 20%;
        }
        td:nth-child(5) {
          width: 5%;
        }
      }
    }

    tfoot {
      display: none;
    }
  }

  .swatch-color.with-dropdown-button {
    svg {
      display inline-block;
      vertical-align: middle;
    }
  }

  input[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  .edit-cell-container {
    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
  }

  .footer-total-count {
    font-size: 0.85rem;
    margin: 0;
    padding: 12px;
    color: ${styledTheme.colors.gray800};
    line-height: 32px;
  }
`;

export const RecipeSection = styled.section`
  padding: ${space.lg};

  .title_section {
    display: flex;
    h2 {
      margin: 0.5rem 0;
    }
  }
`;
