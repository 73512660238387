import React from 'react';

import { useFeedFactorWidget } from './hooks/useFeedFactorWidget';
import { PieChartWidget } from 'common/components';
import { WidgetDateRangeProvider } from 'common/ui';
import { useFilterSelected } from 'components';
import { Tooltip } from './Tooltip';

export const FeedFactorWidget = (props?: Record<string, unknown>): JSX.Element => {
  return (
    <WidgetDateRangeProvider
      defaultLabel="last 60 minutes"
      subtractHours={1}
      //debugLabel={`feed factor widget`}
    >
      <Provided {...props} />
    </WidgetDateRangeProvider>
  );
};

const Provided = (props: Record<string, unknown>): JSX.Element => {
  const { isLoading, hasMessage, hasError, chartData, avg_feed_factor } = useFeedFactorWidget();
  const [selected] = useFilterSelected();
  // chartData = chartData.slice().sort((a, b) => b.count - a.count);

  /*
  const handleClick = (slice: Record<string, unknown>) => {
    //const isSelected = selected?.group?.includes(slice.group as string) ? true : false;
    return console.log('feed factor click', { slice }); //handle(isSelected ? 'toggle' : 'set', { group: slice.group as string });
  };
  */

  // the function that helps determins if the slice is selected
  const checkIfSelected = (slice: Record<string, unknown>) => {
    if (!selected) return true;
    const group = (slice.label as string) || (slice.group as string);
    const selectedVals = selected?.group || selected?.label || [];
    if (selectedVals.length && selectedVals.includes(group)) return true;
    return false;
  };

  const widgetSettings = {
    title: 'Feed Factor',
    className: 'feed-factor-widget',
    hasError,
    isLoading,
    hasMessage
  };

  const pieChartSettings = {
    type: 'doughnut',
    data: chartData,
    //handleClick,
    checkIfSelected,
    centerCellValues: {
      value: avg_feed_factor,
      label: 'average feed factor'
    },
    autoLegend: true,
    format: {
      legendItem: (props: Record<string, unknown>) => {
        const label = props?.label as string;
        return <>{label}</>;
      }
    },
    hasDateFooter: true,
    TooltipComponent: Tooltip,
    ...props
  };
  return <PieChartWidget {...pieChartSettings} {...widgetSettings} />;
};
