import React from 'react';
import { ActualDuration } from './elements';
import { BarItemProp, DurationProps } from './types';
import { Bars } from './Bars';

export const ActualDurationComponent = ({ title, className, data }: DurationProps): JSX.Element => {
  const content =
    data.length > 0 ? <Bars items={data as unknown as BarItemProp[]} /> : <p>No data</p>;

  return (
    <ActualDuration className={className}>
      <h3>{title}</h3>
      {content}
    </ActualDuration>
  );
};
