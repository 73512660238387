import React from 'react';
import type { FC, SVGProps } from 'react';

const IcoMediumFillCircle: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="0.5" y="0.5" width="12" height="12" rx="6" fill="#BA4E00" />
    </svg>
  );
};

export default IcoMediumFillCircle;
