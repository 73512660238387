import React, { FC, ReactNode } from 'react';
import { useContainerSize, UseContainerSizeReturnProps } from './hooks/useContainerSize';

import {
  DimensionsContainerWrapper,
  DimensionsContainerWrapperProps
} from './DimensionsContainer.elements';
import { TSErrorWrapper } from 'common/components/TSErrorWrapper/TSErrorWrapper';

/** ************************************************************************************
 *
 * DimensionsContainer uses the useRef hook to create a reference to a div element that will be attached
 *  to the DimensionsContainerWrapper. This reference is used to track the size of the container.
 *
 *  Then, the useContainerSize hook is used to get the width, height, triggerResize, and divRef properties.
 *  This hook calculates the size of the container based on the size of its parent element.
 *
 *  The className property is added to the base class dimensions-container if it exists.
 * Finally, the DimensionsContainerWrapper is rendered with the divRef and Component with the width and height props.
 *
 *************************************************************************************** */
export type DimensionsContainerReturnProps = FC<UseContainerSizeReturnProps>;

export interface DimensionsContainerProps extends DimensionsContainerWrapperProps {
  Component: (x?: Record<string, unknown>) => JSX.Element;
  className?: string;
  gridArea?: string;
  debounce?: number;
  width?: number | string;
  height?: number | string;
  passProps?: Record<string, unknown>;
  After?: ReactNode;
}
const baseClass = `dimensions-container`;

export const DimensionsContainer = ({
  Component,
  className,
  gridArea,
  debounce,
  dims,
  passProps,
  After
}: DimensionsContainerProps): JSX.Element => {
  // Create a ref that will be attached to the DimensionsContainerWrapper to track the size of the container

  const { width, height, containerRef } = useContainerSize(debounce || 300);

  // Use the useContainerSize hook to get the width and height of the container

  // Add the base class to the className if it exists
  className = className ? `${baseClass} ${className}` : baseClass;

  //dims = dims || { width, height }
  // Render the DimensionsContainerWrapper with the divRef and the Component with the width and height props
  return (
    <TSErrorWrapper>
      <>
        <DimensionsContainerWrapper ref={containerRef} {...{ className, gridArea, dims }}>
          <Component {...{ ...passProps, width, height }} />
        </DimensionsContainerWrapper>
        {After}
      </>
    </TSErrorWrapper>
  );
};
