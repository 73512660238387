import React, { ReactNode, createContext, useContext, useState, useCallback } from 'react';

interface Props {
  children?: ReactNode | ReactNode[];
}

export type ChartSelectorHistoryItemProps = Record<string, unknown> | undefined;
export type UseChartSelectorHandlerProps = (type: string, x?: Record<string, unknown>) => unknown;
export type UseChartSelectorReturnProps = [
  ChartSelectorHistoryItemProps,
  UseChartSelectorHandlerProps,
  {
    isInit?: boolean;
  }
];

export const UseChartSelectionsProvider = ({ children }: Props): JSX.Element => {
  const [selected, setSelected] = useState<Record<string, unknown> | undefined>(undefined);

  const handlers = useCallback(
    (type: string, x?: Record<string, string | number | unknown>) => {
      let keyToUse = 'group';
      let valueToUse: string | undefined = undefined;

      if (typeof x === 'object') {
        keyToUse = Object.keys(x)[0];
        valueToUse = x?.[keyToUse] as string;
      }

      switch (type) {
        case 'checkIfSelected': {
          const keyToCheck = keyToUse;
          const valueToCheck = valueToUse;

          if (!keyToCheck || !valueToCheck) return false;
          if (!selected) return false;
          if (!selected?.[keyToCheck as string]) return false;
          if (selected?.[keyToCheck as string] !== valueToCheck) return false;
          return true;
        }

        case 'checkIfFiltered': {
          const keyToCheck = keyToUse;
          const valueToCheck = valueToUse;

          if (!keyToCheck || !valueToCheck) return false;
          if (!selected) return false;
          if (selected?.[keyToCheck as string] !== valueToCheck) return true;
          return false;
        }

        case 'set': {
          const keyToCheck = keyToUse;
          const valueToCheck = valueToUse;
          if (!keyToCheck || !valueToCheck) return;
          if (!selected) return setSelected({ [keyToCheck as string]: valueToCheck });
          break;
        }

        case 'clear': {
          setSelected(undefined);
          break;
        }

        case 'toggle': {
          const keyToCheck = keyToUse;
          const valueToCheck = valueToUse;

          if (!keyToCheck || !valueToCheck) return;
          if (!selected) return setSelected({ [keyToCheck as string]: valueToCheck });
          if (selected?.[keyToCheck as string] === valueToCheck) return setSelected(undefined);
          return setSelected({ [keyToCheck as string]: valueToCheck });
        }

        default: {
          console.log('invalie handler type for useChartSelector', { type, x });
          return undefined;
        }
      }
    },
    [selected]
  );

  return (
    <ChartSelectionsContext.Provider value={[selected, handlers, { isInit: true }]}>
      {children}
    </ChartSelectionsContext.Provider>
  );
};

const ChartSelectionsContext = createContext<UseChartSelectorReturnProps>([
  undefined,
  (type: string) => console.log('filter not set', { type }),
  {}
]);

export const useChartSelections = (): UseChartSelectorReturnProps =>
  useContext(ChartSelectionsContext);
