import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5625rem;
  min-height: 24.313rem;
`;

export const WidgetMainContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  overflow: visible !important;
  position: relative;
  gap: 2em;
  height: 19rem !important;
`;
