import styled from 'styled-components';
import theme from 'common/theme';

export const ProductionOutputWidgetContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr auto;
  padding-bottom: 2em;

  .chart-legend-container {
    grid-column-start: span 2;
    padding: 0.5em 1.2em;
  }

  .chart-generator-container {
    padding: 1em 4em 2em 4em;
    grid-row: 2;
    grid-column-start: span 1;
  }

  .tooltip-group {
    display: flex;
    flex-direction: column;
    gap: 0.25em;
  }
`;

export const TotalsColumnContainer = styled.div`
  padding: 0 2em;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  border-left: solid 1px ${theme.colors.border.lightest};

  .group-spacer {
    display: flex;
    flex-grow: 1;
    height: 100%;
    flex-direction: column;
    justify-content: center;

    border-top: solid 1px ${theme.colors.border.lightest};

    &:nth-child(1) {
      border-top: none;
    }
  }
`;

export const ValueGroupContainer = styled.div<{ clr?: string }>`
  font-weight: 500;
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: auto;
  align-items: center;
  height: max-content;

  .group {
    padding: 0.5em 0;
  }
  .value-group-title {
    text-transform: capitalize;
    padding-top: 1em;
  }

  .group-value {
    font-size: 1.5em;
    font-weight: 600;
  }

  .group-label {
    color: ${theme.colors.label};
    text-transform: capitalize;
    font-size: 0.9em;
  }

  .is-colored {
    color: ${({ clr }) => clr};
  }
`;
