// 3rd party libs
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConsumablesLogo } from 'icons/ConsumablesLogo';
import { startOfDay, differenceInDays } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

import {
  createContainer,
  VictoryAxis,
  VictoryAxisProps,
  VictoryBrushContainerProps,
  VictoryChart,
  VictoryContainerProps,
  VictoryLabel,
  VictoryLabelProps,
  VictoryLine,
  VictoryTooltip,
  VictoryVoronoiContainerProps
} from 'victory';
import { faCircle, faInfoCircle, faSquare } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuidv4 } from 'uuid';

import ChartVectorRed from './assets/chart_vector_red.svg';
import ChartVectorCyan from './assets/chart_vector_cyan.svg';
import ChartVectorBlue from './assets/chart_vector_blue.svg';
import ChartVectorOrange from './assets/chart_vector_orange.svg';
import ChartVectorPurple from './assets/chart_vector_purple.svg';

// Components
import { ActionButton, Card, Typography } from 'components';

// Theme
import victoryTheme from 'themes/victory';

// Hooks
import { useContainerSize } from 'hooks';
import { useContainerResize, useDate, useTimeZone } from 'providers';

// Types
import { DateTuple, ZoomObjectTuples } from 'types';
import { BrushProps, Data, DatePoint, Series } from 'types/graph';

// Helpers
import { DEFAULT_LEFT_PADDING, getXaxisOffset } from 'helpers/graph';
import {
  drawLegends,
  drawLineSeries,
  drawPoints,
  formatBooleanAxis,
  SEPARATOR,
  zoom
} from './plot';

// Themes
import theme from 'themes';
import { SettingIcon } from 'icons/SettingIcon';
import {
  Container,
  RightSideContainer,
  KPICellValueContainer,
  LabelComponent,
  LeftSideContainer,
  NewLegend,
  NoContentText,
  StyledIconButton,
  StyledMetadataButton,
  StyledMetadataIcon,
  StyledMetadataInfo,
  TitleDiv,
  TitleText,
  VictoryChartContainer,
  RightHeaderDiv,
  RightSubContainer
} from './NewKPIOverTimeGraph.elements';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { GoBackIcon } from 'icons/IconGoBack';

type PaddingObject = {
  bottom: number;
  left: number;
  right: number;
  top: number;
};

const DataZoom = styled.div`
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.text.lightBlack};
  border-radius: 0.5rem;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.6rem;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 18px;
  vertical-align: center;
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.colors.mediumBlue4};
  }
`;

export const ZoomContainer = styled.div`
  display: flex;
`;

export const IconStyle = styled.div`
  display: flex;
  align-items: center;
  margin-left: 4px;
`;
const ChartVectorImgList = [
  ChartVectorRed,
  ChartVectorCyan,
  ChartVectorBlue,
  ChartVectorOrange,
  ChartVectorPurple
];

export type KPIOverTimeGraphHandle = {
  resetZoom: () => void;
} | null;

export type GraphProps = {
  // Graph title
  title?: string;
  // Graph heading
  heading?: React.ReactNode;
  // The horizontal axis props
  axisH: VictoryAxisProps;
  // The (left) vertical axis props, if any
  axisV?: VictoryAxisProps;
  // The (right) axis, if any
  rightAxisV?: VictoryAxisProps;
  // Line series that should be drawn
  series: Series[];
  // range of x values the component will include,
  // If this prop is not provided, a domain will be calculated from data, [min, max] of all
  // series
  xDomain?: DateTuple;
  yDomain?: [number, number];
  // Renders padding in the body of the graph. X is for padding on the
  // horizontal axis, Y is for the vertical.
  domainPadding?: { x?: number; y?: number };
  // Optional icon/button to display
  iconButton?: React.ReactNode;
  // Flag to sync zooming with other graphs
  sync?: boolean;
  brush?: BrushProps;
  target?: { value?: number; colour?: string; label?: string };
  legendBgColor?: string;
  tooltip?: { color: string; bg: string; width?: number };
  interpolation?: string;
  hideLegend?: boolean;
  graphContainerHeight?: string;
  containerComponent?: string;
  children?:
    | React.ReactNode
    | ((graphWidth: number, graphHeight: number, padding: PaddingObject) => React.ReactNode);
  // Flag to format axis
  isBooleanChart?: boolean;
  // Display Zero Line axis
  displayZeroLine?: boolean;
  isZoomEnabled?: boolean;
  onZoom?: () => void;
  // Upon enabling this, info icon will appear on the right side of chart,
  // by clicking on the icon, a slider will show up on the right, which shows metadata information.
  // metadata information can also be passed to "series" data property as "metadata" to override this "metadata"
  // to be able to update metadata according to each point when hovering over a point on graph and seeing tooltip
  metadata?: { mainTitle: string; data?: { title: string; value?: string; values?: string[] }[] };
  // Add points to line chart
  points?: { id: string; data: DatePoint[] }[];
  setIsEditAdminPopupOpen?: () => void;
  isLoading?: boolean;
};

const PADDING: PaddingObject = { top: 10, right: 50, bottom: 50, left: 70 };

const VictoryBrushCursorContainer = createContainer<
  VictoryBrushContainerProps,
  VictoryVoronoiContainerProps
>('brush', 'voronoi');

type MetadataTooltipProps = {
  datum?: {
    metadata: { mainTitle: string; data?: { title: string; value?: string; values?: string[] }[] };
    x: Date | string;
  };
  setMetadata: (metadata: {
    mainTitle: string;
    data?: { title: string; value?: string; values?: string[] }[];
  }) => void;
  metadata?: { mainTitle: string; data?: { title: string; value?: string; values?: string[] }[] };
  tooltip: { color: string; bg: string; width?: number };
};

// Render tooltip onMouseOver
const CustomLabelComponent = (
  props: VictoryContainerProps & VictoryLabelProps & MetadataTooltipProps
) => {
  const { x, y, text, datum, setMetadata, metadata, tooltip } = props;
  // Number of labels to be displayed
  const filteredText = (text as string[]).filter(String);
  // const nb = filteredText.length * 1.1;
  const initialX = (x || 0) - 115;
  const initialY = (y || 0) - 18 * 1.5;
  const metadataValue = datum?.metadata;
  // To update metadata when we hover over values and values change
  if (metadata && metadataValue) {
    useEffect(() => {
      setMetadata(metadataValue);
    }, [metadataValue]);
  }

  return (
    <g>
      <foreignObject x={initialX} y={initialY} width="230" height={50}>
        <LabelComponent {...tooltip}>
          {filteredText.map((t, i) => {
            const data = t
              .split(SEPARATOR)
              .map((part) => part.replace(/accumulated/gi, 'Consumption'));
            return (
              <div key={`${t}-${i}`}>
                {data[1] && <FontAwesomeIcon icon={faSquare} color={data[1]} />}
                <span>{data[0]}</span>
              </div>
            );
          })}
        </LabelComponent>
      </foreignObject>
    </g>
  );
};
// Depending on the orientation, we allow more padding on top or bottom
// so that the labels do not lay on the edge,
// Use DEFAULT_LEFT_PADDING in case the sync flag is 'true'
const getPadding = (
  orientation: 'top' | 'bottom' | 'left' | 'right' = 'bottom',
  sync?: boolean
) => {
  const padding = { ...PADDING, left: sync ? DEFAULT_LEFT_PADDING : PADDING.left };
  return {
    ...padding,
    top: orientation == 'top' ? 50 : 10,
    bottom: orientation == 'bottom' ? 50 : 10
  };
};

// Make all series 'active'
const initState = (series: Series[]) => {
  return series.map((s) => {
    return { ...s, active: true };
  });
};

interface DataItem {
  x: Date;
  y: number;
}

// higher-order function to takes the additional parameter
const modifiedSeriesWithTimeZone = (timeZone: string) => {
  // Return the actual modifier function that takes the series as an argument
  return (series: Series) => {
    return modifySeriesWithDailyDifference(series, timeZone);
  };
};

const modifySeriesWithDailyDifference = (originalSeries: Series, timeZone: string): Series => {
  const modifiedSeries: Series = {
    ...originalSeries,
    data: calculateDailyDifference(originalSeries.data, timeZone)
  };
  return modifiedSeries;
};

// Function to calculate the difference between the last and first values for each day
const calculateDailyDifference = (data: Data[], timeZone: string): DataItem[] => {
  const firstDate = new Date(data[0]?.x);
  const lastDate = new Date(data[data.length - 1]?.x);

  // Error handling for empty data array
  if (!firstDate || !lastDate) {
    return [];
  }

  const isLessThanTwoDays = differenceInDays(lastDate, firstDate) <= 3;

  if (isLessThanTwoDays) {
    // Calculate difference per hour
    const resultData = data.map((item, index) => {
      const difference = index > 0 ? (item.y as number) - (data[index - 1].y as number) : 0;

      return {
        y: difference,
        x: new Date(item.x)
      };
    });

    return resultData;
  } else {
    // Calculate difference per day
    const groupedByDay: { [key: string]: number[] } = {};

    // Group data by day
    data.forEach((item) => {
      const day = startOfDay(new Date(item.x));
      const actualDay = zonedTimeToUtc(day, timeZone);
      const dayString = actualDay.toISOString();
      if (!groupedByDay[dayString]) {
        groupedByDay[dayString] = [];
      }
      groupedByDay[dayString].push(Number(item.y));
    });

    // Calculate difference for each day
    const resultData: DataItem[] = Object.keys(groupedByDay).map((day) => {
      const values = groupedByDay[day];
      const difference = values.length > 1 ? values[values.length - 1] - values[0] : 0;
      return {
        y: difference,
        x: new Date(day)
      };
    });

    return resultData;
  }
};

const NewKPIOverTimeGraph = React.forwardRef<KPIOverTimeGraphHandle, GraphProps>(
  ({
    xDomain,
    yDomain,
    domainPadding,
    title,
    // heading,
    axisH,
    axisV,
    rightAxisV,
    series,
    // iconButton,
    sync,
    brush,
    target,
    // legendBgColor,
    tooltip,
    interpolation,
    hideLegend,
    containerComponent,
    graphContainerHeight,
    children,
    isBooleanChart,
    displayZeroLine,
    isZoomEnabled = true,
    onZoom,
    metadata,
    points,
    setIsEditAdminPopupOpen
  }: GraphProps): JSX.Element => {
    const { t } = useTranslation(['mh']);
    const [isShowMetadata, setIsShowMetadata] = useState(false);
    const initialMetadata: {
      mainTitle: string;
      data?: { title: string; value?: string; values?: string[] }[];
    } = { mainTitle: '' };
    const [metadataValue, setMetadata] = useState(initialMetadata);
    const [modifiedLineSeries, setModifiedLineSeries] = useState<Series[]>([]);
    const { timeZone } = useTimeZone();

    // Get datePicker values
    const { startTime, endTime } = useDate();

    // Measure the graph container and pass the size into victory, so it can size itself to fit
    const {
      width: graphWidth,
      height: graphHeight,
      containerRef: graphContainerRef,
      triggerResize
    } = useContainerSize();
    // const [zoomOn, setZoomOn] = useState<boolean>(true);

    // Resize the graph when the parent container tells us to
    const { resizeDelay, shouldResize } = useContainerResize();

    useEffect(() => {
      const timer = setTimeout(() => {
        triggerResize();
      }, resizeDelay);
      return () => clearTimeout(timer);
    }, [shouldResize]);

    const [innerSeries, setInnerSeries] = useState(initState(series));
    const [isInnerSeriesDataEmpty, setIsInnerSeriesDataEmpty] = useState(true);

    // To update graph when Y-axis values change
    useEffect(() => {
      // When series changes, we need to setInnerSeries with the new series values, but we
      // want to retain the existing `active` states from the existing innerSeries data
      // (so that the state of legends is retained)
      const newInnerSeries = series.map((s) => {
        const existingInnerSeries = innerSeries.find((inner) => inner.id === s.id);
        return { ...s, active: existingInnerSeries?.active ?? true };
      });

      const isInnerSeriesData = newInnerSeries.filter((item) => item.data.length > 0);
      if (isInnerSeriesData.length !== 0) setIsInnerSeriesDataEmpty(false);

      setInnerSeries(newInnerSeries);
    }, [series]);

    // Not zoomed by default
    const [zoomedDomain, setZoomedDomain] = useState<ZoomObjectTuples | undefined>();

    // True - when an area is being selecting
    const [zooming, setZooming] = useState(false);

    const computeXDomain = useMemo(() => {
      let x: DateTuple;
      // Domain provided by parent component is priority 1
      if (xDomain && !sync) return xDomain;
      // Domain provided by date Picker is priority 2
      if (sync) {
        return [startTime, endTime] as DateTuple;
        // We end here in case no domain is provided as props
        // thus, computing it based on the series
      } else {
        const offset = getXaxisOffset(innerSeries);
        x = [offset.min, offset.max];
      }
      return x;
    }, [xDomain, startTime, endTime]);

    // Define the domain shown. Use the zoomedXDomain if there is one set (i.e. we have zoomed in),
    // otherwise use the supplied xDomain prop
    const domain = { x: zoomedDomain?.x || computeXDomain, y: zoomedDomain?.y || yDomain };

    const padding = getPadding(axisH.orientation, sync);

    //  Set the AxisH y-offset to move down or top when the chart area has negative values,
    //  in which case the x-axis (AxisV) in placed the incorrect y-offset.
    const offsetY = axisH.orientation == 'top' ? padding.top : padding.bottom;

    /**
     * Line Series
     */
    //error here
    const lineSeries = useMemo(() => {
      return zoom(
        innerSeries.filter((s) => s.mode == 'LINE' && s.active),
        domain.x,
        title as string
      );
    }, [domain.x, innerSeries]);

    useEffect(() => {
      const modifierWithTwoParameters = modifiedSeriesWithTimeZone(timeZone || 'UTC');
      const modifiedSeriesData = lineSeries.map(modifierWithTwoParameters);
      setModifiedLineSeries(modifiedSeriesData);
    }, [lineSeries]);

    // Memoize series
    const lineSeriesRenderer = useMemo(() => {
      return drawLineSeries(modifiedLineSeries, interpolation);
    }, [modifiedLineSeries]);

    // Add Points to chart
    const pointsRenderer = useMemo(() => {
      return points && drawPoints(points, innerSeries);
    }, [points, innerSeries]);

    const calculateTagsAverage = (
      dataArray: Series[]
    ): { name: string | undefined; value: number }[] => {
      const averages: { name: string | undefined; value: number }[] = [];
      dataArray.forEach((element) => {
        if (averages.length < 5) {
          const averageValue =
            element.data.reduce((sum, dataPoint) => sum + Number(dataPoint.y), 0) /
            element.data.length;
          const average = {
            name: element?.label?.replace(/accumulated/gi, 'Consumption'),
            value: Number(averageValue.toFixed(0))
          };
          averages.push(average);
        }
      });
      return averages;
    };

    // State to store the history of zoomed domains
    const [zoomedDomainHistory, setZoomedDomainHistory] = useState<ZoomObjectTuples[]>([]);
    // ***Function to handle undoing the zoom
    const handleUndo = () => {
      // Check if there's a previous zoomed domain in history
      if (zoomedDomainHistory.length > 0) {
        // Get the last zoomed domain from history
        const previousZoomedDomain = zoomedDomainHistory.slice(0, -1);
        setZoomedDomain(previousZoomedDomain[previousZoomedDomain.length - 1]);
        // Update the zooming state to indicate zooming
        setZooming(true);
        setZoomedDomainHistory(previousZoomedDomain);
      }
    };

    const memoizedSetZoomHistory = useCallback((newZoomedDomain) => {
      setZoomedDomainHistory((prevZoomHistory) => [...prevZoomHistory, newZoomedDomain]);
    }, []);

    useEffect(() => {
      // Check if zoomedDomain is defined and not equal to the last zoomedDomain
      if (zoomedDomain && zoomedDomain !== zoomedDomainHistory[zoomedDomainHistory.length - 1]) {
        // Update zoom history using memoized function
        memoizedSetZoomHistory(zoomedDomain);
      }
    }, [zoomedDomain, memoizedSetZoomHistory, zoomedDomainHistory]);

    /** renderMetadata if "metadata" field exists **/
    const renderMetadata = () => {
      return metadataValue.data?.map((metadataItem, index) => {
        return (
          <div key={index}>
            {metadataItem.title && (
              <Typography size="1rem" weight="bold" as="span">
                {metadataItem.title + ' '}
              </Typography>
            )}
            {metadataItem.value && (
              <Typography size="1rem" as="span">
                {metadataItem.value}
              </Typography>
            )}
            {metadataItem.values &&
              metadataItem.values.map((value, i) => {
                return (
                  <Typography key={i} size="1rem" mb="0.125rem" style={{ marginTop: '0.125rem' }}>
                    <FontAwesomeIcon
                      icon={faCircle}
                      color={theme.colors.darkGrey}
                      style={{ fontSize: '0.3125rem' }}
                    />{' '}
                    {value}
                  </Typography>
                );
              })}
          </div>
        );
      });
    };
    return (
      <Container as={containerComponent ?? Card}>
        <LeftSideContainer>
          <TitleDiv>
            <ConsumablesLogo />
            <TitleText>{t('consumables')}</TitleText>
          </TitleDiv>
          {calculateTagsAverage(modifiedLineSeries)?.map((ele, i) => {
            return (
              <KPICellValueContainer
                key={`${ele.name}-${i}`}
                bg={ChartVectorImgList[i]}
                className="cell-value"
              >
                <div className="cell-value__value">{ele.value}</div>
                <div className="cell-value__label">
                  {t('average')} {t(ele?.name as string)}
                </div>
              </KPICellValueContainer>
            );
          })}
        </LeftSideContainer>
        <RightSideContainer ref={graphContainerRef} height={graphContainerHeight}>
          <RightSubContainer>
            <RightHeaderDiv>
              {!hideLegend ? (
                <NewLegend className="legendBox">
                  {drawLegends(
                    innerSeries,
                    (id) => {
                      setInnerSeries(
                        innerSeries.map((s) => {
                          if (s.id == id) return { ...s, active: !s.active };
                          return s;
                        })
                      );
                    },
                    'circle'
                  )}
                </NewLegend>
              ) : (
                ''
              )}
              <div>
                <StyledIconButton
                  onClick={() => setIsEditAdminPopupOpen && setIsEditAdminPopupOpen()}
                >
                  <SettingIcon color={theme.colors.primaryBlue5} />
                </StyledIconButton>
              </div>
            </RightHeaderDiv>
            <ZoomContainer>
              {zoomedDomain?.x && (
                <>
                  <DataZoom onClick={handleUndo}>
                    {t('undo')}
                    <IconStyle>
                      <GoBackIcon />
                    </IconStyle>
                  </DataZoom>
                  <ActionButton onClick={() => setZoomedDomain(undefined)} hideArrow>
                    {t('reset_zoom')}
                  </ActionButton>
                </>
              )}
            </ZoomContainer>
          </RightSubContainer>

          <VictoryChartContainer>
            {!isInnerSeriesDataEmpty ? (
              <VictoryChart
                key={uuidv4()}
                width={graphWidth}
                height={graphHeight - 100}
                padding={padding}
                scale={{ x: 'time', y: 'linear' }}
                domain={{ x: domain.x }}
                domainPadding={domainPadding || { y: 20 }}
                theme={victoryTheme}
                containerComponent={
                  <VictoryBrushCursorContainer
                    // List of components to ignore when computing
                    // ... the position for the <VictoryTooltip />
                    voronoiBlacklist={innerSeries.filter((s) => s.ignore).map((s) => s.id)}
                    // Clear previously selected area
                    defaultBrushArea="none"
                    allowDraw={isZoomEnabled}
                    allowDrag={false}
                    brushDomain={{ x: [0.1, 0.3] }}
                    brushStyle={{
                      stroke: 'transparent',
                      fill: 'black',
                      fillOpacity: 0.1,
                      cursor: 'default'
                    }}
                    onBrushDomainChangeEnd={(domain) => {
                      // Skip if the domain doesn't change,
                      // ...this is likely to happen when the user clicks on the chart without dragging
                      if (domain.x[0] == 0) return;

                      const nextDomain = {
                        x: domain.x as DateTuple,
                        y: domain.y as [number, number]
                      };

                      if (sync) {
                        brush?.onBrushDomainChangeEnd(nextDomain);
                        onZoom?.();
                      } else {
                        setZoomedDomain(nextDomain);
                        setZooming(false);
                      }
                    }}
                    onBrushDomainChange={() => {
                      if (zooming) setZooming(true);
                    }}
                    labels={({ datum }) => datum.tooltipContent}
                    labelComponent={
                      <VictoryTooltip
                        cornerRadius={4}
                        flyoutWidth={tooltip && tooltip.width ? tooltip.width : 250}
                        flyoutHeight={70}
                        style={{
                          fill: tooltip && tooltip.color ? tooltip.color : theme.colors.darkGrey
                        }}
                        flyoutStyle={{
                          strokeWidth: 1,
                          fill: tooltip && tooltip.bg ? tooltip.bg : theme.colors.white,
                          fillOpacity: 0.8,
                          pointerEvents: 'none',
                          stroke: theme.colors.lightGrey5
                        }}
                        pointerLength={({ datum }) => (datum.y > 0 ? 30 : -90)}
                        constrainToVisibleArea
                        labelComponent={
                          <CustomLabelComponent
                            setMetadata={(metadata) => setMetadata(metadata)}
                            metadata={metadata}
                            tooltip={{
                              color: (tooltip && tooltip.color) ?? theme.colors.darkGrey,
                              bg: (tooltip && tooltip.bg) ?? theme.colors.white,
                              width: (tooltip && tooltip.width) ?? 250
                            }}
                          />
                        }
                      />
                    }
                    style={{ cursor: 'crosshair' }}
                  />
                }
              >
                {sync ? (
                  <VictoryAxis
                    key={uuidv4()}
                    offsetY={offsetY}
                    minDomain={{ x: domain.x[0].getTime() }}
                    maxDomain={{ x: domain.x[1].getTime() }}
                    {...axisH}
                  />
                ) : (
                  <VictoryAxis key={uuidv4()} {...axisH} offsetY={offsetY} />
                )}

                {displayZeroLine && (
                  <VictoryAxis
                    key={uuidv4()}
                    crossAxis={false}
                    style={{
                      axis: { stroke: theme.colors.lightGrey3 }
                    }}
                    tickFormat={() => ''}
                  />
                )}

                {isBooleanChart
                  ? axisV && (
                      <VictoryAxis
                        key={uuidv4()}
                        {...axisV}
                        dependentAxis
                        tickFormat={(t) => formatBooleanAxis(t)}
                        crossAxis={false}
                      />
                    )
                  : axisV && (
                      <VictoryAxis key={uuidv4()} {...axisV} dependentAxis crossAxis={false} />
                    )}
                {rightAxisV && (
                  <VictoryAxis
                    key={uuidv4()}
                    {...rightAxisV}
                    dependentAxis
                    offsetX={padding.right}
                    crossAxis={false}
                  />
                )}

                {lineSeriesRenderer}
                {points && pointsRenderer}
                {target && target.value && !isNaN(target.value) && (
                  <VictoryLine
                    theme={victoryTheme}
                    style={{
                      data: {
                        stroke: target.colour ?? theme.colors.mediumGrey3,
                        strokeDasharray: '5,5'
                      },
                      parent: { border: '0.0625rem solid #ccc' }
                    }}
                    labels={({ datum }) =>
                      xDomain && datum.x === xDomain[1] && !target.label
                        ? `Target(${target.value})`
                        : (target && target.label) ?? ''
                    }
                    labelComponent={
                      <VictoryLabel
                        renderInPortal
                        dy={5}
                        dx={25}
                        style={{ color: target.colour ?? theme.colors.mediumGrey3 }}
                      />
                    }
                    data={[
                      { x: xDomain && xDomain[0], y: 5 },
                      { x: xDomain && xDomain[1], y: 5 }
                    ]}
                  />
                )}
                {typeof children === 'function'
                  ? children(graphWidth, graphHeight, padding)
                  : children}
              </VictoryChart>
            ) : (
              <NoContentText>No data available in the range</NoContentText>
            )}
          </VictoryChartContainer>
        </RightSideContainer>
        {metadata && !isShowMetadata && (
          <StyledMetadataIcon
            icon={faInfoCircle}
            onClick={() => {
              if (metadata) {
                setMetadata(metadata);
                setIsShowMetadata(true);
              }
            }}
          />
        )}
        {isShowMetadata && metadata && metadataValue && (
          <StyledMetadataInfo>
            <Typography weight="bold" size="1.25rem" mb={0}>
              {metadataValue.mainTitle.replaceAll('"', '')}
            </Typography>
            {renderMetadata()}
            <StyledMetadataButton
              variant="primary"
              size="small"
              onClick={() => {
                setIsShowMetadata(false);
              }}
            >
              Close
            </StyledMetadataButton>
          </StyledMetadataInfo>
        )}
      </Container>
    );
  }
);

NewKPIOverTimeGraph.displayName = 'NewKPIOverTimeGraph';

export default NewKPIOverTimeGraph;
