import { ReactNode, useMemo } from 'react';

import { useGetMachineOverviewAlarmsQuery } from 'api';
import { processApiData } from 'common/helpers/processApiData';
import { useRouter } from 'common/hooks/useRouter';
import { useLiveTimeFrame } from 'common/ui';
import { fleetPageRoute } from 'common/pages/fleetV2/settings/fleetPageRoutes';
import { removeDuplicatesByKeys } from 'common/helpers/dataFilters';

const GetData = (seconds?: number) => {
  seconds = seconds || 60000;

  const { id } = useRouter(fleetPageRoute) as { id: string };
  const { timeZone } = useLiveTimeFrame(seconds, {});

  // path /mh/v1/${machineType}/machine-health/${machineId}/alarms/active${addArgsToPath(rest)}
  const { data, isLoading, error } = useGetMachineOverviewAlarmsQuery({
    machineId: id,
    machineType: 'aseptic'
  }) as {
    data?: Record<string, unknown>[];
    isLoading?: boolean;
    [key: string]: unknown;
  };

  if (!data) return { isLoading, error };

  const processedData =
    data &&
    (processApiData(data, {
      startTimeKey: 'startTimestamp',
      timeZone,
      formatTimestamps: 'P, p',
      formatTimestampsAs: 'date'
    }) as Record<string, unknown>[]);

  const filteredData: Record<string, unknown>[] | undefined = removeDuplicatesByKeys(
    ['startTimestamp', 'code'],
    {
      data: processedData
    }
  );

  return { data: filteredData, rawData: data };
};

export const useCurrentIssuesWidget = (): {
  isLoading?: boolean;
  hasError?: ReactNode;
  hasMessage?: ReactNode;
  cachedData?: {
    text?: string;
    timestamp?: string;
    id?: string;
    strong?: string;
    [key: string]: unknown;
  }[];
  [key: string]: unknown;
} => {
  const { data, isLoading, error } = GetData();

  const hasMessage = isLoading ? undefined : data?.length === 0 ? true : undefined;
  const hasError = error ? true : undefined;

  if (error) console.log('useCurrentIssuesWidet', { error });

  const currentIssues = useMemo(
    () =>
      data?.map((item) => ({
        text: item?.description as string,
        timestamp: item?.startTimestampFormatted, // item?.startTimestamp as string,//format(parseISO(item?.startTimestamp as string), 'P, p'),
        id: item?.code as string,
        strong: item?.code as string,
        ...item
      })),
    [data]
  );

  if (currentIssues) return { currentIssues };

  return { isLoading, hasError, hasMessage };
};
