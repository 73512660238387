import React, { useState } from 'react';
import { Downtime24hChartContainer } from './elements/Chart.elements';
import { BarsList } from './elements/Bars';
import { CategoriesList } from './elements/Categories';
import { SwitchIcon } from 'icons/SwitchIcon';
import { styledTheme } from 'common/theme';
import { useTranslation } from 'react-i18next';
import { useGetDowntimeDataQuery } from 'common/pages/fleetV2/machine/proseal/api';
import { useMachineInfo } from 'common/pages/fleetV2/providers';
import { useDateRangeV2 } from 'components/StyledUi/DateRange/hooks/useDateRangeV2';
import { WidgetUi } from 'common/components/WidgetUi/WidgetUi';
import { Footer } from './elements/Footer';
import { generateWidgetData } from './utils';

interface Props {
  className?: string;
}

export const Downtime24hChart = ({ className }: Props): JSX.Element => {
  const { t } = useTranslation(['mh']);
  const { id } = useMachineInfo() as { id: string };
  const { startMoment: startDate, endMoment: endDate } = useDateRangeV2().machineDateRange;
  const [showDetailedView, setShowDetailedView] = useState<boolean>(true);

  //When I put zoom date range in api call as start an end date it does not return anything
  //const { zoomDaterange } = useDataManagementProvider();
  // const start = zoomDaterange ? zoomDaterange[0].toISOString() : startDate;
  // const end = zoomDaterange ? zoomDaterange[1].toISOString() : endDate;

  const start = startDate;
  const end = endDate;

  const { data, isLoading, isError } = useGetDowntimeDataQuery(
    {
      machineId: id,
      start_datetime_utc: start,
      end_datetime_utc: end
    },
    {
      pollingInterval: 60000
    }
  );

  const { processedData, categories } = generateWidgetData(data);

  const widgetSettings = {
    title: (
      <>
        {t('top_status_downtime')}
        <button
          className="bars-header--switch"
          onClick={() => setShowDetailedView(!showDetailedView)}
        >
          <SwitchIcon color={styledTheme.colors.secondary} />
        </button>
      </>
    ),
    className: showDetailedView
      ? `${className} view--downtimedetails`
      : `${className} view--categories`,
    isLoading,
    hasError: isError ? true : false,
    hasMessage: !data || data?.length == 0 ? true : false
  };

  return (
    <WidgetUi
      {...widgetSettings}
      Main={
        <Downtime24hChartContainer>
          <div className="sidebar--conatiner">
            {showDetailedView ? (
              data == undefined ? (
                <CategoriesList data={categories} />
              ) : (
                <BarsList data={processedData} />
              )
            ) : (
              <CategoriesList data={categories} />
            )}
          </div>
          <Footer start={new Date(start)} end={new Date(end)} />
        </Downtime24hChartContainer>
      }
    />
  );
};
