import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useGetAsepticMachineHealthKpiQuery } from 'api';
import { API_INTERVAL, COLORMAPPING } from '../utils';
import { utcToZonedTime } from 'date-fns-tz';
import { MachineStatesExtended, MachineStatesTransform } from '../types';
import { AsepticMachineHealthKpiItem } from 'types/machine-health';
import { useAPIIntervalProvider } from 'common/pages/fleetV2/hooks/useAPITimer';
import _ from 'lodash';

const MachineModesAPIProviderContext = createContext<MachineModesProps>({
  isLoading: true
});

interface MachineModesProps {
  isLoading: boolean;
  data?: MachineStatesTransform[];
  hasError?: string;
  hasMessage?: string;
  end_time?: string;
}

interface MachineModeItem extends AsepticMachineHealthKpiItem {
  recipe?: string;
  runLoginId?: string;
}

export const useMachineModesAPIProvider = (): MachineModesProps =>
  useContext(MachineModesAPIProviderContext);

interface Props {
  children?: ReactNode | ReactNode[];
  timeZone: string;
  machineId: string;
}

//const UPDATE_CACHE_INTERVAL = 3 * 60 * 1000; // This is an interval for cache update if we are recieveing SAME state. This is no t api frequency.

export const MachineModesAPIProvider = ({ children, timeZone, machineId }: Props): JSX.Element => {
  const { start_time, end_time } = useAPIIntervalProvider();
  const [cached, setCachedData] = useState<MachineModeItem[] | undefined>(undefined);
  const lastItemEndDate = useRef<string | undefined>(end_time);

  const {
    data: machineHealth,
    error,
    isLoading
  } = useGetAsepticMachineHealthKpiQuery({
    machineId,
    utc_start_datetime: start_time,
    utc_end_datetime: end_time,
    widgetType: 'machine_utilization',
    limit: 100000,
    includeHistoricData: true
  });

  useEffect(() => {
    if (!machineHealth || machineHealth?.length === 0) return;
    if (!start_time || !end_time) return;

    // Here we compare data with already exisiting and:
    // 1. add new data to array, or
    // 2. update end date of already exisitng item in array, meaning this state is still running
    const newCache = machineHealth;
    if (cached) {
      if (machineHealth.length > 1) {
        lastItemEndDate.current = newCache[0].endTime; //api is sorted in desc order, so the first item is the most recent
        return newCache && setCachedData(newCache);
      } else {
        // If we select one day time frame and 1 state lasts the whole day, we will only recieve 1 item in array back from api.
        // if returned item start and end date is equal to start and end time from provider then assign new cache
        const mhItem = machineHealth[0];
        const mhItemStartTime = utcToZonedTime(mhItem.startTime, 'UTC');
        const mhItemEndTime = utcToZonedTime(mhItem.endTime, 'UTC');
        const dateRangeStartTime = utcToZonedTime(start_time, 'UTC');
        const dateRangeEndTime = utcToZonedTime(end_time, 'UTC');

        if (
          mhItemStartTime.getTime() === dateRangeStartTime.getTime() &&
          mhItemEndTime.getTime() === dateRangeEndTime.getTime() &&
          dateRangeEndTime.getTime() - dateRangeStartTime.getTime() > API_INTERVAL
        ) {
          lastItemEndDate.current = mhItem.endTime; //api is sorted in desc order, so the first item is the most recent
          return newCache && setCachedData(newCache);
        }

        const exisitingItem = cached[0];
        const newItem = machineHealth[machineHealth.length - 1] as unknown as MachineModeItem;
        const updated = _.cloneDeep(cached);
        if (
          exisitingItem.modeDescr === newItem.modeDescr &&
          exisitingItem.modeNumber === newItem.modeNumber &&
          exisitingItem.recipe === newItem.recipe &&
          exisitingItem.recipeDescr === newItem.recipeDescr &&
          exisitingItem.runLoginId === newItem.runLoginId
        ) {
          lastItemEndDate.current = newItem.endTime;
          updated[0].endTime = newItem.endTime;
          setCachedData(updated);
        } else {
          //Remove old item and add new one
          //Remove
          updated.pop();
          //Add
          updated.unshift(newCache);
          setCachedData(updated);
        }
      }
    } else {
      lastItemEndDate.current = newCache[0].endTime; //api is sorted in desc order, so the first item is the most recent
      return newCache && setCachedData(newCache);
    }
  }, [machineHealth]);

  const cachedData = useMemo(() => {
    return cached?.map((item) => {
      const zonedStart = utcToZonedTime(new Date(item.startTime as string), timeZone);
      const zonedEnd = utcToZonedTime(new Date(item.endTime as string), timeZone);

      return {
        ...item,
        start_time: utcToZonedTime(new Date(item.startTime as string), timeZone),
        end_time: utcToZonedTime(new Date(item.endTime as string), timeZone),
        color: COLORMAPPING[item.modeDescr as unknown as string],
        colorHover: COLORMAPPING[item.modeDescr as unknown as string],
        duration: zonedEnd.getTime() - zonedStart.getTime(), // duration is in MILLISECONDS
        data_type: 'status',
        mode_descr: item.modeDescr,
        mode_number: item.modeNumber
      };
    }) as unknown as MachineStatesExtended[];
  }, [cached]);

  const hasError = error ? 'Error getting Machine Modes data' : undefined;
  //const hasError = 'test error';
  let hasMessage = undefined;

  if (hasError) console.warn('Error getting Machine Modes data');

  if ((!isLoading && !hasError) || machineHealth?.length === 0) {
    hasMessage = 'no data';
  }

  return (
    <MachineModesAPIProviderContext.Provider
      value={{
        data: cachedData,
        end_time,
        hasError,
        hasMessage,
        isLoading
      }}
    >
      {children}
    </MachineModesAPIProviderContext.Provider>
  );
};
