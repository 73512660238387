import styled from 'styled-components';
import { StyledUiContainerProps } from 'components';

export const ViewContainer = styled.div<StyledUiContainerProps>`
  grid-gap: 1em;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto auto 400px;

  .machine-overview-widget {
    grid-column-end: span 2;
  }

  .feed-factor-widget {
    grid-column-end: span 1;
  }

  .downtime-widget {
    grid-column-end: span 1;
  }

  .live-graph-widget {
    position: relative;
    grid-column-end: span 4;

    .widget-ui__main {
      position: static;
      overflow: visible;
    }
  }

  .oee-metrics-widget {
    grid-column-end: span 4;
  }

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
`;
