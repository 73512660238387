import React from 'react';
import { DimensionsContainer } from 'components';
import { ZoomBrushComponenet } from './ZoomBrushComponent';
import { ICONMAP } from './utils';

interface Props {
  data?: Record<string, unknown>;
  filteredData?: Record<string, unknown>[];
  className: string;
  chartDaterange: Date[] | undefined;
  zoomDaterange: Date[] | undefined;
  handelZoomInDaterangeUpdate: (range: Date[]) => void;
  xAxisKey?: string;
  colorMap?: Record<string, string>;
  colorMapKey?: string;
}

export const ZoomBrushContainer = ({
  data,
  className,
  chartDaterange,
  zoomDaterange,
  xAxisKey,
  colorMap,
  colorMapKey,
  handelZoomInDaterangeUpdate
}: Props): JSX.Element => {
  const settings = {
    height: 100,
    margins: {
      top: 50,
      right: 5,
      bottom: 50,
      left: 5
    },
    dataSettings: {
      data: (data?.data as unknown as Record<string, unknown>[]) || data,
      xAxisKey: xAxisKey || 'start_time',
      colorMapKey: colorMapKey || 'mode_number',
      colorMap: colorMap || ICONMAP,
      type: 'flags'
    },
    rangeX: chartDaterange, // Range for the whole component
    zoomedRangeX: zoomDaterange, // Range for the zoomed in part
    updateZoomedRangeX: (range: Date[]) => handelZoomInDaterangeUpdate(range), //Update zoomed in part
    defaultBackground: true
  };

  return (
    <DimensionsContainer
      className={className}
      Component={({ width, triggerResize }) => {
        const set = { ...settings, width: width, triggerResize };
        return <ZoomBrushComponenet {...set} />;
      }}
    />
  );
};
