import React, { useState } from 'react';
import { BtnContainer, Card, CardContent, FileItem } from '../documentation.elements';
import { getFileIcon } from 'pages/MachineManagement/UploadMachinePdf/FileItem';
import { Button, Typography } from 'components';
import { IcoLink } from 'icons/IcoLink';
import { BookmarkIcon } from 'icons/docsIcons';
import theme from 'themes';
import { SearchHistory } from 'types/machine-management';
import { v4 as uuidv4 } from 'uuid';
import {
  useAddBookmarkDocumentMutation,
  useDeleteDocSearchHistoryMutation,
  useLazyGetFpsMachineDocFileQuery,
  useRemoveBookmarkDocumentMutation
} from 'api';
import { ToastMsg } from 'common/components/Toast/Toast';
import CustomLoader from 'components/CustomLoader';
import CopyTextComponent from 'common/components/CopyLink';
import { IcoTrashV2 } from 'icons/IcoTrashV2';
import { OverflowEllipsis } from 'common/components/common-styled';

type Props = {
  data?: SearchHistory[];
  isGridView?: boolean;
};
const HistoryFile = ({ data = [], isGridView }: Props): JSX.Element => {
  const [removeBookmark, { isLoading: removeBookmarkLoading }] =
    useRemoveBookmarkDocumentMutation();

  const [addBookmark, { isLoading: addBookmarkLoading }] = useAddBookmarkDocumentMutation();
  const [deleteSearchHistory, { isLoading: deleteHistoryLoading }] =
    useDeleteDocSearchHistoryMutation();
  const [getSignedUrl, { isLoading, isFetching }] = useLazyGetFpsMachineDocFileQuery();
  const [activeDocId, setActiveDocId] = useState('');
  const handleCopyText = async (doc_id: string) => {
    try {
      const resp = await getSignedUrl({ document_id: doc_id });
      if (document.hasFocus()) {
        navigator.clipboard.writeText(resp.data?.signedUrl || '');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddBookmark = async (doc_id: string) => {
    setActiveDocId(doc_id);
    const id = uuidv4();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const resp = (await addBookmark({ document_id: doc_id, id })) as any;
    if (resp.data) {
      ToastMsg({
        heading: 'Successful',
        message: 'Bookmark saved successfully',
        type: 'success',
        position: 'top-right'
      });
    } else {
      ToastMsg({
        heading: 'Error',
        message: resp.error.data.detail,
        type: 'error',
        position: 'top-right'
      });
    }
  };

  const handleRemoveBookmark = async (doc_id: string) => {
    setActiveDocId(doc_id);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const res = (await removeBookmark(doc_id)) as any;
    if (res.error) {
      ToastMsg({
        heading: 'Error',
        message: res.error.data.detail,
        type: 'error',
        position: 'top-right'
      });
    } else {
      ToastMsg({
        heading: 'Successful',
        message: 'File removed from bookmark',
        type: 'success',
        position: 'top-right'
      });
    }
  };

  const handleDeleteSearchHistory = async (id: string) => {
    setActiveDocId(id);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const resp = (await deleteSearchHistory({ document_id: id })) as any;
    if (resp.error) {
      ToastMsg({
        heading: 'Error',
        message: resp?.error?.data?.detail,
        type: 'error',
        position: 'top-right'
      });
    } else {
      ToastMsg({
        heading: 'Successful',
        message: 'History deleted successfully.',
        type: 'success',
        position: 'top-right'
      });
    }
  };
  return (
    <>
      {data.length ? (
        data.map((doc) => {
          const {
            documentDetails: { userFileName = '', id },
            hasBookmark,
            id: history_id
          } = doc;
          return isGridView ? (
            <Card hoverDisable gridRows="repeat(3, 1fr)">
              <BtnContainer>
                <Button variant="text">
                  {(addBookmarkLoading || removeBookmarkLoading) && activeDocId === id ? (
                    <CustomLoader padding="0 .75rem" size="1.3rem" thickness=".2rem" />
                  ) : (
                    <BookmarkIcon
                      fill={hasBookmark ? theme.colors.primaryBlue5 : 'none'}
                      onClick={
                        hasBookmark
                          ? () => handleRemoveBookmark(id || '')
                          : () => handleAddBookmark(id || '')
                      }
                      width="22"
                    />
                  )}
                </Button>
                <Button className="deleteIconBtn" variant="text">
                  {deleteHistoryLoading && history_id === activeDocId ? (
                    <CustomLoader size="1.3rem" thickness=".2rem" />
                  ) : (
                    <IcoTrashV2
                      width={20}
                      height={20}
                      cursor={'pointer'}
                      onClick={() => handleDeleteSearchHistory(history_id || '')}
                    />
                  )}
                </Button>
              </BtnContainer>
              <CardContent>
                <div>{getFileIcon(userFileName)}</div>
                <OverflowEllipsis
                  data-title={userFileName}
                  title={userFileName}
                  textAlign="center"
                  width="12rem"
                >
                  {userFileName}
                </OverflowEllipsis>
              </CardContent>
              <BtnContainer justifyContent="end">
                <CopyTextComponent
                  icon={<IcoLink width="20" />}
                  isLoading={isLoading || isFetching}
                  handleCopyText={() => handleCopyText(id || '')}
                  // isOnlyIcon
                />
              </BtnContainer>
            </Card>
          ) : (
            <FileItem>
              {getFileIcon(userFileName)}
              <Typography mb={0} style={{ flex: 1 }}>
                {userFileName}
              </Typography>
              <CopyTextComponent
                icon={<IcoLink width="20" />}
                isLoading={isLoading || isFetching}
                handleCopyText={() => handleCopyText(id || '')}
                isOnlyIcon
              />
              {(addBookmarkLoading || removeBookmarkLoading) && activeDocId === id ? (
                <CustomLoader size="1.3rem" thickness=".2rem" />
              ) : (
                <BookmarkIcon
                  width={'1.25rem'}
                  height={'1.25rem'}
                  cursor={'pointer'}
                  className="bookmarkIcon"
                  fill={hasBookmark ? theme.colors.primaryBlue5 : 'none'}
                  onClick={
                    hasBookmark
                      ? () => handleRemoveBookmark(id || '')
                      : () => handleAddBookmark(id || '')
                  }
                />
              )}
              {deleteHistoryLoading && history_id === activeDocId ? (
                <CustomLoader size="1.3rem" thickness=".2rem" />
              ) : (
                <IcoTrashV2
                  onClick={() => handleDeleteSearchHistory(history_id || '')}
                  width={20}
                  height={20}
                  cursor={'pointer'}
                />
              )}
            </FileItem>
          );
        })
      ) : (
        <></>
      )}
    </>
  );
};

export default HistoryFile;
