import React from 'react';
import { CategoryLineProps } from './Chart.types';
import { Line } from './Categories.elements';

interface CategoryLineItemProps {
  item: CategoryLineProps;
}

const CategoryLine = ({ item }: CategoryLineItemProps): JSX.Element => {
  const { icon, label, color } = item;

  return (
    <Line className="bar-indicator--icon category">
      {icon}
      <p style={{ color: color as unknown as string }}> {label} </p>
    </Line>
  );
};

interface CategoriesListProps {
  data?: CategoryLineProps[];
}

export const CategoriesList = ({ data }: CategoriesListProps): JSX.Element => {
  const categoriesList = data?.map((category) => (
    <CategoryLine key={category.label} item={category} />
  )) as unknown as CategoryLineProps[];

  return <>{categoriesList}</>;
};
