import React, { useMemo } from 'react';
import { ViewContainer } from './View.elements';
import { OverviewView } from './views/Overview/OverviewView';
import { MachineHealthViewTabs } from './components/MachineHealthViewTabs/MachineHealthViewTabs';
import { useFleetRouter } from 'common/pages/fleetV2/hooks/useFleetRouter';
import { HistoricalProductionView } from './views/HistoricalProduction/HistoricalProductionView';

export const MachineHealthView = (): JSX.Element => {
  return (
    <ViewContainer className="machine-health-container content-padding view-container">
      <MachineHealthViewTabs />
      <Routes />
    </ViewContainer>
  );
};

const Routes = (): JSX.Element => {
  const [{ id, tab }, go] = useFleetRouter();

  if (!tab && id) go('updatePath', { main: `machine-health`, tab: 'overview' });

  const CachedRoute = useMemo(() => {
    // load overview as default tab, this should be done higher up in the component tree thoug
    if (tab === 'overview') return <OverviewView />;
    if (tab === 'historical-production') return <HistoricalProductionView />; // production view here;
    if (tab === 'alarms') return <></>;
    if (tab === 'data-analysis') return <></>;
    return <div>404</div>;
  }, [tab, id]);

  return CachedRoute;
};
