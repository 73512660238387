import React from 'react';
import { BarChartWidget } from 'common/components';
import { useFilterSelected } from 'components';
import { useChangeoverStepsDurationWidget } from './hooks/useChangeoverStepsDurationWidget';
import { useMachineInfo } from 'common/pages/fleetV2/providers';
import {
  //convertSecondsToHours,
  convertSecondsToTime,
  convertToZonedTimestamp
} from 'common/helpers/dateAndTimeHelpersV2';
//import { convertSecondsToHours, convertSecondsToTime } from 'common/helpers/dateAndTimeHelpers';
import { Tooltip } from './Tooltip';

const Provided = (): JSX.Element => {
  const {
    //filteredChartData,
    chartData,
    isLoading,
    hasError,
    hasMessage,
    //categories,
    //sessionStartDates,
    changeoverSessionsStartDates,
    //changeoverSessionIdsByDate,
    legendItems
  } = useChangeoverStepsDurationWidget() as {
    chartData?: Record<string, unknown>[];
    [key: string]: unknown;
  };

  //const { colors } = useBuSettings();
  const { timeZone } = useMachineInfo();

  const [selected, handle] = useFilterSelected();

  // the function that helps determins if the slice is selected
  const checkIfSelected = (bar: Record<string, unknown>) => {
    if (!selected) return true;
    const group = (bar?.group as string) || (bar?.label as string);
    const selectedVals = selected?.group || [];
    if (selectedVals.length && group && selectedVals.includes(group)) return true;
    return false;
  };

  const handleClick = ({ group }: { group?: string }) => {
    const selections = selected?.group || [];
    if (group) {
      const isSelected = selections.includes(group) ? true : false;
      return handle(isSelected ? 'toggle' : 'set', { group });
    }
  };

  /*
    const widgetSettings = {
      className: 'changeover-step-duration-widget',
      title: 'Changover Step Duration',
      hasDateFooter: true,
      isLoading,
      hasError,
      hasMessage,
      legendItems,
      selected,
      handleClick
    };

    const chartSettings = {
      colors,
      chartData,
      categories,
      valueKey: 'total',
      leftTickFormatType: 'seconds-to-hours',
      checkIfSelected,
      handleClick,
      margins: {
        left: 50
      },
      format: {
        bottomTicks: (sessionId?: string | number) => {
          if (changeoverSessionsStartDates === undefined) return sessionId;
          // thanks ts
          const session = (
            changeoverSessionsStartDates as Record<string, { start: string; end: string }>
          )?.[sessionId as string];
          const date = session?.start as string;
          if (date) {
            return convertToZonedTimestamp(date, timeZone as string, 'chart-axis');
          }
          return sessionId;
        },
        leftTicks: (label?: number | string) => {
          const tickMark = convertSecondsToTime(Number(label), 'hrs');
          return <>{tickMark || 0}</>;
        }
      },
      Tooltip
    };
  */

  return (
    <BarChartWidget
      {...{
        chartData,
        valueKey: 'total',
        className: 'changeover-step-duration-widget',
        title: 'Changover Step Duration',
        hasDateFooter: true,
        isLoading: isLoading as boolean,
        hasError,
        checkIfSelected,
        // this rounds the left axis to the nearest hour
        leftTickFormatType: 'seconds-to-hours',
        hasMessage,
        legendItems,
        selected,
        handleClick,
        format: {
          bottomTicks: (sessionId?: string | number) => {
            if (changeoverSessionsStartDates === undefined) return sessionId;
            // thanks ts
            const session = (
              changeoverSessionsStartDates as Record<string, { start: string; end: string }>
            )?.[sessionId as string];
            const date = session?.start as string;
            if (date) {
              return convertToZonedTimestamp(date, timeZone as string, 'chart-axis');
            }
            return sessionId;
          },
          leftTicks: (label?: number | string) => {
            const tickMark = convertSecondsToTime(Number(label), 'hrs');
            return <>{tickMark || 0}</>;
          },
          legendItems: (props?: Record<string, unknown>) => {
            console.log({ props });
            return ``;
          }
        },
        Tooltip
      }}
    />
  );
};

export const ChangeoverStepDurationWidget = (): JSX.Element => {
  return <Provided />;
};
