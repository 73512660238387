import React from 'react';
import { KPIProgressTile } from 'common/components/KPIProgressTile';
import { useKPIApi } from './hooks/useKPIApi';

export const ProcessedProductPercentageKPIProgressTile = (): JSX.Element => {
  const { current, ...rest } = useKPIApi();

  const progressTileSettings = {
    progress: current as number,
    title: 'Processed Product Percentage',
    className: 'processed-product-percentage',
    ...rest
  };

  return <KPIProgressTile {...progressTileSettings} />;
};
