import styled from 'styled-components';
import theme from 'common/theme';

export const WidgetMainContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  overflow: visible !important;
  position: relative;

  padding: 0 2em 1em 1em;
  gap: 2em;

  .point-chart-container {
    padding-bottom: 1.5em;
  }

  .col-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    padding-left: 2em;
    border-width: 0 0 0 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.2);
  }

  .oee-value-tiles {
    display: grid;
    height: 100%;
    grid-template-rows: repeat(4, 1fr);
  }

  .date-label {
    font-weight: 500;
    text-transform: capitalize;
    font-size: 0.8em;
    color: ${theme.colors.gray};
  }
`;

export const TilesContainer = styled.div<{ color?: string; size?: string }>`
  .colored,
  .tile__value {
    color: ${({ color }) => color || 'red'};
    font-weight: bold;
    font-size: 1.3em;
  }

  &:first-child .tile__value {
    font-size: 1.9em;
  }

  .tile__label {
    font-size: 0.8em;
    font-weight: 500;
    text-transform: capitalize;
  }
`;

export const OEEChartPoint = styled.div<{ x?: number; y?: number; color?: string }>`
  width: 8px;
  height: 8px;
  position: relative;

  &:hover {
    .tooltip-wrapper {
      display: block;
    }
  }
`;

export const ChartTooltipItem = styled.div<{ color?: string }>`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.5em;
  min-width: max-content;
  align-items: center;
  text-transform: capitalize;

  &:before {
    display: block;
    content: '';
    width: 0.9em;
    height: 0.9em;
    border-radius: 50%;
    background-color: ${({ color }) => color || 'black'};
  }
`;
