import styled from 'styled-components';
import theme from 'common/theme';

export interface ProgressBarContainerProps {
  progress?: number;
  /** default color types */
  barColor?: 'bad' | 'warning' | 'good' | 'unknown' | string;
  /** if you want a custom height */
  barHeight?: string | number;
}
export const ProgressBarContainer = styled.div<ProgressBarContainerProps>`
  width: 100%;
  position: relative;

  .progress-bar-title-container {
    height: max-content;
    margin-bottom: 0.25em;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto;
    grid-gap: 0.3em;
    align-items: center;

    .progress-bar-title {
      font-weight: 600;
      text-transform: capitalize;
      font-size: 1em;
      max-width: max-content;

      color: ${theme.colors.subTitle};
    }

    .tooltip__inner {
      font-size: 0.85em;
      line-height: 1.3;
    }

    .icon--info {
      width: 1.3em !important;
      height: 1.3em !important;
    }
  }

  .progress-bar-sub-title {
    font-size: 0.9em;

    color: ${theme?.colors?.gray600};
  }

  .display-area {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr auto;
    grid-template-rows: auto;
    font-weight: 800;
    align-items: center;
    column-gap: 0.5em;
    line-height: 1;
    transition: color 0.3s;

    color: ${({ barColor }: { barColor?: string }) =>
      barColor
        ? `${theme?.colors?.[barColor as string] as string}`
          ? theme.colors[barColor as string]
          : barColor
        : theme.colors.unknown};

    .progress-bar {
      position: relative;
      width: 100%;
      display: block;
      border-radius: 0.5em;

      background-color: ${theme.colors.bg.lighter};
      height: ${({ barHeight }) =>
        typeof barHeight === 'number' ? `${barHeight}px` : barHeight || '9px'};

      &:after {
        display: block;
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        border-radius: 0.5em;
        transition: all 0.3s;

        width: ${({ progress }) => progress || 0}%;
        background-color: ${({ barColor }) =>
          barColor
            ? theme.colors?.[barColor]
              ? theme.colors[barColor]
              : barColor
            : theme.colors.unknown};
      }
    }

    .progress-value {
      font-size: 1.2em;
      font-weight: 800;
      min-width: 2em;
      text-align: right;
    }

    .progress-bar-legend {
      grid-row: 2;
      grid-column: 1;
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 1fr;
      font-weight: 400;
      font-size: 0.75em;
      justify-content: space-between;

      color: ${theme.colors.gray600};

      .progress-bar-legend-label {
        text-align: center;
        &:first-child {
          text-align: left;
        }

        &:last-child {
          text-align: right;
        }
      }
    }
  }

  .progress-bar-label {
    font-size: 0.9em;

    color: ${theme.colors.label};
  }
`;
