import { styledTheme } from 'common/theme';
import styled from 'styled-components';

export const MachineModesContainer = styled.div`
  &.machine-mode-container {
    grid-column: 1 / span 4;
    width: 100%;

    .widget-ui__main {
      display: flex;
      flex-direction: column;
      height: 400px;
      align-items: center;
      justify-content: center;
      border-color: #c2c2c6;
      position: relative;
      padding-top: 0;
      overflow-y: auto;
      width: 100%;
      border-width: 0 1px 1px 1px;
      border-style: solid;
      border-bottom-left-radius: 0.625rem;
      border-bottom-right-radius: 0.625rem;
      gap: 1.5em;

      img {
        max-height: 85px;
        width: auto;
        height: 100%;
      }

      .loader-message {
        font-size: clamp(13.5px, 1.2vw, 16px);
        font-weight: 500;
        color: #828285;
      }
    }
  }

  .widget-ui__header {
    display: flex;
    border-width: 1px 1px 0 1px;
    border-style: solid;
    gap: 0.5em;
    align-items: center;
    padding: 1.3em;
    padding-bottom: 0;
    border-top-left-radius: 0.625rem;
    border-top-right-radius: 0.625rem;
    border-color: ${styledTheme.colors.border.main};

    h2 {
      color: ${styledTheme.colors.title};
      font-weight: 500;
      font-size: 1.1em;
      flex-grow: 1;
      padding: 0;
      text-transform: capitalize;
      margin: 0;
    }
  }

  .machine-modes-main {
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-bottom-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    border-color: ${styledTheme.colors.border.main};
    padding: 0 1.3em;
    width: 100%;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-template-areas:
      'legend time-bar'
      'border-line border-line'
      'machine-states--zoom machine-states--zoom'
      'machine-states-duration machine-states-zoomed';

    @media (max-width: 1325px) {
      grid-template-areas:
        'legend time-bar'
        'border-line border-line'
        'machine-states--zoom machine-states--zoom'
        'machine-states-zoomed machine-states-zoomed'
        'machine-states-duration machine-states-duration';
    }

    .chart-legend {
      margin-top: 0;
      height: 42px;
    }
  }

  .container_no_data {
    padding: 1rem;
    max-width: 250px;
    margin: 3rem auto;
    grid-column: 2;
    justify-self: center;
    align-self: center;
    text-align: center;

    img {
      width: 100%;
    }
  }

  .machine-states--zoom {
    height: 135px;
    grid-area: machine-states--zoom;
    max-width: 100%;
    box-sizing: border-box;
  }

  .new-machine-states-zoomed {
    grid-area: machine-states-zoomed;
    min-width: 100%;
  }

  .machine-states-duration {
    box-sizing: border-box;
    min-height: 300px;
    padding-right: 2rem;
    margin-right: 1rem;

    @media (min-width: 1025px) {
      min-height: 340px;
      border-right: 1px solid ${styledTheme.colors.border.light};
    }

    h3 {
      color: ${styledTheme.colors.title};
      margin: 0;
      padding-bottom: 0.5rem;
    }
  }

  .widget__time-bar {
    align-items: baseline;

    .reset-button {
      top: 0;
      margin-right: 0.5rem;
      color: ${styledTheme.colors.main};
      font-size: 0.85rem;

      @media (min-width: 1025px) {
        top: 0px;
        font-size: 0.85rem;
      }
    }

    p {
      font-size: 0.9em;
      margin: 0;
    }
  }

  .border-line {
    grid-area: border-line;
    margin: 0.5rem 0 0.3rem;
    width: 100%;
    color: ${styledTheme.colors.main};
    opacity: 0.5;
    box-sizing: border-box;
  }

  .d3-tooltip {
    padding: 0.25rem;
    font-size: 0.75rem;
    padding: 0.35rem 0.25rem 0.35rem 0.35rem;
    background: ${styledTheme.colors.neg};
    border-width: 1px;
    border-style: solid;
    border-color: ${styledTheme.colors.border.main};
    border-radius: 5px;
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.3);
    width: 310px;

    .tooltip--header {
      font-weight: bold;
    }

    .tooltip--subheader {
      display: flex;
      justify-content: space-between;
      padding: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      &.time,
      &.duration {
        color: ${styledTheme.colors.gray700};
        padding: 0.15rem 0;
        border-bottom: 0;

        b {
          color: ${styledTheme.colors.black};
        }
      }

      &.time {
        padding-right: 1rem;
      }
    }

    .tooltip--line-item {
      margin: 0.75rem 0 0.25rem;
    }

    .tooltip--value {
      font-weight: 500;
    }

    .color-indicator {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      margin-right: 3px;
    }
  }
`;

export const MachineStateContainer = styled.div`
  width: 100%;
  box-sizing: border-box;

  .bar-parent,
  .bar-child {
    rx: 5px; /* Horizontal corner radius */
    ry: 5px; /* Vertical corner radius */
    cursor: pointer;
  }

  .custom-tick {
    text-align: right;

    &.top-level p,
    &.top-level button {
      font-size: 0.85rem;
      color: ${styledTheme.colors.black};
    }

    button {
      display: flex;
      border: 0;
      background: none;
      cursor: pointer;
      font-weight: 600;
      padding: 0;
      justify-content: end;
      width: 100%;

      img {
        padding-right: 1rem;
      }
    }

    p {
      color: ${styledTheme.colors.gray700};
      font-size: 0.75rem;
      font-weight: 600;
      margin: 0;
    }
  }

  .no_data {
    padding-left: 25%;
  }
`;
