import React from 'react';
import { BarChartWidget } from 'common/components';
import { useLaneAlarmsWidget } from './hooks/useLaneAlarmsWidget';
import { WidgetDateRangeProvider } from 'common/ui/WidgetUi/providers/useWidgetDateRange';
import { ChartTooltipItem } from '../OEEWidget/OEEWidget.elements';
import { useBuSettings } from 'common/pages/fleetV2/providers';

export const Provided = (): JSX.Element => {
  const { chartData, isLoading, hasError, hasMessage, groupedByLaneData } = useLaneAlarmsWidget();
  const { colors } = useBuSettings();
  const Tooltip = (props?: Record<string, unknown>) => {
    if (!props || !groupedByLaneData) return <></>;

    return (
      <>
        <div className="tooltip__title">{props?.category}</div>
        {groupedByLaneData?.[props?.category as string]
          ?.map((item?: Record<string, unknown>) => (
            <ChartTooltipItem
              className="item"
              key={item?.group as string}
              color={colors?.[item?.group as string]}
            >
              <span>
                {item?.group} - <strong>{item?.value}</strong>
              </span>
            </ChartTooltipItem>
          ))
          .reverse()}
      </>
    );
  };

  const widgetSettings = {
    className: 'lane-alarms-widget',
    title: 'Lane Alarms',
    hasDateFooter: true,
    isLoading,
    hasError,
    hasMessage,
    colors,
    hasDateRanges: {
      'Last 3 Days': {
        subtractDays: 2
      },
      'Last 7 Days': {
        subtractDays: 6
      },
      'Last 14 Days': {
        subtractDays: 13
      },
      'Last 30 Days': {
        subtractDays: 29
      }
    }
    //defaultDateRange: '30 days'
  };

  const chartSettings = {
    chartData,
    format: {
      bottomTicks: (label?: string | number) => label,
      leftTicks: (label?: number | string) => label
    },
    Tooltip
  };

  return <BarChartWidget {...widgetSettings} {...chartSettings} />;
};

export const LaneAlarmsWidget = (): JSX.Element => {
  return (
    <WidgetDateRangeProvider subtractDays={3} defaultLabel="Last 3 Days">
      <Provided />
    </WidgetDateRangeProvider>
  );
};

//<StackedBarChart {...dims} {...chartSettings} />
