// 3rd party libs
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import NewKPIOverTimeGraph from 'components/NewKPIOverTimeGraph';
import {
  axisStyle,
  formatLineSeriesTooltip,
  toLineSeries,
  yAxis
} from 'components/machine-health/ConfiguredMatrixWidgetGroup/utils';
import { Card, Loader, PermissionWrapper, useDateRange } from 'components';
import { PermissionScopeName } from 'types/user-management';
import { Role, UserScopes } from 'types';
import { AdminCardEditButton, GenericWidgetPage } from 'components/machine-health';
import { useGetMachineConfiguratorDataQuery } from 'api';
import {
  BaseTag,
  MachinePerformanceSubTabs,
  MachinePerformanceTabs,
  ProteinMachineRouteQueryParams
} from 'types/protein';
import { useParams } from 'react-router-dom';
import { useLanguage, useTimeZone } from 'providers';
import { useQueryMachineTags } from 'hooks';
import { Interpolation } from 'types/graph';
import {
  LeftSideContainer,
  NoContentText,
  RightHeaderDiv,
  RightSideContainer,
  RightSubContainer,
  StyledIconButton,
  TitleDiv,
  TitleText,
  VictoryChartContainer,
  Container as ContainerNoData
} from 'components/NewKPIOverTimeGraph/NewKPIOverTimeGraph.elements';
import { ConsumablesLogo } from 'icons/ConsumablesLogo';
import { SettingIcon } from 'icons/SettingIcon';
import theme from 'themes';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  padding: 0 3.25rem;
`;

export const Consumables = (): JSX.Element => {
  const { t } = useTranslation(['mh']);
  const { machineId } = useParams<ProteinMachineRouteQueryParams>();
  const { timeZone } = useTimeZone();
  const { languageId } = useLanguage();
  const [chartTags, setChartTags] = useState<string[]>();
  const [isEditAdminPopupOpen, setIsEditAdminPopupOpen] = useState<boolean>(false);
  const handleIsEditAdminPopupOpen = () => {
    setIsEditAdminPopupOpen(!isEditAdminPopupOpen);
  };

  const { data } = useGetMachineConfiguratorDataQuery({
    machineId,
    labels: [MachinePerformanceTabs.Consumables],
    languageId: languageId
  });

  useEffect(() => {
    if (data && data[0]) {
      setChartTags(data && data[0].tags?.map((tag) => tag.id));
    }
  }, [data]);

  const { dateRange } = useDateRange();

  const { tags, isLoading } = useQueryMachineTags({
    startDatetime: dateRange.startTime,
    endDatetime: dateRange.endTime,
    tagCodes: chartTags || [],
    isPollingSkip: false
  });

  const { numberTags } = useMemo(() => {
    const numberTags: BaseTag[] = [];
    tags?.forEach((tag) => {
      if (tag.values.every((value) => typeof value.value === 'number')) {
        numberTags.push(tag);
      }
    });

    return { numberTags };
  }, [tags]);

  const { numberSeries } = useMemo(() => {
    const numberSeries = formatLineSeriesTooltip(toLineSeries(numberTags), timeZone);
    return { numberSeries };
  }, [numberTags, timeZone]);

  if (isLoading && !numberSeries.length) {
    return <Loader size={50} />;
  }

  return (
    <>
      <Container>
        {!isLoading && numberSeries.length ? (
          <NewKPIOverTimeGraph
            key={`chart-${MachinePerformanceTabs.Consumables}`}
            series={numberSeries}
            graphContainerHeight="18.3125rem"
            title={'Consumables'}
            axisH={yAxis(timeZone)}
            axisV={axisStyle.vertical}
            isBooleanChart={false}
            interpolation={Interpolation.Linear}
            displayZeroLine={true}
            setIsEditAdminPopupOpen={handleIsEditAdminPopupOpen}
            isLoading={isLoading}
            xDomain={[dateRange.startTime, dateRange.endTime]}
          />
        ) : (
          <ContainerNoData as={Card}>
            <LeftSideContainer>
              <TitleDiv>
                <ConsumablesLogo />
                <TitleText>{t('consumables')}</TitleText>
              </TitleDiv>
            </LeftSideContainer>
            <RightSideContainer>
              <RightSubContainer>
                <RightHeaderDiv style={{ justifyContent: 'end' }}>
                  <div>
                    <StyledIconButton onClick={() => handleIsEditAdminPopupOpen()}>
                      <SettingIcon color={theme.colors.primaryBlue5} />
                    </StyledIconButton>
                  </div>
                </RightHeaderDiv>
              </RightSubContainer>
              <VictoryChartContainer>
                <NoContentText>No data available</NoContentText>
              </VictoryChartContainer>
            </RightSideContainer>
          </ContainerNoData>
        )}

        {data?.[0]?.id && (
          <PermissionWrapper
            page={PermissionScopeName.FLEET}
            scope={UserScopes.Write}
            role={Role.Admin}
          >
            <AdminCardEditButton
              hideInactive
              machineId={machineId}
              widgetId={data[0].id}
              isEditAdminPopupOpen={isEditAdminPopupOpen}
              setIsEditAdminPopupOpen={handleIsEditAdminPopupOpen}
            />
          </PermissionWrapper>
        )}
      </Container>
      <GenericWidgetPage
        pageTemplateId={MachinePerformanceSubTabs.ConsumablesMain}
        {...dateRange}
      />
    </>
  );
};

export default Consumables;
