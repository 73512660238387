import React, { ReactNode } from 'react';
import { CenterValuesContainer } from './CenterValues.elements';
import { useChartGenerator, useChartGeneratorData } from '../../../hooks';
import { useChartSelections } from '../../../hooks/useChartSelections';
import { useTranslation } from 'react-i18next';

export const CenterValues = (): JSX.Element => {
  const { t } = useTranslation(['mh']);
  const { keysToUse, getScale, groupKey, Components, format, colors, ...dims } =
    useChartGenerator() || {};
  const { getData } = useChartGeneratorData();
  const [selected] = useChartSelections();
  const chartTotals = getData('groupKeyTotals') as Record<string, unknown>;

  //const dataValueKey = dims?.valueKey;
  const chartValueKey = keysToUse?.valueKey;

  const total = getScale('valueKeyTotal', { valueKey: chartValueKey });

  const CenterValuesComponent = Components?.CenterValues;
  const formatter = format?.centerValues;

  const width = dims?.width || 0;
  const height = dims?.height || 0;

  const sizeToUse = (width > height ? height : width) as number;

  let valueToUse = total;
  const labelToUse = formatter?.({ label: true, chartValueKey, chartTotals });
  let titleToUse: ReactNode = undefined;

  const selectedGroupKey = selected?.[groupKey || 'group'] as ReactNode;
  const selectedGroupTotals = chartTotals?.[selectedGroupKey as string] as Record<string, unknown>;

  let color: string | undefined = undefined;

  const isDefaultView = selectedGroupTotals ? true : false;

  if (selectedGroupTotals) {
    valueToUse = selectedGroupTotals?.[chartValueKey as string] as number;
    titleToUse = selectedGroupKey || labelToUse;
    color =
      (selectedGroupTotals?.color as string) ||
      (colors?.[selectedGroupKey as string] as string) ||
      color;
  }

  let title = (formatter?.({ title: titleToUse, isDefaultView }) || titleToUse) as ReactNode;
  let label = (formatter?.({ label: labelToUse, isDefaultView, valueKey: chartValueKey }) ||
    labelToUse) as ReactNode;
  let value = (formatter?.({ value: valueToUse, isDefaultView }) || valueToUse) as ReactNode;

  if (typeof title === 'string') title = t(title);
  if (typeof label === 'string') label = t(label);
  if (typeof value === 'string') value = t(value);

  if (CenterValuesComponent)
    return <CenterValuesComponent {...{ title, value, label, chartTotals, isDefaultView }} />;

  return (
    <CenterValuesContainer className="center-values-container" {...{ sizeToUse, color }}>
      <div className="center-values">
        {label && <div className="center-values-label">{label}</div>}
        {value && <div className="center-values-value">{value}</div>}
        {title && <div className="center-values-title has-color-circle">{title}</div>}
      </div>
    </CenterValuesContainer>
  );
};
