export const getMaxOrMinValue = (
  valueKeys: string | string[],
  maxOrMin: 'max' | 'min',
  data?: Record<string, unknown>[]
): number | undefined => {
  if (!data) return undefined;

  if (typeof valueKeys === 'string') valueKeys = [valueKeys];

  const maxValue = data?.reduce((acc: number, item: Record<string, unknown>) => {
    valueKeys.forEach((valueKey: string) => {
      if (typeof valueKey === 'string') {
        const curVal = item?.[valueKey];
        // make sure it's valid
        if (typeof curVal === 'number') {
          if (curVal > acc) acc = maxOrMin === 'max' ? curVal : acc;
          else if (curVal < acc) acc = maxOrMin === 'min' ? curVal : acc;
        }
      }
    });
    return acc;
  }, 0);

  return maxValue;
};

export const getMaxValue = (
  valueKeys: string | string[],
  data?: Record<string, unknown>[]
): number | undefined => {
  return getMaxOrMinValue(valueKeys, 'max', data);
};

export const getMinValue = (
  valueKeys: string | string[],
  data?: Record<string, unknown>[]
): number | undefined => getMaxOrMinValue(valueKeys, 'min', data);
