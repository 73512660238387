import React, { useState } from 'react';
import { ItemContainer } from './bookmarkDocItem.element';
import { BookmarkIcon } from 'icons/docsIcons';
import { Typography } from 'components';
import theme from 'themes';
import CopyTextComponent from '../CopyLink';
import { Documents } from 'types/machine-management';
import {
  useAddBookmarkDocumentMutation,
  useLazyGetFpsMachineDocFileQuery,
  useRemoveBookmarkDocumentMutation
} from 'api';
import FilePreviewModal, {
  FileObj
} from 'pages/MachineManagement/UploadMachinePdf/FilePreviewModal';
import { v4 as uuidv4 } from 'uuid';
import { ToastMsg } from '../Toast/Toast';
import CustomLoader from 'components/CustomLoader';
import { IcoLink } from 'icons/IcoLink';
import BookmarkLimitModal from './BookmarkLimitModal';
type RespObj = {
  data: RespObj;
  detail: string;
};
type Resp = {
  error: RespObj;
  data: RespObj;
};
type Props = {
  file: Documents;
  totalBookmark: number;
};
const BookmarkDocItem = ({ file, totalBookmark }: Props): JSX.Element => {
  const [isBookmark, setIsBookmark] = useState(file.hasBookmark);
  const [selectedFilePreview, setSelectedFilePreview] = useState<FileObj | undefined>();

  const [getSignedUrl, { isLoading, isFetching }] = useLazyGetFpsMachineDocFileQuery();
  const [isLimitModal, setIsLimitModal] = useState(false);

  const [addBookmark, { isLoading: addBookmarkLoading }] = useAddBookmarkDocumentMutation();
  const [removeBookmark, { isLoading: removeBookmarkLoading }] =
    useRemoveBookmarkDocumentMutation();

  const handleAddBookmark = async () => {
    if (totalBookmark >= 10) {
      return setIsLimitModal(true);
    }
    const id = uuidv4();
    const resp = (await addBookmark({ document_id: file.id, id })) as Resp;
    if (resp.data) {
      setIsBookmark(true);
      ToastMsg({
        heading: 'Successful',
        message: 'Bookmark saved successfully',
        type: 'success',
        position: 'top-right'
      });
    } else {
      ToastMsg({
        heading: 'Error',
        message: resp.error.data.detail,
        type: 'error',
        position: 'top-right'
      });
    }
  };

  const handleRemoveBookmark = async () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const res = (await removeBookmark(file.id)) as { data: any; error: any };
    if (res.error) {
      ToastMsg({
        heading: 'Error',
        message: res.error.data.detail,
        type: 'error',
        position: 'top-right'
      });
    } else {
      ToastMsg({
        heading: 'Successful',
        message: 'File removed from bookmark',
        type: 'success',
        position: 'top-right'
      });
      setIsBookmark(false);
    }
  };

  const handlePreviewModal = () => {
    setSelectedFilePreview({ id: file.id });
  };

  const handleCopyText = async () => {
    try {
      const resp = await getSignedUrl({ document_id: file.id });
      if (document.hasFocus()) {
        navigator.clipboard.writeText(resp.data?.signedUrl || '');
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <ItemContainer>
        {addBookmarkLoading || removeBookmarkLoading ? (
          <CustomLoader size="1.3rem" thickness=".2rem" />
        ) : (
          <BookmarkIcon
            className="bookmarkIcon"
            fill={isBookmark ? theme.colors.primaryBlue5 : 'none'}
            onClick={isBookmark ? handleRemoveBookmark : handleAddBookmark}
          />
        )}
        <Typography
          onClick={handlePreviewModal}
          mb={0}
          color={theme.colors.primaryBlue5}
          size={'0.875rem'}
          className="fileName"
        >
          {file.userFileName}
        </Typography>
        <CopyTextComponent
          icon={<IcoLink width="18" />}
          isLoading={isLoading || isFetching}
          handleCopyText={handleCopyText}
        />
      </ItemContainer>
      {selectedFilePreview && (
        <FilePreviewModal
          type="fps"
          setSelectedFilePreview={setSelectedFilePreview}
          file={{ ...file, name: file.userFileName }}
        />
      )}
      {isLimitModal && (
        <BookmarkLimitModal showModal={isLimitModal} closeModal={() => setIsLimitModal(false)} />
      )}
    </>
  );
};

export default BookmarkDocItem;
