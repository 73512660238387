// 3rd party libs
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { find } from 'lodash';

// Components
import ImageUploadModal from './ImageUploadModal';
import { Column, DashboardWidget, PageHeader, Row, DateRangeProvider } from 'components';
import { LineDurationChart, LineTable } from 'components/line';
import { LineCardWidget } from 'components/StyledUi/LineCardWidget/LineCardWidget';
import MachineVisionContainer from './MachineVisionContainer';

import { useFleetNavigation, FleetNavigationEntityType } from 'providers';

// Routes
import { JBTRoutes } from 'constants/routes';

// Types
import { LineRouteQueryParams, MachineUtilization } from 'types/protein';
import { ResourceType } from 'types';
import { PermissionScopeName } from 'types/user-management';

// Api
import {
  useGetLineViewAssetsQuery,
  useGetLineInfoQuery,
  useGetPlantByIdQuery,
  useGetLineDisplayOrderQuery,
  useCreateMachineDisplayOrderMutation,
  useUpdateMachineDisplayOrderMutation
} from 'api';

// Hooks
import { usePermissions } from 'hooks';

// Helpers
import { mapBusinessUnitId } from 'helpers/machine';
import { NAV_ITEMS_TO_ID } from 'constants/nav';
import { ColumnIcon } from 'icons/ColumnIcon';
import {
  LineCardContainerHeader,
  LineCardContainerParent,
  LineContainerTitle
} from './Line.element';
import { StyledIconButton } from 'components/NewKPIOverTimeGraph/NewKPIOverTimeGraph.elements';
import ChangeItemOrderPopUp from './ChangeItemOrderPopUp';
import { MachineDisplayOrderItemProps } from 'types/machine-health/widget-table';
import { ToastMsg } from 'common/components/Toast/Toast';
import { useTranslation } from 'react-i18next';

// Styling
const Container = styled.div`
  .line-status-col {
    height: unset;
  }
  width: 100%;
  position: relative;
  padding: 1.5rem 3.125rem 0 3.125rem;
  margin-top: 1rem;

  .bread-crumbs {
    margin-bottom: 2em;
  }

  .line-status-col {
    header {
      height: 5em !important;
    }
  }
`;

const StyledLineImage = styled.img`
  padding: 1rem;
`;

const LineCardContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin-bottom: 1.25rem;

  .data-loader {
    height: 12rem !important;
  }

  .ui-dashboard-widget {
    width: 32%;
    flex-grow: 1;
    min-width: 300px;
  }
  .widget-ui {
    width: auto;
    flex: 1;
  }

  .widget-ui__main {
    padding: 0 1.5rem 1.5rem 1.5rem;
  }

  .widget-ui__header {
    padding: 1.25rem;
  }
`;

const defaultImageSrc = '/assets/placeholder/machines/place-holder.jpg';

const Line = (): JSX.Element => {
  const { lineId } = useParams<LineRouteQueryParams>();
  const { t } = useTranslation(['mh']);

  const navContext = useFleetNavigation();
  const { data: dataLineOrder, isFetching: dataLineOrderIsFetching } = useGetLineDisplayOrderQuery({
    lineId
  });
  const [createMachineDisplayOrder] = useCreateMachineDisplayOrderMutation();
  const [updateMachineDisplayOrder] = useUpdateMachineDisplayOrderMutation();
  const [isChangeOrder, setIsChangeOrder] = useState<boolean>(false);

  const createMachineDisplayOrderFunc = async (
    line_id?: string,
    machine_id?: string,
    display_order?: string
  ) => {
    await createMachineDisplayOrder({
      configuration: {
        machine_id: machine_id,
        line_id: line_id,
        display_order: display_order
      }
    })
      .unwrap()
      .catch((error) => {
        console.error(error?.data?.detail);
      });
  };

  const updateMachineDisplayOrderFunc = async (machine_id?: string, display_order?: string) => {
    await updateMachineDisplayOrder({
      configuration: {
        machine_id: machine_id,
        display_order: display_order
      }
    })
      .unwrap()
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.error(error?.data?.detail);
        throw new Error(error);
      });
  };

  useEffect(() => {
    lineId && navContext.updateEntityIfNeeded({ type: FleetNavigationEntityType.LINE, id: lineId });
    navContext.updateNavIdIfNeeded(NAV_ITEMS_TO_ID.fleet);
  }, [lineId, navContext]);

  const history = useHistory();
  const { data } = useGetLineInfoQuery({ lineId });
  const { name, plantId } = data || { name: '', plantId: '' };
  const { data: accountData, isFetching: accountDataIsFetching } = useGetPlantByIdQuery(plantId);
  const permission = usePermissions();
  const scopePermission = find(
    permission?.scopes,
    (scopeItem) => scopeItem.name === PermissionScopeName.FLEET
  );
  const [isVisualOverviewModalOpen, setIsVisualOverviewModalOpen] = useState(false);

  const [utilizationByMachine, setUtilizationByMachine] = useState<
    { id: string; utilization: MachineUtilization }[]
  >([]);

  const {
    data: lineAssets,
    isLoading: isImageLoading,
    error: imageError
  } = useGetLineViewAssetsQuery({
    assetType: ResourceType.LineImage,
    lineId
  });

  const handleClick = (id: string) => {
    history.push(`${JBTRoutes.machineHealthOverview.replace(':machineId', id)}`);
  };
  const handleEditTagsClick = () => {
    return null;
  };

  const machines = accountData?.machines.filter((item) => item.lineId === lineId);

  const breadCrumbSettings = {
    paths: {
      customer: {
        label: accountData?.name,
        isLoading: !accountData
      },
      site: {
        label: accountData?.siteName,
        isLoading: !accountData,
        slug: `/fleet/site/${plantId}`
      },
      line: {
        label: data?.name,
        isLoading: !data,
        slug: `/fleet/line/${lineId}`
      }
    }
  };

  const [initialMachineList, setInitialMachineList] = useState<
    { id: string; name: string }[] | undefined
  >(undefined);

  const [machineList, setMachineList] = useState<{ id: string; name: string }[] | undefined>(
    undefined
  );

  useEffect(() => {
    if (machines && machines.length > 0) {
      const sortedMachineOrder =
        Array.isArray(dataLineOrder) && dataLineOrder.length
          ? [...dataLineOrder].sort((a, b) => a?.displayOrder - b?.displayOrder)
          : [];

      const updateMachineDisplayOrderWrapper = (machineId: string, order: string) => {
        if (dataLineOrder && dataLineOrder.find((machine) => machine.machineId === machineId)) {
          updateMachineDisplayOrderFunc(machineId, order);
        } else {
          createMachineDisplayOrderFunc(lineId, machineId, order);
        }
      };

      const createMachineList = (order: MachineDisplayOrderItemProps[]) =>
        order.map((item: { machineId: string }) => ({
          id: machines.find((machine) => machine.id === item.machineId)?.id || '',
          name: machines.find((machine) => machine.id === item.machineId)?.description || ''
        }));

      if (machines.length === sortedMachineOrder.length) {
        setMachineList(createMachineList(sortedMachineOrder));
        setInitialMachineList(createMachineList(sortedMachineOrder));
      } else {
        setMachineList(machines.map((item) => ({ id: item.id, name: item.description })));
        setInitialMachineList(machines.map((item) => ({ id: item.id, name: item.description })));

        machines.forEach((element, i) => {
          updateMachineDisplayOrderWrapper(element.id, String(i + 1));
        });
      }
    }
  }, [accountData]);

  const saveChangeOrder = () => {
    const updateMachineDisplayOrderPromisesAll =
      machineList &&
      machineList.map((element, i) => {
        return updateMachineDisplayOrderFunc(element.id, String(i + 1));
      });

    Promise.all(updateMachineDisplayOrderPromisesAll || [])
      .then(() => {
        setInitialMachineList(machineList);
        setIsChangeOrder(!isChangeOrder);
        return ToastMsg({
          message: t('machine_order_change_success_msg', { ns: 'common' }) as string,
          theme: 'colored',
          type: 'success'
        });
      })
      .catch(() => {
        setMachineList(initialMachineList);
        setIsChangeOrder(!isChangeOrder);
        ToastMsg({
          message: t('machine_order_change_error_msg', { ns: 'common' }) as string,
          theme: 'colored',
          type: 'warning'
        });
      });
  };

  const onCancelReOrder = () => {
    setMachineList(initialMachineList);
    setIsChangeOrder(!isChangeOrder);
  };

  return (
    <>
      <Container className="line-container">
        <PageHeader heading={''} breadCrumbSettings={breadCrumbSettings} />
        {process.env.REACT_APP_LINE_VIEW_FEATURE === 'true' && (
          <DateRangeProvider subtractDaysCount={1} timeZone={data?.timezone}>
            {isChangeOrder ? (
              <ChangeItemOrderPopUp
                machineList={machineList as { id: string; name: string }[]}
                setMachineList={setMachineList}
                isChangeItemOrderPopupOpen={isChangeOrder}
                onCancelReOrder={onCancelReOrder}
                onSaveReOrder={saveChangeOrder}
                isLoading={dataLineOrderIsFetching && accountDataIsFetching}
              />
            ) : (
              ''
            )}
            <LineCardContainerParent>
              <LineCardContainerHeader>
                <LineContainerTitle>
                  {t('machines_in_the_line', { ns: 'common' }) as string}
                </LineContainerTitle>
                <StyledIconButton
                  style={{ padding: '0.5rem !important' }}
                  onClick={() => setIsChangeOrder(!isChangeOrder)}
                >
                  <ColumnIcon />
                </StyledIconButton>
              </LineCardContainerHeader>
              <LineCardContainer className="line-card-container">
                {machineList?.map((el, i) => {
                  const settings = {
                    businessUnitId:
                      mapBusinessUnitId(
                        machines?.find((machine) => machine.id == el.id)?.businessUnit
                      ) || 3,
                    machineId: el.id,
                    name: machines?.find((machine) => machine.id == el.id)?.description || '',
                    handleClick,
                    handleEditTagsClick,
                    headerIcon: {
                      label: ''
                    },
                    setUtilizationByMachine
                  };
                  return <LineCardWidget key={i} {...settings} />;
                })}
              </LineCardContainer>
            </LineCardContainerParent>
          </DateRangeProvider>
        )}

        {/* Machine Vision  */}
        <MachineVisionContainer />

        {/* Line Status bar chart */}
        {process.env.REACT_APP_LINE_VIEW_FEATURE === 'true' && (
          <Row>
            <Column className="line-status-col">
              <DateRangeProvider timeZone={data?.timezone}>
                <LineDurationChart lineId={lineId} lineName={name || 'Line'} />
              </DateRangeProvider>
            </Column>
          </Row>
        )}

        {/* Machine table */}
        <Row>
          <Column height="unset">
            <LineTable utilizationByMachine={utilizationByMachine} />
          </Column>
        </Row>

        {/* Visual Overview */}
        {process.env.REACT_APP_LINE_VIEW_FEATURE === 'true' && (
          <Row>
            <Column height="unset">
              <DashboardWidget
                hasError={imageError && 'Failed to load image'}
                isAdminWidget={scopePermission?.write || permission.isAdmin}
                isLoading={isImageLoading}
                onAdminButtonClickCallback={() => setIsVisualOverviewModalOpen(true)}
                title="Visual Overview"
              >
                <StyledLineImage
                  src={lineAssets?.[lineAssets.length - 1]?.url || defaultImageSrc}
                  alt="Machine line layout"
                />
              </DashboardWidget>
            </Column>
          </Row>
        )}
      </Container>

      {process.env.REACT_APP_LINE_VIEW_FEATURE === 'true' && (
        <ImageUploadModal
          isOpen={isVisualOverviewModalOpen}
          lineId={lineId}
          onCloseCallback={() => setIsVisualOverviewModalOpen(false)}
        />
      )}
    </>
  );
};

export default Line;

/*breadcrumbs={[
    {
      label: accountData ? accountData.customer : 'Retrieving Customer',
      link: JBTRoutes.fleet
    },
    {
      label: accountData
        ? process.env.REACT_APP_SIDENAV_ENABLE_ORG_LEVEL === 'true'
          ? accountData.siteName
          : accountData.name
        : 'Retrieving Site',
      link: JBTRoutes.site.replace(':plantId', plantId)
    },
    {
      label: name
    }
  ]}*/
