import React from 'react';

import { StyledUiContainerProps, styledTheme } from 'components';
import {
  MachineOverviewWidgetProps,
  MachineOverviewWidget as OverviewWidget
} from 'common/components/WidgetUi';
import { IcoProductionMetrics } from 'icons/IcoProductionMetrics';
import { IcoRecipes } from 'icons/IcoRecipes';
import MachineImage from 'img/Linear Filler 2.png';
import { useMachineOverviewWidget } from './hooks/useMachineOverviewWidget';
import { useTranslation } from 'react-i18next';
import { useBuSettings } from 'common/pages/fleetV2/providers/useBuSettings';

export const MachineOverviewWidget = ({ className }: StyledUiContainerProps): JSX.Element => {
  className = className ? `${className} machine-overview-widget` : `machine-overview-widget`;
  const { t } = useTranslation(['mh']);
  const {
    isLoading,
    hasMessage,
    hasError,
    approvedbottles,
    run_length_in_seconds,
    recipe_name,
    isRunning,
    machinestate,
    avg_bottles_per_hour
  } = useMachineOverviewWidget();

  const { colors, Icons } = useBuSettings();

  const widgetSettings: MachineOverviewWidgetProps = {
    title: 'Machine Overview',
    image: MachineImage,
    className,
    hasMessage,
    hasError,
    isLoading,
    isRunning
  };

  const color = colors?.[machinestate as string];
  const Icon = Icons?.[machinestate as string];

  const [h, m, s] = (String(run_length_in_seconds) || '').split(':');

  let formattedRunLength = s ? `${s}s` : ``;
  if (m) formattedRunLength = `${m}m ${formattedRunLength}`;
  if (h) formattedRunLength = `${h}h ${formattedRunLength}`;
  formattedRunLength = formattedRunLength.trim();
  // the values of the machine state
  widgetSettings.tiles = [
    {
      id: 'machine-state',
      title: 'Machine State',
      value: machinestate,
      subValue: formattedRunLength,
      color,
      Icon
    },
    {
      id: 'product-recipe',
      title: 'Product Recipe',
      value: recipe_name,
      color: styledTheme.color.secondary,
      Icon: <IcoRecipes color="white" />
    },
    {
      id: 'bottles-produced',
      title: 'Bottles Produced',
      value: Number(approvedbottles)?.toLocaleString(),
      subValue: `${Math.round(Number(avg_bottles_per_hour || 0))?.toLocaleString()} avg/${t(
        'hour'
      )}`,
      color: '#835DD0',
      Icon: <IcoProductionMetrics color="white" />
    }
  ];

  return <OverviewWidget {...widgetSettings} />;
};
