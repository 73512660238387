import React from 'react';
import { Swatch } from './Swatch';

interface SwatchListProps {
  colors: string[];
  onColorClick?: (color: string) => void;
}

export const SwatchList = ({ colors, onColorClick }: SwatchListProps): JSx.Element => {
  return (
    <div>
      {colors.map((color, idx) => (
        <Swatch key={idx} color={color} onClick={onColorClick} />
      ))}
    </div>
  );
};
