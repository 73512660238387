import React from 'react';
import { PieChartWidget } from 'common/components';
import { useGetAsepticMachineHealthKpiQuery } from 'api';
import { useRouter } from 'common/hooks/useRouter';
import { fleetPageRoute } from 'common/pages/fleetV2/settings/fleetPageRoutes';
import { AsepticMachineHealthInterval, WidgetType } from 'types/machine-health';

const chartColor = `rgba(0, 118, 204, 1)`;

export const ThroughputEfficiencyWidget = (): JSX.Element => {
  return <Provided />;
};

const Provided = (): JSX.Element => {
  const { id } = useRouter(fleetPageRoute) as { id: string };

  const { data, error, isLoading } = useGetAsepticMachineHealthKpiQuery({
    machineId: id,
    kpiDataInterval: AsepticMachineHealthInterval.Last24Hours,
    widgetType: WidgetType.THROUGHPUT,
    limit: 100000,
    includeHistoricData: false
  });

  const processedData = data?.reduce((acc: Record<string, Record<string, unknown>>, item) => {
    const id = item?.id;
    const units = item?.unit;
    const value = item?.value?.value === 0 ? `0` : item?.value?.value || undefined;
    if (!acc?.[id]) acc = { ...acc, [id]: { units, value } };
    return acc;
  }, {});

  const actual = Number(processedData?.Actual?.value);
  const target = Number(processedData?.Target?.value);
  const progress = actual === 0 || target === 0 ? 0 : Math.round((actual / target) * 100);

  const hasMessage = data && progress < 1 ? true : false;
  const widgetSettings = {
    className: 'throughput-efficiency-widget',
    title: 'throughput efficiency',
    bottomCellValues: [
      // actual value
      {
        value: actual,
        label: processedData?.Actual?.units
      },
      // target value
      {
        value: target,
        label: `Target ${processedData?.Target?.units}`
      }
    ],
    isLoading,
    hasError: error ? true : false,
    progress,
    color: chartColor,
    hasDateFooter: 'last 5 minutes',
    hideLegend: true,
    hasMessage
  };

  return <PieChartWidget {...widgetSettings} />;
};
