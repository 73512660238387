import React from 'react';

import { StyledUiContainerProps } from 'components';
import { WidgetUi } from 'common/components';
import { demoColors } from './demo/demoColors';
import { PointChart } from 'sandbox/joey/PointChart';
import { OEEChartPoint, WidgetMainContainer } from './OEEWidget.elements';
import { TilesContainer } from './OEEWidget.elements';
import { ResponsiveInlineChartLegend } from 'common/components/WidgetUi/ChartLegends';
import { ChartTooltipItem } from './OEEWidget.elements';
import { ChartTooltipWrapper } from 'common/ui';
import { useOEEWidget } from './hooks/useOEEWidget';
import { useTranslation } from 'react-i18next';
import { formatInTimeZone } from 'date-fns-tz';
import { WidgetDateRangeProvider } from 'common/ui/WidgetUi/providers/useWidgetDateRange';
import { useBuSettings, useMachineInfo } from 'common/pages/fleetV2/providers';

export const OEEWidget = (props: StyledUiContainerProps): JSX.Element => {
  const { timeZone } = useMachineInfo();

  return (
    <WidgetDateRangeProvider {...{ timeZone }} subtractDays={29} defaultLabel="Last 30 Days">
      <Provided {...props} />
    </WidgetDateRangeProvider>
  );
};

const ChartItem = (props: Record<string, unknown>): JSX.Element => {
  const { groupedDataForTooltips } = useOEEWidget();
  const { timeZone } = useMachineInfo();
  const { t } = useTranslation(['mh']);

  const formattedDate =
    timeZone && formatInTimeZone(props?.category as string, timeZone, 'MMM d, yyyy');

  return (
    <OEEChartPoint {...props}>
      <ChartTooltipWrapper>
        <div className="tooltip__title">{formattedDate}</div>
        {groupedDataForTooltips &&
          groupedDataForTooltips?.[props?.category as string].map(
            (item: { group?: string; value?: string | number }) => (
              <ChartTooltipItem
                className="item"
                key={item?.group}
                color={demoColors?.[item?.group as string]}
              >
                <span>
                  {t(item?.group as string)} - <strong>{item?.value}%</strong>
                </span>
              </ChartTooltipItem>
            )
          )}
      </ChartTooltipWrapper>
    </OEEChartPoint>
  );
};

export const Provided = (props: StyledUiContainerProps): JSX.Element => {
  const { chartData, isLoading, hasError, hasMessage, last7Days } = useOEEWidget();
  //const { average_oee, average_availability, average_performance, average_quality } = last7Days || {}
  const { colors } = useBuSettings();
  const { t } = useTranslation(['mh']);
  const { timeZone } = useMachineInfo();
  //console.log({ last7Days });
  const TileItem = ({
    color,
    label,
    value
  }: {
    color?: string;
    label: string;
    value: string;
    size?: string;
  }) => {
    return (
      <TilesContainer color={color} className={`progress-tile`}>
        <div className="tile__value colored">{value}%</div>
        <div className="tile__label">
          {t(label)} {t('rate')}
        </div>
      </TilesContainer>
    );
  };

  // this function gives you a % total for each oee value based on the entire selected time frame
  const weeklyValueTiles = [
    {
      label: 'oee',
      value: last7Days?.oee,
      color: colors?.oee
    },
    {
      label: 'availability',
      value: last7Days?.Availability_Rate,
      color: colors?.availability
    },
    {
      label: 'performance',
      value: last7Days?.Performance_Rate,
      color: colors?.performance
    },
    {
      label: 'quality',
      value: last7Days?.Quality_Rate,
      color: colors?.quality
    }
  ];

  const widgetSettings = {
    className: 'oee-widget',
    title: 'OEE',
    isLoading,
    hasError,
    hasMessage,
    ...props
  };

  const legendSettings = {
    className: 'widget-ui__sub-header no-overflow',
    items: weeklyValueTiles?.map((item) => ({ label: item?.label, color: item?.color }))
  };

  const chartSettings = {
    chartData,
    Item: ChartItem,
    format: {
      bottomTicks: (tick: string) => {
        const formattedDate = (
          <>
            {t('Week')}
            <br />
            {formatInTimeZone(tick as string, timeZone as string, 'I')}
          </>
        );
        return formattedDate || '';
      },
      leftTicks: (tick: string) => `${tick}%`
    },
    domains: {
      y: [0, 25, 50, 75, 100]
    }
  };

  return (
    <WidgetUi
      {...widgetSettings}
      SubHeader={<ResponsiveInlineChartLegend {...legendSettings} />}
      Main={
        <WidgetMainContainer className="widget-ui__main">
          <PointChart {...chartSettings} />
          <div className="col-right">
            <div className="oee-value-tiles">
              {weeklyValueTiles?.map((tile) => (
                <TileItem {...tile} key={tile.label} />
              ))}
            </div>
            <div className="date-label">
              <strong>{t('last 7 days')}</strong>
            </div>
          </div>
        </WidgetMainContainer>
      }
    />
  );
};
