import React, { useEffect, useRef, useState } from 'react';
import { selectAll } from 'd3';
import { getTimezoneOffset, utcToZonedTime } from 'date-fns-tz';
import { subMinutes } from 'date-fns';

import { DimensionsContainer } from 'components';
import { ICONMAP } from 'common/components/WheelZoomChart/utils';
import { ZoomContainer } from 'common/components/WheelZoomChart/elements';
import { ZoomedBrush } from 'common/ui/ZoomBrushD3/ZoomBrushD3-V2';
import { useDataManagementProvider } from '../../provider/useDataManagement';
import { ZOOMED_RANGE_MINNUTES } from '../../utils';

interface Props {
  className?: string;
}

interface ZoomBrushComponenetProps {
  width: number;
  triggerResize?: () => void;
}

export const ZoomBrushContainer = ({ className }: Props): JSX.Element => {
  return (
    <DimensionsContainer
      className={className}
      Component={({ width, triggerResize }) => {
        const set = { width, triggerResize };
        return <ZoomBrushComponenet {...set} />;
      }}
    />
  );
};

const ZoomBrushComponenet = ({ width, triggerResize }: ZoomBrushComponenetProps): JSX.Element => {
  const {
    data,
    zoomDaterange,
    chartDateRange,
    handelZoomInDaterangeUpdate,
    resetTrack,
    end_time,
    timezone
  } = useDataManagementProvider();

  const chartArea = useRef<HTMLDivElement | null>(null);
  const chart = useRef<ZoomedBrush>();

  const [chartWidth, setChartWidth] = useState(width);

  const settings = {
    height: 100,
    margins: {
      top: 50,
      right: 5,
      bottom: 50,
      left: 5
    },
    dataSettings: {
      data,
      xAxisKey: 'start_time',
      colorMapKey: 'mode_number',
      colorMap: ICONMAP,
      type: 'flags'
    },
    rangeX: chartDateRange, // Range for the whole component
    zoomedRangeX: zoomDaterange, // Range for the zoomed in part
    updateZoomedRangeX: (range: Date[]) => handelZoomInDaterangeUpdate(range), //Update zoomed in part
    defaultBackground: true,
    domainY: [0, 120],
    width
  };

  useEffect(() => {
    if (!zoomDaterange) return;

    if (!chart.current) {
      chart.current = new ZoomedBrush(chartArea.current, settings.dataSettings, { ...settings });
    } else {
      chart.current.updateBrushSelection(zoomDaterange);
    }
  }, [resetTrack]);

  useEffect(() => {
    if (!zoomDaterange) return;

    if (!chart.current) {
      chart.current = new ZoomedBrush(chartArea.current, settings.dataSettings, { ...settings });
    } else {
      if (!timezone) return;

      //Compares end date of blue zoomed are with current date.
      const endDateZoneOffset = getTimezoneOffset(timezone);
      const nowOffset = getTimezoneOffset(Intl.DateTimeFormat().resolvedOptions().timeZone);

      const endDateZoomRangeUTC =
        endDateZoneOffset > 0
          ? zoomDaterange[1].getTime() - endDateZoneOffset
          : zoomDaterange[1].getTime() + Math.abs(endDateZoneOffset);
      const nowUTC =
        nowOffset > 0
          ? new Date().getTime() - nowOffset
          : new Date().getTime() + Math.abs(nowOffset);
      const difference = Math.abs(endDateZoomRangeUTC - nowUTC) > 5 * 60 * 1000; //5min is grace perio

      //If we are zoomed in on a current date, then keep updating brush selection
      if (!difference) {
        //we need to check if what is greater zoom start or default zoom start
        const startDefault = subMinutes(utcToZonedTime(end_time, timezone), ZOOMED_RANGE_MINNUTES);
        const startZoom = zoomDaterange[0];

        const newRange = [
          startZoom > startDefault ? startZoom : startDefault,
          utcToZonedTime(end_time, timezone)
        ];
        chart.current.updateBrushSelection(newRange);
      }
    }
  }, [end_time]);

  useEffect(() => {
    if (!data || !chartDateRange || !zoomDaterange) return;

    if (!chart.current) {
      chart.current = new ZoomedBrush(chartArea.current, settings.dataSettings, { ...settings });
      chart.current.populateIcons(data);
      chart.current.updateBrushSelection(zoomDaterange);
    } else {
      chart.current.updateXAxis(chartDateRange);
      //updating brush selection once again
      chart.current.updateBrushSelection(zoomDaterange);
    }
  }, [chartDateRange]);

  useEffect(() => {
    if (!data?.length) return;
    if (!chart.current) {
      chart.current = new ZoomedBrush(chartArea.current, settings.dataSettings, { ...settings });
      chart.current.populateIcons(data);
      chart.current.updateBrushSelection(zoomDaterange);
    } else {
      const hasIcons = selectAll('.zoom-bigbrush .icon').empty();
      if (!hasIcons) {
        chart.current.rePopulateIcons(data); //repopulate line
      } else {
        chart.current.populateIcons(data);
      }
    }
  }, [data]);

  useEffect(() => {
    setChartWidth(width);
  }, [width, triggerResize]);

  useEffect(() => {
    if (!chartDateRange) return;
    chart.current = new ZoomedBrush(chartArea.current, settings.dataSettings, { ...settings });
  }, [chartWidth]);

  return (
    <>
      <ZoomContainer className={`chart-area zoom-brush`} ref={chartArea}></ZoomContainer>
    </>
  );
};
