import React, { ReactNode } from 'react';
import { useChartGenerator, useChartGeneratorData, useChartGeneratorTooltip } from '../../../hooks';
import { GeneratorTooltip } from '../../../generated/Tooltip';
import { ColoredLabelContainer, PieChartTooltipContainer } from './PieChartTooltip.elements';
import { useTranslation } from 'react-i18next';

export const PieChartTooltip = ({
  title,
  ...rest
}: {
  title?: ReactNode;
  rest?: Record<string, unknown>;
}): JSX.Element => {
  const { t } = useTranslation(['mh']);

  const { getData } = useChartGeneratorData();

  const pieChartTotals = getData('groupKeyTotals') as Record<string, unknown> | undefined;

  const tooltipData = (useChartGeneratorTooltip()?.tooltip?.data || {}) as Record<string, unknown>;

  const { Components, format, groupKey, colorKey, colors, mapKeys } = useChartGenerator() as {
    mapKeys?: Record<string, string>;
    format?: Record<string, (x?: Record<string, unknown>) => ReactNode>;
    Components?: Record<string, (x?: Record<string, unknown>) => JSX.Element>;
    colors?: Record<string, string>;
    [key: string]: unknown;
  };

  const formatter = format?.tooltips;
  const TooltipComponent = Components?.Tooltips;

  const groupKeyToUse = (tooltipData?.groupKey || groupKey || 'group') as string;
  const colorKeyToUse = (tooltipData?.colorKey || colorKey || 'color') as string;
  const colorIdToUse = tooltipData?.[colorKeyToUse] as string;

  const colorToUse = ((tooltipData?.color || colors?.[colorIdToUse]) as string) || undefined;

  let groupIdToUse = tooltipData?.[groupKeyToUse];
  groupIdToUse = mapKeys?.[groupKeyToUse] || groupIdToUse;
  groupIdToUse = t((groupIdToUse as string) || '').toLowerCase();

  let { count, total, average } = (pieChartTotals?.[groupIdToUse as string] || {}) as Record<
    string,
    unknown
  >;

  count = formatter?.({ count, value: count }) || count;
  count = (
    <>
      <span className="tooltip-label">{t('count')}:</span> {count}
    </>
  );

  total = formatter?.({ total, value: total }) || total;
  total = (
    <>
      <span className="tooltip-label">{t('total')}:</span> {total}
    </>
  );

  average = formatter?.({ average, value: average }) || average;
  average = (
    <>
      <span className="tooltip-label">{t('average')}:</span> {average}
    </>
  );

  title = title || groupIdToUse;
  title = t((title as string) || '');
  title = formatter?.({ title }) || title;

  return (
    <GeneratorTooltip>
      {TooltipComponent ? (
        <TooltipComponent {...{ ...rest, ...tooltipData }} />
      ) : (
        <PieChartTooltipContainer className="pie-chart-tooltip-container">
          {title && (
            <ColoredLabelContainer
              color={colorToUse}
              className="colored-label-container tooltip__title"
            >
              {title}
            </ColoredLabelContainer>
          )}
          <div className="totals-container">
            {count && <div className="tooltip__count totals-container-row">{count}</div>}
            {total && <div className="tooltip__total totals-container-row">{total}</div>}
            {average && <div className="tooltip__average totals-container-row">{average}</div>}
          </div>
        </PieChartTooltipContainer>
      )}
    </GeneratorTooltip>
  );
};
