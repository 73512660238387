import React from 'react';
import { BarBlockProps } from './Chart.types';
import { styledTheme } from 'common/theme';
import { BarBlockContainer, DD, DL } from './Bars.elements';
import { DowntimeItem } from 'common/pages/fleetV2/machine/proseal/api/types';

const BarBlock = ({
  icon,
  counts,
  duration,
  color,
  status,
  percentage_time,
  percentage_count
}: BarBlockProps): JSX.Element => {
  return (
    <BarBlockContainer className="bar-indicator-container">
      <div className="bar-indicator--row">
        <div className="bar-indicator--icon">{icon}</div>
        {status != undefined && <p className="bar-indicator--name">{status}</p>}
      </div>
      <div className="bar-indicator--bars">
        <DL color={color || styledTheme.colors.gray800}>
          <div className="bar">
            <DD className={`percentage percentage-${percentage_count}`}></DD>
            {counts != undefined && <p className="text"> {counts} </p>}
          </div>

          <div className="bar">
            <DD className={`percentage percentage-${percentage_time}`}></DD>
            {duration != undefined && <p className="text">{duration} min</p>}
          </div>
        </DL>
      </div>
    </BarBlockContainer>
  );
};

interface BarListProps {
  data?: DowntimeItem[];
}

export const BarsList = ({ data }: BarListProps): JSX.Element => {
  const bars = data?.map((el) => {
    const { status, total_time_seconds, counts, color, label } = el;
    const key = label + '_' + status;

    const duration = total_time_seconds ? Number(total_time_seconds / 60).toFixed(0) : '--';
    const colorString = color ? color : styledTheme.colors.gray700;
    const countNumber = counts ? counts : undefined;

    const element = {
      ...el,
      color: colorString,
      counts,
      countNumber,
      duration,
      status
    };

    return <BarBlock key={key} {...element} />;
  });

  return <div className="bars">{bars}</div>;
};
