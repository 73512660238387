import React from 'react';
import { ChartTooltipWrapper } from 'common/ui';

export const Tooltip = (props: Record<string, unknown>): JSX.Element => {
  const { x, y, ...rest } = props as { x: number; y: number; [key: string]: unknown };
  return (
    <ChartTooltipWrapper {...{ x, y }}>
      <div className="tooltip__title">{rest?.label}</div>
    </ChartTooltipWrapper>
  );
};
