import React from 'react';
import { ActualDurationComponent } from '../ActualDurationWidget/ActualDurationComponent';
import { useTranslation } from 'react-i18next';
import { DurationLabelsExtended, WrapperData } from './types';
import { ICONMAP } from '../ActualDurationWidget/utils';
import { COLORMAP } from './utils';

interface Props {
  data?: WrapperData;
  filteredData?: WrapperData;
  className?: string;
  chartDaterange?: Date[];
  zoomDaterange: Date[];
  handelZoomInDaterangeUpdate?: (range: Date[]) => void;
}

export const ActualDurationWrapper = ({
  data,
  filteredData,
  className,
  zoomDaterange
}: Props): JSX.Element => {
  if (!zoomDaterange || !zoomDaterange[0] || !zoomDaterange[1]) return <></>;

  const { t } = useTranslation(['mh']);

  const totalDurationDaterange = zoomDaterange?.[1].getTime() - zoomDaterange?.[0].getTime(); // duration is in MILLISECONDS

  const dataTotalsActualValues = filteredData?.actual_details?.reduce((acc, currentElement) => {
    const mode = currentElement.mode_descr;
    if (acc[mode]) {
      acc[mode].duration_total += currentElement.duration;
    } else {
      acc[mode] = {
        duration_total: currentElement.duration, // duration is in MILLISECONDS
        barTitle: t(mode),
        icon: ICONMAP[currentElement.mode_descr],
        color: COLORMAP[currentElement.mode_descr]
      };
    }

    return acc;
  }, {});

  dataTotalsActualValues &&
    Object.entries(dataTotalsActualValues as unknown as DurationLabelsExtended).forEach(
      ([key, value]) => {
        const act = data?.actualDataSummary?.[key];
        const targ = data?.targetDataSummary?.[key];

        value.extraData = act && targ ? (act.duration - targ.duration) / 1000 : null;
        value.percentage = Math.round((value.duration_total * 100) / totalDurationDaterange);
        value.duration = value.duration_total / 1000;
      }
    );

  const settings = {
    className,
    title: t('Actual Duration'),
    data: Object.values(dataTotalsActualValues) as unknown as Record<string, unknown>
  };

  return <ActualDurationComponent {...settings} />;
};
