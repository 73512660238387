import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { useMachineInfo } from 'common/pages/fleetV2/providers';
import { utcToZonedTime } from 'date-fns-tz';
import { useDateRangeV2 } from 'components/StyledUi/DateRange/hooks/useDateRangeV2';
import { LiveDataRecord } from '../types';
import {
  API_INTERVAL,
  calcModulo,
  generateChartData,
  GeneratedChartDataProps,
  isDateNow
} from '../utils';
import { addSeconds, subMinutes } from 'date-fns';
import { useLiveChartAPIProvider } from './useLiveDataProviderV2';

const DataManagementContext = createContext({
  handleResetButton: () => console.log('handleResetButton not set'),
  handelZoomInDaterangeUpdate: (daterange: Date[]) =>
    console.log('handelZoomInDaterangeUpdate not set', daterange),
  resetTrack: 0
});

interface DataManagementProps {
  data?: GeneratedChartDataProps;
  chartDateRange?: Date[];
  zoomDaterange?: Date[];
  isZoomDaterangeNow?: boolean;
  handleResetButton: () => void;
  handelZoomInDaterangeUpdate: (daterange: Date[]) => void;
  zoomLineData?: LiveDataRecord[];
  resetTrack: number;
  end_time?: Date;
  timezone?: string;
}

export const useDataManagementProvider = (): DataManagementProps =>
  useContext(DataManagementContext);

interface Props {
  children?: ReactNode | ReactNode[];
}

//const ZOOMED_RANGE = 4; //in hours  168 - 7 days
export const ZOOMED_RANGE_MINNUTES = 10;
export const ZOOMED_RANGE_MILLISECONDS = ZOOMED_RANGE_MINNUTES * 60 * 1000;

export const DataManagement = ({ children }: Props): JSX.Element => {
  const { data, isLoading, end_time } = useLiveChartAPIProvider(); //data is in asending order
  const { setDateRange } = useDateRangeV2();

  const { timeZone } = useMachineInfo();
  const timezone = timeZone || 'UTC';

  const [zoomDaterange, setZoomDaterange] = useState<Date[] | undefined>(undefined);
  const [chartDateRange, setChartDateRange] = useState<Date[] | undefined>(undefined);
  const [chartData, setChartData] = useState<GeneratedChartDataProps | undefined>(undefined);
  const [resetTrack, setResetTrack] = useState<number>(0);
  const [zoomLineData, setZoomLineData] = useState<Record<string, unknown>[][] | undefined>(
    undefined
  ); //this is the soft data line that shows up in zoom area

  const isZoomRangeCurrent = useRef<boolean>(true);

  const { startMoment: startTime, endMoment: endTime } = useDateRangeV2().machineDateRange;

  useEffect(() => {
    if (!end_time) return;

    //check if zoomed in area in the past
    if (isZoomRangeCurrent.current) {
      //if current - update blue zoom area
      const newRange = [
        subMinutes(utcToZonedTime(end_time, timezone), ZOOMED_RANGE_MINNUTES),
        utcToZonedTime(end_time, timezone)
      ];
      setZoomDaterange(newRange);
    }

    setChartDateRange([
      addSeconds(utcToZonedTime(startTime, timezone), API_INTERVAL / 1000),
      utcToZonedTime(end_time, timezone)
    ]);
  }, [end_time]);

  useEffect(() => {
    //When we select a new date range, we need to reset the cache
    setChartData(undefined);
  }, [setDateRange]);

  useEffect(() => {
    // on date range change - reset blue zoom area
    const endDate = utcToZonedTime(endTime, timezone);
    const zoomStart = subMinutes(utcToZonedTime(endTime, timezone), ZOOMED_RANGE_MINNUTES);
    let start = zoomStart;
    const originalStartDate = utcToZonedTime(startTime, timezone);
    if (originalStartDate.getTime() > zoomStart.getTime()) {
      start = originalStartDate;
    }

    setZoomDaterange([start, endDate]);
    setChartDateRange([utcToZonedTime(startTime, timezone), utcToZonedTime(endTime, timezone)]);
    //set original data
  }, [startTime, endTime]);

  // we need to filter data to zoomed in range
  useEffect(() => {
    if (!data) return;

    const dataT = data; //in asending order
    const tenMinutes = 10 * 60 * 1000; // 10 minutes in milliseconds
    const resultData = [] as Record<string, unknown>[][];

    let lastSliceIndex = 0;

    for (let i = 0; i < dataT?.length; i++) {
      if (i != dataT?.length - 1) {
        const diff =
          (dataT[i + 1].timestamp as Date).getTime() - (dataT[i].timestamp as Date).getTime();
        if (diff > tenMinutes) {
          const slice = dataT.slice(lastSliceIndex, i + 1);
          const mod = calcModulo(slice as unknown as Record<string, unknown>);
          const filteredSlice =
            slice.length > 2 ? slice.filter((_, index) => index % mod === 0) : slice;
          if (filteredSlice.length === 1) continue; //skip if only one item
          filteredSlice.sort(
            (a, b) => Number(new Date(a.timestamp)) - Number(new Date(b.timestamp))
          );
          resultData.push(filteredSlice as unknown as Record<string, unknown>[]);
          lastSliceIndex = i + 1;
        }
      } else if (i === dataT?.length - 1) {
        const slice = dataT.slice(lastSliceIndex);
        const mod = calcModulo(slice as unknown as Record<string, unknown>);
        const filteredSlice =
          slice.length > 2 ? slice.filter((_, index) => index % mod === 0) : slice;

        filteredSlice.push(dataT[dataT.length - 1]); //add last item too
        filteredSlice.sort(
          (a, b) =>
            (new Date(a.timestamp).getTime() as number) -
            (new Date(b.timestamp).getTime() as number)
        );
        resultData.push(filteredSlice as unknown as Record<string, unknown>[]);
      }
    }

    const sortedArray = data;
    const transformedData = generateChartData(sortedArray as unknown as Record<string, unknown>[]);
    transformedData && setChartData(transformedData);
    setZoomLineData(resultData);
  }, [data]);

  const handleResetButton = () => {
    if (!end_time) return;
    const endDate = utcToZonedTime(new Date(end_time), timezone);
    const zoomStart = utcToZonedTime(
      new Date(new Date(end_time).getTime() - ZOOMED_RANGE_MILLISECONDS),
      timezone
    );
    let start = zoomStart;
    const originalStartDate = utcToZonedTime(new Date(startTime), timezone);
    if (originalStartDate.getTime() > zoomStart.getTime()) {
      start = originalStartDate;
    }
    setZoomDaterange([start, endDate]);
    setResetTrack((c) => c + 1);
  };

  const handelZoomInDaterangeUpdate = useCallback((daterange: Date[]): void => {
    const start = daterange[0];
    const end = daterange[1];

    const isCurrent = isDateNow(end, timezone); //is end date now
    isZoomRangeCurrent.current = isCurrent;

    setZoomDaterange([start, end]);
  }, []);

  const hasData = !data || data?.length === 0 ? false : true;

  if (isLoading) return <></>;

  const values = {
    data: chartData,
    hasData,
    chartDateRange,
    zoomDaterange,
    isZoomDaterangeNow: isZoomRangeCurrent.current,
    zoomLineData,
    handleResetButton,
    handelZoomInDaterangeUpdate,
    resetTrack,
    end_time,
    timezone
  };

  return <DataManagementContext.Provider value={values}>{children}</DataManagementContext.Provider>;
};
