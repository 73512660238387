import styled from 'styled-components';
import { StyledUiContainerProps } from 'components';

/* add grid styles after widdget place holders have been added

grid-gap: 1em;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  grid-auto-flow: row;

  @media (max-width: 1300px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
*/

export const ViewContainer = styled.div<StyledUiContainerProps>`
  grid-gap: 1em;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: auto;
  grid-auto-flow: row;

  .production-output-widget {
    grid-column-start: span 4;
    min-height: 300px;
  }
`;
