/* eslint-disable react/prop-types */
import React, { useState, useEffect, ReactNode } from 'react';

export const TSErrorWrapper = ({ children }: { children?: ReactNode }): JSX.Element => {
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    const handleError = (error?: { name?: string; message?: string }) => {
      setError(`${error?.name}: ${error?.message}`);
    };

    // Add global error listener
    window.addEventListener('error', handleError);

    return () => {
      // Clean up the error listener
      window.removeEventListener('error', handleError);
    };
  }, []);

  if (error) {
    return <div>somethin goin on</div>;
  }

  return <>{children}</>;
};

export default TSErrorWrapper;
