import { useChangeoverSessionDetailsAPI } from 'common/pages/fleetV2/machine/aseptic/providers/useChangeoverSessionDetailsAPIPovider';
import { useState } from 'react';
import { sortAlarms } from '../helpers/sortAlarms';

export const useSessionAlarmsFlyout = (): Record<string, unknown> => {
  const { alarms, ...rest } = useChangeoverSessionDetailsAPI();
  const [order, setOrder] = useState<string>('date by descending');

  const sortedData = alarms && sortAlarms(alarms as Record<string, unknown>[], order as string);

  return {
    order,
    alarms: sortedData,
    setOrder,
    ...rest
  };
};
