import React, { useEffect, useMemo, useRef, useState } from 'react';
import { MachineStateContainer } from '../elements';
//import { StatesD3 } from '../../../../../../../../../ui/StatesHorizontalBarChart/StatesD3';
import { StatesD3V2 } from '../../../../../../../../../ui/StatesHorizontalBarChart/StatesD3-V2';
import { COLORMAP, displayTooltip } from '../utils';
import { createBars } from 'common/ui/StatesHorizontalBarChart/utils';

interface Props {
  width: number;
  height: number;
  zoomedRangeX?: Date[];
  chartData?: Record<string, unknown>[];
  linesData?: Record<string, unknown>[];
}

export const StatesComponent = ({
  width,
  height,
  zoomedRangeX,
  chartData,
  linesData
}: Props): JSX.Element => {
  const chartArea = useRef<HTMLDivElement | null>(null);

  const [chart, setChart] = useState();
  const [chartWidth, setChartWidth] = useState(width);

  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({
    actual_details: true
  });

  const row = useMemo(() => {
    const rows: Record<string, unknown>[] = [];

    // Add the top level button rows
    chartData?.forEach((parentRow) => {
      const topLevelRow = {
        parentRowProperty: parentRow.parentProperty,
        label: parentRow.label,
        isLabel: parentRow.isLabel,
        isExpanded: expandedRows[parentRow.parentProperty],
        state: parentRow.parentProperty,
        bars: createBars(parentRow.data, false),
        isTopLevel: true
      };

      if (Object.prototype.hasOwnProperty.call(expandedRows, parentRow.parentProperty))
        topLevelRow['isExpandable'] = true;

      rows.push(topLevelRow);

      // Add the child rows, if this row is expanded
      if (expandedRows[parentRow.parentProperty]) {
        const nameMap = {};

        // Add mid level rows as non-expandable rows, similar to top level rows
        parentRow.data.forEach(({ mode_number, mode_descr }) => {
          // Combine the parent property with the child state code to make it a unique state
          const uniqueStateCode = `${parentRow.parentProperty}-${mode_descr}`;

          nameMap[uniqueStateCode] = {
            stateName: mode_descr,
            stateCode: mode_number,
            uniqueStateCode
          };
        });

        Object.values(nameMap).forEach((val) => {
          rows.push({
            bars: createBars(
              parentRow.data.filter((bar) => bar.mode_number === val.stateCode),
              true
            ),
            label: nameMap[val.uniqueStateCode].stateName,
            state: val.uniqueStateCode,
            stateNameLabel: val.stateName
          });
        });
      }
    });

    return rows;
  }, [chartData, expandedRows]);

  const handleExpandedRowUpdate = (property: string): void => {
    Object.prototype.hasOwnProperty.call(expandedRows, property) &&
      setExpandedRows({ [property]: !expandedRows[property as string] });
  };

  const settings = {
    margins: {
      top: 3,
      right: 20,
      bottom: 20,
      left: 140
    },
    colorMap: COLORMAP,
    connectedLinesData: linesData,
    rangeX: zoomedRangeX,
    width: chartWidth,
    height,
    className: 'lane-states-view',
    stackKey: 'duration',
    onTickClick: handleExpandedRowUpdate
  };

  useEffect(() => {
    if (!chart) {
      setChart(new StatesD3V2(chartArea.current, (chartData = row), { ...settings }));
    } else {
      chart.draw(displayTooltip);
    }
  }, [chart]);

  useEffect(() => {
    setChartWidth(width);
  }, [width]);

  useEffect(() => {
    chartArea.current &&
      setChart(new StatesD3V2(chartArea.current, (chartData = row), { ...settings }));
  }, [chartWidth, row]);

  return (
    <MachineStateContainer
      className={`chart-area machine-state`}
      ref={chartArea}
    ></MachineStateContainer>
  );
};
