import styled from 'styled-components';
export const ColoredGroupLabel = styled.div<{ clr?: string }>`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-gap: 0.3em;
  text-transform: capitalize;
  align-items: center;

  ${({ clr }) =>
    clr &&
    `
    &:before {
      display: block;
      content: '';
      width: .7em;
      height: .7em;
      border-radius: 50%;
      background-color: ${clr};
      transform: translateY(-.08em);
    }

  `}
`;
