// 3rd party
import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

// Components
import { Loader, NewBaseTable } from 'components';

// Types
import { TableColumnConfigs } from 'types';
import { CleaningStepWithKPI, CleaningStepStatus } from 'types/protein';
import { Alarm } from 'types/machine-health/alarms';
import { Alert } from 'types/machine-health/alerts';

// Helpers
import { ConvertHHMMSStoMinutes, formatDate, formatDuration } from 'helpers';
import { useTimeZone } from 'providers';
import { MachineType } from 'types/machine-health';
import { CellContext } from '@tanstack/react-table';
import { CleaningStepsContainer, Root } from './index.elements';
import { generateIcon } from 'common/pages/fleetV2/machine/aseptic/views/MachineHealth/components/SingleSessionSteps/utils';
import { reshapeCleaningStepData } from './utils';

export interface CleaningStepRow extends CleaningStepWithKPI {
  key: string;
  rowId: number;
  parentRowId?: number;
  duration: string;
  avgOverTimeStr: string;
  targetDuration?: string;
  targetDiffInMinutes?: number;
}

interface CleaningStepsTableProps {
  data: CleaningStepWithKPI[];
  isDataLoading?: boolean;
  setSelectedAlarms: (alarms: Alarm[]) => void;
  setSelectedAlerts: (alerts: Alert[]) => void;
  machineType?: MachineType | undefined;
  className?: string;
  handleAlertClick?: (id?: number | string) => void;
}

// Return formatted duration string from a step
const getFormattedDuration = (step: CleaningStepWithKPI) => {
  if (!step.startTime || !step.endTime) {
    return '—';
  }
  const durationAsMillis = new Date(step.endTime).getTime() - new Date(step.startTime).getTime();
  console.log({ durationAsMillis });
  return formatDuration(durationAsMillis, 'hours:mins:secs');
};

// Generate the configurations for each column of this table
const generateColumnConfigs = (
  numKPIColumns: number,
  setSelectedAlarms: (alarms: Alarm[]) => void,
  setSelectedAlerts: (alerts: Alert[]) => void,
  t: TFunction<'fpns'[], undefined>,
  options?: {
    machineType?: MachineType;
    handleAlertClick?: (id?: string | number) => void;
  }
) => {
  let columns;
  if (options?.machineType === MachineType.Aseptic) {
    columns = [
      {
        header: t('program_steps_num_substeps') as string,
        id: 'name',
        isEnableSorting: true,
        isSelected: true,
        renderer: ({ row, getValue }) => {
          return (
            <div className={row.getCanExpand() ? 'parent-cell' : 'child-cell'}>
              {row.getCanExpand() ? (
                <button
                  className="button-expand"
                  {...{
                    onClick: row.getToggleExpandedHandler(),
                    style: { cursor: 'pointer' }
                  }}
                >
                  {row.getIsExpanded()
                    ? generateIcon('chevron down')
                    : generateIcon('chevron right')}
                </button>
              ) : (
                ''
              )}{' '}
              {getValue()}
            </div>
          );
        }
      },
      {
        header: t('status') as string,
        id: 'status',
        isEnableSorting: true,
        isSelected: true,
        renderer: ({ row }) => {
          const { status, alarms, alerts } = row.original;
          if (status === CleaningStepStatus.Completed) {
            return (
              <div className="cell-status">
                {alarms?.length > 0 ? (
                  <>
                    {generateIcon('bug')}&nbsp;
                    <span style={{ color: '#0076CC' }}>
                      <b>({row.original.alarms.length})</b>&nbsp;Issues{' '}
                    </span>
                    <button
                      className="button-issues"
                      onClick={() => {
                        const tsData = row.original as Record<string, unknown>;
                        const group = tsData?.group as string;
                        if (options?.handleAlertClick) {
                          return options.handleAlertClick(group);
                        } else {
                          setSelectedAlarms(alarms);
                          setSelectedAlerts(alerts);
                        }
                      }}
                      style={{ marginTop: '5px' }}
                    >
                      {generateIcon('blue arrow')}
                    </button>
                  </>
                ) : (
                  <>
                    {generateIcon('green checkmark')}&nbsp;{t('completed')}
                  </>
                )}
              </div>
            );
          } else if (alarms?.length && alerts?.length) {
            return (
              <>
                {generateIcon('bug')}&nbsp;
                <span style={{ color: '#0076CC' }}>
                  <b>({row.original.alarms.length})</b>&nbsp;Issues{' '}
                </span>
                <button
                  className="button-issues"
                  onClick={() => {
                    const tsData = row.original as Record<string, unknown>;
                    const group = tsData?.group as string;
                    if (options?.handleAlertClick) {
                      return options.handleAlertClick(group);
                    } else {
                      setSelectedAlarms(alarms);
                      setSelectedAlerts(alerts);
                    }
                  }}
                  style={{ marginTop: '5px' }}
                >
                  {generateIcon('blue arrow')}
                </button>
              </>
            );
          } else if (alarms?.length) {
            return (
              <>
                {generateIcon('bug')}&nbsp;
                <span style={{ color: '#0076CC' }}>
                  <b>({row.original.alarms.length})</b>&nbsp;Issues{' '}
                </span>
                <button
                  className="button-issues"
                  onClick={() => {
                    const tsData = row.original as Record<string, unknown>;
                    const group = tsData?.group as string;
                    if (options?.handleAlertClick) {
                      return options.handleAlertClick(group);
                    } else {
                      setSelectedAlarms(alarms);
                    }
                  }}
                  style={{ marginTop: '5px' }}
                >
                  {generateIcon('blue arrow')}
                </button>
              </>
            );
          } else if (alerts?.length) {
            return (
              <>
                {generateIcon('bug')}&nbsp;
                <span style={{ color: '#0076CC' }}>
                  <b>({row.original.alarms.length})</b>&nbsp;Issues{' '}
                </span>
                <button
                  className="button-issues"
                  onClick={() => {
                    const tsData = row.original as Record<string, unknown>;
                    const group = tsData?.group as string;
                    if (options?.handleAlertClick) {
                      return options.handleAlertClick(group);
                    } else {
                      setSelectedAlerts(alerts);
                    }
                  }}
                  style={{ marginTop: '5px' }}
                >
                  {generateIcon('blue arrow')}
                </button>
              </>
            );
          } else {
            return <></>;
          }
        }
      },
      {
        header: t('duration') as string,
        id: 'duration',
        isEnableSorting: true,
        isSelected: true,
        renderer: (cellValue) => {
          const { targetDuration, duration } = cellValue.row.original;
          const difference =
            targetDuration && duration ? ConvertHHMMSStoMinutes(targetDuration, duration) : `(0)`;
          return (
            <>
              {cellValue.getValue()} <b className="target-diff">{difference}</b>
            </>
          );
        }
      },
      {
        header: t('target_duration') as string,
        id: 'targetDuration',
        isEnableSorting: true,
        isSelected: true,
        renderer: (cellValue: CellContext<TableColumnConfigs, string>) => cellValue.getValue()
      },
      {
        header: t('avg_duration') as string,
        id: 'avgOverTimeStr',
        isEnableSorting: true,
        isSelected: true,
        renderer: (cellValue: CellContext<TableColumnConfigs, string>) => cellValue.getValue()
      }
    ];
  } else {
    columns = [
      {
        header: t('program_steps_num_substeps') as string,
        id: 'name',
        isEnableSorting: true,
        isSelected: true,
        renderer: ({ row, getValue }) => {
          return (
            <div className={row.getCanExpand() ? 'parent-cell' : 'child-cell'}>
              {row.getCanExpand() ? (
                <button
                  className="button-expand"
                  {...{
                    onClick: row.getToggleExpandedHandler(),
                    style: { cursor: 'pointer' }
                  }}
                >
                  {row.getIsExpanded()
                    ? generateIcon('chevron down')
                    : generateIcon('chevron right')}
                </button>
              ) : (
                ''
              )}{' '}
              {getValue() + ` (${row.original.id})`}
            </div>
          );
        }
      },
      {
        header: t('status') as string,
        id: 'status',
        isEnableSorting: true,
        isSelected: true,
        renderer: ({ row }) => {
          const { status, alarms, alerts } = row.original;
          if (status === CleaningStepStatus.Completed) {
            return (
              <div className="cell-status">
                {alarms?.length > 0 ? (
                  <>
                    {generateIcon('bug')}&nbsp;
                    <span style={{ color: '#0076CC' }}>
                      <b>({row.original.alarms.length})</b>&nbsp;Issues{' '}
                    </span>
                    <button
                      className="button-issues"
                      onClick={() => {
                        setSelectedAlarms(alarms);
                        setSelectedAlerts(alerts);
                      }}
                      style={{ marginTop: '5px' }}
                    >
                      {generateIcon('blue arrow')}
                    </button>
                  </>
                ) : (
                  <>
                    {' '}
                    {generateIcon('green checkmark')}&nbsp;{t('completed')}
                  </>
                )}
              </div>
            );
          } else if (alarms?.length && alerts?.length) {
            return (
              <>
                {generateIcon('bug')}&nbsp;
                <span style={{ color: '#0076CC' }}>
                  <b>({row.original.alarms.length})</b>&nbsp;Issues{' '}
                </span>
                <button
                  className="button-issues"
                  onClick={() => {
                    setSelectedAlarms(alarms);
                    setSelectedAlerts(alerts);
                  }}
                  style={{ marginTop: '5px' }}
                >
                  {generateIcon('blue arrow')}
                </button>
              </>
            );
          } else if (alarms?.length) {
            return (
              <>
                {generateIcon('bug')}&nbsp;
                <span style={{ color: '#0076CC' }}>
                  <b>({row.original.alarms.length})</b>&nbsp;Issues{' '}
                </span>
                <button
                  className="button-issues"
                  onClick={() => {
                    setSelectedAlarms(alarms);
                  }}
                  style={{ marginTop: '5px' }}
                >
                  {generateIcon('blue arrow')}
                </button>
              </>
            );
          } else if (alerts?.length) {
            return (
              <>
                {generateIcon('bug')}&nbsp;
                <span style={{ color: '#0076CC' }}>
                  <b>({row.original.alarms.length})</b>&nbsp;Issues{' '}
                </span>
                <button
                  className="button-issues"
                  onClick={() => {
                    setSelectedAlerts(alerts);
                  }}
                  style={{ marginTop: '5px' }}
                >
                  {generateIcon('blue arrow')}
                </button>
              </>
            );
          } else {
            return <></>;
          }
        }
      },
      {
        header: t('start_time') as string,
        id: 'startTime',
        isEnableSorting: true,
        isSelected: true,
        renderer: (cellValue: CellContext<TableColumnConfigs, string>) => cellValue.getValue()
      },
      {
        header: t('end_time') as string,
        id: 'endTime',
        isEnableSorting: true,
        isSelected: true,
        renderer: (cellValue: CellContext<TableColumnConfigs, string>) => cellValue.getValue()
      },
      {
        header: t('duration') as string,
        id: 'duration',
        isEnableSorting: true,
        isSelected: true,
        renderer: (cellValue: CellContext<TableColumnConfigs, string>) => cellValue.getValue()
      },
      {
        header: t('average_over_time') as string,
        id: 'avgOverTimeStr',
        isEnableSorting: true,
        isSelected: true,
        renderer: ({ row, getValue }) => {
          return <>{row.getCanExpand() ? '—' : getValue()}</>;
        }
      }
    ];
  }

  // Dynamically add the KPI columns, as we do not know how many there will be
  for (let i = 0; i < numKPIColumns; i++) {
    columns.push({
      header: i === 0 ? (t('kpis') as string) : '',
      id: `kpi${i}`,
      isEnableSorting: true,
      isSelected: true,
      renderer: (row) => {
        const { kpis } = row.original as CleaningStepRow;
        if (kpis[i]) {
          const { label, unit, value } = kpis[i];
          return <>{`${value} ${unit} ${label}`}</>;
        } else {
          return <></>;
        }
      }
    });
  }
  return columns;
};

/**
 * Flatten the list of steps into a single level.
 * Nested child elements are given a parentRowId, so we can keep track of which row belongs
 * to which parent.
 */
const formatData = (data: CleaningStepWithKPI[], timeZone?: string): CleaningStepRow[] => {
  const output: CleaningStepRow[] = [];
  let uniqueRowId = 0;

  data.forEach((step) => {
    uniqueRowId++;
    // Add a top level row
    const row: CleaningStepRow = {
      ...step,
      key: `row-${uniqueRowId}`,
      rowId: uniqueRowId,
      startTime: formatDate(step.startTime, 'numeric-date-time', timeZone),
      endTime: formatDate(step.endTime, 'numeric-date-time', timeZone),
      duration: step.duration ?? getFormattedDuration(step),
      avgOverTimeStr: formatDuration(step.avgOverTime ?? 0, 'hours:mins:secs'),
      targetDuration: step.targetDuration,
      targetDiffInMinutes: step.targetDiffInMinutes ?? undefined
    };

    output.push(row);
    const parentRowId = uniqueRowId;

    // If row has substeps, push them onto the row array
    step.subSteps?.forEach((subStep) => {
      uniqueRowId++;
      const row: CleaningStepRow = {
        ...subStep,
        key: `row-${uniqueRowId}`,
        parentRowId,
        rowId: uniqueRowId,
        startTime: formatDate(subStep.startTime, 'numeric-date-time', timeZone),
        endTime: formatDate(subStep.endTime, 'numeric-date-time', timeZone),
        duration: subStep.duration ?? getFormattedDuration(subStep),
        avgOverTimeStr: formatDuration(subStep.avgOverTime ?? 0, 'hours:mins:secs'),
        targetDuration: subStep.targetDuration ?? '0',
        targetDiffInMinutes: subStep.targetDiffInMinutes ?? undefined
      };

      output.push(row);
    });
  });
  return output;
};

const CleaningStepsTable = ({
  data,
  isDataLoading,
  setSelectedAlarms,
  setSelectedAlerts,
  machineType,
  className,
  handleAlertClick
}: CleaningStepsTableProps): ReactElement => {
  const { timeZone } = useTimeZone();
  const { t } = useTranslation(['mh']);

  const formattedData: CleaningStepRow[] = useMemo(
    () => formatData(data, timeZone),
    [data, timeZone]
  );

  // Find out how many KPI columns we need, by finding the step with the max number of KPIs
  const KPICounts = formattedData.map((row) => row.kpis.length);
  const numKPIColumns = Math.max(...KPICounts);

  return (
    <Root className={className}>
      <CleaningStepsContainer className="cleaning-steps-table-container">
        {isDataLoading ? (
          <Loader size={50} />
        ) : (
          <NewBaseTable
            columnConfigs={generateColumnConfigs(
              numKPIColumns,
              setSelectedAlarms,
              setSelectedAlerts,
              t,
              {
                machineType,
                handleAlertClick
              }
            )}
            sortState={{ id: 'startTime', desc: false }}
            newTableData={reshapeCleaningStepData(formattedData)}
            tdMarginLeft="0.625rem"
            textAlign="left"
            padding="0 0 0 0"
            isShowGlobalSearch={false}
            customClassName="cleaning-steps-table"
            maxTableHeight={37.8}
            hideTotalFetchCount={true}
          />
        )}
      </CleaningStepsContainer>
    </Root>
  );
};

export default CleaningStepsTable;
