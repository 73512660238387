import React, { useEffect, useRef, useState } from 'react';
import { ZoomContainer } from './elements';
import { ZoomedBrush } from 'common/ui/ZoomBrushD3/ZoomBrushD3';

interface Props {
  width: number;
  height: number;
  dataSettings: {
    data: Record<string, unknown>[]; //data itself,
    xAxisKey: string;
    colorMapKey: string;
    colorMap: Record<string, unknown>;
  };
  rangeX: Date[] | undefined;
  zoomedRangeX: Date[] | undefined;
  updateZoomedRangeX?: (range: Date[]) => void;
  defaultBackground?: boolean;
  margins: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
  triggerResize?: () => void;
}

export const ZoomBrushComponenet = ({
  width,
  height,
  margins,
  dataSettings,
  rangeX,
  zoomedRangeX,
  updateZoomedRangeX,
  triggerResize,
  defaultBackground
}: Props): JSX.Element => {
  const chartArea = useRef<HTMLDivElement | null>(null);

  const [chart, setChart] = useState();
  const [chartWidth, setChartWidth] = useState(width);

  const settings = {
    margins,
    rangeX,
    domainY: [0, 120],
    width,
    height,
    zoomedRangeX,
    updateZoomedRangeX,
    defaultBackground: defaultBackground == 'undefined' ? true : defaultBackground
  };

  useEffect(() => {
    if (!chart) {
      setChart(new ZoomedBrush(chartArea.current, dataSettings, { ...settings }));
    } else {
      chart.updateChart();
    }
  }, [chart]);

  useEffect(() => {
    setChartWidth(width);
  }, [width, triggerResize]);

  useEffect(() => {
    chartArea.current &&
      setChart(new ZoomedBrush(chartArea.current, dataSettings, { ...settings }));
  }, [chartWidth]);

  return <ZoomContainer className={`chart-area zoom-brush`} ref={chartArea}></ZoomContainer>;
};
