import React from 'react';

interface CleaningGroupIconProps {
  color?: string;
  width?: number;
  height?: number;
}

export const IcoCleaningGroup = ({
  color = '#0076CC',
  width = 46,
  height = 45
}: CleaningGroupIconProps): JSX.Element => {
  return (
    <svg
      className="icon-cleaning-group icon"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.838379 22.5625C0.838379 25.3941 1.40477 28.198 2.5052 30.8141C3.60564 33.4302 5.21858 35.8072 7.25192 37.8095C9.28526 39.8118 11.6992 41.4 14.3559 42.4837C17.0126 43.5673 19.86 44.125 22.7356 44.125C25.6112 44.125 28.4586 43.5673 31.1153 42.4837C33.772 41.4 36.1859 39.8118 38.2192 37.8095C40.2526 35.8072 41.8655 33.4302 42.966 30.8141C44.0664 28.198 44.6328 25.3941 44.6328 22.5625C44.6328 19.7309 44.0664 16.927 42.966 14.3109C41.8655 11.6948 40.2526 9.31777 38.2192 7.31551C36.1859 5.31325 33.772 3.72496 31.1153 2.64135C28.4586 1.55773 25.6112 1 22.7356 1C19.86 1 17.0126 1.55773 14.3559 2.64135C11.6992 3.72496 9.28526 5.31325 7.25192 7.31551C5.21858 9.31777 3.60564 11.6948 2.5052 14.3109C1.40477 16.927 0.838379 19.7309 0.838379 22.5625Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4365 17.7705C15.4365 19.6768 16.2055 21.5049 17.5744 22.8528C18.9432 24.2008 20.7998 24.958 22.7356 24.958C24.6714 24.958 26.528 24.2008 27.8968 22.8528C29.2657 21.5049 30.0347 19.6768 30.0347 17.7705C30.0347 15.8643 29.2657 14.0361 27.8968 12.6882C26.528 11.3403 24.6714 10.583 22.7356 10.583C20.7998 10.583 18.9432 11.3403 17.5744 12.6882C16.2055 14.0361 15.4365 15.8643 15.4365 17.7705Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5459 38.9722C9.14809 36.9986 10.3803 35.2687 12.0598 34.0391C13.7392 32.8096 15.7765 32.1459 17.8692 32.1465H27.6013C29.6968 32.1458 31.7365 32.8111 33.4172 34.0435C35.0979 35.2759 36.3297 37.0095 36.9295 38.9866"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
