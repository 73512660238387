import React from 'react';

export const IcoCleaningAvgSession = ({ color = '#0076CC' }: { color?: string }): JSX.Element => {
  return (
    <svg
      className="icon-cleaning-avg-session icon"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_308_30467)">
        <path
          d="M13.3334 7.33346C13.1704 6.16028 12.6261 5.07324 11.7845 4.23979C10.9429 3.40635 9.85059 2.87274 8.67586 2.72116C7.50114 2.56959 6.30916 2.80845 5.28355 3.40096C4.25794 3.99347 3.45558 4.90675 3.00008 6.00013M2.66675 3.33346V6.00013H5.33341"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.66675 8.6665C2.85787 10.0453 3.57445 11.297 4.66675 12.1598"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.33325 10H8.66659C8.8434 10 9.01297 10.0702 9.13799 10.1953C9.26301 10.3203 9.33325 10.4899 9.33325 10.6667V11.3333C9.33325 11.5101 9.26301 11.6797 9.13799 11.8047C9.01297 11.9298 8.8434 12 8.66659 12H7.99992C7.82311 12 7.65354 12.0702 7.52851 12.1953C7.40349 12.3203 7.33325 12.4899 7.33325 12.6667V13.3333C7.33325 13.5101 7.40349 13.6797 7.52851 13.8047C7.65354 13.9298 7.82311 14 7.99992 14H9.33325"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.3333 10V11.3333C11.3333 11.5101 11.4035 11.6797 11.5285 11.8047C11.6535 11.9298 11.8231 12 11.9999 12H12.6666"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M13.3333 10V14" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_308_30467">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
