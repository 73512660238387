import React from 'react';

import { StyledUiContainerProps, styledTheme } from 'components';
import {
  MachineOverviewWidgetProps,
  MachineOverviewWidget as OverviewWidget,
  WidgetDateRangeProvider
} from 'common/components/WidgetUi';
import { IcoMachineState } from 'icons/IcoMachineState';
import { IcoProductionMetrics } from 'icons/IcoProductionMetrics';
import { IcoRecipes } from 'icons/IcoRecipes';
import MachineImage from '../../../../../../settings/proseal/machine-image.jpg';
import { useMachineOverviewWidget } from './';

export const MachineOverviewWidget = (props?: StyledUiContainerProps): JSX.Element => (
  <WidgetDateRangeProvider subtractDays={7}>
    <Provided {...props} />
  </WidgetDateRangeProvider>
);

const Provided = ({ className }: StyledUiContainerProps): JSX.Element => {
  className = className ? `${className} machine-overview-widget` : `machine-overview-widget`;

  const {
    isLoading,
    hasMessage,
    hasError,
    //latest_job_number,
    latest_machine_status,
    //latest_machine_status_category,
    //latest_machine_status_category_color,
    latest_recipe,
    todays_pack_count,
    statusColor,
    StatusIcon
  } = useMachineOverviewWidget();

  const widgetSettings: MachineOverviewWidgetProps = {
    title: 'Machine Overview',
    image: MachineImage,
    className,
    hasMessage,
    hasError,
    isLoading
  };

  // the values of the machine state
  widgetSettings.tiles = [
    {
      id: 'machine-state',
      title: 'Machine State',
      value: latest_machine_status,
      //subValue: latest_job_number,
      color: statusColor as string,
      Icon: (StatusIcon as JSX.Element) || <IcoMachineState color="white" />
    },
    {
      id: 'product-recipe',
      title: 'Product Recipe',
      value: latest_recipe,
      color: styledTheme.color.secondary,
      Icon: <IcoRecipes color="white" />
    },
    {
      id: 'production-metrics',
      title: 'Production Metrics',
      label: `Today's pack count`,
      value: Number(todays_pack_count)?.toLocaleString(),
      //subValue: `${avg_bottles_per_hour} avg/${t('hour')}`,
      color: '#835DD0',
      Icon: <IcoProductionMetrics color="white" />
    }
  ];

  return <OverviewWidget {...widgetSettings} />;
};
