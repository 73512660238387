import { useGetRecipesQuery } from 'common/pages/fleetV2/machine/proseal/api';
import { ReactNode } from 'react';
import { RecipeType } from '../RecipesTable';

interface Props {
  machineId?: string;
}

interface RecipeData {
  isLoading?: boolean;
  hasError?: ReactNode;
  hasMessage?: ReactNode;
  data?: RecipeType[];
}

export const useRecipeTableData = ({ machineId }: Props): RecipeData => {
  const { data, isLoading, error } = useGetRecipesQuery({ machineId: machineId });
  const tableData = data as unknown as RecipeType[];

  const values = {
    data: tableData,
    isLoading,
    hasError: error
  };

  return values;
};
