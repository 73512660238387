import { useGetAsepticOEEWeeklyQuery } from 'api';
import { groupByKey } from 'common/helpers/dataGroupers';
import { useBuSettings, useMachineInfo } from 'common/pages/fleetV2/providers';

const GetData = (data?: Record<string, unknown>): Record<string, unknown>[] | undefined => {
  const { colors } = useBuSettings();
  if (!data) return undefined;

  const { oee_week_1, oee_week_2, oee_week_3 } = data as {
    oee_week_1: Record<string, unknown>;
    oee_week_2: Record<string, unknown>;
    oee_week_3: Record<string, unknown>;
  };

  const week1 = !oee_week_1
    ? []
    : [
        {
          group: 'oee',
          category: oee_week_1?.plant_week_start_datetime,
          color: colors?.oee,
          value: oee_week_1?.oee
        },
        {
          group: 'availability',
          category: oee_week_1?.plant_week_start_datetime,
          color: colors?.availability,
          value: oee_week_1?.Availability_Rate
        },
        {
          group: 'performance',
          category: oee_week_1?.plant_week_start_datetime,
          color: colors?.performance,
          value: oee_week_1?.Performance_Rate
        },
        {
          group: 'quality',
          category: oee_week_1?.plant_week_start_datetime,
          color: colors?.quality,
          value: oee_week_1?.Quality_Rate
        }
      ];

  const week2 = !oee_week_2
    ? []
    : [
        {
          group: 'oee',
          category: oee_week_2?.plant_week_start_datetime,
          color: colors?.oee,
          value: oee_week_2?.oee
        },
        {
          group: 'availability',
          category: oee_week_2?.plant_week_start_datetime,
          color: colors?.availability,
          value: oee_week_2?.Availability_Rate
        },
        {
          group: 'performance',
          category: oee_week_2?.plant_week_start_datetime,
          color: colors?.performance,
          value: oee_week_2?.Performance_Rate
        },
        {
          group: 'quality',
          category: oee_week_2?.plant_week_start_datetime,
          color: colors?.quality,
          value: oee_week_2?.Quality_Rate
        }
      ];

  const week3 = !oee_week_3
    ? []
    : [
        {
          group: 'oee',
          category: oee_week_3?.plant_week_start_datetime,
          color: colors?.oee,
          value: oee_week_3?.oee
        },
        {
          group: 'availability',
          category: oee_week_3?.plant_week_start_datetime,
          color: colors?.availability,
          value: oee_week_3?.Availability_Rate
        },
        {
          group: 'performance',
          category: oee_week_3?.plant_week_start_datetime,
          color: colors?.performance,
          value: oee_week_3?.Performance_Rate
        },
        {
          group: 'quality',
          category: oee_week_3?.plant_week_start_datetime,
          color: colors?.quality,
          value: oee_week_3?.Quality_Rate
        }
      ];

  return [...week1, ...week2, ...week3];
};

export const useOEEWidget = (): {
  isLoading?: boolean;
  hasError?: string;
  hasMessage?: string;
  chartData?: Record<string, unknown>[];
  noData?: boolean;
  [key: string]: unknown;
} => {
  const { machineId } = useMachineInfo();

  const { data, isLoading, error } = useGetAsepticOEEWeeklyQuery({ machineId }) || {};
  const { oee_week_0, ...weekly } = data || {};
  const chartData = GetData(weekly);

  const groupedDataForTooltips = groupByKey({ data: chartData, key: 'category' });

  return {
    chartData,
    isLoading,
    hasError: error && 'error',
    groupedDataForTooltips,
    last7Days: oee_week_0
  };
};
