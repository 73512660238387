import styled from 'styled-components';

export const MachineUtilizationTilesContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  gap: 1em;
  min-width: max-content;
  overflow: auto;
`;
