import React from 'react';

import { WidgetUi } from 'common/ui';
import { _WidgetTemplateContainer } from './OEEMetricsWidget.elements';
import { use_WidgetTemplate } from './hooks/use_WidgetTemplate';

/**
 * this is just for a custom widget template,
 * if you want a pie chart or bar chart one of the existing global charts, please choose one from common/components/WidgetUi
 */

export const OEEMetricsWidget = (): JSX.Element => {
  const { isLoading, hasError, data } = use_WidgetTemplate();

  const widgetSettings = {
    title: 'OEE Metrics',
    className: 'oee-metrics-widget',
    hasError,
    isLoading,
    hasMessage: true
  };

  return (
    <WidgetUi
      {...widgetSettings}
      Main={
        <_WidgetTemplateContainer className="widget-ui__main">
          {JSON.stringify(data)}
        </_WidgetTemplateContainer>
      }
    />
  );
};
