import React, { useMemo } from 'react';
import { MachineViewContainer } from './MachineView.elements';
import { AsepticView } from './aseptic/AsepticView';
import { useMachineInfo } from '../providers';
import { DSIView } from './dsi/DsiView';
import { ProsealView } from './proseal/ProsealView';
import { showCounters } from '../testing_settings';

let MachineView_counter = 0;
export const MachineView = (): JSX.Element => {
  ++MachineView_counter;

  if (showCounters) console.log({ MachineView_counter });

  return (
    <MachineViewContainer className="machine-view-container">
      <PageRoutes />
    </MachineViewContainer>
  );
};

let MachineView_PageRoutes_counter = 0;
const PageRoutes = () => {
  // get the business unit to build the redirect string based on routing, this can be wiped once all bus use the same routing
  const { businessUnit } = useMachineInfo() as { businessUnit: number | string };

  // cache the route
  const CachedRoute = useMemo(() => {
    ++MachineView_PageRoutes_counter;
    // here is where we start to detect the BUs and load them accordingly
    if (businessUnit === 'protein') return <>protein</>;
    if (businessUnit === 'dsi') return <DSIView />;
    if (businessUnit === 'aseptic') return <AsepticView />;
    if (businessUnit === 'proseal') return <ProsealView />;
    return <div>MachineView 404: invalid business unit in MachineView PageRoutes</div>;
  }, [businessUnit]);

  if (showCounters) console.log({ MachineView_PageRoutes_counter });

  return CachedRoute;
};
