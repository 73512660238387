import React, { useEffect, useRef, useState } from 'react';
import { DimensionsContainer } from 'components';
import { ZoomContainer } from 'common/components/WheelZoomChart/elements';
import { ZoomedBrush } from 'common/ui/ZoomBrushD3/ZoomBrushD3-V2';
import { useDataManagementProvider, ZOOMED_RANGE_MINNUTES } from '../../hooks/useDataManagement';
import { selectAll } from 'd3-selection';
import { getTimezoneOffset, utcToZonedTime } from 'date-fns-tz';
import { subMinutes } from 'date-fns';

interface Props {
  className?: string;
}

interface ZoomBrushComponenetProps {
  width: number;
  triggerResize?: () => void;
}

export const ZoomBrushContainer = ({ className }: Props): JSX.Element => {
  return (
    <DimensionsContainer
      className={className}
      Component={({ width, triggerResize }) => {
        const set = { width, triggerResize };
        return <ZoomBrushComponenet {...set} />;
      }}
    />
  );
};

const ZoomBrushComponenet = ({ width, triggerResize }: ZoomBrushComponenetProps): JSX.Element => {
  const {
    zoomLineData,
    zoomDaterange,
    chartDateRange,
    handelZoomInDaterangeUpdate,
    resetTrack,
    end_time,
    timezone
  } = useDataManagementProvider();

  const chartArea = useRef<HTMLDivElement | null>(null);
  const chart = useRef<ZoomedBrush>();

  const [chartWidth, setChartWidth] = useState(width);

  const settings = {
    height: 100,
    margins: {
      top: 50,
      right: 15,
      bottom: 50,
      left: 15
    },
    dataSettings: {
      data: zoomLineData,
      xAxisKey: 'timestamp',
      type: 'line'
    },
    rangeX: chartDateRange, // Range for the whole component
    zoomedRangeX: zoomDaterange, // Range for the zoomed in part
    updateZoomedRangeX: (range: Date[]) => handelZoomInDaterangeUpdate(range), //Update zoomed in part
    defaultBackground: false,
    domainY: [0, 120],
    width
  };

  useEffect(() => {
    if (!zoomDaterange) return;

    if (!chart.current) {
      chart.current = new ZoomedBrush(chartArea.current, settings.dataSettings, { ...settings });
    } else {
      chart.current.updateBrushSelection(zoomDaterange);
    }
  }, [resetTrack]);

  useEffect(() => {
    if (!zoomDaterange) return;

    if (!chart.current) {
      chart.current = new ZoomedBrush(chartArea.current, settings.dataSettings, { ...settings });
    } else {
      if (!timezone) return;

      //Compares end date of blue zoomed are with current date.
      const endDateZoneOffset = getTimezoneOffset(timezone);
      const nowOffset = getTimezoneOffset(Intl.DateTimeFormat().resolvedOptions().timeZone);

      const endDateZoomRangeUTC =
        endDateZoneOffset > 0
          ? zoomDaterange[1].getTime() - endDateZoneOffset
          : zoomDaterange[1].getTime() + Math.abs(endDateZoneOffset);
      const nowUTC =
        nowOffset > 0
          ? new Date().getTime() - nowOffset
          : new Date().getTime() + Math.abs(nowOffset);
      const difference = Math.abs(endDateZoomRangeUTC - nowUTC) > 5 * 60 * 1000; //5min is grace perio

      //If we are zoomed in on a current date, then keep updating brush selection
      if (!difference) {
        const newRange = [
          subMinutes(utcToZonedTime(end_time, timezone), ZOOMED_RANGE_MINNUTES),
          utcToZonedTime(end_time, timezone)
        ];
        chart.current.updateBrushSelection(newRange);
      }
    }
  }, [end_time]);

  useEffect(() => {
    if (!chartDateRange) return;
    if (!chart.current) {
      chart.current = new ZoomedBrush(chartArea.current, settings.dataSettings, { ...settings });
    } else {
      chart.current.updateXAxis(chartDateRange);
      chart.current.rePopulateLine(zoomLineData); //repopulate line
      chart.current.updateBrushSelection(zoomDaterange);
    }
  }, [chartDateRange]);

  useEffect(() => {
    const isLinePresent = selectAll('.background-line').empty();
    if (!zoomLineData?.length) return;
    if (isLinePresent) {
      chart.current && chart.current.populateLine(zoomLineData);
      chart.current && chart.current.updateBrushSelection(zoomDaterange);
    } else {
      chart.current && chart.current.updateXAxis(chartDateRange);
      chart.current && chart.current.rePopulateLine(zoomLineData); //repopulate line
      chart.current && chart.current.updateBrushSelection(zoomDaterange);
    }
  }, [zoomLineData]);

  useEffect(() => {
    setChartWidth(width);
  }, [width, triggerResize]);

  useEffect(() => {
    if (!chartDateRange) return;
    chart.current = new ZoomedBrush(chartArea.current, settings.dataSettings, { ...settings });
  }, [chartWidth]);

  if (!chartDateRange || !zoomDaterange) return <></>;

  return <ZoomContainer className={`chart-area zoom-brush`} ref={chartArea}></ZoomContainer>;
};
