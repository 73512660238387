import React, { ReactNode, createContext, useContext, useMemo } from 'react';

import { useGetTopAsepticMachineHealthChangeoverByIdQuery } from 'api';

import { processApiData } from 'common/helpers/processApiData';
import { useBuSettings, useMachineInfo } from 'common/pages/fleetV2/providers';
import { calculateDataKeyTotals } from 'common/helpers/dataCounters';
import { useFleetRouter } from 'common/pages/fleetV2/hooks/useFleetRouter';
import {
  combineDateAndTimeToISOTimeStamp,
  convertToZonedTimestamp
} from 'common/helpers/dateAndTimeHelpersV2';
import { useSelectedChangeoverSessionData } from '../hooks/useSelectedChangeoverSessionData';

export interface UseMachineHealthChangeoverSessionDetailsAPIPoviderProps {
  isLoading?: boolean;
  hasMessage?: ReactNode;
  hasError?: ReactNode;
  noData?: boolean;
  tilesData?: Record<string, Record<string, unknown>>;
  chartData?: Record<string, unknown>[];
  data?: Record<string, unknown>;
  [key: string]: unknown;
}

const MachineHealthChangeoverSessionDetailsAPIPoviderContext =
  createContext<UseMachineHealthChangeoverSessionDetailsAPIPoviderProps>({
    isLoading: true
  });

export const useChangeoverSessionDetailsAPI =
  (): UseMachineHealthChangeoverSessionDetailsAPIPoviderProps =>
    useContext(MachineHealthChangeoverSessionDetailsAPIPoviderContext);

interface Props extends UseMachineHealthChangeoverSessionDetailsAPIPoviderProps {
  children?: ReactNode | ReactNode[];
  [key: string]: unknown;
}

const GetSessionData = ({
  sessionId,
  isLoading,
  hasError,
  hasMessage
}: {
  sessionId?: string;
  machineId: string;
  [key: string]: unknown;
}) => {
  const { colors, mapKeys } = useBuSettings();
  const { timeZone } = useMachineInfo();
  const { id } = useFleetRouter()[0];

  const { data, error, isFetching, ...rest } = useGetTopAsepticMachineHealthChangeoverByIdQuery({
    machineId: id as string,
    changeoverId: sessionId as string
  });
  console.log({ data });
  isLoading = !sessionId || rest?.isLoading || isFetching ? true : false;
  hasError = isLoading ? false : error ? true : false;

  const cachedReturn = useMemo(() => {
    if (!data) return;
    // get all the alarms selected session and flattens them
    const alarmsRaw = data?.actualDetails
      ?.map((item: Record<string, unknown>) => item?.alarms)
      .flat();

    // gets an array of the machine modes and processes and formats
    const machineModes =
      data?.actualDetails &&
      processApiData(data.actualDetails as Record<string, unknown>[], {
        timeZone,
        startTimeKey: 'startTime',
        endTimeKey: 'endTime',
        formatTimestamps: 'P, p',
        addDurationAsKey: 'duration',
        renameValues: {
          modeDescr: mapKeys as Record<string, string>
        }
      });

    let sessionStart =
      (data?.startDate &&
        data?.startTime &&
        combineDateAndTimeToISOTimeStamp(data?.startDate, data?.startTime, {
          timeZone: timeZone as string
        })) ||
      undefined;

    let sessionEnd =
      (data?.endDate &&
        data?.endTime &&
        combineDateAndTimeToISOTimeStamp(data?.endDate, data?.endTime, {
          timeZone: timeZone as string
        })) ||
      undefined;

    sessionStart =
      sessionStart && convertToZonedTimestamp(sessionStart, timeZone as string, 'P, p');
    sessionEnd = sessionEnd && convertToZonedTimestamp(sessionEnd, timeZone as string, 'P, p');

    const machineModesTotals =
      machineModes &&
      calculateDataKeyTotals(machineModes, 'modeDescr', {
        valueKey: 'duration',
        colors
      });

    return {
      machineModes,
      // processes alarms array
      alarms:
        alarmsRaw &&
        processApiData(alarmsRaw as Record<string, unknown>[], {
          timeZone,
          startTimeKey: 'startTimestamp',
          endTimeKey: 'endTimestamp',
          formatTimestamps: 'P, p',
          addDuration: true
        }),
      // generats an object with info on the totals of the machine modes (average duration, occurrences, and total duration of each mode)
      machineModesTotals,

      data,
      waitTime: data?.waitTime,
      sessionStart,
      sessionEnd
    };
  }, [data]);

  if (isLoading || hasError || hasMessage) return { isLoading, hasError, hasMessage };

  return cachedReturn as Record<string, unknown>;
};

/** this view loads as the default view, it gets the latest session id from useChangeoverSessionsByDateRangeAPIProvider
 * then uses that to get data from useGetTopAsepticMachineHealthChangeoverByIdQuery */
const DefaultView = ({ children }: { children: ReactNode }) => {
  const { id } = useFleetRouter()[0];

  const { currentSession, ...rest } = useSelectedChangeoverSessionData();

  // we pass the loaders and messages to the GetSessionsData, it will return the current loading and error states, based on wheter or not we have
  // needed props to make the session dtails API call
  // gets data from useGetTopAsepticMachineHealthChangeoverByIdQuery
  const sessionApi = GetSessionData({
    machineId: id as string,
    sessionId: currentSession?.id as string,
    ...rest
  });

  return (
    <MachineHealthChangeoverSessionDetailsAPIPoviderContext.Provider value={sessionApi}>
      {children}
    </MachineHealthChangeoverSessionDetailsAPIPoviderContext.Provider>
  );
};

/** this view loadsif there is a selected sessionID in the url */
const SelectedView = ({ children }: { children: ReactNode }) => {
  const { id, selection: sessionId } = useFleetRouter()[0];
  // gets data from useChangeoverSessionsByDateRangeAPIProvider

  if (!sessionId) return <DefaultView>{children}</DefaultView>;
  // we pass the loaders and messages to the GetSessionsData, it will return the current loading and error states, based on wheter or not we have
  // needed props to make the session dtails API call
  // gets data from useGetTopAsepticMachineHealthChangeoverByIdQuery
  const sessionApi = GetSessionData({
    machineId: id as string,
    sessionId: sessionId as string
  });

  console.log({ sessionApi });

  return (
    <MachineHealthChangeoverSessionDetailsAPIPoviderContext.Provider value={sessionApi}>
      {children}
    </MachineHealthChangeoverSessionDetailsAPIPoviderContext.Provider>
  );
};
// this hook processes the session id from useChangeoverSessionsByDateRangeAPIProvider
// then uses that to get data from useGetTopAsepticMachineHealthChangeoverByIdQuery
export const ChangeoverSessionDetailsAPIPovider = ({ children }: Props): JSX.Element => {
  const { selection: sessionId } = useFleetRouter()[0];
  // gets data from useChangeoverSessionsByDateRangeAPIProvider
  if (!sessionId) return <DefaultView>{children}</DefaultView>;
  return <SelectedView>{children}</SelectedView>;
};
