import styled from 'styled-components';
import { StyledUiContainerProps } from 'components';

const row1Height = `360px`;
export const ViewContainer = styled.div<StyledUiContainerProps>`
  gap: 1em;
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  .machine-overview-widget {
    grid-column: 1 / span 2;
    order: 1;
  }

  .throughput-efficiency-widget {
    grid-column: 3;
    order: 2;
    min-height: ${row1Height};
  }

  .current-issues-widget {
    grid-column: 4;
    order: 3;
  }

  .machine-utilization-widget {
    grid-column: 3 / span 2;
    order: 5;
  }

  .changeover-widget {
    grid-column: 3 / span 2;
    order: 7;
  }

  .widget-ui {
    min-height: 325px;
  }

  .lane-alarms-widget {
    grid-column: 1 / span 2;
    order: 6;
  }

  .machine-modes-widget {
    position: inherit;
    grid-column: 1 / span 2;
    order: 8;

    @media (min-width: 1125px) {
      grid-column: 1 / span 4;
      height: 570px;
    }

    .widget-ui__header {
      padding-bottom: 0;
    }
  }

  .oee-widget {
    grid-column: 1 / span 2;
    order: 4;
  }

  .machine-mode-container {
    order: 8;
  }

  @media (max-width: 1350px) {
    grid-template-columns: 1fr 1fr;

    .changeover-widget {
      grid-column: 1 / span 2;
      order: 7;
    }

    .throughput-efficiency-widget {
      grid-column: 1;
      order: 2;
    }
    .machine-utilization-widget {
      grid-column: 1 / span 2;
      order: 5;
    }
    .machine-state-widget {
      grid-column: 1 / span 2;
      order: 8;
    }

    .current-issues-widget {
      grid-column: 2;
      order: 3;
    }
  }
`;
