import React, { useState } from 'react';
import { PieChartWidget } from 'common/components';
import { convertSecondsToTime } from 'common/helpers/dateAndTimeHelpers';
import { useChangeoverMachineModesWidget } from './hooks/useChangeoverMachineModesWidget';
import { useTranslation } from 'react-i18next';

export const ChangeoverMachineModesWidget = (): JSX.Element => {
  const { isLoading, hasError, chartData } = useChangeoverMachineModesWidget() as {
    chartData?: Record<string, unknown>[];
    [key: string]: unknown;
  };

  const { t } = useTranslation(['mh']);

  chartData?.sort((a, b) => ((b.total as number) || 0) - ((a.total as number) || 0)) || chartData;

  const [selected, setSelected] = useState<Record<string, string[]>>({ group: [] });

  const selectedGroup = ((selected?.group as string[]) || [])?.[0];

  const selectedSlice = !selectedGroup
    ? chartData?.[0]
    : chartData
    ? chartData?.find((x: Record<string, unknown>) => (x?.group === selectedGroup ? x : undefined))
    : undefined;

  // chartData = chartData.slice().sort((a, b) => b.count - a.count);
  const handleClick = (slice: Record<string, unknown>) => {
    const group = (slice?.group as string) || '';
    const label = (slice?.group as string) || group;
    const valToUse = group || label;

    if (valToUse) {
      const isSelected = valToUse === selectedGroup ? true : false;
      return setSelected(isSelected ? { group: [] } : { group: [valToUse] });
    }
  };

  // the function that helps determins if the slice is selected
  const checkIfSelected = (slice: Record<string, unknown>) => {
    if (!selected || !selected?.group?.length) return true;
    const group = (slice?.group as string) || '';
    const label = (slice?.group as string) || group;
    const valToUse = group || label;
    const isSelected = valToUse === selectedGroup ? true : false;
    return isSelected;
  };

  //const filteredData = chartData && filterSelectedData({ data: chartData, selected });
  const widgetUiSettings = {
    isLoading,
    hasError,
    className: 'changeover-machine-modes-widget',
    title: 'changeover machine modes',
    handleClick
  };

  const pieChartWidgetSettings = {
    ...widgetUiSettings,
    type: 'doughnut',
    handleClick,
    checkIfSelected,
    selected: selected?.group || [],
    data: chartData,
    groupKey: 'group',
    valueKey: 'total',
    autoLegend: true,
    centerCellValues: selectedSlice && {
      label: <>{selectedGroup || chartData?.[0]?.group || ''}</>,
      value: convertSecondsToTime(selectedSlice?.total as number, `h:m`)
    },
    //disableLegend: true,
    format: {
      legendItem: (props: Record<string, unknown>) => {
        const label = props?.label as string;
        console.log({ props });
        return <>{label}</>;
      }
    },
    TooltipComponent: (props: Record<string, unknown>) => {
      const label = props?.label ? t((props.label as string).toLowerCase()) : undefined;

      return (
        <>
          {label && <div className="tooltip__title">{label}</div>}
          <div className="tooltip__desc">{props?.description}</div>
        </>
      );
    }
  };
  return <PieChartWidget {...pieChartWidgetSettings} />;
};
