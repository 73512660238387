import React from 'react';

import { WidgetUi } from 'common/ui';
import { KPIProgressTilesWidgetContainer } from './ProductionKPIsWidget.elements';

import { ProcessedProductPercentageKPIProgressTile } from './ProcessedProductPercentageKpiProgressTile';
import { ThroughputPieceCountKPIProgressTile } from './ThroughputPieceCountKPIProgressTile';
import { YieldKPIProgressTile } from './YieldKPIProgressTile';
import { ThroughputRateKPIProgressTile } from './ThroughputRateKPIProgressTile';

/**
 * this is just for a custom widget template,
 * if you want a pie chart or bar chart one of the existing global charts, please choose one from common/components/WidgetUi
 */

export const ProductionKPIsWidget = (): JSX.Element => {
  const widgetSettings = {
    title: 'production kpis',
    className: 'production-kpis-widget',
    hasDateFooter: 'last 30 minutes'
  };

  return (
    <WidgetUi
      {...widgetSettings}
      Main={
        <KPIProgressTilesWidgetContainer className="widget-ui__main">
          <YieldKPIProgressTile />
          <ThroughputRateKPIProgressTile />
          <ThroughputPieceCountKPIProgressTile />
          <ProcessedProductPercentageKPIProgressTile />
        </KPIProgressTilesWidgetContainer>
      }
    />
  );
};
