import React, { useState } from 'react';
import { DimensionsContainer } from './components/DimensionsContainer/DimensionsContainer';
import { ChartGeneratorContainer } from './ChartGenerator.elements';

import { useChartGenerator, useChartGeneratorData, UseChartGeneratorDataProvider } from './hooks';
import { GridAndAxisLayer } from './generated';

// import GeneratedChartsArea from '../D3ChartGeneratorV2/GeneratedAreas/GeneratedChartsArea/GeneratedChartsArea';
// import { GeneratedHoverColumnsArea } from '../D3ChartGeneratorV2/GeneratedAreas/GeneratedHoverColumns/GeneratedHoverColumnsArea';
import { ChartsBaseProps } from './ChartGenerator.types';
import { UseChartGeneratorProvider } from './hooks';
import { ChartArea } from './generated/ChartArea/ChartArea';
import { ChartLegend } from './generated/ChartLegend/ChartLegend';
import { HoverArea } from './generated/HoverArea/HoverArea';
import { UseChartGeneratorTooltipProvider } from './hooks/useChartGeneratorToolip/useChartGeneratorTooltip';
import { DataPointsArea } from './generated/DataPointsArea/DataPointsArea';
import { GeneratorTooltip } from './generated/Tooltip';

export const ChartGenerator = ({
  data,
  groupKey,
  valueKey,
  categoryKey,
  categoryKeys,
  valueKeys,
  colors,
  mapKeys,
  ...rest
}: ChartsBaseProps): JSX.Element => {
  const dataSettings = {
    data,
    groupKey,
    valueKey,
    categoryKey,
    categoryKeys,
    valueKeys,
    colors,
    mapKeys
  } as ChartsBaseProps;

  const { isInitialized } = useChartGeneratorData();

  if (!isInitialized) {
    return (
      <UseChartGeneratorDataProvider {...dataSettings}>
        <Generator {...rest} />
      </UseChartGeneratorDataProvider>
    );
  }

  return <Generator {...dataSettings} {...rest} />;
};

const Generator = ({
  className,
  chartType,
  Components,
  format,
  children,
  hideAutoLegend,
  ...incomingOverrides
}: ChartsBaseProps): JSX.Element => {
  const chartGeneratorData = useChartGeneratorData();

  const [legendItems, setLegendItems] = useState<Record<string, unknown>[] | undefined>(
    chartGeneratorData?.legendItems as Record<string, unknown>[]
  );

  const { groupKey, valueKeys, categoryKey, categoryKeys, valueKey, groupKeys } = {
    ...chartGeneratorData,
    ...incomingOverrides
  };

  if (!groupKey && !groupKeys && !categoryKey && !categoryKeys && !valueKey && !valueKeys)
    return <>chart is missing keys</>;

  const width = (incomingOverrides?.width as number) || undefined;
  const height = (incomingOverrides?.height as number) || undefined;

  chartType = chartType || (chartGeneratorData?.chartType as string);

  if (!chartType) {
    return <div>chartType is required for ChartGenerator</div>;
  }

  const { Legend, Outside, ...restComponents } = Components || {};
  const LegendComponent = Legend || ChartLegend;

  return (
    <>
      {chartType !== 'progress-pie' && !hideAutoLegend && <LegendComponent {...{ legendItems }} />}

      <ChartGeneratorContainer
        className={`chart-generator-container generated-${chartType || 'unknown-type'}-chart ${
          className || ''
        }`.trim()}
      >
        <DimensionsContainer
          {...{ width, height }}
          Component={(dimensions?: Record<string, unknown>) => (
            <UseChartGeneratorProvider
              {...{
                ...chartGeneratorData,
                ...incomingOverrides,
                ...dimensions,
                chartType,
                Components: restComponents,
                format,
                setLegendItems,
                legendItems
              }}
            >
              <GeneratedArea />
              {children}
            </UseChartGeneratorProvider>
          )}
        />
      </ChartGeneratorContainer>

      {Outside && (
        <Outside
          {...{
            ...chartGeneratorData,
            ...incomingOverrides,
            chartType,
            Components: restComponents,
            format
          }}
        />
      )}
    </>
  );
};

const GeneratedArea = () => {
  const { data } = useChartGeneratorData();
  const { chartType, format, Components, hasHoverColumns } = useChartGenerator();

  const { HoverColTooltips, HoverCols } = Components || {};

  const hasHoverArea =
    format?.hoverCols || HoverColTooltips || HoverCols || hasHoverColumns ? true : false;

  if (!data) return <>no chart data</>;

  if (chartType !== 'pie') {
    return (
      <UseChartGeneratorTooltipProvider>
        {hasHoverArea && <HoverArea />}
        <DataPointsArea />
        <GeneratorTooltip />
        <ChartArea />
        <GridAndAxisLayer />
      </UseChartGeneratorTooltipProvider>
    );
  }

  return (
    <UseChartGeneratorTooltipProvider>
      <GeneratorTooltip />
      <ChartArea />
    </UseChartGeneratorTooltipProvider>
  );
};

/* 
return (
    <TooltipProvider>
      {
        // this loads the bottom layer of the line and bar chart elements like axis and grid lines
        <ChartBaseElements />
      }

      {
        // this adds the layer for hover events tooltips
       }

      {
        // this adds the chart svg elements and any covering divs
        <GeneratedChartsArea />
      }
    </TooltipProvider>
  );
  */
/**
 * step 4: lazy load the grid lines, ticks labels and tick marks (axis) and chart backgrounds
 
const ChartBaseElements = (): JSX.Element => {
  const { chartType } = useD3ChartGenerator();

  const ChartBaseElementsCache = useMemo(() => {
    if (chartType === 'pie') return <></>;

    return (
      <Suspense fallback={<GeneratedChartLoader />}>
        <div className="chart-base-area">
          <div className="grid-lines-area">
            <LazyGeneratedGridLines />
          </div>
          <div className="axis-area">
            <LazyGeneratedAxisLabelsAndTicks />
          </div>
        </div>
      </Suspense>
    );
  }, [chartType]);

  return ChartBaseElementsCache;
};

const ChartsHoverLayer = (): JSX.Element => {
  const { chartType, disableAutoTooltips } = useD3ChartGenerator();
  const { format, Components } = useD3ChartGenerator() || {};

  // dont show the hover columns if the chart is a pie chart or data points chart (does not have axis)
  const showHoverCols =
    chartType === 'pie' || chartType === 'data-points'
      ? false
      : Components?.HoverColTooltips || format?.hoverCols
      ? true
      : false;

  const showDataPoints =
    chartType === 'data-points' || chartType === 'line'
      ? true
      : Components?.DataPoints || format?.dataPoints
      ? true
      : false;

  return (
    <>
      {!disableAutoTooltips && chartType !== 'data-points' && (
        <Suspense fallback={<GeneratedChartLoader />}>
          <span className="tooltips-area">
            <LazyGeneratedTooltipsArea />
          </span>
        </Suspense>
      )}

      {showDataPoints && (
        <Suspense fallback={<GeneratedChartLoader />}>
          <span className="data-points-area">
            <LazyGeneratedDataPointsArea />
          </span>
        </Suspense>
      )}

      {showHoverCols && (
        <span className="hover-cols-area">
          <GeneratedHoverColumnsArea />
        </span>
      )}
    </>
  );
};
*/
