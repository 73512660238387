import { ReactNode } from 'react';
//import { useWidgetDateRange } from 'common/ui/WidgetUi/providers/useWidgetDateRange';
import { useRouter } from 'common/hooks/useRouter';
import { fleetPageRoute } from '../../../../../../../settings/fleetPageRoutes';
import { useGetProsealMachineOverviewQuery } from 'api';
import { getZonedStartOfDay } from 'common/helpers/dateAndTimeHelpersV2';
import { useMachineInfo } from '../../../../../../../providers/useMachineInfoProvider';
import { useBuSettings } from 'common/pages/fleetV2/providers';

export const useMachineOverviewWidget = (): {
  isLoading?: boolean;
  hasError?: ReactNode;
  hasMessage?: ReactNode;
  data?: Record<string, unknown>;
  [key: string]: unknown;
} => {
  let hasError = undefined,
    hasMessage = undefined;

  const { id } = useRouter(fleetPageRoute);
  const { timeZone } = useMachineInfo();
  const { colors, Icons } = useBuSettings();

  // api path
  // /proseal/machines/{machine_id}/machine-overview
  const { isLoading, data, error } = useGetProsealMachineOverviewQuery(
    {
      machineId: id,
      beginning_of_day_utc: getZonedStartOfDay(timeZone as string).utc
    },
    {
      pollingInterval: 60000
    }
  );
  let status = data?.latest_machine_status_category as string;
  status = status?.toLowerCase();
  const statusColor = colors?.[status] || data?.latest_machine_status_category_color || 'red';
  const StatusIcon = Icons?.[status];
  if (error) {
    hasError = 'error getting machine overview data';
  }

  if (!isLoading && (!data || data.length === 0)) hasMessage = 'no data available';

  if (!data) return { isLoading, hasError, hasMessage };

  return { statusColor, StatusIcon, ...data };
};
