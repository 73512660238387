import React from 'react';
import { ResetIcon } from 'icons/resetbtn';
import { DatepickerBarContainer } from './elements';
import { utcToZonedTime } from 'date-fns-tz';
import { useDateRangeV2 } from 'components/StyledUi/DateRange/hooks/useDateRangeV2';
import { DisplayDateButtonWithDropdown } from 'components/StyledUi/DisplayDateRangeButton/DisplayDateButtonWithDropdown';

interface DatepickerBarProps {
  showDaterange: boolean;
  handleReset?: () => void;
  currentSession?: Record<string, unknown>;
  timeZone?: string;
}

export const DatepickerBar = ({
  showDaterange,
  handleReset,
  timeZone
}: DatepickerBarProps): JSX.Element => {
  const { dateRange, setDateRange } = useDateRangeV2();

  const handleResetBtn = handleReset;

  const timezone = timeZone || 'UTC';

  const resetButton = (
    <button className="reset-button" onClick={() => handleResetBtn?.()}>
      <ResetIcon /> Reset{' '}
    </button>
  );

  const timeZonedDateRange = {
    startTime: utcToZonedTime(dateRange.startTime, timezone),
    endTime: utcToZonedTime(dateRange.endTime, timezone)
  };

  return (
    <DatepickerBarContainer className="widget__time-bar">
      {resetButton}
      {showDaterange && (
        <>
          <p>Timeframe</p>
          <DisplayDateButtonWithDropdown
            {...{ dateRange: timeZonedDateRange, setDateRange, hasGoBackDateLimit: 30, timeZone }}
          />
        </>
      )}
    </DatepickerBarContainer>
  );
};
