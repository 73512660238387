import { ReactNode, useMemo } from 'react';
import { UseLiveTimeFrameOptionsProps } from './useLiveTimeFrame';

export interface UseApiDataCacherLiveTimeFrameProps extends UseLiveTimeFrameOptionsProps {
  /** in ms */
  refreshInterval: number;
  timeZone?: string;
  startTimeKey?: string;
  endTimeKey?: string;
}

export interface UseApiDataCacherReturnProps {
  isLoading?: boolean;
  hasError?: ReactNode;
  hasMessage?: ReactNode;
  lastUpdated?: string;
  [key: string]: unknown;
}

export const useApiDataCacher = (
  func: (x?: Record<string, unknown>) => Record<string, unknown>,
  options?: Record<string, unknown>
): Record<string, unknown> => {
  const { data, ...rest } = func(options) as {
    data: Record<string, unknown> | undefined | Record<string, unknown>[];
    rest: Record<string, unknown>;
  };

  const cachedData = useMemo(() => data, [data]);

  if (!cachedData) return rest;

  return { data: cachedData };
};
