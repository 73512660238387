import React, { Fragment } from 'react';
import { useChartGenerator } from '../../hooks';
import { GridLine } from './GridLine';
import { TickMark } from './TickMark';

export const GridAndAxisLayer = (): JSX.Element => {
  const { getScale } = useChartGenerator();

  const bottomTicks = getScale('bottomTicks') as (string | number)[];
  const leftTicks = getScale('leftTicks') as (string | number)[];

  const axisX = (bottomTicks || [])?.map((x: string | number, i) => ({
    x,
    isFirst: i === 0,
    isLast: i === bottomTicks?.length - 1
  }));

  const axisY = ((leftTicks as number[]) || [])?.map((y: number, i) => ({
    y,
    isFirst: i === 0,
    isLast: i === leftTicks?.length - 1
  }));

  return (
    <>
      {axisX?.length &&
        axisX.map((x, i) => {
          return (
            <Fragment key={`x${i}`}>
              <GridLine {...x} />
              <TickMark {...x} />
            </Fragment>
          );
        })}
      {axisY?.length &&
        axisY.map((y, i) => {
          return (
            <Fragment key={`y${i}`}>
              <GridLine {...y} />
              <TickMark {...y} />
            </Fragment>
          );
        })}
    </>
  );
};
