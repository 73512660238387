import React from 'react';
import {
  convertToZonedTimestamp,
  getDurationISOTimeStamps,
  convertSecondsToTime
} from 'common/helpers/dateAndTimeHelpersV2';
import { useMachineInfo } from 'common/pages/fleetV2/providers';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useChangeoverStepDetailsApiProvider } from '../../views/Changeover/views/SessionsOverTime/providers/ChangeoverStepDetailsApiProvider';
import { ChartTooltipContainer } from './ChangeoverStepDurationWidget.elements';
import { ChartTooltipColorItem } from 'common/ui';

export const Tooltip = ({ category }: Record<string, unknown>): JSX.Element => {
  //const {  changeoverSessionStepsDurationTotals, changeoverSessionsStartDates, changeoverCategoriesTotals} = useChangeoverStepsDurationWidget() as { [key: string]: unknown, changeoverSessionStepsDurationTotals: Record<string, Record<string, unknown>> };
  const { t } = useTranslation();
  const { timeZone } = useMachineInfo();
  const { changeoverSessionStepsDurationTotals, changeoverSessionsStartDates } =
    useChangeoverStepDetailsApiProvider();

  if (changeoverSessionsStartDates && changeoverSessionStepsDurationTotals) {
    //console.log({changeoverSessionsStartDates, changeoverSessionStepsDurationTotals})
    const { start, end } =
      changeoverSessionsStartDates?.[category as string] ||
      ({} as { start?: string; end?: string });
    const sessionTotals = Object.values(
      changeoverSessionStepsDurationTotals?.[category as string] as Record<string, unknown>
    );
    const sessionStart = convertToZonedTimestamp(start as string, timeZone, 'p, P');
    const sessionEnd = end as string;
    const sessionEndYear = sessionEnd && format(new Date(sessionEnd), 'yyyy');
    const sessionStartTime = sessionStart && format(new Date(sessionStart), 'H:mm');
    const sessionEndTime = sessionEnd && format(new Date(sessionEnd), 'H:mm');
    const sessionDuraion = start && end && getDurationISOTimeStamps(end, start);
    return (
      <ChartTooltipContainer>
        <div className="title">
          <strong>
            {convertToZonedTimestamp(start as string, timeZone, 'MMM d')} -{' '}
            {convertToZonedTimestamp(end as string, timeZone, 'MMM d')} {sessionEndYear}
          </strong>
        </div>
        <div className="split-col border-bottom">
          <div className="col">
            <span className="label">{t('Time')}: </span>
            <strong>
              {sessionStartTime} - {sessionEndTime}
            </strong>
          </div>
          <div className="col">
            <span className="label">{t('duration')}: </span>
            <strong>{convertSecondsToTime(sessionDuraion, 'hr:m')}</strong>
          </div>
        </div>
        {sessionTotals?.map(
          ({ group, total, color, count }: Record<string, unknown> | undefined) => {
            const time = convertSecondsToTime(total as number, 'hr:m');
            return (
              <ChartTooltipColorItem
                className="item"
                key={group as string}
                color={String(color) || 'red'}
              >
                <div>
                  <span className="label">
                    {group} <span className="counter-value">{`(${count} runs)`}</span>
                  </span>{' '}
                  - <strong>{time}</strong>
                </div>
              </ChartTooltipColorItem>
            );
          }
        )}
      </ChartTooltipContainer>
    );
  }

  return <></>;
};
