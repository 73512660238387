import { styledTheme } from 'common/theme';
import styled, { css } from 'styled-components';

function createCSS() {
  let styles = '';
  for (let i = 0; i < 100; i += 1) {
    if (i > 85) {
      styles += `
          &.percentage-${i}:after {
              width: calc(${i} * 1%);
          }
          &.percentage-${i} .text {
            color: #ffffff;
          }
         `;
    } else {
      styles += `
          &.percentage-${i}:after {
              width: calc(${i} * 1%);
          }
         `;
    }
  }
  return css`
    ${styles}
  `;
}

export const BarBlockContainer = styled.div`
  &.bar-indicator-container {
    display: flex;
    flex-direction: column;
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
    width: 100%;

    p {
      margin: 0;
    }

    .bar-indicator--row {
      display: flex;
      flex-direction: row;

      .bar-indicator--icon {
        padding-right: 0.25rem;
      }

      .bar-indicator--name {
        color: ${styledTheme.colors.gray800};
        font-weight: 600;
      }
    }

   
    .bar-indicator--status {
      display: flex;
      font-weight: 700;
      align-items: end;
      margin-left: 1rem;
      margin-bottom: 0.25rem;
      width: 39%;
      justify-content: flex-start;
    }

    .bar-indicator--bars {
      width: 100%;

      .bar {
        display: flex;
        flex-direction: row;
        width: 100%;

         .text {
            display: block;
            width: 25%;
            position: relative;
            color: ${styledTheme.colors.gray700};
            font-weight: 600;
            display: block;
            text-align: right;
            padding-right: 1rem;
          }
      }

      dl {
        margin-top: 0;
        margin-bottom: 0;
      }
  }
`;

interface Props {
  color: string;
}

export const DL = styled.dl<Props>`
  .percentage: after {
    background-color: ${({ color }) => color};
  }
`;

export const DD = styled.dd`
  width: 75%;

  &.percentage {
    height: 12px;
    margin: 0;
    margin-bottom: 0.25rem;
    margin-right: 1rem;

    &:after,
    &:before {
      border-radius: 3px;
    }

    &:after {
      content: '';
      top: -11px;
      display: block;
      height: 90%;
      position: relative;
      cursor: pointer;
    }

    &:before {
      content: '';
      display: block;
      background-color: ${styledTheme.colors.gray200};
      height: 90%;
      position: relative;
      cursor: pointer;
    }
  }

  ${createCSS()};
`;
