import { convertSecondsToTime } from 'common/helpers/dateAndTimeHelpersV2';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlyoutItemContainer } from './FlyoutItem.elements';

export const FlyoutItem = (props: Record<string, unknown>): JSX.Element => {
  const { t } = useTranslation(['mh']);
  const { description, code, startTimestampFormatted, endTimestampFormatted, ...rest } = props;

  let duration = rest?.duration;

  if (typeof duration === 'number') duration = convertSecondsToTime(duration);

  return (
    <FlyoutItemContainer className="session-alarm-flyout-item">
      <div className="item-title">{description}</div>
      <div className="item-rows">
        {code && (
          <div className="item-row">
            <span className="label">{t('id')}: </span>
            {code}
          </div>
        )}

        {duration && (
          <div className="item-row">
            <span className="label">{t('duration')}: </span> {duration}
          </div>
        )}

        {startTimestampFormatted && (
          <div className="item-row">
            <span className="label">{t('start time')}: </span> {startTimestampFormatted}
          </div>
        )}

        {endTimestampFormatted && (
          <div className="item-row">
            <span className="label">{t('end time')}: </span> {endTimestampFormatted}
          </div>
        )}
      </div>
    </FlyoutItemContainer>
  );
};
