import styled from 'styled-components';
import { StyledUiContainerProps } from 'components';

export const ViewContainer = styled.div<StyledUiContainerProps>`
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 1em;

  .sub-tabs-wrapper {
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 1em;
    align-items: center;
    span {
      font-weight: 800;
    }
  }
`;
