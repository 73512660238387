import styled from 'styled-components';

export const IconWrapper = styled.span`
  margin-top: 0.2em;
  color: ${({ theme }) => {
    return theme.colors.primaryBlue5;
  }};
`;

export const StyledButton = styled.button`
  background: ${({ theme, disabled }) => {
    return disabled ? theme.colors.white : theme.colors.white;
  }};
  color: ${({ theme, disabled }) => {
    return disabled ? theme.colors.mediumGrey2 : theme.colors.text.active;
  }};
  border-radius: 0.5rem;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.125rem;
  vertical-align: center;
  cursor: ${({ disabled }) => {
    return disabled ? 'not-allowed' : 'pointer';
  }};
  &:hover {
    background: ${({ theme, disabled }) => {
      return disabled ? undefined : theme.colors.mediumBlue4;
    }};
  }
`;

export const ButtonLabel = styled.span`
  padding: 0.5rem;
  margin: 0;
  font-size: 0.87rem;
  width: fit-content;
  white-space: nowrap;
`;
