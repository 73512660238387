import styled from 'styled-components';

export const SessionAlarmsFlyoutContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: max-content;

  .ui-date-button-w-dropdown {
    margin: 0 auto;
  }

  .scroll-area {
    overflow: hidden;
  }

  .main-padder {
    padding: 1em;
    overflow: hidden;
    max-height: 100%;
    max-width: 100%;
    background: pink;
  }

  .cta-cell {
    display: flex;
    align-items: flex-end;
    grid-column: 2;
    justify-content: flex-end;
    color: rgb(0, 118, 204);
  }
`;

export const SessionSelectPopupContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 1em;
  height: 100%;
  width: max-content;

  .ui-date-button-w-dropdown {
    margin: 0 auto;
  }

  .scroll-area {
    overflow: hidden;
  }

  .main-padder {
    padding: 1em;
    overflow: hidden;
    max-height: 100%;
    max-width: 100%;
    background: pink;
  }
`;

export const SelectSessionFlyoutHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 0.5em;

  > strong {
    display: block;
    transform: translateY(0.05em);
  }
`;
