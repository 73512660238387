import React from 'react';
import { ViewContainer } from './View.elements';
import { ProductionOutputWidget } from '../../components/ProductionOutputWidget';

export const HistoricalProductionView = (): JSX.Element => {
  return (
    <ViewContainer className="historical-production-vew view-container">
      <ProductionOutputWidget />
    </ViewContainer>
  );
};
