import React from 'react';

interface IconProps {
  color?: string;
  width?: number;
  height?: number;
}

export const IncreaseValueArrow = ({
  color = '#29A429',
  width = 25,
  height = 25
}: IconProps): JSX.Element => {
  return (
    <svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_13274_15723)">
        <path
          d="M14.2912 9.90078L11.5365 6.10312C11.4365 5.96563 11.2318 5.96563 11.1326 6.10312L8.37716 9.90078C8.37038 9.91012 8.36631 9.92116 8.36542 9.93266C8.36453 9.94417 8.36684 9.9557 8.3721 9.96598C8.37736 9.97625 8.38536 9.98487 8.39521 9.99087C8.40507 9.99688 8.4164 10 8.42794 10H9.01388C9.05372 10 9.09122 9.98047 9.11466 9.94844L11.3342 6.88906L13.5537 9.94844C13.5772 9.98047 13.6147 10 13.6545 10H14.2404C14.2912 10 14.3209 9.94219 14.2912 9.90078Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_13274_15723">
          <rect width={width} height={height} fill="white" transform="translate(7.83398 4.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
