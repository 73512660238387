import React from 'react';

import { WidgetUi } from 'common/ui';
import { LatestIssuesWidgetContainer } from './LatestIssuesWidget.elements';

export const LatestIssuesWidget = (): JSX.Element => {
  //const { isLoading, hasMessage, hasError, data } = useLatestIssuesWidget();

  const widgetSettings = {
    title: 'latest issues',
    className: 'latest-issues-widget',
    hasMessage: true
  };

  return (
    <WidgetUi
      {...widgetSettings}
      Main={
        <LatestIssuesWidgetContainer className="widget-ui__main">
          asdfasdf
        </LatestIssuesWidgetContainer>
      }
    />
  );
};
