import React, { ReactNode, createContext, useContext } from 'react';
import { useGetAsepticMachineHealthChangeoverByDateRangeQuery } from 'api';
//import { useDateRangeV2 } from 'common/providers';
import {
  addOffsetTextToTimestamp,
  convertToZonedTimestamp
} from 'common/helpers/dateAndTimeHelpersV2';
import { useWidgetDateRange } from 'common/ui';
import { useFleetRouter } from 'common/pages/fleetV2/hooks/useFleetRouter';

export interface UseChangeoverSessionsByDateRangeAPIProviderReturnProps {
  isLoading?: boolean;
  hasMessage?: ReactNode;
  hasError?: ReactNode;
  data?: Record<string, unknown>[];
  [key: string]: unknown;
}

const ChangeoverSessionsByDateRangeAPIProviderContext =
  createContext<UseChangeoverSessionsByDateRangeAPIProviderReturnProps>({
    isLoading: true
  });

export const useChangeoverSessionsByDateRangeAPIProvider =
  (): UseChangeoverSessionsByDateRangeAPIProviderReturnProps =>
    useContext(ChangeoverSessionsByDateRangeAPIProviderContext);

interface Props extends UseChangeoverSessionsByDateRangeAPIProviderReturnProps {
  children?: ReactNode | ReactNode[];
}

/**
 * This api call can be trimmed down to not give duration or update to use the correct timestamp format
 */
export const ChangeoverSessionsByDateRangeAPIProvider = ({ children }: Props): JSX.Element => {
  const [{ id }] = useFleetRouter();

  const { timeZone, startTime, endTime } = useWidgetDateRange();

  const splitStart = startTime.split('T')[0];
  const splitEnd = endTime.split('T')[0];

  // path: /mh/v1/aseptic/machine-health/${machineId ?? ''}/change-over${addArgsToPath(rest)}
  // date format should be string: 2024-04-17
  const { data, isLoading } = useGetAsepticMachineHealthChangeoverByDateRangeQuery({
    machineId: id as string,
    start_date: splitStart,
    end_date: splitEnd,
    limit: 10000
  });

  const processed = data?.map((item: Record<string, unknown>) => {
    const startTime =
      item.startDate && item.startTime
        ? addOffsetTextToTimestamp(`${item.startDate}T${item.startTime}`)
        : undefined;
    const endTime =
      item.endDate && item.endTime
        ? addOffsetTextToTimestamp(`${item.endDate}T${item.endTime}`)
        : undefined;

    return {
      ...item,
      startTimestamp: startTime,
      endTimestamp: endTime,
      startTimestampFormatted: startTime && convertToZonedTimestamp(startTime, timeZone, 'P, p'),
      endTimestampFormatted: endTime && convertToZonedTimestamp(endTime, timeZone, 'P, p')
    };
  });

  const hasError = false; //error ? 'error getting KPI Data in ThroughputEfficiencyWidget' : undefined;
  const hasMessage =
    (!isLoading && !hasError && !processed) || processed?.length === 0 ? 'no data' : undefined;

  return (
    <ChangeoverSessionsByDateRangeAPIProviderContext.Provider
      value={{
        data: processed,
        hasError,
        hasMessage,
        isLoading,
        processed
      }}
    >
      {children}
    </ChangeoverSessionsByDateRangeAPIProviderContext.Provider>
  );
};

export const demoAPIData = [
  {
    sessionId: 'aa55ccc9-8079-46ec-bf75-9385821ab460',
    id: '1',
    parentName: 'Initial test (totals)',
    name: 'Mainten',
    startTime: '2023-12-09T18:20:04+00:00',
    endTime: '2023-12-09T18:28:04+00:00'
  },
  {
    sessionId: 'aa55ccc9-8079-46ec-bf75-9385821ab460',
    id: '1',
    parentName: 'Empty machine',
    name: 'Mainten',
    startTime: '2023-12-09T18:28:04+00:00',
    endTime: '2023-12-09T18:30:39+00:00'
  },
  {
    sessionId: 'aa55ccc9-8079-46ec-bf75-9385821ab460',
    id: '1',
    parentName: 'Initial test (totals)',
    name: 'Mainten',
    startTime: '2023-12-09T18:30:39+00:00',
    endTime: '2023-12-09T18:42:17+00:00'
  },
  {
    sessionId: 'aa55ccc9-8079-46ec-bf75-9385821ab460',
    id: '1',
    parentName: 'Maintenance no auto',
    name: 'Mainten',
    startTime: '2023-12-09T18:42:17+00:00',
    endTime: '2023-12-09T20:37:26+00:00'
  },
  {
    sessionId: 'aa55ccc9-8079-46ec-bf75-9385821ab460',
    id: '3',
    parentName: 'CIP Alkaline & Acid & HPV',
    name: 'Cleaning',
    startTime: '2023-12-09T20:37:26+00:00',
    endTime: '2023-12-09T22:14:45+00:00'
  },
  {
    sessionId: 'aa55ccc9-8079-46ec-bf75-9385821ab460',
    id: '1',
    parentName: 'Maintenance no auto',
    name: 'Mainten',
    startTime: '2023-12-09T22:14:45+00:00',
    endTime: '2023-12-10T01:00:24+00:00'
  },
  {
    sessionId: 'aa55ccc9-8079-46ec-bf75-9385821ab460',
    id: '4',
    parentName: 'Sterilisation',
    name: 'Steril',
    startTime: '2023-12-10T01:00:24+00:00',
    endTime: '2023-12-10T03:10:18+00:00'
  },
  {
    sessionId: 'aa55ccc9-8079-46ec-bf75-9385821ab460',
    id: '4',
    parentName: 'Wait time',
    name: 'Wait time',
    startTime: '2023-12-10T03:10:18+00:00',
    endTime: '2023-12-10T03:37:43+00:00'
  },
  {
    sessionId: '3c0bb363-8485-4809-a88b-ca3c018cc695',
    id: '1',
    parentName: 'Initial test (totals)',
    name: 'Mainten',
    startTime: '2023-12-07T23:47:08+00:00',
    endTime: '2023-12-08T00:15:33+00:00'
  },
  {
    sessionId: '3c0bb363-8485-4809-a88b-ca3c018cc695',
    id: '1',
    parentName: 'Maintenance no auto',
    name: 'Mainten',
    startTime: '2023-12-08T00:15:33+00:00',
    endTime: '2023-12-08T00:15:52+00:00'
  },
  {
    sessionId: '3c0bb363-8485-4809-a88b-ca3c018cc695',
    id: '1',
    parentName: 'Maintenance no auto',
    name: 'Mainten',
    startTime: '2023-12-08T00:15:52+00:00',
    endTime: '2023-12-08T00:16:22+00:00'
  },
  {
    sessionId: '3c0bb363-8485-4809-a88b-ca3c018cc695',
    id: '1',
    parentName: 'Maintenance no auto',
    name: 'Mainten',
    startTime: '2023-12-08T00:16:22+00:00',
    endTime: '2023-12-08T02:31:23+00:00'
  },
  {
    sessionId: '3c0bb363-8485-4809-a88b-ca3c018cc695',
    id: '3',
    parentName: 'CIP Alkaline & Acid & HPV',
    name: 'Cleaning',
    startTime: '2023-12-08T02:31:23+00:00',
    endTime: '2023-12-08T02:38:03+00:00'
  },
  {
    sessionId: '3c0bb363-8485-4809-a88b-ca3c018cc695',
    id: '1',
    parentName: 'Maintenance no auto',
    name: 'Mainten',
    startTime: '2023-12-08T02:38:03+00:00',
    endTime: '2023-12-08T02:47:39+00:00'
  },
  {
    sessionId: '3c0bb363-8485-4809-a88b-ca3c018cc695',
    id: '3',
    parentName: 'CIP Alkaline & Acid & HPV',
    name: 'Cleaning',
    startTime: '2023-12-08T02:47:39+00:00',
    endTime: '2023-12-08T04:24:30+00:00'
  },
  {
    sessionId: '3c0bb363-8485-4809-a88b-ca3c018cc695',
    id: '1',
    parentName: 'Maintenance no auto',
    name: 'Mainten',
    startTime: '2023-12-08T04:24:30+00:00',
    endTime: '2023-12-08T05:40:45+00:00'
  },
  {
    sessionId: '3c0bb363-8485-4809-a88b-ca3c018cc695',
    id: '3',
    parentName: 'External Cleaning No Sprayer',
    name: 'Cleaning',
    startTime: '2023-12-08T05:40:45+00:00',
    endTime: '2023-12-08T06:44:21+00:00'
  },
  {
    sessionId: '3c0bb363-8485-4809-a88b-ca3c018cc695',
    id: '1',
    parentName: 'Maintenance no auto',
    name: 'Mainten',
    startTime: '2023-12-08T06:44:21+00:00',
    endTime: '2023-12-08T09:58:42+00:00'
  },
  {
    sessionId: '3c0bb363-8485-4809-a88b-ca3c018cc695',
    id: '4',
    parentName: 'Sterilisation',
    name: 'Steril',
    startTime: '2023-12-08T09:58:42+00:00',
    endTime: '2023-12-08T12:19:11+00:00'
  },
  {
    sessionId: '3c0bb363-8485-4809-a88b-ca3c018cc695',
    id: '4',
    parentName: 'Wait time',
    name: 'Wait time',
    startTime: '2023-12-08T12:19:11+00:00',
    endTime: '2023-12-08T16:13:12+00:00'
  },
  {
    sessionId: '8828eee8-bd52-46d3-9e21-b60105b269e1',
    id: '1',
    parentName: 'Initial test (totals)',
    name: 'Mainten',
    startTime: '2023-12-05T09:23:13+00:00',
    endTime: '2023-12-05T10:04:09+00:00'
  },
  {
    sessionId: '8828eee8-bd52-46d3-9e21-b60105b269e1',
    id: '1',
    parentName: 'Maintenance no auto',
    name: 'Mainten',
    startTime: '2023-12-05T10:04:09+00:00',
    endTime: '2023-12-05T11:42:15+00:00'
  },
  {
    sessionId: '8828eee8-bd52-46d3-9e21-b60105b269e1',
    id: '3',
    parentName: 'CIP Alkaline & Acid & HPV',
    name: 'Cleaning',
    startTime: '2023-12-05T11:42:15+00:00',
    endTime: '2023-12-05T13:18:50+00:00'
  },
  {
    sessionId: '8828eee8-bd52-46d3-9e21-b60105b269e1',
    id: '1',
    parentName: 'Maintenance no auto',
    name: 'Mainten',
    startTime: '2023-12-05T13:18:50+00:00',
    endTime: '2023-12-05T14:44:05+00:00'
  },
  {
    sessionId: '8828eee8-bd52-46d3-9e21-b60105b269e1',
    id: '3',
    parentName: 'External Cleaning No Sprayer',
    name: 'Cleaning',
    startTime: '2023-12-05T14:44:05+00:00',
    endTime: '2023-12-05T15:16:46+00:00'
  },
  {
    sessionId: '8828eee8-bd52-46d3-9e21-b60105b269e1',
    id: '3',
    parentName: 'External Cleaning No Sprayer',
    name: 'Cleaning',
    startTime: '2023-12-05T15:16:46+00:00',
    endTime: '2023-12-05T15:49:41+00:00'
  },
  {
    sessionId: '8828eee8-bd52-46d3-9e21-b60105b269e1',
    id: '1',
    parentName: 'Maintenance no auto',
    name: 'Mainten',
    startTime: '2023-12-05T15:49:41+00:00',
    endTime: '2023-12-05T18:22:55+00:00'
  },
  {
    sessionId: '8828eee8-bd52-46d3-9e21-b60105b269e1',
    id: '4',
    parentName: 'Sterilisation',
    name: 'Steril',
    startTime: '2023-12-05T18:22:55+00:00',
    endTime: '2023-12-05T21:19:36+00:00'
  },
  {
    sessionId: '8828eee8-bd52-46d3-9e21-b60105b269e1',
    id: '4',
    parentName: 'Wait time',
    name: 'Wait time',
    startTime: '2023-12-05T21:19:36+00:00',
    endTime: '2023-12-05T23:28:38+00:00'
  },
  {
    sessionId: '0994a6e0-546b-40ed-b69a-ee7212fb4c35',
    id: '1',
    parentName: 'Initial test (totals)',
    name: 'Mainten',
    startTime: '2023-12-03T02:40:12+00:00',
    endTime: '2023-12-03T03:14:40+00:00'
  },
  {
    sessionId: '0994a6e0-546b-40ed-b69a-ee7212fb4c35',
    id: '1',
    parentName: 'Maintenance no auto',
    name: 'Mainten',
    startTime: '2023-12-03T03:14:40+00:00',
    endTime: '2023-12-03T06:00:15+00:00'
  },
  {
    sessionId: '0994a6e0-546b-40ed-b69a-ee7212fb4c35',
    id: '3',
    parentName: 'CIP Alkaline & Acid & HPV',
    name: 'Cleaning',
    startTime: '2023-12-03T06:00:15+00:00',
    endTime: '2023-12-03T07:37:10+00:00'
  },
  {
    sessionId: '0994a6e0-546b-40ed-b69a-ee7212fb4c35',
    id: '3',
    parentName: 'External Cleaning No Sprayer',
    name: 'Cleaning',
    startTime: '2023-12-03T07:37:10+00:00',
    endTime: '2023-12-03T08:15:01+00:00'
  },
  {
    sessionId: '0994a6e0-546b-40ed-b69a-ee7212fb4c35',
    id: '1',
    parentName: 'Maintenance no auto',
    name: 'Mainten',
    startTime: '2023-12-03T08:15:01+00:00',
    endTime: '2023-12-03T12:31:04+00:00'
  },
  {
    sessionId: '0994a6e0-546b-40ed-b69a-ee7212fb4c35',
    id: '4',
    parentName: 'Sterilisation',
    name: 'Steril',
    startTime: '2023-12-03T12:31:04+00:00',
    endTime: '2023-12-03T14:50:55+00:00'
  },
  {
    sessionId: '0994a6e0-546b-40ed-b69a-ee7212fb4c35',
    id: '4',
    parentName: 'Wait time',
    name: 'Wait time',
    startTime: '2023-12-03T14:50:55+00:00',
    endTime: '2023-12-03T15:57:33+00:00'
  },
  {
    sessionId: '8dab18c8-9a53-44fc-bdf2-762003f3b85c',
    id: '1',
    parentName: 'Initial test (totals)',
    name: 'Mainten',
    startTime: '2023-12-01T00:45:01+00:00',
    endTime: '2023-12-01T01:15:12+00:00'
  },
  {
    sessionId: '8dab18c8-9a53-44fc-bdf2-762003f3b85c',
    id: '1',
    parentName: 'Maintenance no auto',
    name: 'Mainten',
    startTime: '2023-12-01T01:15:12+00:00',
    endTime: '2023-12-01T03:01:34+00:00'
  },
  {
    sessionId: '8dab18c8-9a53-44fc-bdf2-762003f3b85c',
    id: '3',
    parentName: 'CIP Alkaline & Acid & HPV',
    name: 'Cleaning',
    startTime: '2023-12-01T03:01:34+00:00',
    endTime: '2023-12-01T04:36:20+00:00'
  },
  {
    sessionId: '8dab18c8-9a53-44fc-bdf2-762003f3b85c',
    id: '1',
    parentName: 'Maintenance no auto',
    name: 'Mainten',
    startTime: '2023-12-01T04:36:20+00:00',
    endTime: '2023-12-01T04:45:56+00:00'
  },
  {
    sessionId: '8dab18c8-9a53-44fc-bdf2-762003f3b85c',
    id: '3',
    parentName: 'External Cleaning No Sprayer',
    name: 'Cleaning',
    startTime: '2023-12-01T04:45:56+00:00',
    endTime: '2023-12-01T05:24:20+00:00'
  },
  {
    sessionId: '8dab18c8-9a53-44fc-bdf2-762003f3b85c',
    id: '1',
    parentName: 'Maintenance no auto',
    name: 'Mainten',
    startTime: '2023-12-01T05:24:20+00:00',
    endTime: '2023-12-01T08:39:59+00:00'
  },
  {
    sessionId: '8dab18c8-9a53-44fc-bdf2-762003f3b85c',
    id: '4',
    parentName: 'Sterilisation',
    name: 'Steril',
    startTime: '2023-12-01T08:39:59+00:00',
    endTime: '2023-12-01T10:54:41+00:00'
  },
  {
    sessionId: '8dab18c8-9a53-44fc-bdf2-762003f3b85c',
    id: '4',
    parentName: 'Wait time',
    name: 'Wait time',
    startTime: '2023-12-01T10:54:41+00:00',
    endTime: '2023-12-01T12:04:32+00:00'
  },
  {
    sessionId: '9571827e-4344-4780-88f5-ee1c9c3e31c4',
    id: '1',
    parentName: 'Initial test (totals)',
    name: 'Mainten',
    startTime: '2023-11-28T17:44:21+00:00',
    endTime: '2023-11-28T18:19:55+00:00'
  },
  {
    sessionId: '9571827e-4344-4780-88f5-ee1c9c3e31c4',
    id: '1',
    parentName: 'Maintenance no auto',
    name: 'Mainten',
    startTime: '2023-11-28T18:19:55+00:00',
    endTime: '2023-11-28T19:45:05+00:00'
  },
  {
    sessionId: '9571827e-4344-4780-88f5-ee1c9c3e31c4',
    id: '3',
    parentName: 'CIP Alkaline & Acid & HPV',
    name: 'Cleaning',
    startTime: '2023-11-28T19:45:05+00:00',
    endTime: '2023-11-28T21:31:29+00:00'
  },
  {
    sessionId: '9571827e-4344-4780-88f5-ee1c9c3e31c4',
    id: '3',
    parentName: 'External Cleaning No Sprayer',
    name: 'Cleaning',
    startTime: '2023-11-28T21:31:29+00:00',
    endTime: '2023-11-28T21:34:44+00:00'
  },
  {
    sessionId: '9571827e-4344-4780-88f5-ee1c9c3e31c4',
    id: '1',
    parentName: 'Maintenance no auto',
    name: 'Mainten',
    startTime: '2023-11-28T21:34:44+00:00',
    endTime: '2023-11-28T21:38:48+00:00'
  },
  {
    sessionId: '9571827e-4344-4780-88f5-ee1c9c3e31c4',
    id: '3',
    parentName: 'External Cleaning No Sprayer',
    name: 'Cleaning',
    startTime: '2023-11-28T21:38:48+00:00',
    endTime: '2023-11-28T21:43:11+00:00'
  },
  {
    sessionId: '9571827e-4344-4780-88f5-ee1c9c3e31c4',
    id: '1',
    parentName: 'Maintenance no auto',
    name: 'Mainten',
    startTime: '2023-11-28T21:43:11+00:00',
    endTime: '2023-11-28T22:09:53+00:00'
  },
  {
    sessionId: '9571827e-4344-4780-88f5-ee1c9c3e31c4',
    id: '3',
    parentName: 'External Cleaning No Sprayer',
    name: 'Cleaning',
    startTime: '2023-11-28T22:09:53+00:00',
    endTime: '2023-11-28T22:34:59+00:00'
  },
  {
    sessionId: '9571827e-4344-4780-88f5-ee1c9c3e31c4',
    id: '1',
    parentName: 'Maintenance no auto',
    name: 'Mainten',
    startTime: '2023-11-28T22:34:59+00:00',
    endTime: '2023-11-29T00:30:09+00:00'
  },
  {
    sessionId: '9571827e-4344-4780-88f5-ee1c9c3e31c4',
    id: '4',
    parentName: 'Sterilisation',
    name: 'Steril',
    startTime: '2023-11-29T00:30:09+00:00',
    endTime: '2023-11-29T02:57:41+00:00'
  },
  {
    sessionId: '9571827e-4344-4780-88f5-ee1c9c3e31c4',
    id: '4',
    parentName: 'Wait time',
    name: 'Wait time',
    startTime: '2023-11-29T02:57:41+00:00',
    endTime: '2023-11-29T09:20:38+00:00'
  },
  {
    sessionId: '85946231-a932-4429-9dd5-13b39a8a31e5',
    id: '1',
    parentName: 'Initial test (totals)',
    name: 'Mainten',
    startTime: '2023-11-18T15:44:12+00:00',
    endTime: '2023-11-18T16:18:25+00:00'
  },
  {
    sessionId: '85946231-a932-4429-9dd5-13b39a8a31e5',
    id: '1',
    parentName: 'Maintenance no auto',
    name: 'Mainten',
    startTime: '2023-11-18T16:18:25+00:00',
    endTime: '2023-11-18T17:17:16+00:00'
  },
  {
    sessionId: '85946231-a932-4429-9dd5-13b39a8a31e5',
    id: '3',
    parentName: 'CIP Alkaline & Acid & HPV',
    name: 'Cleaning',
    startTime: '2023-11-18T17:17:16+00:00',
    endTime: '2023-11-18T19:01:47+00:00'
  },
  {
    sessionId: '85946231-a932-4429-9dd5-13b39a8a31e5',
    id: '3',
    parentName: 'External Cleaning No Sprayer',
    name: 'Cleaning',
    startTime: '2023-11-18T19:01:47+00:00',
    endTime: '2023-11-18T19:25:28+00:00'
  },
  {
    sessionId: '85946231-a932-4429-9dd5-13b39a8a31e5',
    id: '1',
    parentName: 'Maintenance no auto',
    name: 'Mainten',
    startTime: '2023-11-18T19:25:28+00:00',
    endTime: '2023-11-19T00:18:10+00:00'
  },
  {
    sessionId: '85946231-a932-4429-9dd5-13b39a8a31e5',
    id: '0',
    parentName: 'Idle',
    name: 'Idle',
    startTime: '2023-11-19T00:18:10+00:00',
    endTime: '2023-11-22T11:23:48+00:00'
  },
  {
    sessionId: '85946231-a932-4429-9dd5-13b39a8a31e5',
    id: '1',
    parentName: 'Maintenance no auto',
    name: 'Mainten',
    startTime: '2023-11-22T11:23:48+00:00',
    endTime: '2023-11-24T10:59:53+00:00'
  },
  {
    sessionId: '85946231-a932-4429-9dd5-13b39a8a31e5',
    id: '0',
    parentName: 'Idle',
    name: 'Idle',
    startTime: '2023-11-24T10:59:53+00:00',
    endTime: '2023-11-26T03:14:46+00:00'
  },
  {
    sessionId: '85946231-a932-4429-9dd5-13b39a8a31e5',
    id: '1',
    parentName: 'Maintenance no auto',
    name: 'Mainten',
    startTime: '2023-11-26T03:14:46+00:00',
    endTime: '2023-11-26T03:24:43+00:00'
  },
  {
    sessionId: '85946231-a932-4429-9dd5-13b39a8a31e5',
    id: '3',
    parentName: 'CIP Alkaline & Acid & HPV',
    name: 'Cleaning',
    startTime: '2023-11-26T03:24:43+00:00',
    endTime: '2023-11-26T04:57:58+00:00'
  },
  {
    sessionId: '85946231-a932-4429-9dd5-13b39a8a31e5',
    id: '1',
    parentName: 'Maintenance no auto',
    name: 'Mainten',
    startTime: '2023-11-26T04:57:58+00:00',
    endTime: '2023-11-26T06:55:17+00:00'
  },
  {
    sessionId: '85946231-a932-4429-9dd5-13b39a8a31e5',
    id: '4',
    parentName: 'Sterilisation',
    name: 'Steril',
    startTime: '2023-11-26T06:55:17+00:00',
    endTime: '2023-11-26T09:14:31+00:00'
  },
  {
    sessionId: '85946231-a932-4429-9dd5-13b39a8a31e5',
    id: '4',
    parentName: 'Wait time',
    name: 'Wait time',
    startTime: '2023-11-26T09:14:31+00:00',
    endTime: '2023-11-26T12:33:39+00:00'
  },
  {
    sessionId: 'dd9f508a-813f-48a4-b7d0-17efe5d2e315',
    id: '1',
    parentName: 'Initial test (totals)',
    name: 'Mainten',
    startTime: '2023-11-16T02:09:50+00:00',
    endTime: '2023-11-16T02:45:43+00:00'
  },
  {
    sessionId: 'dd9f508a-813f-48a4-b7d0-17efe5d2e315',
    id: '1',
    parentName: 'Maintenance no auto',
    name: 'Mainten',
    startTime: '2023-11-16T02:45:43+00:00',
    endTime: '2023-11-16T06:19:05+00:00'
  },
  {
    sessionId: 'dd9f508a-813f-48a4-b7d0-17efe5d2e315',
    id: '3',
    parentName: 'CIP Alkaline & Acid & HPV',
    name: 'Cleaning',
    startTime: '2023-11-16T06:19:05+00:00',
    endTime: '2023-11-16T07:58:29+00:00'
  },
  {
    sessionId: 'dd9f508a-813f-48a4-b7d0-17efe5d2e315',
    id: '1',
    parentName: 'Maintenance no auto',
    name: 'Mainten',
    startTime: '2023-11-16T07:58:29+00:00',
    endTime: '2023-11-16T11:07:09+00:00'
  },
  {
    sessionId: 'dd9f508a-813f-48a4-b7d0-17efe5d2e315',
    id: '4',
    parentName: 'Sterilisation',
    name: 'Steril',
    startTime: '2023-11-16T11:07:09+00:00',
    endTime: '2023-11-16T13:26:30+00:00'
  },
  {
    sessionId: 'dd9f508a-813f-48a4-b7d0-17efe5d2e315',
    id: '4',
    parentName: 'Wait time',
    name: 'Wait time',
    startTime: '2023-11-16T13:26:30+00:00',
    endTime: '2023-11-16T14:40:54+00:00'
  },
  {
    sessionId: '98f452e3-c237-452f-9a0a-52f51a49e50b',
    id: '1',
    parentName: 'Initial test (totals)',
    name: 'Mainten',
    startTime: '2023-11-15T01:46:54+00:00',
    endTime: '2023-11-15T02:03:46+00:00'
  },
  {
    sessionId: '98f452e3-c237-452f-9a0a-52f51a49e50b',
    id: '1',
    parentName: 'Maintenance no auto',
    name: 'Mainten',
    startTime: '2023-11-15T02:03:46+00:00',
    endTime: '2023-11-15T05:05:15+00:00'
  },
  {
    sessionId: '98f452e3-c237-452f-9a0a-52f51a49e50b',
    id: '3',
    parentName: 'CIP Alkaline & Acid & HPV',
    name: 'Cleaning',
    startTime: '2023-11-15T05:05:15+00:00',
    endTime: '2023-11-15T06:56:53+00:00'
  },
  {
    sessionId: '98f452e3-c237-452f-9a0a-52f51a49e50b',
    id: '1',
    parentName: 'Maintenance no auto',
    name: 'Mainten',
    startTime: '2023-11-15T06:56:53+00:00',
    endTime: '2023-11-15T08:16:04+00:00'
  },
  {
    sessionId: '98f452e3-c237-452f-9a0a-52f51a49e50b',
    id: '3',
    parentName: 'External Cleaning No Sprayer',
    name: 'Cleaning',
    startTime: '2023-11-15T08:16:04+00:00',
    endTime: '2023-11-15T08:59:06+00:00'
  },
  {
    sessionId: '98f452e3-c237-452f-9a0a-52f51a49e50b',
    id: '1',
    parentName: 'Maintenance no auto',
    name: 'Mainten',
    startTime: '2023-11-15T08:59:06+00:00',
    endTime: '2023-11-15T10:38:37+00:00'
  },
  {
    sessionId: '98f452e3-c237-452f-9a0a-52f51a49e50b',
    id: '4',
    parentName: 'Sterilisation',
    name: 'Steril',
    startTime: '2023-11-15T10:38:37+00:00',
    endTime: '2023-11-15T12:58:13+00:00'
  },
  {
    sessionId: '98f452e3-c237-452f-9a0a-52f51a49e50b',
    id: '4',
    parentName: 'Wait time',
    name: 'Wait time',
    startTime: '2023-11-15T12:58:13+00:00',
    endTime: '2023-11-15T13:56:46+00:00'
  },
  {
    sessionId: '22c1de30-e1ed-496c-bd88-fca3a7b05db0',
    id: '1',
    parentName: 'Initial test (totals)',
    name: 'Mainten',
    startTime: '2023-11-11T13:21:34+00:00',
    endTime: '2023-11-11T13:43:23+00:00'
  },
  {
    sessionId: '22c1de30-e1ed-496c-bd88-fca3a7b05db0',
    id: '1',
    parentName: 'Maintenance no auto',
    name: 'Mainten',
    startTime: '2023-11-11T13:43:23+00:00',
    endTime: '2023-11-11T13:59:23+00:00'
  },
  {
    sessionId: '22c1de30-e1ed-496c-bd88-fca3a7b05db0',
    id: '1',
    parentName: 'Maintenance no auto',
    name: 'Mainten',
    startTime: '2023-11-11T13:59:23+00:00',
    endTime: '2023-11-11T14:50:41+00:00'
  },
  {
    sessionId: '22c1de30-e1ed-496c-bd88-fca3a7b05db0',
    id: '3',
    parentName: 'CIP Alkaline & Acid & HPV',
    name: 'Cleaning',
    startTime: '2023-11-11T14:50:41+00:00',
    endTime: '2023-11-11T16:24:25+00:00'
  },
  {
    sessionId: '22c1de30-e1ed-496c-bd88-fca3a7b05db0',
    id: '1',
    parentName: 'Maintenance no auto',
    name: 'Mainten',
    startTime: '2023-11-11T16:24:25+00:00',
    endTime: '2023-11-11T16:37:13+00:00'
  },
  {
    sessionId: '22c1de30-e1ed-496c-bd88-fca3a7b05db0',
    id: '3',
    parentName: 'External Cleaning No Sprayer',
    name: 'Cleaning',
    startTime: '2023-11-11T16:37:13+00:00',
    endTime: '2023-11-11T16:51:50+00:00'
  },
  {
    sessionId: '22c1de30-e1ed-496c-bd88-fca3a7b05db0',
    id: '1',
    parentName: 'Maintenance no auto',
    name: 'Mainten',
    startTime: '2023-11-11T16:51:50+00:00',
    endTime: '2023-11-11T17:05:29+00:00'
  },
  {
    sessionId: '22c1de30-e1ed-496c-bd88-fca3a7b05db0',
    id: '0',
    parentName: 'Idle',
    name: 'Idle',
    startTime: '2023-11-11T17:05:29+00:00',
    endTime: '2023-11-11T20:19:39+00:00'
  },
  {
    sessionId: '22c1de30-e1ed-496c-bd88-fca3a7b05db0',
    id: '1',
    parentName: 'Maintenance no auto',
    name: 'Mainten',
    startTime: '2023-11-11T20:19:39+00:00',
    endTime: '2023-11-11T20:51:23+00:00'
  },
  {
    sessionId: '22c1de30-e1ed-496c-bd88-fca3a7b05db0',
    id: '0',
    parentName: 'Idle',
    name: 'Idle',
    startTime: '2023-11-11T20:51:23+00:00',
    endTime: '2023-11-12T19:33:09+00:00'
  },
  {
    sessionId: '22c1de30-e1ed-496c-bd88-fca3a7b05db0',
    id: '1',
    parentName: 'Maintenance no auto',
    name: 'Mainten',
    startTime: '2023-11-12T19:33:09+00:00',
    endTime: '2023-11-12T19:37:12+00:00'
  },
  {
    sessionId: '22c1de30-e1ed-496c-bd88-fca3a7b05db0',
    id: '3',
    parentName: 'CIP Alkaline & Acid & HPV',
    name: 'Cleaning',
    startTime: '2023-11-12T19:37:12+00:00',
    endTime: '2023-11-12T23:45:18+00:00'
  },
  {
    sessionId: '22c1de30-e1ed-496c-bd88-fca3a7b05db0',
    id: '0',
    parentName: 'Idle',
    name: 'Idle',
    startTime: '2023-11-12T23:45:18+00:00',
    endTime: '2023-11-13T05:13:44+00:00'
  },
  {
    sessionId: '22c1de30-e1ed-496c-bd88-fca3a7b05db0',
    id: '4',
    parentName: 'Sterilisation',
    name: 'Steril',
    startTime: '2023-11-13T05:13:44+00:00',
    endTime: '2023-11-13T07:34:48+00:00'
  },
  {
    sessionId: '22c1de30-e1ed-496c-bd88-fca3a7b05db0',
    id: '4',
    parentName: 'Wait time',
    name: 'Wait time',
    startTime: '2023-11-13T07:34:48+00:00',
    endTime: '2023-11-13T11:24:28+00:00'
  },
  {
    sessionId: 'f67145e9-ed9c-4460-b610-8b5ce8473835',
    id: '1',
    parentName: 'Initial test (totals)',
    name: 'Mainten',
    startTime: '2023-11-10T19:11:12+00:00',
    endTime: '2023-11-10T19:33:44+00:00'
  },
  {
    sessionId: 'f67145e9-ed9c-4460-b610-8b5ce8473835',
    id: '1',
    parentName: 'Maintenance no auto',
    name: 'Mainten',
    startTime: '2023-11-10T19:33:44+00:00',
    endTime: '2023-11-10T20:28:11+00:00'
  },
  {
    sessionId: 'f67145e9-ed9c-4460-b610-8b5ce8473835',
    id: '3',
    parentName: 'CIP Alkaline & Acid & HPV',
    name: 'Cleaning',
    startTime: '2023-11-10T20:28:11+00:00',
    endTime: '2023-11-10T22:03:40+00:00'
  },
  {
    sessionId: 'f67145e9-ed9c-4460-b610-8b5ce8473835',
    id: '3',
    parentName: 'External Cleaning No Sprayer',
    name: 'Cleaning',
    startTime: '2023-11-10T22:03:40+00:00',
    endTime: '2023-11-10T22:32:56+00:00'
  },
  {
    sessionId: 'f67145e9-ed9c-4460-b610-8b5ce8473835',
    id: '1',
    parentName: 'Maintenance no auto',
    name: 'Mainten',
    startTime: '2023-11-10T22:32:56+00:00',
    endTime: '2023-11-10T23:50:19+00:00'
  },
  {
    sessionId: 'f67145e9-ed9c-4460-b610-8b5ce8473835',
    id: '4',
    parentName: 'Sterilisation',
    name: 'Steril',
    startTime: '2023-11-10T23:50:19+00:00',
    endTime: '2023-11-11T02:03:34+00:00'
  },
  {
    sessionId: 'f67145e9-ed9c-4460-b610-8b5ce8473835',
    id: '4',
    parentName: 'Wait time',
    name: 'Wait time',
    startTime: '2023-11-11T02:03:34+00:00',
    endTime: '2023-11-11T03:39:25+00:00'
  }
];
