import React, { FC, ReactNode } from 'react';
import { GeneratorTooltipContainer } from './GeneratorTooltip.elements';

import { useChartGeneratorTooltip } from '../../hooks/useChartGeneratorToolip/useChartGeneratorTooltip';
import { useChartGenerator } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { ColoredGroupLabel } from '../../components/ColoredGroupLabels.elements';

export interface GeneratorTooltipProps {
  x?: number;
  y?: number;
  left?: string;
  centerLeft?: number;
  centerTop?: number;
  top?: string;
  bottom?: string;
  right?: string;
  title?: ReactNode;
  InnerComponent?: ReactNode;
  format?: {
    title?: (x?: Record<string, unknown>) => ReactNode;
    innerComponent?: (x?: Record<string, unknown>) => ReactNode;
  };
}

export const GeneratorTooltip = (): JSX.Element => {
  const { t } = useTranslation(['mh']);
  const { format, Components, groupKey, valueKey, categoryKey, colors, dataToIndex } =
    useChartGenerator();
  const { tooltip } = useChartGeneratorTooltip();

  if (!tooltip) return <></>;

  const { centerLeft, centerTop, left, top, x, y, ...rest } = (tooltip || {}) as Record<
    string,
    unknown
  >;

  const data = rest?.data as Record<string, unknown>;

  const TooltipComponent = (tooltip?.Component || Components?.Tooltips) as FC<
    Record<string, unknown>
  >;
  const tooltipFormatter = format?.tooltips;

  const category: ReactNode = data?.[categoryKey as string] || data?.category || tooltip?.category;

  let title: ReactNode = category;
  let group: ReactNode = data?.[groupKey as string] || data?.group || tooltip?.group;
  let color: string | undefined = (data?.color || colors?.[group as string]) as string;
  let value: ReactNode = data?.[valueKey as string] || data?.value || tooltip?.value;

  if (typeof title === 'string') title = t(title);
  if (typeof group === 'string') group = t(group);

  const indexedItem = ((dataToIndex as Record<string, unknown>[]) || [])[data?.dataIndex as number];
  const InnerComponent = tooltipFormatter?.({
    innerArea: {
      color,
      ColoredGroupLabel,
      group,
      indexedItem,
      data
    }
  });

  group = tooltipFormatter?.({ group, data }) || group;
  title = tooltipFormatter?.({ title, category, data }) || title;
  color = (tooltipFormatter?.({ color, data }) || color) as string | undefined;
  value = tooltipFormatter?.({ value, data }) || value;

  if (typeof value === 'number') value = value.toLocaleString();

  let leftPos = `${centerLeft || left || x}px`;
  let topPos = `${centerTop || top || y}px`;

  leftPos = (tooltipFormatter?.({ left: leftPos, data }) || leftPos) as string;
  topPos = (tooltipFormatter?.({ top: topPos, data }) || topPos) as string;

  const containerSettings = {
    className: 'generator-tooltip-wrapper',
    left: leftPos,
    top: topPos
  };

  return (
    <GeneratorTooltipContainer {...containerSettings}>
      <div className="tooltip__inner">
        {TooltipComponent ? (
          <TooltipComponent {...{ title, category, data, indexedItem, ColoredGroupLabel }} />
        ) : (
          <>
            {title && <div className="tooltip__title">{title}</div>}
            <div className="tooltip__content">
              {typeof group === 'string' && typeof value === 'string' && (
                <ColoredGroupLabel clr={color}>
                  <span>{group}</span> - <span>{value}</span>
                </ColoredGroupLabel>
              )}
              {InnerComponent}
            </div>
          </>
        )}
      </div>
    </GeneratorTooltipContainer>
  );
};
