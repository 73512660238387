import React from 'react';
import { useChartGenerator } from '../../../hooks';
import { SVGContainer, Path } from './LineChart.elements';
import { useChartSelections } from '../../../hooks/useChartSelections';

export const LinePath = ({
  path,
  group,
  color,
  data,
  groupKey
}: {
  groupKey?: string;
  path?: string;
  data?: Record<string, unknown>[];
  group?: string;
  color?: string;
}): JSX.Element => {
  const { colors, getScale, size, ...keys } = useChartGenerator();
  const selectHandler = useChartSelections()[1];

  if (!data && !path) return <></>;

  color = ((color || data?.[0]?.color || colors?.[group as string]) as string) || keys?.color;
  path = path || (getScale('linePath', { data }) as string);
  groupKey = groupKey || (data?.[0]?.groupKey as string) || keys?.groupKey;

  const formattedGroupId = group ? String(group).split(' ').join('-') : undefined;

  let className = `generated-line-path line-path group--${formattedGroupId}`;

  const isFiltered = groupKey
    ? selectHandler('checkIfFiltered', { [groupKey as string]: group })
    : false;

  if (isFiltered) className += ' is-filtered';
  else className += ' is-not-filtered';

  const pathSettings = {
    className,
    stroke: color,
    size,
    d: path
  };

  return <Path {...pathSettings} />;
};

export const LineChart = (): JSX.Element => {
  const { width, height, valueKeys, indexedData, groupKeys, getScale } = useChartGenerator();

  const className = `genrated-chart line-chart-svg generated-line-chart`;

  if (valueKeys || groupKeys) {
    const groupedData = getScale('groupedData') as Record<string, Record<string, unknown>[]>;

    if (!groupedData) return <></>;

    return (
      <SVGContainer {...{ className, width, height }}>
        {Object.entries(groupedData).map(([group, data]) => (
          <LinePath key={group} {...{ data, group }} />
        ))}
      </SVGContainer>
    );
  } else {
    return (
      <SVGContainer {...{ className, width, height }}>
        <LinePath {...{ data: indexedData as Record<string, unknown>[] }} />
      </SVGContainer>
    );
  }
};
