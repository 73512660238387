import { styledTheme } from 'components';
import styled from 'styled-components';

export const SessionSelectButtonContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  align-items: center;
  grid-gap: 1em;

  button {
    display: flex;
    align-items: center;
    font-family: inherit;
    font-size: 13px;
    cursor: pointer;
    text-align: left;
    transition: color 300ms ease;
    gap: 1em;
    padding: 1em;
    border: none;
    box-shadow: 0 0.0625rem 0.375rem 0 rgb(0 0 0 / 15%);

    background-color: ${styledTheme.color.neg};
    color: ${styledTheme.color.main};
    border-radius: ${styledTheme.radius.base};

    &:hover {
      color: ${styledTheme.color.secondary};
    }

    .date-col {
      padding: 0.5em 0 0.3em 0;

      &.pad-l {
        margin-left: 5px;
      }

      .date-col__label {
        font-weight: 300;
        font-size: 0.8em;
        opacity: 0.7;
      }
    }

    .icon-calendar {
      transform: translateY(-0.09em);
    }
  }
`;
