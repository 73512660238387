import { useGetAsepticMachineHealthAlarmByLaneQuery } from 'api';
import { groupByKey } from 'common/helpers/dataGroupers';
import { useRouter } from 'common/hooks/useRouter';
import { useBuSettings } from 'common/pages/fleetV2/providers';
import { fleetPageRoute } from 'common/pages/fleetV2/settings/fleetPageRoutes';

import { useWidgetDateRange } from 'common/ui/WidgetUi/providers/useWidgetDateRange';
import { ReactNode } from 'react';

//import { convertToStackedChartData } from 'common/components';

export const useLaneAlarmsWidget = (): // dateRange: DateRangeProps | Record<string, string>
{
  data?: Record<string, unknown>[];
  isLoading?: boolean;
  hasError?: ReactNode;
  hasMessage?: ReactNode;
  processedData?: Record<string, Record<string, unknown>>;
  [key: string]: unknown;
} => {
  const { id } = useRouter(fleetPageRoute) as { id: string };
  const { colors } = useBuSettings();
  const { startTime } = useWidgetDateRange().utcDateRange;

  const formattedDate = startTime.replace(/T/, ' ').replace(/\..+/, '');

  const { data, isLoading, error } = useGetAsepticMachineHealthAlarmByLaneQuery({
    machineId: id,
    start_Datetime: formattedDate
  });

  const hasError = error ? 'error getting KPI Data in ThroughputEfficiencyWidget' : undefined;

  const hasMessage =
    (!isLoading && !hasError && !data) || data?.length === 0 ? 'no data available' : undefined;

  const laneTotalsObj = data?.reduce((acc: Record<string, unknown>, item) => {
    const group = item?.location as string;

    const value = Number(item?.alarmCount) as number;

    if (group && value) {
      if (!acc[group]) acc = { ...acc, [group]: 0 };
      acc[group] = Number(acc[group]) + value;
    }

    return acc;
  }, {});

  const laneTotalsArr =
    laneTotalsObj &&
    Object.entries(laneTotalsObj)
      .map(([group, value]) => ({
        group,
        value
      }))
      .sort((a, b) => (b.value as number) - (a.value as number));

  const lanesToUse = laneTotalsArr?.slice(0, 5).reduce((acc: Record<string, boolean>, item) => {
    if (item?.group) acc[item.group] = true;
    return acc;
  }, {});

  const chartData =
    lanesToUse &&
    data?.reduce((acc: Record<string, unknown>[], item) => {
      const group = item?.location as string;

      if (lanesToUse?.[group])
        acc = [
          ...acc,
          {
            category: item?.location,
            group: item?.type.toLowerCase(),
            value: item?.alarmCount,
            color: colors?.[(item?.type as string).toLowerCase()]
          }
        ];

      return acc;
    }, []);

  // make an object where the l
  const groupedByLaneData = groupByKey({ data: chartData, key: 'category' });

  return {
    chartData,
    data,
    hasError,
    hasMessage,
    isLoading,
    groupedByLaneData
  };
};
