import React from 'react';
import { ViewContainer } from './View.elements';
import { useSiteInfo } from 'common/pages/fleet/hooks/useSiteInfo';

export const AlertsView = (): JSX.Element => {
  const { businessUnit } = useSiteInfo();
  return (
    <ViewContainer className="alerts-container content-padding">
      {businessUnit} alerts
    </ViewContainer>
  );
};
