import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  CategoryName,
  CollapseHeader,
  CollapseMenu,
  DeleteIcon,
  EmptyData,
  Filter,
  Heading,
  LoaderContainer,
  Results
} from '../documentation.elements';
import { Button, Typography } from 'components';
import { useTranslation } from 'react-i18next';
import { useGetDocumentCategoryQuery, useGetFpsMachineDocumentQuery } from 'api';
import { MachineIcon, getDocIcons } from 'icons/docsIcons';
import {
  MenuPanel,
  expandIcon
} from 'pages/MaintenanceServiceDashboard/MaintenanceQueryTableByFrequency';
import Collapse from 'rc-collapse';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Documents } from 'types/machine-management';
import BookmarkDocItem from 'common/components/BookmarkDocItem';
import CustomLoader from 'components/CustomLoader';
import { IcoNoFiles } from 'icons/IcoNoFiles';
import theme from 'themes';
import { useHistory } from 'react-router-dom';
import { IcoChevRight } from 'icons';
import { ChipItem, ChipList } from 'components/CartFulfillmentDetails/Chip';
import { IcoClose } from 'icons/IcoClose';
import { JBTRoutes } from 'constants/routes';

interface FilesByMachineId {
  machineId: string;
  machineDescription?: string;
  files: Documents[];
}
type Props = {
  machineDescription?: string;
  machineDescLoading?: boolean;
  totalBookmark?: number;
};
const CategoryPage = ({
  machineDescription,
  machineDescLoading,
  totalBookmark = 0
}: Props): JSX.Element => {
  const { t } = useTranslation(['fpns']);
  const history = useHistory();
  const urlParams = new URLSearchParams(location.search);
  const machineId = urlParams.get('machineId') || '';
  const categoryId = urlParams.get('categoryId');
  const previousCategoryId = useRef(categoryId);

  const { data: docsCategories = [] } = useGetDocumentCategoryQuery({
    machineId: machineId || ''
  });

  const [activePanelKeys, setActivePanelKeys] = useState<React.Key[]>([]);
  const [dataFetching, setIsDataFetching] = useState(false);

  const {
    data: documentsList,
    isLoading: isFileLoading,
    isFetching
  } = useGetFpsMachineDocumentQuery(
    categoryId
      ? {
          machine_id: machineId,
          category_id: categoryId
        }
      : skipToken
  );

  useEffect(() => {
    if (categoryId === previousCategoryId.current) {
      setIsDataFetching(false);
    } else {
      setIsDataFetching(isFetching);
    }
  }, [categoryId, isFetching]);

  const docs = useMemo(() => {
    const filesByMachineId: { [key: string]: FilesByMachineId } = {};
    if (documentsList) {
      documentsList.forEach((file) => {
        const { machineId = '', machineDescription = '' } = file;
        if (!filesByMachineId[machineId]) {
          filesByMachineId[machineId] = {
            machineId: machineId,
            machineDescription,
            files: []
          };
        }
        filesByMachineId[machineId].files.push(file);
      });
      return Object.values(filesByMachineId);
    } else return [];
  }, [documentsList]);

  const category = docsCategories.find((category) => {
    return category.id === categoryId;
  });
  const Icon = getDocIcons(category?.name || 'how_to_user_guide');

  return (
    <>
      <Heading>
        <Typography size={'1.5rem'} weight="semi-bold">
          {t('library')}
        </Typography>
      </Heading>
      {machineId && (
        <Filter>
          {machineDescLoading ? (
            <CustomLoader size="2rem" thickness=".3rem" />
          ) : (
            <ChipList width="auto">
              <ChipItem width="auto">{machineDescription}</ChipItem>
              <DeleteIcon onClick={() => history.push(JBTRoutes.documentation)}>
                {IcoClose()}
              </DeleteIcon>
            </ChipList>
          )}
        </Filter>
      )}
      <Results flex>
        <div onClick={() => history.push(JBTRoutes.documentation)}>Back</div>{' '}
        <IcoChevRight width={7} /> {t('showings')} <span>1~1</span> {t('of_all')} <span>1</span>{' '}
        {t('categories')}
      </Results>

      {category && (
        <CategoryName>
          <Icon width="2rem" />
          <Typography mb="0" size={'1.25rem'} weight="semi-bold">
            {t(category.name)}
          </Typography>
        </CategoryName>
      )}

      {isFileLoading || dataFetching ? (
        <LoaderContainer>
          <CustomLoader size="3rem" thickness=".3rem" />
        </LoaderContainer>
      ) : docs.length ? (
        <CollapseMenu>
          <Typography className="machine" size={'1.25rem'} weight="semi-bold">
            {t('machine')}
          </Typography>
          <Collapse
            destroyInactivePanel={true}
            activeKey={activePanelKeys}
            expandIcon={({ ...props }) => expandIcon({ color: theme.colors.darkGrey, ...props })}
            onChange={(key: React.Key | React.Key[]) => {
              setActivePanelKeys(Array.isArray(key) ? key : [key]);
            }}
          >
            {docs.map((panel) => {
              const isActive = activePanelKeys.includes(panel.machineId || '');
              const { files } = panel;
              return (
                <MenuPanel
                  showArrow={true}
                  key={panel.machineId}
                  header={
                    <CollapseHeader>
                      <MachineIcon className="machineIcon" />
                      <Typography mb="0">{panel.machineDescription}</Typography>
                    </CollapseHeader>
                  }
                  mb={0}
                >
                  {isActive && (
                    <div>
                      {files.map((file) => {
                        return (
                          <BookmarkDocItem
                            key={file.id}
                            totalBookmark={totalBookmark}
                            file={file}
                          />
                        );
                      })}
                    </div>
                  )}
                </MenuPanel>
              );
            })}
          </Collapse>
        </CollapseMenu>
      ) : (
        <EmptyData>
          <IcoNoFiles className="emptyIcon" />
          <Typography weight="semi-bold">Nothing... no files to show... </Typography>
          <Button
            borderRadius="3rem"
            variant="hover-blue"
            bgColor={theme.colors.primaryBlue5}
            width="fit-content"
            onClick={() => history.push(JBTRoutes.documentation)}
            className="backBtn"
          >
            {t('back_to_doc_center')}
          </Button>
        </EmptyData>
      )}
    </>
  );
};

export default CategoryPage;
