import React, { useState } from 'react';
import { RecipeSection, RecipesTableContainer } from './elements';
import { NewBaseTable } from 'components';
import { useTranslation } from 'react-i18next';
import { TableCell } from './ui/TableCell';
import { EditCell } from './ui/EditCell';
import { TableColumnConfigs } from 'types';
import { CellContext } from '@tanstack/react-table';
import { ChevronDown } from 'icons/IconChevronDown';

export interface RecipeType {
  id: string;
  salesforceMachineId: string;
  recipeName: string;
  numberOfImpressions: number;
  targetPacksPerMinute: number;
  color: string;
}

interface RecipesTableProps extends TableColumnConfigs {
  data?: RecipeType[];
}

export const RecipesTable = ({ data }: RecipesTableProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const { t } = useTranslation(['mh']);

  const columns = [
    {
      id: 'recipeName',
      header: t('Recipe Name'),
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => cellValue.getValue()
    },
    {
      id: 'numberOfImpressions',
      header: t('Number Of Impressions'),
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => cellValue.getValue()
    },
    {
      id: 'targetPacksPerMinute',
      header: t('Target Packs Per Minute'),
      isEnableSorting: true,
      isSelected: true,
      cell: TableCell,
      meta: {
        type: 'number-input'
      }
    },
    {
      id: 'color',
      header: t('Recipe Color'),
      isEnableSorting: true,
      isSelected: true,
      cell: TableCell,
      meta: {
        type: 'color'
      }
    },
    {
      id: 'action',
      header: '',
      isSelected: true,
      isEnableSorting: false,
      cell: EditCell
    }
  ];

  if (!data || data.length === 0) return <></>;

  return (
    <RecipeSection className="recipe-table-section">
      <div className="title_section">
        <button
          onClick={() => setIsOpen(!isOpen)}
          type="button"
          className={`collapse_button ${isOpen ? 'open' : 'closed'}`}
        >
          <ChevronDown />
        </button>
        <h2>{t('Recipes')}</h2>
      </div>
      <small>{t('Modify recipe configuration. Admin access required.')}</small>
      <div className={`content_section ${isOpen ? 'open' : 'closed'}`}>
        <RecipesTableContainer className="recipes-table-container">
          <NewBaseTable
            newTableData={data}
            columnConfigs={columns}
            customClassName="recipes-table"
            sortState={{ id: 'recipe_name', desc: false }} // sort by name in descending order by default
            isShowColumnOptions={true}
            isShowGlobalSearch={true}
          />
        </RecipesTableContainer>
      </div>
    </RecipeSection>
  );
};
