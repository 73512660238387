import { WidgetUiBorderStyles } from 'common/ui/WidgetUi/WidgetUi.elements';
import styled from 'styled-components';
import theme from 'common/theme';

export const FlyoutItemContainer = styled.a`
  ${WidgetUiBorderStyles};

  color: inherit;
  text-decoration: none;
  padding: 1em;
  gap: 0.5em;

  .item-title {
    font-weight: bold;
    color: black;
    margin-bottom: 0.5em;
  }

  .item-rows {
    display: grid;
    grid-auto-rows: auto;
    grid-auto-flow: row;
    gap: 0.5em;
  }

  .item-row {
    display: grid;
    grid-template-columns: 5em 1fr;
    grid-gap: 0.5em;
    font-weight: 500;
    font-size: 0.9em;
  }

  .label {
    text-transform: capitalize;
    color: ${theme.colors.label};
  }
`;
