import React from 'react';
import { UiChartsAndFiltersPage } from 'components/StyledUi/StyledChartsV2/UIChartsAndFiltersPage/UiChartsAndFiltersPage';
import { UseStyledChartProps } from 'components/StyledUi/StyledChartsV2/hooks';
import { UseProteinMachineAlarmsProvider } from 'pages/ProteinMachine/hooks/useProteinMachineAlarms';
import { useApiData } from 'components/StyledUi/hooks';
import { useDateRange, DateButtonWithDropdown } from 'components';
import { styledTheme } from 'components';
import { defaultLabelStyles } from 'components/StyledUi/StyledChartsV2/StyledCharts.styles';
import { parseISO, format } from 'date-fns';
import { AlarmsViewContainer } from './Alarms.elements';
import { useTranslation } from 'react-i18next';
import { AlarmsTotalsBar } from '../components/AlarmsTotalsBar';
import { convertSecondsToTime } from 'common/helpers/dateAndTimeHelpersV2';
import styled from 'styled-components';
import { ColumnConfig } from 'components/NewBaseTable/NewBaseTable';
import { Row } from '@tanstack/react-table';
import { TableColumnConfigs } from 'types';
import { formatDate } from 'helpers';
import { useTimeZone } from 'providers';

const TableCell = styled.div<{ width?: number }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: break-word;
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
`;

export const AlarmsView = (): JSX.Element => {
  // sending the predefined colors to the charts, this will be used
  // for the bar chart and pie chart with legend.
  const alarmsColors = styledTheme.charts.proteinAlarms;
  const { t } = useTranslation(['mh']);
  const { timeZone } = useTimeZone();

  // this is the legend for the alarms pie chart that is grouped by `type`
  // keeping it separate to keep code clean
  const alarmsLegendItems = [
    {
      label: 'Critical Alarm',
      id: 'Critical Alarm'
    },
    {
      label: 'Alarm',
      id: 'Alarm'
    },
    {
      label: 'Warning Information',
      id: 'Warning Information'
    },
    {
      label: 'undefined',
      id: 'undefined'
    }
  ];

  //------------------------------------------------//----/
  // TABLES
  //------------------------------------------------//----/

  //Alarms Table columns config

  const alarmsColumnsConfig: ColumnConfig[] = [
    {
      header: `${t('start_date')}`,
      id: 'startTimestamp',
      isEnableSorting: true,
      isSelected: true,
      renderer: ({ getValue }) => {
        const startTimestamp = getValue();
        const formattedStartTimestamp = `${formatDate(
          String(startTimestamp),
          'long',
          timeZone
        )} - ${formatDate(String(startTimestamp), 'hours-minutes-seconds', timeZone)}`;
        return (
          <TableCell title={formattedStartTimestamp} width={180}>
            {formattedStartTimestamp}
          </TableCell>
        );
      }
    },
    {
      header: `${t('duration')}`,
      id: 'duration',
      isEnableSorting: true,
      isSelected: true,
      renderer: ({ getValue }) => (
        <TableCell title={getValue()} width={100}>
          {getValue() ? convertSecondsToTime(getValue()) : ''}
        </TableCell>
      )
    },
    {
      header: `${t('description')}`,
      id: 'description',
      isEnableSorting: true,
      isSelected: true,
      renderer: ({ getValue }) => (
        <TableCell title={getValue()} width={300}>
          {getValue()}
        </TableCell>
      )
    },
    {
      header: `${t('area')}`,
      id: 'location',
      isEnableSorting: true,
      isSelected: true,
      renderer: ({ getValue }) => (
        <TableCell title={getValue()} width={200}>
          {getValue()}
        </TableCell>
      )
    },
    {
      header: `${t('id')}`,
      id: 'code',
      isEnableSorting: true,
      isSelected: true,
      renderer: ({ getValue }) => (
        <TableCell title={getValue()} width={100}>
          {getValue()}
        </TableCell>
      )
    },
    {
      header: `${t('type')}`,
      id: 'type',
      isEnableSorting: true,
      isSelected: true,
      renderer: ({ getValue }) => (
        <TableCell title={getValue()} width={200}>
          {getValue()}
        </TableCell>
      )
    }
  ];

  const sortState = {
    id: 'startTimestamp',
    desc: true
  };

  // alarms summary table
  const tableAlarmsSummary = {
    sortState: sortState,
    columnsConfig: alarmsColumnsConfig,
    getRowStyles: (row: Row<TableColumnConfigs>) => {
      if (!row.getValue('duration')) {
        return { background: 'rgb(245, 158, 12)' };
      }
      return {};
    }
  };

  //------------------------------------------------//----/
  // CHARTS
  //------------------------------------------------//----/

  // bar chart over time - grouped by `type` and stacked by `date`
  const chartAlarmsOverTime: UseStyledChartProps = {
    colors: alarmsColors,
    type: 'bar-over-time',
    groupKey: 'type',
    categoryKey: 'date',
    title: 'Stacked Bar Chart Demo',
    //filteredByKeys: ['code', 'date', 'type'],
    format: {
      tooltip: (d: Record<string, unknown>) => {
        const datum = d?.datum as Record<string, unknown>;
        return [
          `${datum.x === 'undefined' ? 'Undefined' : datum.x}: ${datum.count}`,
          ` \n `,
          `${Math.round(Number(datum.count))}`
        ];
      }
    },
    tooltipStyles: [
      defaultLabelStyles,
      { fontSize: 5 },
      { ...defaultLabelStyles, fontSize: 15, fontWeight: 500 }
    ]
  };

  // pie chart with legend - grouped by `type`
  const chartAlarmsByTpe: UseStyledChartProps = {
    colors: alarmsColors,
    type: 'pie',
    groupKey: 'type',
    title: 'Pie Chart By Type',
    hasLegend: true,
    legendSettings: {
      items: alarmsLegendItems
    },
    //filteredByKeys: ['date', 'type', 'code'],
    format: {
      tooltip: (d: Record<string, unknown>) => {
        const datum = d?.datum as Record<string, unknown>;
        return [
          `${datum.x === 'undefined' ? 'Undefined' : datum.x}: ${datum.count}`,
          ` \n `,
          `${Math.round(Number(datum.y))}%`
        ];
      },
      label: (d: Record<string, unknown>) => {
        const datum = d?.datum as Record<string, unknown>;
        return [`${datum.y}%`];
      }
    },
    tooltipStyles: [
      defaultLabelStyles,
      { fontSize: 5 },
      { ...defaultLabelStyles, fontSize: 15, fontWeight: 500 }
    ]
  };

  // pie chart  - grouped by `code`
  const chartAlarmsByCode: UseStyledChartProps = {
    type: 'pie',
    groupKey: 'code',
    title: 'Pie Chart By Code',
    //filteredByKeys: ['code', 'date', 'type'],
    format: {
      tooltip: (d: Record<string, unknown>) => {
        const datum = d?.datum as Record<string, unknown>;

        let desc = datum.description as string;
        desc = desc.length > 40 ? desc.slice(0, 40) + '...' : desc;

        return [
          `Code ${datum.x === 'undefined' ? 'Undefined' : datum.x}: ${datum.count}`,
          ` \n `,
          `${desc}`,
          ` \n `,
          `${Math.round(Number(datum.y))}%`
        ];
      },
      label: (d: Record<string, unknown>) => {
        const datum = d?.datum as Record<string, unknown>;
        return [`${datum.y}%`];
      }
    },
    tooltipStyles: [
      defaultLabelStyles,
      { fontSize: 5 },
      { ...defaultLabelStyles, fontSize: 13 },
      { fontSize: 5 },
      { ...defaultLabelStyles, fontSize: 16, fontWeight: 500 }
    ]
  };

  // this has to render AFTER the provider has Loaded
  const Provided = () => {
    const { t } = useTranslation(['mh']);

    const { data, hasMessage, isLoading, hasError } = useApiData();
    const filteredAlarmData = data && data.filter((AlarmData) => AlarmData.location !== 'Hidden');

    const { startTime, endTime } = useDateRange().isoDateRange;

    const start = format(parseISO(startTime), 'P');
    const end = format(parseISO(endTime), 'P');

    chartAlarmsOverTime.title = t('alarm_occurrence_by_day') as string;
    chartAlarmsByTpe.title = t('alarms by type') as string;
    chartAlarmsByCode.title = t('alarms by code') as string;

    const csvSettings = {
      data: filteredAlarmData,
      hasMessage,
      isLoading,
      hasError,
      fileName: `machine-alarms--${start}-to-${end}.csv`
    };

    const chartSettings = {
      charts: [chartAlarmsOverTime, chartAlarmsByTpe, chartAlarmsByCode],
      tables: [tableAlarmsSummary],
      csvSettings,
      data: filteredAlarmData,
      hasMessage,
      isLoading,
      hasError,
      isProteinMachine: true
    };

    return (
      <UiChartsAndFiltersPage {...chartSettings} usesFilteredData>
        <AlarmsTotalsBar />
      </UiChartsAndFiltersPage>
    );
  };

  // we first load the provider, then we can load the charts and filters page and send it the data via
  // provider.  At this point, we can add the date picker button and wrapping div.
  const { dateRange, setDateRange } = useDateRange();
  return (
    <UseProteinMachineAlarmsProvider>
      <AlarmsViewContainer>
        <div className="alarms-view__date-picker">
          <DateButtonWithDropdown {...{ dateRange, setDateRange }} />
        </div>
        <Provided />
      </AlarmsViewContainer>
    </UseProteinMachineAlarmsProvider>
  );
};
