import React from 'react';
import { useMachineInfo } from 'common/pages/fleetV2/providers';
import { DateRangeProviderV2 } from 'components/StyledUi/DateRange/hooks/useDateRangeV2';
import { useMemo } from 'react';
import { DataManagement } from './hooks/useDataManagement';
import { LiveChartAPIProvider } from './hooks/useLiveDataProviderV2';
import { LiveChartWidget } from './LiveChartWidget';

export const LiveChart = (): JSX.Element => {
  const { id, timeZone } = useMachineInfo() as { id: string; timeZone: string };

  const widgetContent = useMemo(() => {
    return (
      <DateRangeProviderV2 timeZone={timeZone} subtractHoursCount={4}>
        <LiveChartAPIProvider timeZone={timeZone} machineId={id}>
          <DataManagement>
            <LiveChartWidget />
          </DataManagement>
        </LiveChartAPIProvider>
      </DateRangeProviderV2>
    );
  }, [id]);

  return <>{widgetContent}</>;
};
