import { useChangeoverSessionsByDateRangeAPIProvider } from 'common/pages/fleetV2/machine/aseptic/providers/useChangeoverSessionsByDateRangeAPIProvider';
import { useState } from 'react';
import { sortSessionsByDateRangeData } from '../helpers';

export const useSelectSessionFlyout = (): Record<string, unknown> => {
  const { data, isLoading, hasError, hasMessage } = useChangeoverSessionsByDateRangeAPIProvider();
  const [order, setOrder] = useState<string>('date by descending');

  const sortedData = data && sortSessionsByDateRangeData(data, order as string);

  return { data: sortedData, order, setOrder, isLoading, hasError, hasMessage };
};
