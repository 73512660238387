import React from 'react';
import { ViewContainer } from './View.elements';
import { FeedFactorWidget } from '../components/FeedFactorWidget';
import { MachineOverviewWidget } from '../components/MachineOverviewWidget';
import { DowntimeWidget } from '../components/DowntimeWidget/DowntimeWidget';
import { OEEMetricsWidget } from '../components/OEEMetricsWidget';
import { UseDowntimeDataProvider } from '../hooks/useDowntimeData';
import { LiveChart } from '../components/LiveGraphWidgetV2/Provided';

const DowntimeProvider = () => (
  <UseDowntimeDataProvider>
    <DowntimeWidget />
  </UseDowntimeDataProvider>
);

export const OverviewView = (): JSX.Element => {
  return (
    <ViewContainer className="overview view-container">
      <MachineOverviewWidget />
      <FeedFactorWidget />
      <DowntimeProvider />
      <LiveChart />
      <OEEMetricsWidget />
    </ViewContainer>
  );
};
