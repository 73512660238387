import React from 'react';
//import { testData } from './data';
import { NewBaseTable } from 'components';
import { ColumnConfig } from 'components/NewBaseTable/NewBaseTable';
import { generateIcon } from './utils';
import { ProgrammedStepsContainer } from './elements';
import { CellContext } from '@tanstack/react-table';
import { TableColumnConfigs } from 'types';
import { useTranslation } from 'react-i18next';
import { useFleetRouter } from 'common/pages/fleetV2/hooks/useFleetRouter';
import { formatDuration } from 'helpers/dates';

export const StepsTable = ({ data }: Record<string, unknown>[]): JSX.Element => {
  const { t } = useTranslation(['mh']);
  const handle = useFleetRouter()[1];

  const generateColumnConfigsNewTable = (): ColumnConfig[] => {
    return [
      {
        id: 'name',
        header: t('Programmed Steps') as string,
        isEnableSorting: true,
        isSelected: true,
        renderer: ({ row, getValue }) => (
          <div className={row.getCanExpand() ? 'parent-cell' : 'child-cell'}>
            {row.getCanExpand() ? (
              <button
                className="button-expand"
                {...{
                  onClick: row.getToggleExpandedHandler(),
                  style: { cursor: 'pointer' }
                }}
              >
                {row.getIsExpanded() ? generateIcon('chevron down') : generateIcon('chevron right')}
              </button>
            ) : (
              ' '
            )}{' '}
            {getValue()}
          </div>
        )
      },
      {
        id: 'status',
        header: t('Status') as string,
        isEnableSorting: true,
        isSelected: true,
        renderer: ({ row }) => (
          <div className="cell-status">
            {row.original.alarms.length > 0 ? (
              <>
                {generateIcon('bug')}
                <b>({row.original.alarms.length})</b>&nbsp;Issues{' '}
                <button
                  className="button-issues"
                  onClick={() => handle('updatePath', { subTab: 'session-alarms' })}
                >
                  {generateIcon('blue arrow')}
                </button>
              </>
            ) : (
              <>{generateIcon('green checkmark')}&nbsp;Complete</>
            )}
          </div>
        )
      },
      {
        id: 'duration',
        header: t('Duration') as string,
        isEnableSorting: true,
        isSelected: true,
        renderer: ({ row, getValue }) => {
          const totalDiff = row.original.actualVStargetDiff;
          const formatted = formatDuration(Math.abs(totalDiff), 'hours:mins:secs');
          const sign = (totalDiff as number) > 0 ? '+' : '-';
          return (
            <>
              {getValue()}{' '}
              {row.original.actualVStargetDiff !== undefined && (
                <b className={`target-diff ${(totalDiff as number) > 0 ? `red` : `green`}`}>
                  ({sign + formatted})
                </b>
              )}
            </>
          );
        }
      },
      {
        id: 'targetDuration',
        header: t('Target Duration') as string,
        isEnableSorting: true,
        isSelected: true,
        renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
          return cellValue.row.subRows.length > 0 && cellValue.getValue();
        }
      },
      {
        id: 'averageDuration',
        header: t('Average Duration') as string,
        isEnableSorting: false,
        isSelected: true,
        renderer: (cellValue: CellContext<TableColumnConfigs, string>) => cellValue.getValue()
      }
    ];
  };

  return (
    <ProgrammedStepsContainer className="widget-ui__main changeover-single-session--program-steps">
      <NewBaseTable
        newTableData={data}
        columnConfigs={generateColumnConfigsNewTable()}
        customClassName="changeover-program-steps"
        textAlign="left"
        tdMarginLeft="0.625rem"
        sortState={{ id: 'name', desc: false }} // sort by name in descending order by default
        isShowColumnOptions={false}
        isShowGlobalSearch={false}
        disableVirtualize={true}
        hideTotalFetchCount={true}
      />
    </ProgrammedStepsContainer>
  );
};
