import React from 'react';
import { useChartGenerator } from '../../../hooks';
import { useChartGeneratorTooltip } from '../../../hooks/useChartGeneratorToolip/useChartGeneratorTooltip';
import { PieChartContainer, SVGPieChartContainer } from './PieChart.elements';
import { CenterValues } from './CenterValues';
import { useChartSelections } from '../../../hooks/useChartSelections';
import { PieChartTooltip } from './PieChartTooltip';

export const PieChart = ({ hasHoverLayer }: { hasHoverLayer?: boolean }): JSX.Element => {
  const { getScale, width, height } = useChartGenerator();
  const pieChartSlices = getScale('pieSlices') as Record<string, unknown>[];

  const containerClassName = 'pie-chart-container';

  if (!pieChartSlices) return <>no data</>;

  const Chart = ({ isHoverLayer }: { isHoverLayer?: boolean }) => {
    let svgClassName = 'genrated-chart pie-chart-svg generated-pie-chart';
    if (isHoverLayer) svgClassName += ' hover-layer';
    return (
      <>
        <SVGPieChartContainer {...{ width, height, className: svgClassName }}>
          <g className="is-centered">
            {pieChartSlices?.map((slice, i) => (
              <PieSlicePath key={i} {...{ ...slice, isHoverLayer }} />
            ))}
          </g>
        </SVGPieChartContainer>
        {!isHoverLayer && <CenterValues />}
      </>
    );
  };

  return (
    <>
      <PieChartTooltip />
      <PieChartContainer
        {...{ width: width as number, height: height as number, className: containerClassName }}
      >
        {hasHoverLayer && <Chart isHoverLayer />}
        <Chart />
      </PieChartContainer>
    </>
  );
};

const PieSlicePath = ({
  d,
  data,
  centerLeft,
  centerTop,
  isHoverLayer,
  ...rest
}: {
  d?: string;
  data?: Record<string, unknown>;
  centerLeft?: number;
  centerTop?: number;
  isHoverLayer?: boolean;
  rest?: Record<string, unknown>;
}): JSX.Element => {
  const { color } = data || {};
  const groupKey = useChartGenerator()?.groupKey || 'group';
  const { setTooltip } = useChartGeneratorTooltip();
  const [selected, selectHandler] = useChartSelections();

  let className = 'generated-pie-slice-path pie-slice-path';

  const isSelected = selectHandler('checkIfSelected', { [groupKey]: data?.[groupKey] as string });

  if (selected && !isSelected) className += ' is-filtered';
  if (isHoverLayer) className += ' hover-slice';

  let pathSettings: Record<string, unknown> = {
    className,
    fill: (color as string) || 'red',
    d
  };

  if (isHoverLayer) {
    pathSettings = {
      ...pathSettings,
      onMouseEnter: () => setTooltip({ data, left: centerLeft, top: centerTop, ...rest }),
      onMouseLeave: () => setTooltip(undefined),
      onClick: () => selectHandler('toggle', { [groupKey]: data?.[groupKey] as string }),
      fill: 'transparent'
    };
  }

  return <path {...pathSettings} />;
};
