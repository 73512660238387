import React from 'react';

export const IcoCleaningClock = ({ color = '#0076CC' }: { color?: string }): JSX.Element => {
  return (
    <svg
      className="icon-cleaning-clock icon"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_308_30455)">
        <path
          d="M2.66675 3.33317C2.66675 3.15636 2.73699 2.98679 2.86201 2.86177C2.98703 2.73674 3.1566 2.6665 3.33341 2.6665H12.6667C12.8436 2.6665 13.0131 2.73674 13.1382 2.86177C13.2632 2.98679 13.3334 3.15636 13.3334 3.33317V12.6665C13.3334 12.8433 13.2632 13.0129 13.1382 13.1379C13.0131 13.2629 12.8436 13.3332 12.6667 13.3332H3.33341C3.1566 13.3332 2.98703 13.2629 2.86201 13.1379C2.73699 13.0129 2.66675 12.8433 2.66675 12.6665V3.33317Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 4.6665V7.99984L10 9.99984"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M2.66675 8H3.33341" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.6667 8H13.3334" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8 12.6665V13.3332" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_308_30455">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
