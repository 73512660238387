import { ReactNode, useMemo } from 'react';
import { useDSIHistoricalProductionQuery } from 'api';
import { useFleetRouter } from 'common/pages/fleetV2/hooks/useFleetRouter';
import { useBuSettings } from 'common/pages/fleetV2/providers';
import { calculateGroupTotals } from 'common/helpers/dataCounters';

/****************************************************
 * API Path
 * /v1/dsi/machine-health/56f7660f-487b-4f77-bee8-0b3aa88c5f84/historical-production?kpi_data_interval=LAST_HOUR&aggregate_by=DAY' 
 *
 * API Sample [{
    "timestamp": 1718812560,
    "product_output": 1255.9244053988534,
    "cut_weight_scan": 1521.8265685970566,
    "piece_count": 12670,
    "running_state_duration": 16139,
    "not_running_state_duration": 1800,
    "no_product_state_duration": 1079,
    "offline_state_duration": 0,
    "product_types": {
      "Portion1": {
        "sku_name": "Unassigned",
        "app_name": "5oz_Fillet_w_Nug_EZL",
        "app_type": "poultrypak3",
        "scanned_wt": 639.2655157487344,
        "piece_count": 1883
      },
      "Nugget": {
        "sku_name": "Unassigned",
        "app_name": "All_Nuggets",
        "app_type": "supershape",
        "scanned_wt": 614.2665587951277,
        "piece_count": 10785
      }
    }
  }]
*/

export const useProductionOutputWidget = (): {
  isLoading?: boolean;
  hasError?: ReactNode;
  hasMessage?: ReactNode;
  data?: Record<string, unknown>[];
  dailyDetails?: Record<string | number, Record<string, string | number>>;
  dailyProductionRuns?: Record<string | number, unknown>;
  [key: string]: unknown;
} => {
  // /v1/dsi/machine-health/56f7660f-487b-4f77-bee8-0b3aa88c5f84/historical-production?kpi_data_interval=LAST_HOUR&aggregate_by=DAY'
  //const { startTime, endTime } = useWidgetDateRange();
  const { mapKeys } = useBuSettings();
  const [{ id }] = useFleetRouter();

  const {
    data: apiData,
    isLoading,
    error
  } = useDSIHistoricalProductionQuery({
    machineId: id as string,
    kpi_data_interval: 'LAST_WEEK',
    aggregate_by: 'DAY'
  });

  const data = useMemo(() => apiData, [apiData]);

  const dailyDetails: Record<string | number, Record<string, string | number>> = {};
  const dailyProductionRuns: Record<string | number, Record<string, unknown>[]> = {};

  if (data) {
    data.forEach((item: Record<string, unknown>) => {
      const { timestamp, product_types, ...rest } = item;
      // convert to MS epoch
      const msTime = Number(timestamp) * 1000;

      if (msTime < 1) return;
      // add day to dailyDetails
      dailyDetails[msTime] = { timestamp: msTime };
      dailyProductionRuns[msTime] = [];
      // format with key mapper
      Object.entries(rest).forEach(([key, value]) => {
        const newKey = mapKeys?.[key] || key;
        dailyDetails[msTime][newKey] = value as string | number;
      });

      Object.entries(product_types || {}).forEach(([type, productDetails]) => {
        let groupType = String(type).toLowerCase();
        if (groupType !== 'nugget') groupType = 'portions';
        if (groupType === 'nugget') groupType = 'nuggets';
        groupType = mapKeys?.[groupType] || groupType;
        type = mapKeys?.[type] || type;
        const newItem = { type, chartGroup: groupType, timestamp: msTime, ...productDetails };
        dailyProductionRuns[msTime].push(newItem);
      });
    });
  }

  const flatDailyProductionRuns = Object.values(dailyProductionRuns || {}).flat();

  const totalWeight = calculateGroupTotals('chartGroup', {
    data: flatDailyProductionRuns,
    valueKey: 'scanned_wt'
  });

  const totalCount = calculateGroupTotals('chartGroup', {
    data: flatDailyProductionRuns,
    valueKey: 'piece_count'
  });

  const demoApi = {
    isLoading,
    hasError: error ? true : false,
    totalWeight,
    totalCount,
    dailyDetails,
    dailyProductionRuns
  };

  return demoApi;
};
