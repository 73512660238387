import React, { useMemo } from 'react';
import { useRouter } from 'common/hooks/useRouter';
import { MachineProductionView } from './views/MachineProduction/MachineProductionView';
import { AdminView } from './views/Admin/AdminView';
import { fleetPageRoute, fleetPageVersionPath } from '../../settings/fleetPageRoutes';
import { useHistory } from 'react-router-dom';
import { RouterTabsNav } from 'common/components';

const ViewRoutes = (): JSX.Element => {
  const { id, view, tab } = useRouter(fleetPageRoute);

  const history = useHistory();

  if (!view || (view === 'machine-production' && !tab))
    history.push(`/${fleetPageVersionPath}/${id}/machine-production/overview`);
  if (view === 'machine-production') return <MachineProductionView />;
  if (view === 'admin') return <AdminView />;

  return <div>Aseptic View 404</div>;
};

export const ViewTabs = (): JSX.Element => {
  const { id } = useRouter(fleetPageRoute);

  const tabsNavSettings: Record<string, unknown> = {
    tabs: [
      {
        label: 'Machine Production',
        // the slug for this tab, this will be used to populate url
        slug: 'machine-production',
        path: `/${fleetPageVersionPath}/${id}/machine-production/overview`
      },
      {
        label: 'Admin',
        slug: 'admin',
        path: `/${fleetPageVersionPath}/${id}/admin`
      }
    ],
    //basePath,
    // the route for this page, you can use custom as long as you're the value
    // of the current tab from the url
    pageRoute: fleetPageRoute,
    // this is the key to get the position of the tab value from the url to compare if current tab is active
    // and for the link
    pathKey: 'view',
    style: 'full-width'
  };

  const CachedTabs = useMemo(() => <RouterTabsNav {...tabsNavSettings} />, [id]);

  return CachedTabs;
};

export const ProsealView = (): JSX.Element => {
  return (
    <>
      <ViewTabs />
      <ViewRoutes />
    </>
  );
};

/*
import React from 'react';
import { useRouter } from 'common/hooks/useRouter';

import { fleetPageRoute, fleetPageVersionPath } from '../../settings/fleetPageRoutes';

import { useHistory } from 'react-router-dom';
import { MachineViewTabs } from './components/MachineViewTabs/MachineViewTabs';
import { MachineProductionView } from './views/MachineProduction/MachineProductionView';
import { AdminView } from './views/Admin/AdminView';

export const ProsealView = (): JSX.Element => {
  return (
    <>
      <MachineViewTabs />
      <Routes />
    </>
  );
};

const Routes = (): JSX.Element => {
  const { id, view, tab } = useRouter(fleetPageRoute);
  const history = useHistory();

  if (!view || (view === 'machine-production' && !tab))
    history.push(`/${fleetPageVersionPath}/${id}/machine-production/overview`);

  if (view === 'machine-production') return <MachineProductionView />;
  if (view === 'admin') return <AdminView />;

  return <div>404</div>;
};
*/
