import React from 'react';
import { Swatch } from './Swatch';
import styled from 'styled-components';
import styledTheme from 'common/theme';
import { radius } from 'common/theme/standard.theme';
import { IcoChevronDown } from 'icons/IcoChevronDown';
import { IncreaseValueArrow } from 'icons/IncreaseValueArrow';
import { DecreaseValueArrow } from 'icons/DecreaseValueArrow';

const NumberInputGroup = styled.div`
  &.number-input {
    position: relative;
    border: 1px solid ${styledTheme.colors.gray400};
    border-radius: ${radius.xs};
    width: 145px;
    height: 32px;
  }

  &.number-input,
  &.number-input * {
    box-sizing: border-box;
  }

  &.number-input button {
    outline: none;
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    width: 30px;
    cursor: pointer;
    margin: 0;
    border-left: 1px solid ${styledTheme.colors.gray400};
    padding: 0;
  }

  .plus,
  .minus {
    position: absolute;

    svg {
      width: 30px;
      transform: scale(1.5);
    }
  }

  &.number-input button.plus {
    height: 51%;
    top: 0;
    right: 0;
    border-bottom: 1px solid ${styledTheme.colors.gray400};
  }

  &.number-input button.minus {
    height: 51%;
    bottom: 0;
    right: 0;
  }
`;

const NumberInput = styled.input`
  display: inline-block;
  background-color: transparent;
  text-align: left;
  font-size: 0.85rem;
  font-weight: 400;
  color: ${styledTheme.colors.gray800};
  border: 0;
  padding: 0.45rem 0.75rem;
`;

const SwatchGroup = styled.div`
  svg {
    position: relative;
    top: -5px;
    right: -5px;
  }
`;

export const DisplayField = (value: string | number, type?: string | number): JSX.Element => {
  switch (type) {
    case 'color':
      return (
        <SwatchGroup clasName="swatch-color with-dropdown-button">
          <Swatch color={value} />
          <IcoChevronDown color={styledTheme.colors.gray400} width={18} height={18} />
        </SwatchGroup>
      );
    case 'number-input':
      // number-input type looks like disabled number input field
      return (
        <NumberInputGroup className="number-input">
          <NumberInput className="number-input" disabled={true} value={value} type="number" />
          <button className="plus">
            <IncreaseValueArrow width={7} height={7} color={styledTheme.colors.gray400} />
          </button>
          <button className="minus">
            <DecreaseValueArrow width={7} height={7} color={styledTheme.colors.gray400} />
          </button>
        </NumberInputGroup>
      );
    default:
      return <span className="display-cell">{value}</span>;
  }
};
