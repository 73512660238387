import styled from 'styled-components';

export const ChartLeftTick = styled.div``;
export const ChartTooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;

  .split-col {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 0.5em;
    min-width: max-content;
  }

  .border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    padding-bottom: 0.5em;
  }

  .label {
    opacity: 0.9;
  }
  .item {
    font-weight: 500;
  }
`;
