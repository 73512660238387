import styled from 'styled-components';
import theme from 'common/theme';

export const ChartGeneratorContainer = styled.div<{ gridRows?: string }>`
  width: 100%;
  height: 100%;
  position: relative;

  display: ${({ gridRows }) => (gridRows ? 'grid' : 'block')};
  grid-template-rows: ${({ gridRows }) => gridRows};

  .chart-padding-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    display: block;
  }

  .cover-container {
    width: 100%;
    height: 100%;
  }

  .is-filtered {
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
  }

  .is-not-filtered {
    opacity: 1;
    transition: opacity 0.1s ease-in-out;
  }

  .axis-group {
    position: absolute;

    &:hover {
      .tick-mark {
        z-index: 7;
      }

      .grid-line {
        z-index: -1;
      }
    }

    &.horizontal {
      transform: translateY(-1px);
      height: 1px;
      width: 100%;
      left: 0;

      .tick-mark {
        padding-right: 0.7em;
        left: 0;
        transform: translate(-100%, -50%);
      }
    }

    &.vertical {
      transform: translateX(-1px);
      height: 100%;
      width: 1px;
      top: 0;

      .tick-mark {
        padding-top: 1.4em;
        bottom: 0;
        transform: translate(-50%, 100%);
        text-align: center;
      }
    }

    .tick-mark {
      position: absolute;
      font-size: 0.9em;
      min-width: max-content;
      z-index: 1;

      .tooltip-icon {
        z-index: 1;
        .icon--info {
          width: 0.8em;
          height: 0.8em;
        }
      }
    }

    .grid-line {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;

      background: ${theme?.colors?.gridLine || 'rgba(0, 0, 0, 0.1)'};
    }
  }
`;

/*
width: ${({ width }) => !width ? `0` : typeof width === 'number' ? `${width}em` : `${width}`};
  height: ${({ height }) => !height ? `0` : typeof height === 'number' ? `${height}em` : `${height}`};
  */
