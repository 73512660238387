export const generateChartDataFromKeyValueObjectArray = ({
  data,
  colors,
  groupKey,
  categoryKey,
  valueKey,
  valueKeys,
  mapKeys
}: {
  data?: Record<string, unknown>[];
  colors?: Record<string, unknown>;
  categoryKey?: string;
  mapKeys?: Record<string, unknown>;
  groupKey?: string;
  valueKey?: string;
  valueKeys?: string[];
}): Record<string, unknown>[] | undefined => {
  const generateChartItem = (key: string, item: Record<string, unknown>, dataIndex: number) => {
    //const color: string | undefined = item?.color as string;

    const groupKeyToUse = groupKey || key;
    const value = item?.[key];

    let group = item?.[groupKeyToUse] as string;
    let category: string | number | undefined = undefined;

    let newItem: Record<string, unknown> = {
      valueKey: key,
      [key]: value,
      color: item?.color || colors?.[group],
      dataIndex
    };

    if (groupKey) {
      group = item?.[groupKey] as string;
      group = (mapKeys?.[group] || group) as string;

      newItem = {
        groupKey,
        [groupKey]: group,
        ...newItem
      };
    }

    if (categoryKey) {
      category = item?.[categoryKey] as string;
      category = (mapKeys?.[category] || category) as string;

      newItem = {
        categoryKey,
        [categoryKey]: category,
        ...newItem
      };
    }

    return newItem;
  };
  const c: Record<string, unknown>[] = [];
  data?.forEach((item, dataIndex) => {
    if (valueKeys) {
      valueKeys?.forEach((valueKey) => c.push(generateChartItem(valueKey, item, dataIndex)));
    } else if (valueKey) {
      c.push(generateChartItem(valueKey, item, dataIndex));
    }
  });

  return c;
};

export const generateChartDataFromObjectArrayWithGroupIDKeys = (
  valueKeys: string[],
  {
    data,
    colors,
    groupKey,
    categoryKey,
    valueKey
  }: {
    data?: Record<string, unknown>[];
    colors?: Record<string, unknown>;
    categoryKey?: string;
    mapKeys?: Record<string, unknown>;
    groupKey?: string;
    valueKey?: string;
  }
): Record<string, unknown>[] | undefined => {
  if (!data || !categoryKey) {
    return;
  }

  const chartData = (data as Record<string, unknown>[]).reduce(
    (acc: Record<string, Record<string, unknown>[]>, item, dataIndex) => {
      valueKeys.forEach((key) => {
        const valueKeyToUse = valueKey || 'value';
        const groupKeyToUse = groupKey || 'group';
        const groupIdToUse = key;
        const valueToUse = item?.[key];

        const newItem = {
          groupKey: groupKeyToUse,
          [groupKeyToUse]: groupIdToUse,
          valueKey: valueKeyToUse,
          [valueKeyToUse]: valueToUse,
          color: colors?.[groupIdToUse],
          categoryKey,
          [categoryKey]: item?.[categoryKey],
          dataIndex
        };

        if (!acc[groupIdToUse]) acc[groupIdToUse] = [];

        acc[groupIdToUse].push(newItem);
      });

      return acc;
    },
    {}
  );

  return Object.values(chartData)
    .flat()
    .sort((a, b) => Number(a?.[categoryKey as string]) - Number(b?.[categoryKey as string]));
};
