import styled, { css } from 'styled-components';
import theme from 'common/theme';

const pointerSize = 10;

const svgMarkup = `<svg  class="tooltip-marker arrow-icon" width="${pointerSize}" height="${pointerSize}" viewBox="0 0 ${pointerSize} ${pointerSize}" xmlns="http://www.w3.org/2000/svg">
  <polygon className="poly" points="${
    pointerSize / 2
  },0 ${pointerSize},${pointerSize} 0,${pointerSize}" fill="white" />
</svg>`;

// Encode the SVG string
const encodedSvg = encodeURIComponent(svgMarkup);

export const tooltipStyles = css`
  .text--capital {
    text-transform: capitalize;
  }

  .text--lrg {
    font-size: 1.1em;
  }

  .tooltip {
    position: absolute;
    bottom: 100%;
    transform: translate(-50%, -${pointerSize / 2}px);
    font-size: 0.8em;

    .icon-triangle {
      position: absolute;
      width: ${pointerSize}px;
      height: ${pointerSize}px;
      left: 50%;
      top: 100%;
      transform: rotate(180deg) translate(3px, 1px);
      path {
        filter: drop-shadow(0 -3px 2px rgba(0, 0, 0, 0.3));
      }
    }

    .tooltip__inner {
      box-shadow: ${theme.effects.boxShadow.tooltip};
      background-color: white;
      padding: 0.5em;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      gap: 0.5em;

      .tooltip__title {
        font-weight: 500;
        margin-bottom: 0.3em;
        padding-bottom: 0.3em;
        border-bottom: solid 1px rgba(0, 0, 0, 0.2);
      }
    }
  }
`;

/**
 *  demo usage
 *  <div className="tooltip">
      <div className="tooltip__inner">
        <div className="tooltip__title">
        {children}
      </div>
      <IcoTriangle fill="white" />
    </div>
 * 
 */

export const GeneratorTooltipContainer = styled.div<{
  left?: string;
  top?: string;
  className?: string;
  color?: string;
}>`
  width: 0;
  height: 0;
  position: absolute;
  z-index: 4;
  display: flex;
  justify-content: center;

  left: ${({ left }) => left};
  top: ${({ top }) => top};

  .tooltip__inner {
    width: max-content;
    position: absolute;
    flex-direction: column;
    font-size: 0.8em;
    padding: 0.5em;
    background-color: white;
    bottom: 100%;
    left: 0;
    border-radius: 5px;

    transform: translate(-50%, -${pointerSize / 2}px);
    box-shadow: ${theme.effects.boxShadow.tooltip};

    &:after {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      left: 50%;
      top: 100%;
      transform: translate(-50%, -1px) rotate(180deg);
      background-size: contain;
      background-repeat: no-repeat;
      background-color: transparent;
      filter: drop-shadow(0 -3px 2px rgba(0, 0, 0, 0.3));

      background-image: url('data:image/svg+xml,${encodedSvg}');
    }

    .centered-text {
      text-align: center;
    }
  }

  .tooltip__title {
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 0.3em;
    padding-bottom: 0.3em;
    text-transform: capitalize;
    border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  }
`;

export const DefaultPieChartTooltipInner = styled.div`
  font-size: 1em;
  .title {
    font-size: 1.2em;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 0.1em;

    color: ${theme.colors.main};
  }

  .value {
    font-size: 2em;
    font-weight: 500;

    color: ${theme.colors.main};
  }

  .data-row {
    display: flex;
    justify-content: space-between;
    width: max-content;
    gap: 0.3em;
    margin-top: 0.3em;

    &.has-color {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 0.3em;
      align-items: center;

      .color-circle {
        width: 0.7em;
        height: 0.7em;
        border-radius: 50%;
      }
    }

    .data-row-label {
      text-transform: capitalize;
      font-weight: 600;
      color: ${theme.colors.gray800};
    }

    .data-row-value {
      color: ${theme.colors.title};
    }
  }
`;
