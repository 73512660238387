import React from 'react';
import { KPIWidget, KPIWidgetProps } from 'common/components';
import { IcoClock, IcoClockWithPause } from 'icons/IcoClock';

import { useWaitTimeWidget } from './hooks/useWaitTimeWidget';

import { useTranslation } from 'react-i18next';
import { convertSecondsToTime } from 'common/helpers/dateAndTimeHelpersV2';
import { useFleetRouter } from 'common/pages/fleetV2/hooks/useFleetRouter';

export const WaitTimeWidget = (): JSX.Element => {
  const { selection } = useFleetRouter()[0];
  const { waitTime, active, ...rest } = useWaitTimeWidget();

  const { t } = useTranslation(['mh']);

  let centerCell = {
    title: <IcoClock />,
    value: t('session in progress'),
    label: ''
  };

  if (!rest?.isLoading) {
    if (!active) {
      if (!waitTime || waitTime === 0) {
        console.log(`error: inactive session is missing wait time.  session ${selection}`);
        rest.hasMessage = rest.hasMessage || true;
      } else
        centerCell = {
          title: <IcoClockWithPause />,
          value: convertSecondsToTime(waitTime as number, 'h:m'),
          label: 'duration'
        };
    }
  }

  const widgetSettings: KPIWidgetProps = {
    className: 'wait-time-widget',
    title: 'wait time',
    centerCell,
    // this sets the center icon and formatting
    type: 'wait-time',
    // change this based on column span
    textSize: 'large',
    ...rest
  };

  return <KPIWidget {...widgetSettings} />;
};
