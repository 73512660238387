import React from 'react';
import { useChartGenerator } from '../../hooks';
import { GridLineContainer } from './GridAndAxisLayer.elements';

export const GridLine = ({
  x,
  y,
  isFirst,
  isLast
}: {
  y?: string | number;
  x?: string | number;
  isFirst?: boolean;
  isLast?: boolean;
}): JSX.Element => {
  if (!x && !y) return <></>;

  const { getScale } = useChartGenerator() || {};

  const position = getScale(x ? 'x' : 'y', x || y) as number;

  const orient = x ? `vertical` : `horizontal`;
  const className = `grid-line ${orient}${isFirst ? ' is-first' : isLast ? ' is-last' : ''}`.trim();

  const settings = {
    left: x ? `${position}px` : undefined,
    top: y ? `${position}px` : undefined,
    className
  };

  return <GridLineContainer {...settings} />;
};
