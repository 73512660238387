import React from 'react';

export interface Props {
  color?: string;
  height?: string;
  width?: string;
}

export const AccountInfoIcon = ({ color, height, width }: Props): JSX.Element => {
  return (
    <svg
      width={width ? width : '16'}
      height={height ? height : '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1063_32385)">
        <path
          d="M2 4.66669C2 4.13625 2.21071 3.62755 2.58579 3.25247C2.96086 2.8774 3.46957 2.66669 4 2.66669H12C12.5304 2.66669 13.0391 2.8774 13.4142 3.25247C13.7893 3.62755 14 4.13625 14 4.66669V11.3334C14 11.8638 13.7893 12.3725 13.4142 12.7476C13.0391 13.1226 12.5304 13.3334 12 13.3334H4C3.46957 13.3334 2.96086 13.1226 2.58579 12.7476C2.21071 12.3725 2 11.8638 2 11.3334V4.66669Z"
          stroke={color ? color : '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.66667 6.66665C4.66667 7.02027 4.80714 7.35941 5.05719 7.60946C5.30724 7.8595 5.64638 7.99998 6 7.99998C6.35362 7.99998 6.69276 7.8595 6.94281 7.60946C7.19286 7.35941 7.33333 7.02027 7.33333 6.66665C7.33333 6.31302 7.19286 5.97389 6.94281 5.72384C6.69276 5.47379 6.35362 5.33331 6 5.33331C5.64638 5.33331 5.30724 5.47379 5.05719 5.72384C4.80714 5.97389 4.66667 6.31302 4.66667 6.66665Z"
          stroke={color ? color : '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 5.33331H11.3333"
          stroke={color ? color : '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 8H11.3333"
          stroke={color ? color : '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.66667 10.6667H11.3333"
          stroke={color ? color : '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1063_32385">
          <rect width={width ? width : '16'} height={height ? height : '16'} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
