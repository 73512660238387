// takes a UTC timestamp and a timezone, applies the offset to the time and adds the offset to the timestamp then returns a new ISO timestamp with zoned time
// EXAMPLE --------------
// UTC timestamp: 2023-11-15T01:50:42+00:00
// Timezone: Pacific/Honolulu (which is -10 hours from utc)

import { formatInTimeZone } from 'date-fns-tz';

// will return the string 2023-11-14T15:50:42-10:00
export const formatAsZonedTimetamp = (
  timestamp: string | Date | number,
  timeZone: string,
  format?: string
): string | undefined => {
  timeZone = timeZone || 'UTC';
  format = format || `yyyy-MM-dd'T'HH:mm:ssxxx`;

  // can't convert because there's no timeZone yet
  if (!timeZone) return undefined;
  // Format the converted date with the new time zone offset
  const formattedDate = formatInTimeZone(timestamp, timeZone, format); //format(convertedDate, "yyyy-MM-dd'T'HH:mm:ssXXX");
  // return the new formated timestamp string
  return formattedDate;
};

export const convertSecondsToTime = (
  seconds: unknown,
  formatType?: 'small' | 'hours' | 'long' | 'hr-min' | 'h:m:s' | 'h:m' | 'hrs' | string
): string => {
  if (typeof seconds === 'string') seconds = Number(seconds);
  if (typeof seconds !== 'number') return '';

  let hours: string | number = Math.floor(seconds / 3600);

  seconds -= hours * 3600;

  hours = String(hours);

  const minutes = Math.floor(seconds / 60);

  seconds -= minutes * 60;
  seconds = Math.floor(seconds);
  seconds = Number(seconds) < 1 ? 1 : seconds;

  const showMin = minutes < 10 ? `0${minutes}` : minutes;
  const showSec = Number(seconds) < 10 ? `0${seconds}` : seconds;

  let formatted = `${hours || '00'}:${showMin || '00'}:${showSec || '00'}`;

  // costom formatter, "hours:minutes:seconds" leave out the seconds to now show them and set to 0 if you don't want to use
  if (formatType?.includes(':') || formatType?.includes('#')) {
    const splitter = formatType.includes('#') ? '#' : ':';
    const [customH, customM, customS] = formatType.split(splitter);
    formatted = customH === '0' || !hours ? `` : `${hours}${customH}`;

    if (splitter === ':') formatted = `${formatted} `;
    if (customM) {
      if (customM !== '0' && minutes) formatted = `${formatted}${minutes}${customM}`;
      if (splitter === ':') formatted = `${formatted} `;
      if (customS && customS !== '0' && showSec) formatted = `${formatted}${seconds}${customS}`;
    }
  }

  if (formatType === 'hrs') {
    formatted = `${hours || `0`} hrs`;
  }

  if (formatType === 'long' || formatType === 'hr-min') {
    formatted = `${hours || minutes || seconds}`;
    if (hours) formatted += ` hr`;
    if (minutes) formatted += ` ${showMin} min`;
  }

  if (formatType === 'small') {
    formatted = ``;
    if (hours) formatted += `${hours} hr`;
    if (showMin) formatted += ` ${showMin} min`;
  }

  return formatted;
};
