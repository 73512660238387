import React from 'react';

interface IconProps {
  color?: string;
  width?: number;
  height?: number;
}

export const DecreaseValueArrow = ({
  color = '#29A429',
  width = 25,
  height = 25
}: IconProps): JSX.Element => {
  return (
    <svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_13274_15728)">
        <path
          d="M14.2403 6H13.6544C13.6146 6 13.5771 6.01953 13.5536 6.05156L11.3341 9.11094L9.11455 6.05156C9.09111 6.01953 9.05361 6 9.01377 6H8.42783C8.37705 6 8.34736 6.05781 8.37705 6.09922L11.1317 9.89687C11.2317 10.0344 11.4364 10.0344 11.5356 9.89687L14.2903 6.09922C14.3208 6.05781 14.2911 6 14.2403 6Z"
          fill={`${color}`}
        />
      </g>
      <defs>
        <clipPath id="clip0_13274_15728">
          <rect width={width} height={height} fill="white" transform="translate(7.83398 4.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
