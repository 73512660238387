import React, { createContext, useContext, useState, ReactNode } from 'react';

export interface UseTooltipReturnProps {
  tooltip?: Record<string, unknown>;
  setTooltip: (x?: Record<string, unknown>) => void;
  [key: string]: unknown;
}

// Define the default context value matching UseTooltipReturnProps
const defaultContextValue: UseTooltipReturnProps = {
  setTooltip: () => console.log('tooltip must be wrapped in TooltipProvider')
};

export interface UseTooltipProviderProps {
  groupKey?: string;
  valueKey?: string;
  categoryKey?: string;
}

interface Props extends UseTooltipProviderProps {
  children?: ReactNode;
}

const TooltipContext = createContext<UseTooltipReturnProps>(defaultContextValue);

export const useChartGeneratorTooltip = (): UseTooltipReturnProps => useContext(TooltipContext);

export const UseChartGeneratorTooltipProvider = ({ children }: Props): JSX.Element => {
  const [tooltip, setTooltip] = useState<Record<string, unknown> | undefined>(undefined);

  return (
    <TooltipContext.Provider value={{ tooltip, setTooltip }}>{children}</TooltipContext.Provider>
  );
};
