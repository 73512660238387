import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
  AlertsContainer,
  AlertsTabHeaderWrapper,
  ShowingLatestWrapper,
  StatusPill
} from './Alerts.elements';
import { UseProteinMachineAlertsProvider } from 'pages/ProteinMachine/hooks/useProteinMachineAlerts';
import { AlertsTable } from './AlertsTable';
import { useHistory } from 'react-router-dom';
import { usePermissions } from 'hooks';
import { DateButtonWithDropdown, useDateRange } from 'components';
import { useTranslation } from 'react-i18next';
import { useApiData } from 'components/StyledUi/hooks';
import CustomLoader from 'components/CustomLoader';
import { toISO8601 } from 'helpers';
import { sub } from 'date-fns';

export const HeaderPills = ['all', 'Active', 'Historical'];

export const Alerts = (): JSX.Element => {
  const { scopes } = usePermissions();
  const { t } = useTranslation(['mh']);

  const history = useHistory();
  const params = new URLSearchParams(location.search);

  useLayoutEffect(() => {
    const alertScope =
      scopes.find((scope) => {
        return scope.name === 'Alert Management';
      })?.read || false;

    if (!alertScope) {
      history.goBack();
    }
  }, [scopes]);
  const { dateRange, setDateRange } = useDateRange();
  const [status, setStatus] = useState((params.get('status') as string) || 'active');

  const onPillClicked = (selectedpill: string) => {
    history.push('?status=' + selectedpill.toLocaleLowerCase());
    setStatus(selectedpill.toLocaleLowerCase());
    setDateRange({
      startTime: sub(new Date(), { days: 7 }),
      endTime: new Date()
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (status === 'active') {
        setDateRange({
          startTime: sub(new Date(), { days: 7 }),
          endTime: new Date()
        });
      }
    }, 30000);

    return () => clearInterval(interval);
  }, [status]);

  return (
    <UseProteinMachineAlertsProvider
      status={status === 'all' ? undefined : status}
      start={toISO8601(dateRange.startTime)}
      end={toISO8601(dateRange.endTime)}
    >
      <AlertsContainer>
        <Header />
        <AlertsTabHeaderWrapper>
          <div className="left-header">
            <span id="filter-by">{t('Filter by')} :</span>
            <div className="filter-pills">
              {HeaderPills.map((pill) => {
                return (
                  <StatusPill
                    selected={status === pill?.toLocaleLowerCase()}
                    className="pill"
                    onClick={() => onPillClicked(pill?.toLocaleLowerCase())}
                  >
                    {t(pill)}
                  </StatusPill>
                );
              })}
            </div>
          </div>
          <div className="alerts-view__date-picker">
            {status !== 'active' ? (
              <DateButtonWithDropdown {...{ dateRange, setDateRange }} />
            ) : null}
          </div>
        </AlertsTabHeaderWrapper>

        <AlertsTable />
      </AlertsContainer>
    </UseProteinMachineAlertsProvider>
  );
};

function Header(): JSX.Element {
  const { t } = useTranslation(['mh']);
  const { data, isLoading } = useApiData();

  return (
    <>
      <ShowingLatestWrapper>
        <div className="showing_alerts">
          <span id="label">{t('showing_latest')}</span>
          {!data || isLoading ? (
            <CustomLoader size={'1.1rem'} thickness="0.2rem" />
          ) : (
            <span id="actual_value">{`${data.length} ${t('alerts')}`}</span>
          )}
        </div>
      </ShowingLatestWrapper>
    </>
  );
}
