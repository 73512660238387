import React, { ReactNode, createContext, useContext } from 'react';
import { useAccountInfo } from './useAccountInfo';
import { useGetMachineByIdQuery } from 'api';
import { useParams } from 'react-router';

export interface UseSiteInfoProps {
  isLoading?: boolean;
  hasMessage?: ReactNode;
  hasError?: ReactNode;
  timeZone?: string;
  [key: string]: unknown;
}

const SiteInfoContext = createContext<UseSiteInfoProps>({
  isLoading: true
});

export const useSiteInfo = (): UseSiteInfoProps => useContext(SiteInfoContext);

interface Props extends UseSiteInfoProps {
  children?: ReactNode | ReactNode[];
}

export const UseSiteInfoProvider = ({ children }: Props): JSX.Element => {
  const { machineId } = useParams<{ machineId: string }>();

  // this api call is kind of weird in the sense we're using the machine ID to get site and line info
  // so we do that and get the site specific info from the parent provider
  // we don't need to worry about the parent loading because we only return chidren on load
  const AccountInfoData = useAccountInfo();

  // get the site name from the Account info api call
  const siteName = AccountInfoData?.siteName;

  // set error if there is no site name, by now, custimerInfoData should be loaded
  let hasError = undefined;

  // then we get the rest of the site info from the machine query, this will give us line info and timezone
  // need to fix this api call to use the site id instead of the machine id
  const { data: machineData, isLoading, error } = useGetMachineByIdQuery(machineId);

  // return early if loading
  if (isLoading) {
    return <div className="loader">loading site info</div>;
  } else {
    if (!siteName) hasError = 'error getting site name from useAccountInfo';
  }

  // get the timeZone from the new machine info api call
  const timeZone = isLoading ? undefined : machineData?.timezone || 'UTC';

  // set error based on machineByIdQuery
  if (error) hasError = 'error getting machine info from useGetMachineByIdQuery';
  // this machine is missing a timeZone, this should never happen
  if (machineData && !timeZone) hasError = 'machine is missing timeZone';

  // return early if error
  if (hasError) {
    return <div className="loader">{hasError}</div>;
  }

  return (
    <SiteInfoContext.Provider
      value={{
        ...machineData,
        siteName,
        timeZone
      }}
    >
      {children}
    </SiteInfoContext.Provider>
  );
};
