import styled from 'styled-components';
import theme from 'common/theme';

export const GridLineContainer = styled.span<{ left?: string; top?: string }>`
  position: absolute;
  z-index: 0;

  left: ${({ left }) => left || 0};
  top: ${({ top }) => top || 0};
  background: ${theme?.colors?.gridLine || 'rgba(0, 0, 0, 0.1)'};

  &.horizontal {
    transform: translateY(-1px);
    height: 1px;
    width: 100%;
    left: 0;
  }

  &.vertical {
    transform: translateX(-1px);
    height: 100%;
    width: 1px;
    top: 0;
  }
`;

export const TickMarkContainer = styled.div`
  position: absolute;
  z-index: 0;
  font-size: 0.9em;
  min-width: max-content;

  left: ${({ left }) => left || 0};
  top: ${({ top }) => top || 0};

  &.horizontal {
    transform: translate(-100%, -50%);
    left: 0;
    padding-right: 1em;
  }

  &.vertical {
    transform: translate(-50%, 100%);
    top: 100%;
  }

  .tooltip-icon {
    z-index: 1;
    .icon--info {
      width: 0.8em;
      height: 0.8em;
    }
  }
`;
