import styled from 'styled-components';
import { colors, themeColors } from 'themes';

export const AlertsContainer = styled.div`
  padding: 1em;
  grid-gap: 1em;
  display: grid;
  grid-template-rows: auto 1fr;

  .alerts-view__date-picker {
    display: flex;
    justify-content: flex-end;
  }
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .showing_alerts {
    .test {
      border: 0.063rem solid ${colors.primaryBlue5};
      padding: 0.313rem 0.5rem;
      border-radius: 1.25rem;
    }
    display: flex;
    justify-content: flex-end;
    gap: 0.313rem;
    padding-right: 0.8rem;
    padding-bottom: 0.625rem;
    #label {
      font-size: 0.875rem;
      color: ${colors.text.lightBlack};
      padding-top: 0.125rem;
    }
    #actual_value {
      font-size: 1rem;
      font-weight: 600;
    }
  }

  width: 100%;

  tr td {
    &:first-child {
      width: 18%;
      word-break: break-all;
    }

    &:nth-child(2) {
      width: 8%;
      word-break: break-all;
    }

    &:nth-child(3) {
      width: 15%;
    }

    &:nth-child(4) {
      width: 12%;
    }

    &:nth-child(5) {
      width: 15%;
    }

    &:nth-child(6) {
      width: 7%;
    }
    &:nth-child(7) {
      width: 8%;
    }
    &:nth-child(8) {
      width: 14%;
    }
  }

  .historical-alerts {
    height: calc(100vh - 31rem);
    table {
      height: 100%;
      overflow: auto;
    }
    div:nth-child(2) {
      max-height: calc(100vh - 29rem) !important;
    }
    tbody {
      max-height: calc(100vh - 35rem);
    }
  }
`;

export const AlertsTabHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;
  border-bottom: 0.063rem solid #ccc;
  .left-header {
    margin-left: 1.4rem;
    display: flex;
    align-items: center;

    gap: 1rem;
    #filter-by {
      font-weight: 600;
      font-size: 0.875rem;
    }

    .filter-pills {
      display: flex;
      gap: 1rem;
      align-items: center;
    }
  }
`;

export const StatusPill = styled.div<{ selected?: boolean }>`
  font-size: 0.875rem;
  width: fit-content;
  font-weight: 400;
  border-radius: 1.25rem;
  cursor: pointer;
  padding: 0.625rem 1.563rem 0.563rem 1.563rem;
  border: ${({ selected }) => (selected ? '1px solid transparent' : '0.063rem solid #666666')};
  background: ${({ selected }) => (selected ? themeColors.primaryBlue5 : '')};
  color: ${({ selected }) => (selected ? themeColors.white : '')};

  &:hover {
    color: ${themeColors.white};
    border: ${({ selected }) =>
      selected ? '1px solid transparent' : `0.063rem solid ${themeColors.primaryBlue5}`};
    background: ${themeColors.primaryBlue5};
    box-shadow: 0 0.0625rem 0.375rem 0 rgb(0 0 0 / 15%);
  }
`;

export const ShowingLatestWrapper = styled.div`
border-bottom:1px solid #ccc;
.showing_alerts {
  display: flex;
  gap: 0.313rem;
  margin-left: 1.4rem;
  padding-bottom: 0.625rem;
  font-size: 1rem;
  font-weight: 600;
`;

export const ModalHeader = styled.div`
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  color: #303e47;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.3125rem;
  -webkit-letter-spacing: 0rem;
  -moz-letter-spacing: 0rem;
  -ms-letter-spacing: 0rem;
  letter-spacing: 0rem;
  padding: 1.5rem 1rem 0.5rem;
  text-align: left;
  width: 100%;
  gap: 1rem;
`;

export const AlertStartTimeColumnWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
  #date {
    width: 11rem;
  }
`;

export const AlertEndTimeColumnWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const AlertTableButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`;
export const ConfirmButtonTextWrapper = styled.div`
  margin: 0;
  padding-right: 0.4rem;
`;

export const AlertAcknowledgeModal = styled.div`
  padding: 1.25rem;
  #message {
    margin: 0;
    padding-bottom: 4rem;
  }
`;
