import styledTheme from 'common/theme';
import styled from 'styled-components';

export const ViewContainer = styled.section`
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 1em;
  padding: 1em;

  // tiles on the page are collapsible with arrow as buttons
  .collapse_button {
    background: none;
    border: 0;
    cursor: pointer;
    margin-right: 1rem;
    &.open {
      transform: rotate(0deg);
    }
    &.closed {
      transform: rotate(-90deg);
    }
  }

  .content_section {
    border-top: 1px solid ${styledTheme.colors.gray300};
    margin: 0.5rem 0;
    transition: all 0.1s ease;
    overflow: hidden;
    overflow-y: hidden;
    width: 100%;
    &.open {
      overflow-y: hidden;
      @media (min-width: 1100px) {
        max-height: 600px;
        overflow-y: hidden;
      }
    }
    &.closed {
      overflow-y: hidden;
      max-height: 0px;
    }
  }
`;
