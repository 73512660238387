import React from 'react';
import { ViewContainer } from './View.elements';

import { ChangeoverStepCategoriesWidget } from '../../../../components/ChangeoverStepCategoriesWidget/ChangeoverStepCategoriesWidget';
import { ChangeoverStepDurationWidget } from '../../../../components/ChangeoverStepDurationWidget/ChangeoverStepDurationWidget';
import { FilterSelectedProvider } from 'components';
import { ChangeoverStepDetailsApiProvider } from './providers/ChangeoverStepDetailsApiProvider';

const Provided = () => {
  //  const sessionsOverTimeViewData = useSessionsOverTimeData()
  return (
    <ChangeoverStepDetailsApiProvider>
      <ChangeoverStepCategoriesWidget />
      <ChangeoverStepDurationWidget />
    </ChangeoverStepDetailsApiProvider>
  );
};

export const SessionsOverTimeView = (): JSX.Element => {
  return (
    <ViewContainer className="changeover sessions-over-time view-container">
      <FilterSelectedProvider>
        <Provided />
      </FilterSelectedProvider>
    </ViewContainer>
  );
};
