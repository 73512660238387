import React, { ReactNode, createContext, useContext, useMemo } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useRouter } from 'common/hooks/useRouter';
import { fleetPageRoute } from 'common/pages/fleetV2/settings/fleetPageRoutes';
import { useGetTopAsepticMachineHealthChangeoverByIdV2Query } from 'api';
import { useSelectedChangeoverSessionData } from 'common/pages/fleetV2/machine/aseptic/hooks/useSelectedChangeoverSessionData';

export interface MachineStateAsepticProps {
  isLoading?: boolean;
  hasError?: string;
  hasMessage?: string;
  data?: Record<string, unknown>;
  currentSession?: Record<string, unknown>;
}

const MachineStateAsepticContext = createContext<MachineStateAsepticProps>({
  isLoading: true
});

export const useMachineStateAsepticContext = (): MachineStateAsepticProps =>
  useContext(MachineStateAsepticContext);

export const MachineStateAsepticProvider = ({
  children
}: {
  children?: ReactNode;
}): JSX.Element => {
  const { data, isLoading, hasError, currentSession } = useMachineStateAsepticQuery();

  const value = {
    currentSession,
    data,
    isLoading,
    hasError
  };

  return (
    <MachineStateAsepticContext.Provider value={value}>
      {children}
    </MachineStateAsepticContext.Provider>
  );
};

export const useMachineStateAsepticQuery = (): MachineStateAsepticProps => {
  const { id } = useRouter(fleetPageRoute) as { id: string };

  const {
    currentSession,
    isLoading: isLoadingSessionData,
    hasMessage: hasMessageSessionData
  } = useSelectedChangeoverSessionData();

  const {
    data: selectedChangeoverFullDetails,
    isFetching: isSelectedChangeoverFullDetailsFetching,
    error: selectedChangeoverFullDetailsError
  } = useGetTopAsepticMachineHealthChangeoverByIdV2Query(
    currentSession?.id !== undefined
      ? {
          machineId: id,
          changeoverId: currentSession?.id as string
        }
      : skipToken,
    {
      skip: !id,
      pollingInterval: !currentSession?.endTimestamp ? 180000 : undefined
    }
  );

  const cachedData = useMemo(() => selectedChangeoverFullDetails, [selectedChangeoverFullDetails]);

  const isLoading = isSelectedChangeoverFullDetailsFetching || isLoadingSessionData;
  const hasError = selectedChangeoverFullDetailsError ? `error getting machine alarms` : undefined;
  const hasMessage = hasMessageSessionData ? 'Error loading data' : undefined;

  const returnValue = {
    currentSession,
    data: cachedData,
    isLoading,
    hasError,
    hasMessage
  };

  return { ...returnValue };
};
