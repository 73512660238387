import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLiveChartAPIProvider } from './hooks/useLiveDataProviderV2';
import { LiveGraphWidgetContainer, Widget } from './elements';
import { DatepickerBarWrapper } from './ui/DatepickerBar/DatepickerBarWrappr';
import { ZoomBrushContainer } from './ui/Zoom/ZoomBrushContainer';
import { Downtime24hChart } from './Downtime24hChart/DowntimeChartWrapper';
import { LiveGraphChart } from './ui/Chart/LiveGraph';
import { DataLoaderIcons } from 'common/components/DataLoader/DataLoaderIcons';

export const LiveChartWidget = (): JSX.Element => {
  const { t } = useTranslation(['mh']);

  const { hasError, hasData } = useLiveChartAPIProvider();

  const displayError = (
    <div className="error--message">
      {DataLoaderIcons.hasError}
      <div className="loader-message">{hasError}</div>
    </div>
  );

  const liveChart = !hasError ? (
    <LiveGraphChart className="live-graph-widget__line-chart" />
  ) : (
    <div className="live-graph-widget__line-chart has-error">{displayError}</div>
  );

  const widget = useMemo(() => {
    return (
      <LiveGraphWidgetContainer className={`widget-ui__main`}>
        <DatepickerBarWrapper />
        <hr className="border-line"></hr>
        <ZoomBrushContainer className="live-graph-widget__zoom-bar" />
        <Downtime24hChart className="live-graph-widget__stats" />
        {liveChart}
      </LiveGraphWidgetContainer>
    );
  }, [hasError, hasData]);

  return (
    <Widget className="widget-ui live-graph-widget">
      <header className={`widget-ui__header ${hasError && 'has-error'}`}>
        <h2>{t('Live Chart')}</h2>
      </header>
      {widget}
    </Widget>
  );
};
