import React from 'react';
import { ViewContainer } from './View.elements';
import { MachineOverviewWidget } from '../../components';
import { ThroughputEfficiencyWidget } from '../../components/ThroughputEfficiencyWidget/ThroughputEfficiencyWidget';
import { CurrentIssuesWidget } from '../../components/CurrentIssuesWidget/CurrentIssuesWidget';
import { MachineUtilizationWidget } from '../../components/MachineUtilizationWidget/MachineUtilizationWidget';
import { ChangeoverWidget } from '../../components/ChangeoverWidget/ChangeoverWidget';
import { LaneAlarmsWidget } from '../../components/LaneAlarmsWidget';
import { OEEWidget } from '../../components/OEEWidget/OEEWidget';
import { useMachineInfo } from 'common/pages/fleetV2/providers';
import { WidgetDateRangeProvider } from 'common/ui';
import { ChangeoverSessionsByDateRangeAPIProvider } from '../../../../providers/useChangeoverSessionsByDateRangeAPIProvider';
import { MachineModes } from '../../components/MachineModesChartWidget/Provided';

const UsingSessionsOverTimeAPI = () => {
  const { timeZone } = useMachineInfo();

  return (
    <>
      <MachineOverviewWidget />
      <WidgetDateRangeProvider subtractDays={29} timeZone={timeZone}>
        <ChangeoverSessionsByDateRangeAPIProvider>
          <ChangeoverWidget />
        </ChangeoverSessionsByDateRangeAPIProvider>
      </WidgetDateRangeProvider>
    </>
  );
};

export const OverviewView = (): JSX.Element => {
  return (
    <ViewContainer className="overview view-container">
      <ThroughputEfficiencyWidget />
      <CurrentIssuesWidget />

      <OEEWidget />
      <MachineUtilizationWidget />

      <LaneAlarmsWidget />
      <UsingSessionsOverTimeAPI />

      <MachineModes />
    </ViewContainer>
  );
};

/*
<MachineOverviewWidget />
      <ThroughputEfficiencyWidget />
      <CurrentIssuesWidget />

      <OEEWidget />
      <MachineUtilizationWidget />

      <LaneAlarmsWidget />
      <UsingSessionsOverTimeAPI />

      <MachineModes />
      */
