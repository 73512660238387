import React from 'react';
import { BarItemProp, BarsProps } from './types';
import { uniqueId } from 'lodash';
import { Bar } from './Bar';

export const Bars = ({ items }: BarsProps): JSX.Element => {
  const bars = items.map((item: BarItemProp) => <Bar key={uniqueId()} item={item} />);

  return <>{bars}</>;
};
