import React from 'react';
import { ViewContainer } from './View.elements';
import { useMachineInfo } from '../../../../providers';

export const AlertsView = (): JSX.Element => {
  const { businessUnit } = useMachineInfo();
  return (
    <ViewContainer className="alerts-container content-padding">
      {businessUnit} alerts
    </ViewContainer>
  );
};
