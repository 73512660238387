import React, { useEffect, useState } from 'react';
import { Button } from 'components';
import Modal from 'components/Modal/Modal';
import Typography from 'components/Typography/Typography';
import { ButtonBox } from 'pages/MachineManagement/MachineManagementPopup/MachineImageUploaderStyledComponents';
import styled from 'styled-components';
import theme from 'themes';
import { ModalSize } from 'types';
import { GraphIcon } from 'icons/GraphIcon';
import { useTranslation } from 'react-i18next';
import { IconInfo } from 'icons/IconInfo';

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 1rem 1rem 2rem;
`;

const Tabs = styled.div`
  display: flex;
  border-bottom: 1px solid #ccc;
`;

const Tab = styled.button<{ isActive: boolean }>`
  flex: 1;
  padding: 10px;
  background: ${({ isActive }) => (isActive ? 'white' : 'white')};
  border: none;
  border-bottom: ${({ isActive }) => (isActive ? '2px solid #0076CC' : 'none')};
  color: ${({ isActive }) => (isActive ? `${theme.colors.donutChart.target}` : 'none')};
  font-weight: 500;
  cursor: pointer;
`;

const Content = styled.div`
  margin-top: 0.625rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Label = styled.label`
  font-size: 0.875rem;
  color: #333;
  font-weight: 600;
`;

const Input = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 50%;
`;

const SmallText = styled.small`
  display: flex;
  > span {
    margin-left: 0.25rem;
    color: #666666;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 0.879rem;
    margin-bottom: 12.5rem;
  }
`;

const ModalHeader = styled.div`
  padding: 2rem 1rem 1rem 2rem;
`;

const WarningTag = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;
`;

const GraphContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.25rem;
`;

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  activeTab: string;
}

const ModalDeforest: React.FC<Props> = ({
  isOpen,
  onRequestClose,
  activeTab: initialActiveTab
}) => {
  const [activeTab, setActiveTab] = useState(initialActiveTab);
  const { t } = useTranslation(['mh']);

  useEffect(() => {
    setActiveTab(initialActiveTab);
  }, [initialActiveTab]);

  return (
    <Modal visible={isOpen} onClose={onRequestClose} size={ModalSize.SMALL} overrideStyles={false}>
      <ModalHeader>
        <Typography as="h3" mb={2} size="1.125rem" weight="bold">
          {t('format_linear_trendline' as string) || ''}
        </Typography>
        {/* <p style={{ color: '#000000' }}>
          {t(
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. In mollitia nam iusto quos cupiditate ipsum ut doloribus voluptatum, accusamus at assumenda, esse maiores quis exercitationem cum veniam consequuntur! Facilis, deserunt!' as string
          ) || ''}
        </p> */}
      </ModalHeader>

      <ModalContainer>
        <Tabs>
          <Tab isActive={activeTab === 'maxPressure'} onClick={() => setActiveTab('maxPressure')}>
            {t('max_pressure' as string) || ''}
          </Tab>
          <Tab isActive={activeTab === 'warningLevel'} onClick={() => setActiveTab('warningLevel')}>
            {t('warning_level' as string) || ''}
          </Tab>
        </Tabs>
        <Content>
          {activeTab === 'maxPressure' && (
            <FormGroup>
              <Label htmlFor="maxPressure">{t('max_pressure' as string) || ''}</Label>
              <Input type="number" id="maxPressure" defaultValue="1000" min="400" max="1200" />
              <SmallText>
                <IconInfo color="#666666" />
                <span>{t('set_maximum_pressure_between_400-1200Pa' as string) || ''}</span>
              </SmallText>
            </FormGroup>
          )}
          {activeTab === 'warningLevel' && (
            <>
              <WarningTag>
                <div style={{ width: '100%' }}>
                  <FormGroup>
                    <Label htmlFor="warningLevelStart">{t('start' as string) || ''}</Label>
                    <Input type="number" id="warningLevelStart" defaultValue="875" />
                  </FormGroup>
                  <br></br>
                  <FormGroup>
                    <Label htmlFor="warningLevelEnd">{t('end' as string) || ''}</Label>
                    <Input type="number" id="warningLevelEnd" defaultValue="950" />
                  </FormGroup>
                </div>
                <GraphContainer>
                  <GraphIcon />
                </GraphContainer>
              </WarningTag>
            </>
          )}
        </Content>
        <ButtonBox>
          <Button
            width="6.688rem"
            height="2rem"
            borderRadius="3rem"
            borderColor={theme.colors.donutChart.target}
            onClick={onRequestClose}
            color={theme.colors.donutChart.target}
          >
            {t('cancel' as string) || ''}
          </Button>
          <Button
            width="6.688rem"
            height="2rem"
            borderRadius="3rem"
            bgColor={theme.colors.donutChart.target}
            color={`white`}
          >
            {t('apply' as string) || ''}
          </Button>
        </ButtonBox>
      </ModalContainer>
    </Modal>
  );
};

export default ModalDeforest;
