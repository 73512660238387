import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ICONMAP } from '../../../ActualDurationWidget/utils';
import { useDataManagementProvider } from '../../provider/useDataManagement';
import { LegendItem, legendSettings } from '../../utils';
import { ActualDuration } from '../../../ActualDurationWidget/elements';
import { Bar } from '../../../ActualDurationWidget/Bar';

interface Props {
  className?: string;
  chartDaterange?: Date[];
}

export const ActualDurationWrapper = ({ className }: Props): JSX.Element => {
  const { t } = useTranslation(['mh']);

  return (
    <ActualDuration className={className}>
      <h3>{t('Status')}</h3>
      <Duration />
    </ActualDuration>
  );
};

export interface LegendBar extends LegendItem {
  barTitle: string;
  percentage?: number;
  icon?: Element;
  color: string;
  numberOcuurences?: number;
  duration?: number;
  extraData?: number;
  duration_total?: number;
  modeDescr?: string;
  descr: string;
  label: string;
}

const Duration = (): JSX.Element => {
  const { t } = useTranslation(['mh']);
  const { filteredData, zoomDaterange } = useDataManagementProvider();

  const [dat, setDat] = useState(filteredData);

  useEffect(() => {
    setDat(filteredData);
  }, [filteredData]);

  const dataToProcess = dat;

  const totalDurationDaterange = zoomDaterange
    ? zoomDaterange[1]?.getTime() - zoomDaterange[0]?.getTime()
    : 0; // duration is in MILLISECONDS

  const dataTotalsActualValues = dataToProcess?.reduce(
    (acc: Record<string, LegendBar>, currentElement): Record<string, LegendBar> => {
      const currElement = currentElement as unknown as LegendBar;
      const mode = currElement.modeDescr as string;
      if (acc[mode]) {
        const total = (acc[mode].duration_total || 0) + (currElement?.duration || 0);
        acc[mode].duration_total = total as unknown as number;
      } else {
        //@ts-expect-error: error
        acc[mode] = {
          duration_total: currElement.duration, // duration is in MILLISECONDS
          barTitle: t(mode),
          icon:
            ((currElement.modeDescr &&
              ICONMAP[currElement.modeDescr as string]) as unknown as Element) || undefined,
          color: currElement.color as string
        };
      }
      return acc as unknown as Record<string, LegendBar>;
    },
    {}
  ) as Record<string, LegendBar>;

  dataTotalsActualValues &&
    Object.entries(dataTotalsActualValues).forEach(([, value]) => {
      value.duration = (value.duration_total && value.duration_total / 1000) || 0;
      value.percentage = Math.round((value.duration_total || 0 * 100) / totalDurationDaterange);
    });

  const legend = legendSettings as unknown as LegendBar[];

  legend.map((item) => {
    const newItem = item as unknown as LegendBar;
    const durTotal = dataToProcess?.reduce((acc, curr) => {
      if ((curr.modeDescr as string) === item.descr) {
        acc += curr.duration as number;
        return acc;
      }
      return acc;
    }, 0) as number;

    newItem.duration_total = durTotal;
    newItem.barTitle = t(item.descr as string) || '';
    newItem.icon = ICONMAP[item.descr as string] as Element;
    newItem.duration = durTotal / 1000;
    newItem.percentage = Math.round((durTotal * 100) / totalDurationDaterange);
  }) as unknown as LegendBar[];

  const bars = legend.map((item) => <Bar key={item.descr} item={item} />);

  return <>{bars}</>;
};
