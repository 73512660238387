import React, { useMemo } from 'react';
import { useRouter } from 'common/hooks/useRouter';
import { MachineHealthView } from './views/MachineHealth/MachineHealthView';
import { AlertsView } from './views/Alerts/AlertsView';
import { fleetPageRoute, fleetPageVersionPath } from '../../settings/fleetPageRoutes';
import { useHistory } from 'react-router-dom';
import { RouterTabsNav } from 'common/components';
import { useFleetRouter } from '../../hooks/useFleetRouter';
import { useAccountInfo } from 'common/pages/fleetV2/providers/useAccountInfoProvider';
import { useDispatch } from 'react-redux';
import { Reports } from 'components';
import { PowerBiItem } from 'components/Reports';

const ViewRoutes = (): JSX.Element => {
  const [{ id, view, tab }] = useFleetRouter();
  const dispatch = useDispatch();
  const { reportId, powerBiList } = useAccountInfo();
  const history = useHistory();

  if (reportId) {
    dispatch({ type: 'SHOW_REPORT', machineId: id as string });
  } else {
    dispatch({ type: 'HIDE_REPORT', machineId: id as string });
  }

  if (!view || (view === 'machine-health' && !tab))
    history.push(`/${fleetPageVersionPath}/${id}/machine-health/overview`);

  if (view === 'machine-health') return <MachineHealthView />;

  if (view === 'alerts') return <AlertsView />;

  if (view === 'reports')
    return (
      <Reports
        // uuIDs={{ reportId: accountData?.reportId, workspaceId: accountData?.workspaceId }}
        powerBiList={powerBiList as PowerBiItem[]}
      />
    );

  return <div>Aseptic View 404</div>;
};

export const ViewTabs = (): JSX.Element => {
  const { id } = useRouter(fleetPageRoute);
  const { reportId } = useAccountInfo();

  const tabsNavSettings: Record<string, unknown> = {
    tabs: [
      {
        label: 'Machine Health',
        // the slug for this tab, this will be used to populate url
        slug: 'machine-health',
        path: `/${fleetPageVersionPath}/${id}/machine-health/overview`
      },
      reportId && {
        label: 'Reports',
        slug: 'reports',
        path: `/${fleetPageVersionPath}/${id}/reports`,
        isDisabled: reportId ? false : true
      },
      {
        label: 'Alerts',
        slug: 'alerts',
        path: `/${fleetPageVersionPath}/${id}/alerts`,
        isDisabled: true
      }
    ].filter((x) => x),
    //basePath,
    // the route for this page, you can use custom as long as you're the value
    // of the current tab from the url
    pageRoute: fleetPageRoute,
    // this is the key to get the position of the tab value from the url to compare if current tab is active
    // and for the link
    pathKey: 'view',
    style: 'full-width'
  };

  const CachedTabs = useMemo(() => <RouterTabsNav {...tabsNavSettings} />, [id]);

  return CachedTabs;
};

export const AsepticView = (): JSX.Element => {
  return (
    <>
      <ViewTabs />
      <ViewRoutes />
    </>
  );
};
