import React, { useMemo } from 'react';
import { PointChartProps } from './PointChart.types';
import { DimensionsContainer } from 'components';
import { generatePointChart } from './helpers/generatePointChart';
import { PointChartAxis } from './PointChartAxis';
import { PointChartGrid } from './PointChartGrid';
import { ChartContainer, PointsChartPoint } from 'common/ui';

const Provided = ({
  chartData,
  width,
  height,
  margins,
  Item,
  format,
  domains
}: PointChartProps): JSX.Element => {
  if (!chartData) return <></>;

  width = width || 0;
  height = height || 0;

  const marginLeft = margins?.left || 40;
  const marginTop = margins?.top || 10;
  const marginBottom = margins?.bottom || 30;
  const marginRight = margins?.right || 10;

  const chartHeight = Number(height) - (marginBottom + marginTop);
  const chartWidth = Number(width) - (marginLeft + marginRight);

  /*const chartElements = generateChartElements(chartData, {
    width: width as number,
    height: height as number,
    marginLeft,
    marginTop,
    marginBottom,
    marginRight,
    domainX: domains?.x,
    domainY: domains?.y,
    chartTypes: ['points', 'lines']
  })*/

  const [points, axisL, axisB, gridLines, lines] = generatePointChart(chartData, {
    width: width as number,
    height: height as number,
    marginLeft,
    marginTop,
    marginBottom,
    marginRight,
    domainX: domains?.x,
    domainY: domains?.y
  });

  const ChartCache = useMemo(
    () => (
      <>
        {gridLines && (
          <div className="grid chart-area">
            <PointChartGrid {...{ gridLines }} />
          </div>
        )}

        {axisB.length > 1 && (
          <div className="axis axis--bottom">
            <PointChartAxis axisItems={axisB} {...{ formatter: format?.bottomTicks }} />
          </div>
        )}

        {axisL.length > 1 && (
          <div className="axis axis--left">
            <PointChartAxis axisItems={axisL} {...{ marginLeft, formatter: format?.leftTicks }} />
          </div>
        )}

        {lines && (
          <svg className="line-chart chart-area">
            {lines.map((line, i) => (
              <path key={i} d={line.d} fill="none" stroke={line?.color} strokeWidth={1} />
            ))}
          </svg>
        )}
        <div className="points-chart chart-area">
          {points?.length > 0 &&
            points?.map((point, i) => (
              <PointsChartPoint key={i} {...point} className="point">
                <div className="point-inner">
                  {Item ? <Item className="point__item item" {...point} /> : 0}
                </div>
              </PointsChartPoint>
            ))}
        </div>
      </>
    ),
    [points, axisL, axisB, gridLines, lines, width, width]
  );

  //console.log({ chartData, points, xAxis, yAxis, grid })
  return (
    <ChartContainer
      {...{
        marginLeft,
        marginTop,
        marginBottom,
        marginRight,
        chartHeight,
        chartWidth
      }}
    >
      {ChartCache}
    </ChartContainer>
  );
};

export const PointChart = ({ chartData, ...props }: PointChartProps): JSX.Element => {
  return (
    <DimensionsContainer
      className="point-chart-container"
      Component={(dims) => <Provided {...props} {...dims} {...{ chartData }} />}
    />
  );
};
