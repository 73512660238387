import { ReactNode } from 'react';
import { useMachineInfo } from 'common/pages/fleetV2/providers';
import { useRecipeTableData } from '../../RecipesTable/hooks';
import { RecipeType } from '../../RecipesTable/RecipesTable';

export const useRecipesForm = (): {
  isLoading?: boolean;
  hasError?: ReactNode;
  hasMessage?: ReactNode;
  data?: RecipeType[];
  [key: string]: unknown;
} => {
  const { id: machineId } = useMachineInfo() as { id: string; timeZone: string };
  const { data, isLoading, hasError, hasMessage } = useRecipeTableData({ ...{ machineId } });

  return { data, isLoading, hasError, hasMessage };
};
