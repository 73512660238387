import { DropDownItem } from 'pages/ProteinMachine/MachineConfig/Common/Alerts/FormElementsTypes';
import { BaseType, Id, Line, Role, UserScopes } from 'types';

export interface Group extends BaseType {
  id: string;
  name: string;
  internalAccessGroup: boolean;
  allOrganizations: boolean;
  organizationsCount: number;
  usersCount: number;
  isInternal?: boolean;
}

export interface GroupItem extends BaseType {
  id?: string;
  name: string;
  isInternal?: boolean;
  internalAccessGroup?: boolean;
  allOrganizations: boolean;
  allPlants: boolean;
  allMachines: boolean;
  organizations?: GroupOrganization[];
  plants?: GroupPlant[];
  machines?: string[];
  permissions?: GroupPermission[];
  users?: User[] | UserTypes[];
  businessUnits?: { id: number; name?: string; displayName?: string }[];
}

export interface UserGroup {
  id: string;
  isAdmin?: boolean;
  name?: string;
}
export interface ValidatedUsersResponse {
  [key: string]: unknown;
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  alertNotification: string;
  errorMessages: { columnName: string; errors: string[] }[];
  selectedNotifications?: DropDownItem[];
  selectedRole?: DropDownItem;
  selectedGroups?: DropDownItem[];
}
export interface BulkUser extends ValidatedUsersResponse {
  [key: string]: unknown;
  groups: { value: string; label: string }[];
  role: { value: string; label: string }[];
  alertNotifications: { value: string; label: string }[];
}

interface UserAlert {
  textAlert?: boolean;
  emailAlert?: boolean;
}
export interface UserTypes extends BaseType, UserAlert {
  id?: string;
  isActive?: boolean;
  status?: boolean;
  email?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  groups?: UserGroup[];
  group?: UserGroup[];
  isAdmin?: boolean;
  createdAt?: string;
  updatedAt?: string;
  ssoAuthentication?: boolean;
  name?: string;
  alertNotification?: UserAlert;
}
export interface User extends BaseType {
  id?: Id;
  firstName?: string;
  lastName?: string;
  email?: string;
  sfUserId?: string;
  sfUserName?: string;
  organizations?: string[];
  plants?: string[];
  machines?: string[];
  scopes?: UserScopes[];
  preferences?: BaseType;
  allOrganizations?: boolean;
  allMachines?: boolean;
  allPlants?: boolean;
  textAlert?: boolean;
  emailAlert?: boolean;
  phone?: string;
  groupId?: Id;
}

export interface UserValidationState extends BaseType {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
}

export interface UserFormElement extends BaseType {
  user: User | UserTypes;
  validationState: UserValidationState;
  id?: string;
}

export interface UserFormTypes extends BaseType {
  user?: UserTypes;
  validationState: UserValidationState;
}

export interface UserManagementPermission extends BaseType {
  id: string;
  name: string;
}

export interface GroupOrganization extends BaseType {
  organizationId: string;
  value: string;
  label: string;
  allPlants: boolean;
  allMachines: boolean;
  plants: GroupPlant[];
  machines: string[];
  selectedPlants: { plantIndex: number; value: string }[];
}

export interface GroupPlant extends BaseType {
  plantId: string;
  value?: string;
  label?: string;
  allMachines: boolean;
  machines: string[];
}

export interface GroupPermission extends BaseType {
  permissionId: string;
  read?: boolean;
  write?: boolean;
}

export interface GroupScope extends BaseType {
  allOrganizations: boolean;
  allPlants: boolean;
  allMachines: boolean;
  organizations: GroupOrganization[];
  plants: GroupPlant[];
  machines: string[];
}

export interface ScopeCount extends BaseType {
  organizations: string;
  plants: string;
  machines: string;
}

export interface UserManagementGroupsParams extends BaseType {
  type: string;
  offset?: number;
  limit?: number;
}
export interface UserParams extends BaseType {
  group_id?: string;
  name_search_query?: string;
  offset?: number;
  limit?: number;
}
export interface IdType extends BaseType {
  id?: string;
}
export interface DeleteUserParam extends IdType {
  groups?: IdType[];
}

export interface UserStatusParams extends IdType {
  isActive?: boolean;
}

export interface ValidateUserEmailAvailableParams extends BaseType {
  email: string;
  userId?: Id;
}

export interface UserItem extends User, BaseType {
  id?: string;
}

export interface DeleteUserParams extends BaseType {
  userId: Id;
}

export enum UserManagementTableType {
  GROUP = 'GROUP',
  USER = 'USER'
}

export interface PermissionScope extends BaseType {
  permissionId: string;
  name: string;
  read?: boolean;
  write?: boolean;
}

export enum PermissionScopeName {
  FLEET = 'Dashboards',
  MACHINE_MANAGEMENT = 'Machine Management',
  MAINTENANCE_MANAGER = 'Maintenance Manager',
  SHOP = 'Parts Shop',
  USER_MANAGEMENT = 'Groups and User Management',
  DOCUMENTATION_CENTER = 'Documentation Center'
}

export interface Permission {
  isAdmin: boolean;
  role: Role;
  scopes: PermissionScope[];
  noAccess: boolean;
  isEnabledPermission: boolean;
  machines: string[];
}

export type LinesById = { [lineId: Id]: Line };
