import React from 'react';

interface IconProps {
  color?: string;
  width?: number;
  height?: number;
}

export const ConsumablesLogo = ({
  color = '#29A429',
  width = 25,
  height = 25
}: IconProps): JSX.Element => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.728516" width="20" height="20" rx="10" fill={color} />
      <g clipPath="url(#clip0_494_19111)">
        <path
          d="M9.45557 10.7284C9.45557 10.8728 9.51296 11.0114 9.61512 11.1136C9.71729 11.2157 9.85585 11.2731 10.0003 11.2731C10.1448 11.2731 10.2834 11.2157 10.3855 11.1136C10.4877 11.0114 10.5451 10.8728 10.5451 10.7284C10.5451 10.5839 10.4877 10.4453 10.3855 10.3432C10.2834 10.241 10.1448 10.1836 10.0003 10.1836C9.85585 10.1836 9.71729 10.241 9.61512 10.3432C9.51296 10.4453 9.45557 10.5839 9.45557 10.7284Z"
          stroke="white"
          strokeWidth="0.81714"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.6821 10.2549C12.5886 9.72435 12.3399 9.23345 11.9674 8.84428C11.5949 8.4551 11.1153 8.18513 10.5894 8.0685C10.0634 7.95187 9.5147 7.99383 9.0126 8.18905C8.5105 8.38428 8.07756 8.72402 7.76854 9.1653C7.45953 9.60658 7.2883 10.1296 7.27653 10.6682C7.26476 11.2068 7.41296 11.7368 7.70239 12.1911C7.99183 12.6455 8.4095 13.0038 8.90259 13.2208C9.39568 13.4378 9.94204 13.5037 10.4726 13.4101"
          stroke="white"
          strokeWidth="0.81714"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.09717 10.7271C5.09717 11.6968 5.38471 12.6447 5.92345 13.4509C6.46218 14.2572 7.2279 14.8856 8.12377 15.2567C9.01965 15.6278 10.0054 15.7249 10.9565 15.5357C11.9076 15.3465 12.7812 14.8796 13.4668 14.1939C14.1525 13.5082 14.6195 12.6346 14.8086 11.6836C14.9978 10.7325 14.9007 9.7467 14.5296 8.85082C14.1586 7.95495 13.5301 7.18923 12.7239 6.6505C11.9176 6.11177 10.9697 5.82422 10 5.82422"
          stroke="white"
          strokeWidth="0.81714"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_494_19111">
          <rect
            width="13.0742"
            height="13.0742"
            fill="white"
            transform="translate(3.46289 4.19141)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
