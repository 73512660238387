import styled from 'styled-components';

export const PieChartContainer = styled.div<{ width?: number; height?: number }>`
  position: relative;

  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;

  .is-centered {
    transform: translate(50%, 50%);
  }
`;

export const SVGPieChartContainer = styled.svg<{
  width?: number | string;
  height?: number | string;
}>`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;

  &.hover-layer {
    z-index: 4;
  }

  path {
    &.hover-slice {
      cursor: pointer;
    }

    &.is-filtered {
      opacity: 0.3;
    }
  }
`;
