import React, { ReactElement } from 'react';

import DocsCenter from './DocsCenter';

const DocumentationCenter = (): ReactElement => {
  return (
    <>
      <DocsCenter />
    </>
  );
};

export default DocumentationCenter;
