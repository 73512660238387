import React, { useMemo } from 'react';
import { ViewContainer } from './View.elements';

import { RouterTabsNav } from 'common/components';
import { useRouter } from 'common/hooks/useRouter';
import {
  fleetPageRoute,
  fleetPageVersionPath
} from 'common/pages/fleetV2/settings/fleetPageRoutes';
import { useHistory } from 'react-router-dom';
import { OverviewView } from './Overview/OverviewView';

export const ViewTabs = (): JSX.Element => {
  const { id } = useRouter(fleetPageRoute);

  const tabsNavSettings: Record<string, unknown> = {
    tabs: [
      {
        label: 'Overview',
        // we're just sending the full path
        path: `/${fleetPageVersionPath}/${id}/machine-health/overview`,
        // this is value of the pathKey in the url, it it used to check if current
        slug: 'overview'
      }
    ],
    // the route for this page, you can use custom as long as you're the value
    // of the current tab from the url
    pageRoute: fleetPageRoute,
    // this is the key to get the position of the tab value from the url to compare if current tab is active
    // and for the link
    pathKey: 'tab'
    // basePath
  };

  const CachedTabs = useMemo(() => <RouterTabsNav {...tabsNavSettings} />, [id]);

  return CachedTabs;
};

const ViewRoutes = (): JSX.Element => {
  const { id, tab } = useRouter(fleetPageRoute);
  const history = useHistory();

  // load overview as default tab, this should be done higher up in the component tree though
  if (!tab && id) history.push(`/${fleetPageVersionPath}/${id}/machine-production/overview`);

  const CachedRoute = useMemo(() => {
    if (tab === 'overview') return <OverviewView />;
    return <div>proseal 404</div>;
  }, [tab, id]);

  return CachedRoute;
};

export const MachineProductionView = (): JSX.Element => {
  return (
    <ViewContainer className="machine-production view-container">
      <ViewTabs />
      <ViewRoutes />
    </ViewContainer>
  );
};
