import React from 'react';
import { useRouter } from 'common/hooks/useRouter';
import { MachineHealthView } from './views/MachineHealth/MachineHealthView';
import { AlertsView } from './views/Alerts/AlertsView';
import { fleetPageRoute } from '../../settings/fleetPageRoutes';
import { useHistory } from 'react-router-dom';
import { MachineViewTabs } from './components/MachineViewTabs/MachineViewTabs';

export const DSIView = (): JSX.Element => {
  return (
    <>
      <MachineViewTabs />
      <Routes />
    </>
  );
};

const Routes = (): JSX.Element => {
  const { id, view, tab } = useRouter(fleetPageRoute);
  const history = useHistory();

  if (!view || (view === 'machine-health' && !tab))
    history.push(`/fleet/machine/${id}/machine-health/overview`);

  if (view === 'machine-health') return <MachineHealthView />;
  if (process.env.REACT_APP_ENABLE_ALERTS_TAB === 'true' && view === 'alerts')
    return <AlertsView />;

  return <div>404</div>;
};
