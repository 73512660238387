export const data = [
  {
    id: '02i1R000008CQixQAG_changeover_1716281410',
    startDate: '2024-05-21',
    startTime: '08:50:10',
    endDate: '2024-05-24',
    endTime: '12:30:20',
    active: false,
    previousRecipeDesc: 'OWYN',
    nextRecipeDesc: 'Maintenance no auto',
    status: 'completed',
    duration: 272410,
    waitTime: 10278,
    excessTime: 236410,
    targetDuration: 36000,
    alerts: [],
    startDatetime: '2024-05-21T08:50:10-04:00',
    endDatetime: '2024-05-24T12:30:20-04:00'
  },
  {
    id: '02i1R000008CQixQAG_changeover_1715977675',
    startDate: '2024-05-17',
    startTime: '20:27:55',
    endDate: '2024-05-19',
    endTime: '18:45:54',
    active: false,
    nextRecipeDesc: 'Maintenance no auto',
    status: 'completed',
    duration: 166679,
    excessTime: 130679,
    targetDuration: 36000,
    alerts: [],
    startDatetime: '2024-05-17T20:27:55-04:00',
    endDatetime: '2024-05-19T18:45:54-04:00'
  },
  {
    id: '02i1R000008CQixQAG_changeover_1715766860',
    startDate: '2024-05-15',
    startTime: '09:54:20',
    endDate: '2024-05-16',
    endTime: '04:14:59',
    active: false,
    nextRecipeDesc: 'Maintenance no auto',
    status: 'completed',
    duration: 66039,
    excessTime: 30039,
    targetDuration: 36000,
    alerts: [],
    startDatetime: '2024-05-15T09:54:20-04:00',
    endDatetime: '2024-05-16T04:14:59-04:00'
  },
  {
    id: '02i1R000008CQixQAG_changeover_1715471450',
    startDate: '2024-05-11',
    startTime: '23:50:50',
    endDate: '2024-05-13',
    endTime: '09:13:59',
    active: false,
    nextRecipeDesc: 'Maintenance no auto',
    status: 'completed',
    duration: 120189,
    excessTime: 84189,
    targetDuration: 36000,
    alerts: [],
    startDatetime: '2024-05-11T23:50:50-04:00',
    endDatetime: '2024-05-13T09:13:59-04:00'
  },
  {
    id: '02i1R000008CQixQAG_changeover_1715289363',
    startDate: '2024-05-09',
    startTime: '21:16:03',
    endDate: '2024-05-10',
    endTime: '08:42:56',
    active: false,
    nextRecipeDesc: 'Maintenance no auto',
    status: 'completed',
    duration: 41213,
    excessTime: 5213,
    targetDuration: 36000,
    alerts: [],
    startDatetime: '2024-05-09T21:16:03-04:00',
    endDatetime: '2024-05-10T08:42:56-04:00'
  },
  {
    id: '02i1R000008CQixQAG_changeover_1715113367',
    startDate: '2024-05-07',
    startTime: '20:22:47',
    endDate: '2024-05-08',
    endTime: '09:10:25',
    active: false,
    nextRecipeDesc: 'Maintenance no auto',
    status: 'completed',
    duration: 46058,
    excessTime: 10058,
    targetDuration: 36000,
    alerts: [],
    startDatetime: '2024-05-07T20:22:47-04:00',
    endDatetime: '2024-05-08T09:10:25-04:00'
  },
  {
    id: '02i1R000008CQixQAG_changeover_1714153305',
    startDate: '2024-04-26',
    startTime: '17:41:45',
    endDate: '2024-05-06',
    endTime: '09:57:07',
    active: false,
    nextRecipeDesc: 'Maintenance no auto',
    status: 'completed',
    duration: 836122,
    excessTime: 800122,
    targetDuration: 36000,
    alerts: [],
    startDatetime: '2024-04-26T17:41:45-04:00',
    endDatetime: '2024-05-06T09:57:07-04:00'
  }
];
