import React from 'react';

import { WidgetUi } from 'common/ui';
import { MachineInformationFormContainer } from './MachineInformationForm.elements';
import { useMachineInformationForm } from './hooks/useMachineInformationForm';
// import { MachineInformationAdmin } from 'common/pages/fleet/machine/proseal/MachineProduction/subtabs/Admin';
import { MachineInformation } from 'common/pages/fleet/machine/proseal/MachineProduction/subtabs/Admin/components/MachineInformation';

/**
 * this is just for a custom widget template,
 * if you want a pie chart or bar chart one of the existing global charts, please choose one from common/components/WidgetUi
 */

export const MachineInformationForm = (): JSX.Element => {
  const { isLoading, hasMessage, hasError, data } = useMachineInformationForm();
  if (isLoading === true) console.log(data);

  const widgetSettings = {
    className: 'machine-information-form',
    hasError,
    isLoading,
    hasMessage
  };

  return (
    <WidgetUi
      {...widgetSettings}
      Main={
        <MachineInformationFormContainer className="widget-ui__main">
          <MachineInformation />
        </MachineInformationFormContainer>
      }
    />
  );
};
