'use strict';
import * as d3 from 'd3';
import { styledTheme } from 'common/theme';
import ChevronDown from '../../../icons/Chevron_Down.svg';
import ChevronRight from '../../../icons/Chevron_Right.svg';

/* eslint-disable */
export class StatesD3V2 {
  constructor(
    element,
    chartData,
    {
      margins,
      rangeX,
      width,
      height,
      connectedLinesData,
      className,
      stackKey,
      onTickClick,
      tooltipPositionOffsetX,
      tooltipPositionOffsetY
    }
  ) {
    const data = chartData;
    this.data = chartData;
    this.connectedLinesData = connectedLinesData;

    this.stackKey = stackKey;

    this.element = element;
    this.brush = null;

    this.defaultSelection = null;

    const { top, right, bottom, left } = margins;

    this.top = top;
    this.right = right;
    this.bottom = bottom;
    this.left = left;

    this.width = width;
    this.height = height;

    this.className = className;

    this.onTickClick = onTickClick;

    this.newTooltip = undefined;

    this.transitionTime = 50;
    this.tooltipPositionOffsetX = tooltipPositionOffsetX ? tooltipPositionOffsetX : 0;
    this.tooltipPositionOffsetY = tooltipPositionOffsetY ? tooltipPositionOffsetY : 0;

    // Delete svg if already exists
    d3.select(this.element).selectAll('svg').remove();
    d3.selectAll('.d3-tooltip').remove();

    // Create and style svg
    this.svg = this.createSVG(this.element, 'stacked-chart');

    // Create group for X axis
    this.xAxisGroup = this.svg
      .append('g')
      .attr('transform', `translate(0, ${this.height - (this.bottom * 2 + this.top)})`)
      .attr('class', 'x-axis-group');

    // Create group for Y axis top
    this.yAxisGroup = this.svg
      .append('g')
      .attr('transform', `translate(${this.left}, 0)`)
      .attr('class', 'y-axis-group');

    this.drawArea = this.generateZoomContainer(this.svg);

    this.xScale = this.createXAxis();
    this.xScale = this.updateXAxis(rangeX);

    this.createXGroup(this.xScale);

    const chartHeight = this.getChartHeight(data?.length);
    this.yScale = this.createYAxis([this.top, chartHeight + this.bottom], 0.5);
    this.yScale = this.updateYAxis(this.yScale, data);
    this.createYGroup(this.yScale);

    this.stackD3 = d3
      .stack()
      .keys(['duration'])
      .order(d3.stackOrderNone)
      .offset(d3.stackOffsetDiverging);
  }

  createSVG(element, className) {
    return d3
      .select(element)
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height)
      .attr('class', `${className}`);
  }

  createXAxis() {
    return d3.scaleTime().range([this.left, this.width]);
  }

  updateXAxis(domain, range) {
    if (domain) {
      return this.xScale.domain(domain);
    }
    if (range) {
      return this.xScale.range(range);
    }
  }

  createXGroup(x) {
    // Call X axis
    const xAxisCall = d3.axisBottom(x);

    // This styles the axis
    return this.xAxisGroup
      .call(xAxisCall)
      .call((g) => g.select('.domain').remove())
      .call((g) =>
        g
          .selectAll('.tick line:first-child')
          .clone()
          .attr('y1', -(this.height - this.top - this.bottom))
          .attr('y2', 0)
          //.transition()
          //.duration(this.transitionTime)
          .attr('stroke-opacity', 0.1)
      )
      .call((g) =>
        g
          .selectAll('.tick text')
          .attr('fill', styledTheme.colors.black)
          .attr('transform', `translate(0, 10)`)
      )
      .call((g) => g.selectAll('.tick line:first-child').remove());
  }

  createYAxis(range, padding) {
    //Create Y axis
    return d3
      .scaleBand()
      .range(range) //physical space in pixels that your scale will cover
      .padding(padding);
  }

  updateYAxis(axis, data, range) {
    const domain = data.map((item) => item.state);

    if (domain) {
      return axis.domain(domain);
    }
    if (range) {
      return axis.range(range);
    }
  }

  createYGroup(y) {
    const yAxisCall = d3.axisLeft(y).ticks(null, 's');

    //Unbind previos click event
    this.unbindEvents();

    return (
      this.yAxisGroup
        .call(yAxisCall)
        .call((g) => g.select('.domain').remove())
        .selectAll('.tick')
        //This is part of the styling where we remove domain line and automatically generated ticks and switch it with custom ones
        .call((g) => g.selectAll('.tick line').remove())
        .call((g) => g.selectAll('.tick text').remove())

        //This appends custom ticks
        .append('foreignObject')
        .attr('width', 120)
        .attr('height', 20) // height of a bar
        .attr('y', (d, i) => {
          return this.data[i].isTopLevel === undefined ? -10 : 0; // half of a tick height to center it vertically relative to the bar
        })
        .attr('x', -140)
        .append('xhtml:div')
        .attr('class', (_, i) => {
          const tickClassName = this.data[i].isTopLevel ? 'custom-tick top-level' : 'custom-tick';
          return tickClassName;
        })
        .html((_, i) => {
          const icon = `<img src="${
            this.data[i].isExpanded ? ChevronDown : ChevronRight
          }" alt="Chevron icon" />`;
          const buttonTick = `<button class="tick button">${icon} ${this.data[i].label}</button>`;
          const textTick = `<p className="tick text">${this.data[i].label}</p>`;

          const tick = this.data[i].isExpandable === undefined ? textTick : buttonTick;
          return `${tick}`;
        })
        .on('click', (_, i) => {
          this.onTickClick(i);
        })
    );
  }

  generateZoomContainer(svg) {
    // Add a clipPath: everything out of this area won't be drawn.
    const clip = svg
      .append('defs')
      .append('svg:clipPath')
      .attr('id', 'clip')
      .append('svg:rect')
      .attr('width', (d) => {
        const width = this.width - this.right;
        if (width < 0) {
          //console.log('Negative width found', d);
        }
        return Math.abs(width);
      })
      .attr('height', this.height)
      .attr('x', this.left)
      .attr('y', 0);

    // to make TS happy
    const showMSG = false;
    if (showMSG) console.log(clip);
    const scatter = svg.append('g').attr('clip-path', 'url(#clip)').attr('class', 'zoom-container');
    return scatter;
  }

  draw(showTooltip, range, data, linesData) {
    this.newTooltip = d3
      .select(`.${this.className}`)
      .append('div')
      .attr('class', 'd3-tooltip')
      .style('position', 'absolute')
      .style('z-index', '10')
      .style('visibility', 'hidden')
      .style('padding', '10px')
      .style('background', '#ffffff')
      .style('border-radius', '4px')
      .style('color', '#323130');

    const formattedData = data.reduce((acc, curr) => {
      curr.bars ? acc.push(...curr.bars) : acc.push(...curr);
      return acc;
    }, []);

    this.stackedData = this.stackD3(formattedData);

    const newTooltip = this.newTooltip;
    const tooltipExtraOffsetX = this.tooltipPositionOffsetX;
    const tooltipExtraOffsetY = this.tooltipPositionOffsetY;

    //Unbind previously binded events
    this.drawArea
      .selectAll('.bars')
      .on('mouseover', null)
      .on('mousemove', null)
      .on('mouseout', null);

    this.drawArea
      .selectAll('.bars')
      .data(this.stackedData)
      .enter()
      .append('g')
      .attr('class', 'bars')
      .selectAll('rect')
      .data((d) => d)
      .enter()
      .append('rect')
      //.attr('class', 'bar')
      .attr('x', (d) => this.xScale(d.data.start_time))
      .attr('y', (d) => this.yScale(d.data.state))
      .attr('height', (d) => d.data.height)
      .on('mouseover', function (d, i) {
        const tooltipContent = showTooltip(i);
        newTooltip.html(`${tooltipContent}`).style('visibility', 'visible');
        //d3.select(this).transition().attr('fill', i.data.colorHover);
      })
      .on('mousemove', function (event) {
        newTooltip
          .style('top', event.pageY - 40 - tooltipExtraOffsetY + 'px')
          .style('left', event.pageX - 290 - tooltipExtraOffsetX + 'px'); //290 is a width of a tooltip
      })
      .on('mouseout', function (d, i) {
        newTooltip.html(``).style('visibility', 'hidden');
        //d3.select(this).transition().attr('fill', i.data.color);
      })
      .attr('width', (d) => {
        const width = this.xScale(d[1]) - this.xScale(d[0]);
        if (width < 0) {
          //console.log('Negative width found', d);
        }
        return Math.abs(width);
      })
      .attr('stroke', '#ffffff')
      .attr('stroke-width', '3')
      .attr('fill', (d) => d.data.color)
      .attr('class', (d) => d.data.class);

    // Create and append line segments to the SVG
    linesData && this.drawDashedLines(linesData, this.xScale, this.yScale);
  }

  reDrawBars(data, showTooltip) {
    // Select all bars and adjust their width
    const formattedData = data.reduce((acc, curr) => {
      curr.bars ? acc.push(...curr.bars) : acc.push(...curr);
      return acc;
    }, []);

    const newTooltip = this.newTooltip;

    const stackedData = this.stackD3(formattedData);

    const bars = this.drawArea.selectAll('.bars').data(stackedData);
    const tooltipExtraOffsetX = this.tooltipPositionOffsetX;
    const tooltipExtraOffsetY = this.tooltipPositionOffsetY;

    bars
      .enter()
      .append('rect')
      .merge(bars)
      .selectAll('rect')
      .data((d) => d)
      .join(
        (enter) =>
          enter
            .append('rect')
            .attr('class', (d) => d.data.class)
            .attr('fill', (d) => d.data.color)
            .attr('stroke', '#ffffff')
            .attr('stroke-width', '3')
            .attr('y', (d) => this.yScale(d.data.state))
            .attr('x', (d) => this.xScale(d.data.start_time))
            .attr('height', (d) => d.data.height)
            .attr('width', (d) => {
              const width = this.xScale(d[1]) - this.xScale(d[0]);
              if (width < 0) {
                //console.log('Negative width found', d);
              }
              return Math.abs(width);
            })
            .on('mouseover', function (d, i) {
              const tooltipContent = showTooltip(i);
              newTooltip.html(`${tooltipContent}`).style('visibility', 'visible');
              //d3.select(this).transition().attr('fill', i.data.colorHover);
            })
            .on('mousemove', function (event) {
              newTooltip
                .style('top', event.pageY - 40 - tooltipExtraOffsetY + 'px')
                .style('left', event.pageX - 290 - tooltipExtraOffsetX + 'px'); //290 is a width of a tooltip
            })
            .on('mouseout', function (d, i) {
              newTooltip.html(``).style('visibility', 'hidden');
              //d3.select(this).transition().attr('fill', i.data.color);
            })
            .call((enter) =>
              enter
                //.transition()
                //.duration(750)
                .attr('x', (d) => this.xScale(d.data.start_time))
                .attr('width', (d) => {
                  const width = this.xScale(d[1]) - this.xScale(d[0]);
                  if (width < 0) {
                    //console.log('Negative width found', d);
                  }
                  return Math.abs(width);
                })
            ),
        (update) =>
          update.call(
            (update) =>
              update
                //.transition()
                //.duration(750)
                .attr('fill', (d) => d.data.color)
                .attr('class', (d) => d.data.class)
                .attr('y', (d) => this.yScale(d.data.state))
                .attr('x', (d) => this.xScale(d.data.start_time))
                .attr('height', (d) => d.data.height)
                .attr('width', (d) => {
                  const width = this.xScale(d[1]) - this.xScale(d[0]);
                  if (width < 0) {
                    //console.log('Negative width found', d);
                  }
                  return Math.abs(width);
                }),
            (exit) =>
              exit.call((exit) =>
                exit
                  //.transition()
                  //.duration(750)
                  .attr('y', (d) => this.yScale(d.data.state))
                  .attr('height', 0)
                  .on('mouseover', null)
                  .on('mousemove', null)
                  .on('mouseout', null)
                  .remove()
              )
          )
      );

    this.drawArea
      .select('.x-axis-group')
      //.transition()
      //.duration(750)
      .call(d3.axisBottom(this.xScale));

    this.createXGroup(this.xScale);
  }

  onExpand(isExpanded) {
    if (isExpanded) {
      d3.select(this.element).selectAll('svg .bar-child').attr('visibility', `visible`);

      d3.select(this.element)
        .selectAll('svg .y-axis-group .tick:not(:first-child)')
        .attr('visibility', 'visible');

      d3.select(this.element).selectAll('svg .tick.button img').attr('src', ChevronDown);
    } else {
      d3.select(this.element).selectAll('svg .bar-child').attr('visibility', `hidden`);

      d3.select(this.element)
        .selectAll('svg .y-axis-group .tick:not(:first-child)')
        .attr('visibility', 'hidden');

      d3.select(this.element).selectAll('svg .tick.button img').attr('src', ChevronRight);
    }
  }

  removeOldData(): void {
    //Remove old data
    this.data &&
      this.svg
        .selectAll('rect')
        .data(this.data)
        .exit()
        .attr('opacity', 0)
        .on('mouseover', null)
        .on('mousemove', null)
        .on('mouseout', null)
        //.transition()
        //.duration(this.transitionTime)
        .remove();

    this.connectedLinesData &&
      this.svg
        .selectAll(
          '.lines, .lines-short-top-bg, .lines-short-top, .lines-short-bottom-bg, .lines-short-bottom-bg, .lines-label'
        )
        .data(this.connectedLinesData)
        .exit()
        .attr('opacity', 0)
        //.transition()
        //.duration(this.transitionTime)
        .remove();
  }

  drawDashedLines(linesData, xScale, yScale) {
    // Connecting line
    linesData &&
      this.drawArea
        .selectAll('.line-segment-connector')
        .data(linesData)
        .enter()
        .append('g')
        .attr('class', 'lines')
        .append('line')
        .attr('class', 'line-segment-connector')
        .attr('x1', (d) => xScale(d.end_time)) // x-coordinate of start point
        .attr('x2', (d) => xScale(d.other_end_time)) // x-coordinate of end point
        .attr('y1', yScale('actual_details') + 40) // +40 is a height of a bar and we position line starting at the bottom of a bar
        .attr('y2', yScale('target_details')) // y-coordinate of end point
        .style('stroke', '#B62C10') // Customize line color as needed
        .attr('stroke-dasharray', '2 2')
        .attr('stroke-width', 2);

    //Background vertical line top
    linesData &&
      this.drawArea
        .selectAll('.line-segment-top-bg')
        .data(linesData)
        .enter()
        .append('g')
        .attr('class', 'lines-short-top-bg')
        .append('line')
        .attr('class', 'line-segment-top-bg')
        .attr('x1', (d) => xScale(d.end_time))
        .attr('x2', (d) => xScale(d.end_time))
        .attr('y1', (d) => yScale('actual_details') + 40) // y-coordinate of start point
        .attr('y2', (d) => yScale('actual_details') - 5) // y-coordinate of end point
        .style('stroke', '#ffffff') // Customize line color as needed
        .attr('stroke-width', 6);

    // Vertical line top
    linesData &&
      this.drawArea
        .selectAll('.line-segment-top')
        .data(linesData)
        .enter()
        .append('g')
        .attr('class', 'lines-short-top')
        .append('line')
        .attr('class', 'line-segment-top')
        .attr('x1', (d) => xScale(d.end_time))
        .attr('x2', (d) => xScale(d.end_time))
        .attr('y1', (d) => yScale('actual_details') + 40) // y-coordinate of start point
        .attr('y2', (d) => yScale('actual_details')) // y-coordinate of end point
        .style('stroke', '#B62C10') // Customize line color as needed
        .attr('stroke-dasharray', '2 2')
        .attr('stroke-width', 2);

    //background line bottom
    linesData &&
      this.drawArea
        .selectAll('.line-segment-bottom-bg')
        .data(linesData)
        .enter()
        .append('g')
        .attr('class', 'lines-short-bottom-bg')
        .append('line')
        .attr('class', 'line-segment-bottom-bg')
        .attr('x1', (d) => xScale(d.other_end_time))
        .attr('x2', (d) => xScale(d.other_end_time))
        .attr('y1', (d) => yScale('target_details') + 40) // y-coordinate of start point
        .attr('y2', (d) => yScale('target_details')) // y-coordinate of end point
        .style('stroke', '#ffffff') // Customize line color as needed
        .attr('stroke-width', 6);

    // Bottom line
    linesData &&
      this.drawArea
        .selectAll('.line-segment-bottom')
        .data(linesData)
        .enter()
        .append('g')
        .attr('class', 'lines-short-bottom')
        .append('line')
        .attr('class', 'line-segment-bottom')
        .attr('x1', (d) => xScale(d.other_end_time))
        .attr('x2', (d) => xScale(d.other_end_time))
        .attr('y1', yScale('target_details') + 40) // y-coordinate of start point
        .attr('y2', yScale('target_details')) // y-coordinate of end point
        .style('stroke', '#B62C10') // Customize line color as needed
        .attr('stroke-dasharray', '2 2')
        .attr('stroke-width', 2);

    // Labels
    linesData &&
      this.drawArea
        .selectAll('.line-label')
        .data(linesData)
        .enter()
        .append('g')
        .attr('class', 'lines-label')
        .append('text')
        .attr('text-anchor', (d) => d.target_diff_in_minutes)
        .text((d) => `${d.target_diff_in_minutes}`)
        .attr('fill', '#B62C10')
        .attr('x', (d, i) =>
          linesData.length - 1 === i ? xScale(d.end_time) - 40 : xScale(d.end_time) + 5
        ) //18 is to center the text
        .attr('y', (d) => yScale('actual_details') - 4)
        .attr('font-size', 10)
        .attr('font-weight', 'bold');
  }

  getChartHeight(dataLength) {
    if (dataLength === 1) {
      return 52;
    } else if (dataLength === 2) {
      return 125;
    } else {
      return dataLength * 50;
    }
  }

  unbindEvents() {
    this.yAxisGroup && this.yAxisGroup.on('click', null);
  }
}
/* eslint-enable */
