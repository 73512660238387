import React, { useEffect, useState, useMemo } from 'react';
import {
  AlertAcknowledgeModal,
  AlertEndTimeColumnWrapper,
  AlertStartTimeColumnWrapper,
  AlertTableButtonWrapper,
  ConfirmButtonTextWrapper,
  ModalHeader,
  TableWrapper
} from './Alerts.elements';
import { Button, Modal, NewBaseTable } from 'components';
import { BuiltInSortingFn, CellContext, Row } from '@tanstack/react-table';
import { TableColumnConfigs } from 'types';
import { capitalizeFirst, formatDate } from 'helpers';
import { useTranslation } from 'react-i18next';
import { useApiData } from 'components/StyledUi/hooks';
import { DataRenderer } from 'components/machine-health';
import IcoLowFillCircle from 'icons/IcoLowFillCircle';
import IcoMediumFillCircle from 'icons/IcoMediumFillCircle';
import IcoHighFillCircle from 'icons/IcoHighFillCircle';
import IcoWarningNew from 'icons/IcoWarningNew';
import { themeColors } from 'themes';
import IcoCheckbox from 'icons/IcoCheckbox';
import { AlertAcknowledgeStatus, AlertTriggers } from 'types/machine-health/alerts';
import CustomLoader from 'components/CustomLoader';
import { useAcknowledgeAlertTriggerMutation } from 'api';
import { ToastMsg } from 'common/components/Toast/Toast';
import { useTimeZone } from 'providers';

function convertSecondsToTime(seconds: number): string {
  if (!Number.isNaN(seconds)) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    const hoursString = hours.toString().padStart(2, '0');
    const minutesString = minutes.toString().padStart(2, '0');

    return `${hoursString}:${minutesString}`;
  } else {
    return '-';
  }
}

export const AlertsTable = (): JSX.Element => {
  const { t } = useTranslation(['mh']);
  const { timeZone } = useTimeZone();
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  const [selectedTrigger, setSelectedTrigger] = useState<AlertTriggers | null>(null);
  const [triggerModalOpen, setTriggerModalOpen] = useState(false);
  const { data, hasMessage, isLoading, hasError } = useApiData();

  const updatedAlertTableData = useMemo(() => {
    return data?.map((item) => {
      const dataData =
        item.needsAcknowledgment === true
          ? item.acknowledgedBy === null
            ? t('Acknowledge')
            : item?.acknowledgedByFirstname + ' ' + item?.acknowledgedByLastname
          : 'N/A';
      return { ...item, acknowledgedByFirstname: dataData };
    });
  }, [data]);

  useEffect(() => {
    return () => {
      setIsFirstRender(false);
    };
  }, [isLoading]);

  const [acknowledgeAlertTrigger, { isLoading: isTriggerUpdating }] =
    useAcknowledgeAlertTriggerMutation();

  const onAlertTriggerUpdate = async () => {
    try {
      if (selectedTrigger === null) throw Error('Trigger ID not found');

      const response = await acknowledgeAlertTrigger({
        alertTriggerId: selectedTrigger.id,
        acknowledged: AlertAcknowledgeStatus.ACKNOWLEDGED
      }).unwrap();
      ToastMsg({
        message: `${response.alertName} acknowledge successfully`,
        type: 'success',
        position: 'top-right'
      });
      setTriggerModalOpen(false);
      setSelectedTrigger(null);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const ErrorMessage = error?.data
        ? error.data && error.data?.detail
        : 'Failed to acknowldge a trigger!!';
      if (ErrorMessage) {
        ToastMsg({
          message: ErrorMessage,
          type: 'error',
          position: 'top-right'
        });
        console.error(error);
      } else {
        ToastMsg({
          message: 'Failed to acknowldge a trigger!!',
          type: 'error',
          position: 'top-right'
        });
        console.error(error);
      }
    }
  };

  const columns = [
    {
      id: 'start',
      header: t('start_date'),
      isEnableSorting: true,
      isSelected: true,
      isAlwaysVisible: true,
      disableGlobalSearch: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => (
        <AlertStartTimeColumnWrapper>
          <div id="date">
            {formatDate(cellValue.getValue().toLocaleLowerCase(), 'long', timeZone)} {' - '}
            {formatDate(
              cellValue.getValue().toLocaleLowerCase(),
              'hours-minutes-seconds',
              timeZone
            )}
          </div>
          <span>
            {cellValue.row.original.status === 'ACTIVE' ? (
              <IcoWarningNew height={20} width={20} />
            ) : null}
          </span>
        </AlertStartTimeColumnWrapper>
      )
    },
    {
      id: 'alertIncrementId',
      header: t('Alert ID'),
      isEnableSorting: true,
      isSelected: true,
      isAlwaysVisible: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        return <div>{cellValue.getValue()}</div>;
      }
    },
    {
      id: 'alertName',
      header: t('alert_name'),
      isEnableSorting: true,
      isSelected: true,
      isAlwaysVisible: true,
      customSort: 'text' as BuiltInSortingFn,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        return <div>{cellValue.getValue()}</div>;
      }
    },
    {
      id: 'alertMessage',
      header: t('alert_message'),
      isEnableSorting: false,
      isSelected: true,
      isAlwaysVisible: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        const length = cellValue.getValue()?.length ?? 0;
        return length > 20
          ? cellValue.getValue()?.substring(0, 20) + '...'
          : cellValue.getValue() ?? '';
      }
    },
    {
      id: 'end',
      header: t('end_date'),
      isEnableSorting: true,
      isSelected: true,
      isAlwaysVisible: true,
      disableGlobalSearch: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => (
        <AlertEndTimeColumnWrapper>
          {cellValue.getValue() === null ? (
            'N/A'
          ) : (
            <>
              <div>{formatDate(cellValue.getValue().toLocaleLowerCase(), 'long', timeZone)}</div>
              {' - '}
              <div>
                {formatDate(
                  cellValue.getValue().toLocaleLowerCase(),
                  'hours-minutes-seconds',
                  timeZone
                )}
              </div>
            </>
          )}
        </AlertEndTimeColumnWrapper>
      )
    },
    {
      id: 'duration',
      header: `${t('duration')} (HH:MM)`,
      isEnableSorting: true,
      isSelected: true,
      isAlwaysVisible: true,
      disableGlobalSearch: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) =>
        cellValue.getValue() === null ? (
          'N/A'
        ) : (
          <div>{convertSecondsToTime(Number(cellValue.getValue()))}</div>
        )
    },
    {
      id: 'priority',
      header: t('priority'),
      isEnableSorting: true,
      isSelected: true,
      isAlwaysVisible: true,
      disableGlobalSearch: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        let Icon: JSX.Element = <></>;
        switch (cellValue.getValue()) {
          case 'HIGH':
            Icon = <IcoHighFillCircle height={12} width={12} />;
            break;
          case 'LOW':
            Icon = <IcoLowFillCircle height={12} width={12} />;
            break;
          case 'MEDIUM':
            Icon = <IcoMediumFillCircle height={12} width={12} />;
            break;
          default:
            Icon = <></>;
        }

        return (
          <div style={{ display: 'flex', gap: '.3rem' }}>
            <span style={{ margin: 0 }}>{Icon}</span>
            {capitalizeFirst(cellValue.getValue().toLocaleLowerCase())}
          </div>
        );
      }
    },
    {
      id: 'acknowledgedByFirstname',
      header: t('Acknowledged by'),
      isEnableSorting: true,
      isSelected: true,
      isAlwaysVisible: true,
      customSort: 'text' as BuiltInSortingFn,
      disableGlobalSearch: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => (
        <div>
          {cellValue?.row?.original.acknowledgedByFirstname === 'Acknowledge' ? (
            <span
              style={{
                margin: 0,
                color: themeColors.primaryBlue5,
                cursor: 'pointer'
              }}
              onClick={() => {
                setSelectedTrigger(cellValue.row.original as AlertTriggers);
                setTriggerModalOpen(true);
              }}
            >
              {t('Acknowledge')}
            </span>
          ) : (
            cellValue?.row?.original.acknowledgedByFirstname
          )}
        </div>
      )
    }
  ];

  const defaultSortState = {
    id: 'status123213',
    desc: false
  };

  const LoadingAlerts = (isFirstRender && isLoading) as boolean;

  // Adding condition for showing different colour for active alerts.
  const getRowStyles = (row: Row<TableColumnConfigs>) => {
    if (row.original.status === 'ACTIVE') {
      return {
        background: 'rgba(218, 112, 41, 0.05)',
        height: '10px'
      };
    }
    return {
      background: 'transparent'
    };
  };

  return (
    <>
      <Modal
        title={
          <ModalHeader>
            <span id="icon">
              <IcoCheckbox />
            </span>
            <span id="heading">{t('Acknowledge')}</span>
          </ModalHeader>
        }
        visible={triggerModalOpen}
        size="xsmall_auto_height"
        widthOverride="30rem"
        onClose={() => {
          setTriggerModalOpen(false);
          setSelectedTrigger(null);
        }}
      >
        <AlertAcknowledgeModal>
          <p id="message">
            {t('Once')} <b>&quot;{selectedTrigger?.alertName}&quot;</b>{' '}
            {t('is acknowledged it can not be undone, are you sure you want to continue')}
          </p>
          <AlertTableButtonWrapper>
            <Button
              variant="link"
              width="8rem"
              borderRadius="13rem"
              borderColor={`${themeColors.primaryBlue5}`}
              onClick={() => {
                setTriggerModalOpen(false);
                setSelectedTrigger(null);
              }}
            >
              {t('cancel')}
            </Button>
            <Button
              variant="hover-blue"
              width="8rem"
              borderRadius="13rem"
              disabled={isTriggerUpdating}
              onClick={onAlertTriggerUpdate}
            >
              <ConfirmButtonTextWrapper>{t('confirm', { ns: 'common' })}</ConfirmButtonTextWrapper>
              {isTriggerUpdating ? (
                <CustomLoader size={'1.2rem'} thickness="0.3rem" color="#ffffff" />
              ) : null}
            </Button>
          </AlertTableButtonWrapper>
        </AlertAcknowledgeModal>
      </Modal>
      <DataRenderer
        isLoading={LoadingAlerts}
        error={hasError && (t('failed_to_load_data', { ns: 'common' }) as string)}
      >
        {hasMessage ? (
          hasMessage
        ) : (
          <TableWrapper>
            {updatedAlertTableData && !hasError && (
              <NewBaseTable
                newTableData={updatedAlertTableData}
                columnConfigs={columns}
                sortState={defaultSortState}
                isShowColumnOptions
                isShowGlobalSearch
                customClassName="historical-alerts"
                textAlign="left"
                maxTableHeight={30}
                tdMarginLeft="0.7rem"
                getRowStyles={getRowStyles}
              />
            )}
          </TableWrapper>
        )}
      </DataRenderer>
    </>
  );
};
