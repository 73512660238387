import React from 'react';

import { SessionAlarmsFlyoutContainer } from './SessionAlarmsFlyout.elements';

import { FilterButtonWithDoropdownItemProps, FilterButtonWithDropdown } from 'common/ui';
import { Flyout } from 'common/ui';

import { useFleetRouter } from 'common/pages/fleetV2/hooks/useFleetRouter';

import { FlyoutItem } from './FlyoutItem';
import { DataLoader } from 'common/components/DataLoader/DataLoader';
import { useSessionAlarmsFlyout } from './hooks/useSessionAlarmsFlyout';

const dropdownItems: Record<string, FilterButtonWithDoropdownItemProps> = {
  'date by ascending': {
    label: 'date by ascending'
  },
  'date by descending': {
    label: 'date by descending'
  },
  'duration by ascending': {
    label: 'duration by ascending'
  },
  'duration by descending': {
    label: 'duration by descending'
  }
};

export const SessionAlarmsFlyout = (): JSX.Element => {
  const [{ subTab }, handle] = useFleetRouter();
  const { alarms, hasMessage, hasError, isLoading, order, setOrder } = useSessionAlarmsFlyout();

  const isOpen = subTab === 'session-alarms' ? true : false;

  const flyoutSettings = {
    handleClose: () => handle('updatePath', { subTab: 'single-session' }),
    title: (
      <>
        <h2>Session Alarms</h2>
        <FilterButtonWithDropdown
          {...{
            selected: order,
            handleSelect: ({ id }: { id?: string }) => id && setOrder(id as string),
            items: dropdownItems
          }}
        >
          Sorting By
        </FilterButtonWithDropdown>
      </>
    ),
    isOpen
  };

  return (
    <Flyout {...flyoutSettings}>
      <SessionAlarmsFlyoutContainer className="flyout-session-salarms">
        <DataLoader {...{ isLoading, hasError, hasMessage }}>
          {alarms?.map((session: Record<string, unknown>, i: number) => {
            return (
              <FlyoutItem
                key={`session${i}`}
                {...{
                  handleClick: () =>
                    handle('updatePath', { subTab: `single-session/${session?.id}` }),
                  ...session
                }}
              />
            );
          })}
        </DataLoader>
      </SessionAlarmsFlyoutContainer>
    </Flyout>
  );
};

/*export const SessionAlarmsFlyoutV1 = (): JSX.Element => {
  const { order, setOrder } = {};
  const [{ subTab }, handle] = useFleetRouter(); //useRouterWithHandler(`${fleetPageRoute}/sessionId`);
  const isOpen = subTab === 'select-session' ? true : false;
  const { alarms } = useChangeoverSessionDetailsAPI();
  const flyoutSettings = {
    handleClose: () => handle('updatePath', { subTab: 'single-session' }),
    title: (
      <>
        <h2>Select Session</h2>
        <FilterButtonWithDropdown
          {...{
            selected: order,
            handleSelect: ({ id }: { id?: string }) => setOrder(id),
            items: dropdownItems
          }}
        />
      </>
    ),
    isOpen
  };

  return (
    <Flyout {...flyoutSettings}>
      <SessionAlarmsFlyoutContainer className="flyout-select-sessions">
        {alarms?.map((alarm: Record<string, unknown>, i: number) => {
          return <FlyoutItem key={`session${i}`} {...alarm} />;
        })}
      </SessionAlarmsFlyoutContainer>
    </Flyout>
  );
};*/
