import React from 'react';
import { useChartGenerator } from '../../hooks';
import { DataPointContainer } from './DataPointsArea.elements';
import { useChartGeneratorTooltip } from '../../hooks/useChartGeneratorToolip/useChartGeneratorTooltip';
import { useChartSelections } from '../../hooks/useChartSelections';

const DataPoint = ({
  groupKey,
  categoryKey,
  valueKey,
  color,
  isHoverLayer,
  ...data
}: {
  value?: number;
  group?: string;
  category?: string;
  categoryKey?: string;
  valueKey?: string;
  color?: string;
  groupKey?: string;
  isHoverLayer?: boolean;
  data?: Record<string, unknown>;
}): JSX.Element => {
  const { setTooltip } = useChartGeneratorTooltip();

  const selectHandler = useChartSelections()[1];

  const { getScale, size, colors, ...keys } = useChartGenerator();

  if (!data) return <></>;

  groupKey = (groupKey || keys?.groupKey) as string;
  categoryKey = (categoryKey || keys?.categoryKey) as string;
  valueKey = (valueKey || keys?.valueKey) as string;

  const group = (data as Record<string, unknown>)?.[groupKey as string] as string | number;
  const category = (data as Record<string, unknown>)?.[categoryKey] as string | string;
  const value = (data as Record<string, unknown>)?.[valueKey] as number | string;

  color = color || colors?.[group as string];

  const formattedGroupId = group ? String(group).split(' ').join('-') : undefined;

  const className = `data-point data-point--${formattedGroupId}`;

  const isFiltered = groupKey && selectHandler('checkIfFiltered', { [groupKey]: group });

  const top: string | number = getScale('y', value || 0) as number;
  const left: string | number = getScale('x', category || 0) as number;

  let position: Record<string, unknown> = {
    top: `${top}px`,
    left: `${left}px`,
    color: (color || 'red') as string,
    className,
    size
  };

  if (isFiltered) position.className += ' is-filtered';
  else position.className += ' is-not-filtered';

  if (isHoverLayer) {
    position.className += ' hover-item';
    position.color = 'transparent';
    position = {
      ...position,
      size,
      onMouseEnter: () =>
        setTooltip({
          top: top - 10,
          left,
          data: {
            groupKey,
            categoryKey,
            valueKey,
            color,
            isFiltered,
            group,
            category,
            value,
            ...data
          }
        }),
      onMouseLeave: () => setTooltip(undefined),
      onClick: () => selectHandler('toggle', { [groupKey]: group })
    };
  }

  return <DataPointContainer {...position} />;
};

export const DataPointsArea = (): JSX.Element => {
  const { indexedData } = useChartGenerator() as {
    indexedData?: Record<string, unknown>[];
    valueKeys?: string[];
  };

  if (!indexedData) return <></>;

  const Area = ({ isHoverLayer }: { isHoverLayer?: boolean }) => (
    <>
      {indexedData?.map((data: Record<string, unknown>, index: number) => (
        <DataPoint key={index} {...data} isHoverLayer={isHoverLayer} />
      ))}
    </>
  );

  return (
    <>
      <Area isHoverLayer />
      <Area />
    </>
  );
};
