'use strict';
// 3rd party libs
import React, { useEffect, useMemo, useState, FC, ReactElement, useRef, useCallback } from 'react';
import {
  ColumnSort,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  SortingState,
  Column,
  Row,
  CellContext,
  ColumnOrderState,
  Header,
  Table,
  getFilteredRowModel,
  getExpandedRowModel,
  BuiltInSortingFn,
  SortingFn,
  ExpandedState,
  RowData
} from '@tanstack/react-table';
import { QueryClient, QueryClientProvider, useInfiniteQuery } from '@tanstack/react-query';
import { useDrag, useDrop } from 'react-dnd';
import { default as theme } from 'themes';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faGripVertical } from '@fortawesome/free-solid-svg-icons';
import Filter from '../../img/table-filter-icon.svg';
import ClearFilter from '../../img/clear_filter.svg';
import camelCase from 'lodash/camelCase';
import { isEmpty, cloneDeep } from 'lodash';
import { useVirtual } from 'react-virtual';
import { BaseType, ModalSize, TableColumnConfigs } from 'types';
import {
  Typography,
  Modal,
  Checkbox,
  Button,
  StyledTable,
  StyledColumnRefContainer,
  StyledNonDraggableColumnRefContainer,
  StyledDragRefContainer,
  StyledTFoot,
  StyledAscDescContainer,
  OptionWrapper,
  ColumnOption,
  TableFilterOption,
  ImageOptionWrapper,
  ImageOption,
  OptionsModalContainer,
  SelectionHeader,
  OptionalColumnContainer,
  OptionColumnCheckboxWrapper,
  OptionalColumnName,
  SelectAllOptionalColumn,
  ModalButtonsContainer,
  StyledRootContainer,
  TooltipWrapper,
  DebounceInput,
  Loader,
  Wrapper,
  TableHeaderRule,
  TableCoverRule,
  ColumnOptionWrapper,
  SearchIconWrapper
} from 'components';
import { useTanStackFilterOptions } from 'selectors';
//column cofig
import { useTranslation } from 'react-i18next';
import { convertCamelCaseintoTitleCase } from 'helpers';
import styled, { CSSProperties } from 'styled-components';
import DropdownOption, { OptionType } from 'common/components/DropdownOption';
import { DataNotFound } from 'components/DataNotFound';
import { tanStackTableMultiFilterOption } from 'reducers/tanStackTable';
import AdvanceFilterPopUp, { AdvanceFilterOptionType } from 'common/components/AdvanceFilterPopUp';
import { IconSearch } from 'icons/IconSearch';
import IcoTableColumnOption from 'icons/IcoTableColumnOption';

export interface ColumnConfig extends BaseType {
  id: string;
  header: string | ReactElement;
  isEnableSorting: boolean;
  isSelected: boolean;
  renderer: (cellValue: CellContext<TableColumnConfigs, string>) => JSX.Element | React.ReactNode;
  customSort?: BuiltInSortingFn | SortingFn<TableColumnConfigs>;
  disableGlobalSearch?: boolean;
}

export interface SortState extends BaseType {
  id: string;
  desc: boolean;
}

interface NewBaseTableProps {
  newTableData: TableColumnConfigs[];
  columnConfigs: ColumnConfig[];
  sortState: SortState;
  enableFilter?: boolean;
  enableAdvanceFilter?: boolean;
  handleFilterRequest?: (val: string) => void;
  handleAdvanceFilterRequest?: (val: AdvanceFilterOptionType[]) => void;
  isShowColumnOptions?: boolean;
  isShowGlobalSearch?: boolean;
  isDataLoading?: boolean;
  removeColumnOption?: boolean;
  tdMarginLeft?: string;
  textAlign?: 'left' | 'center';
  padding?: string;
  tableHeadBtn?: JSX.Element;
  shadow?: boolean;
  customClassName?: string;
  disableVirtualize?: boolean;
  hideTotalFetchCount?: boolean;
  maxTableHeight?: number;
  searchPlaceholder?: string;
  showTableCoverRule?: boolean;
  showTableHeaderRule?: boolean;
  searchInputValue?: (input?: string, searchKey?: string) => void;
  getRowStyles?: (row: Row<TableColumnConfigs>) => CSSProperties;
}

interface OptionalColumnsState {
  key: string;
  name: string;
  isSelected?: boolean;
  meta?: { [key: string]: boolean | string } | (() => { [key: string]: boolean | string });
}
type RowDataResponse = {
  data: TableColumnConfigs[];

  meta: {
    totalRowCount: number;
  };
};
declare module '@tanstack/react-table' {
  interface TableMeta<TData extends RowData> {
    getRowStyles: (row: Row<TData>) => CSSProperties;
  }
}

const keyPrefix = 'health-data';
const columnHelper = createColumnHelper<TableColumnConfigs>();

const addKeysToData = (keyPrefix: string, data: TableColumnConfigs[]) => {
  return data.map((item) => {
    return {
      ...item,
      key: `${keyPrefix}-${item.id}`
    };
  });
};

const reorderColumn = (
  draggedColumnId: string,
  targetColumnId: string,
  columnOrder: string[]
): ColumnOrderState => {
  columnOrder.splice(
    columnOrder.indexOf(targetColumnId),
    0,
    columnOrder.splice(columnOrder.indexOf(draggedColumnId), 1)[0] as string
  );
  return [...columnOrder];
};

const defaultSortIcons = (isSorted: string) => {
  return (
    {
      asc: (
        <StyledAscDescContainer>
          <div>
            <FontAwesomeIcon icon={faChevronUp} size="xs" />
          </div>
          <div>
            <FontAwesomeIcon icon={faChevronDown} style={{ visibility: 'hidden' }} size="xs" />
          </div>
        </StyledAscDescContainer>
      ),
      desc: (
        <StyledAscDescContainer>
          <div>
            <FontAwesomeIcon icon={faChevronDown} size="xs" />
          </div>
          <div>
            <FontAwesomeIcon icon={faChevronUp} style={{ visibility: 'hidden' }} size="xs" />
          </div>
        </StyledAscDescContainer>
      )
    }[isSorted] ?? (
      <StyledAscDescContainer>
        <div>
          <FontAwesomeIcon icon={faChevronUp} size="xs" />
        </div>
        <div>
          <FontAwesomeIcon icon={faChevronDown} size="xs" />
        </div>
      </StyledAscDescContainer>
    )
  );
};

const DraggableColumnHeader: FC<{
  header: Header<TableColumnConfigs, unknown>;
  table: Table<TableColumnConfigs>;
  tableColumns: OptionalColumnsState[];
  handleOptionChange: (name?: string, isSelected?: boolean) => void;
}> = ({ header, table, tableColumns, handleOptionChange }) => {
  const { getState, setColumnOrder } = table;
  const { columnOrder } = getState();
  const { column } = header;

  const [{ isOver }, dropRef] = useDrop({
    accept: 'column',
    drop: (draggedColumn: Column<TableColumnConfigs>) => {
      const newColumnOrder = reorderColumn(draggedColumn.id, column.id, columnOrder);
      setColumnOrder(newColumnOrder);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  });

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
    item: () => column,
    type: 'column'
  });

  return (
    <StyledColumnRefContainer
      ref={dropRef}
      style={{
        opacity: isDragging ? 0 : 1
      }}
      key={header.id}
    >
      <div ref={previewRef}>
        {header.isPlaceholder ? null : (
          <StyledDragRefContainer ref={dragRef}>
            <OptionColumnCheckboxWrapper
              key={header.column.id}
              style={{
                backgroundColor: isOver ? 'lightgrey' : 'white'
              }}
            >
              <Checkbox
                key={header.column.id}
                id={`${header.column.id}-${header.index}`}
                width={20}
                height={20}
                checked={
                  tableColumns?.find((tableColumn) => tableColumn.key === column.id)?.isSelected
                }
                disabled={
                  tableColumns?.find((tableColumn) => tableColumn.key === column.id)?.meta
                    ?.isAlwaysVisible
                }
                onChange={() => {
                  const opt = tableColumns?.find((tableColumn) => tableColumn.key === column.id);
                  handleOptionChange(opt?.name, opt?.isSelected);
                }}
              />
              <OptionalColumnName>
                {convertCamelCaseintoTitleCase(header.column.id)}
              </OptionalColumnName>
              <FontAwesomeIcon
                icon={faGripVertical}
                color={theme.colors.darkBlue}
                style={{ marginLeft: 'auto', marginTop: 'auto', marginBottom: 'auto' }}
                size="xs"
              />
            </OptionColumnCheckboxWrapper>
          </StyledDragRefContainer>
        )}
      </div>
    </StyledColumnRefContainer>
  );
};

export const TableContainer = styled.div`
  width: auto;
  z-index: 5;
  @media (min-width: 2160px) {
    max-height: 1442rem;
  }
`;
export const FetchDetail = styled.div`
  color: #0076cc;
  margin-top: 12px;
  margin-bottom: -6px;
  padding-left: 10px;
`;

function isAllColumnOptionsVisible(array: OptionalColumnsState[]) {
  // Iterate through each object in the array
  for (let i = 0; i < array.length; i++) {
    // Check if isSelected property is false
    if (!array[i].isSelected) {
      return false; // If any object's isSelected is false, return false
    }
  }
  return true; // If all objects' isSelected are true, return true
}

const TanstackTable = (props: NewBaseTableProps): JSX.Element => {
  const filterOptions = useTanStackFilterOptions();
  const { t } = useTranslation(['common']);
  const {
    newTableData,
    columnConfigs,
    sortState,
    enableFilter,
    enableAdvanceFilter,
    handleFilterRequest,
    handleAdvanceFilterRequest,
    isShowColumnOptions,
    isShowGlobalSearch,
    isDataLoading,
    removeColumnOption,
    tdMarginLeft,
    textAlign,
    padding,
    tableHeadBtn,
    shadow,
    customClassName,
    hideTotalFetchCount,
    disableVirtualize,
    maxTableHeight,
    searchPlaceholder,
    showTableCoverRule,
    showTableHeaderRule,
    searchInputValue,
    getRowStyles
  } = props;

  const [dataTable, setDataTable] = useState(() => (newTableData ? [...newTableData] : []));
  const [originalData, setOriginalData] = useState(() => (newTableData ? [...newTableData] : []));
  const [editedRows, setEditedRows] = useState({});

  const [sorting, setSorting] = useState<SortingState>([sortState]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [showColumnOptions, setShowColumnOptions] = useState<boolean>(false);
  const [tableColumns, setTableColumns] = useState<OptionalColumnsState[]>([]);
  const [tempTableColumns, setTempTableColumns] = useState<OptionalColumnsState[]>([]);
  const [selectAllOptionalColumns, setSelectAllOptionalColumns] = useState<boolean>(
    tableColumns.length !== 0 && isAllColumnOptionsVisible(tableColumns)
  );
  const [expanded, setExpanded] = React.useState<ExpandedState>({});

  const [showFilter, setShowFilter] = useState<boolean>(enableFilter as boolean);
  const [showAdvanceFilter, setShowAdvanceFilter] = useState<boolean>(
    enableAdvanceFilter as boolean
  );
  const [showClearFilter, setShowClearFilter] = useState<boolean>(false);
  const [showClearAdvanceFilter, setShowClearAdvanceFilter] = useState<boolean>(false);

  const [globalFilter, setGlobalFilter] = React.useState('');

  /*
   * Adding this function for getting searchable
   * columns from columnconfig.
   * by default global search works on all columns
   * if you want to remove global search from specific column
   * you have to pass key `disableGlobalSearch = true`
   */

  const searchableCol = useMemo(() => {
    return columnConfigs
      .filter((columnConfig) => {
        return !columnConfig.disableGlobalSearch;
      })
      .map((data) => data.id);
  }, []);

  let columns = columnConfigs.map((columnConfig) => {
    const metaData = {};
    //Backward compatibility for old column configs
    metaData['isAlwaysVisible'] = columnConfig.isAlwaysVisible || false;
    //Dynamicly set keys and values that are stores in meta object
    for (const [key, value] of Object.entries(columnConfig.meta || {})) {
      metaData[key] = value;
    }

    return columnHelper.accessor(columnConfig?.id, {
      id: columnConfig.id,
      header: () => columnConfig.header,
      cell: (info: CellContext<TableColumnConfigs, string> | JSX.Element) => {
        if (typeof columnConfig.cell === 'function') {
          const Cell = columnConfig.cell;
          return <Cell {...info} />;
        } else {
          return <span className="cell">{columnConfig.renderer(info)}</span>;
        }
      },
      enableSorting: columnConfig.isEnableSorting,
      meta: metaData,
      enableGlobalFilter: searchableCol.includes(columnConfig.id),
      sortingFn: columnConfig.customSort || 'basic'
    });
  });

  const fetchSize = !disableVirtualize ? 20 : newTableData?.length;

  useEffect(() => {
    columns.forEach((opt) => {
      const targetCol = columnConfigs.find((colConfig) => colConfig.id === opt.id);
      tableColumns.push({
        key: camelCase(opt.id),
        name: opt.id || '',
        isSelected: targetCol?.isSelected,
        meta: { ...opt?.meta }
      });
    });

    setSelectAllOptionalColumns(isAllColumnOptionsVisible(tableColumns));
  }, []);
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(
    columns.map((column) => column.id as string)
  );

  const tableData = useMemo(() => {
    if (!newTableData?.length) return [];

    return addKeysToData(keyPrefix, newTableData);
  }, [newTableData]);

  const triggerShowColumnOptions = (): void => {
    const newTableColumns = cloneDeep(tableColumns);
    const activeColumns: OptionalColumnsState[] = [];
    newTableColumns.forEach((col) => {
      if (col.key !== 'isDummy') activeColumns.push(col);
    });
    setTempTableColumns(newTableColumns);
    setTableColumns(activeColumns);
    setShowColumnOptions(true);
  };

  const onCloseCommentModal = () => {
    /* eslint-disable  @typescript-eslint/no-non-null-assertion */
    setTableColumns(tempTableColumns);
    setShowColumnOptions!(false);
  };

  const handleOptionChange = (name?: string, isSelected?: boolean) => {
    tableColumns.forEach((col) => {
      if (col.name === name) {
        col.isSelected = !isSelected;
      }
    });
    setSelectAllOptionalColumns(isAllColumnOptionsVisible(tableColumns));
    setTableColumns([...tableColumns]);
  };

  const handleSelectAllOptionsChange = () => {
    tableColumns
      .filter((col) => col.meta?.isAlwaysVisible === false)
      .forEach((col) => {
        col.isSelected = !selectAllOptionalColumns;
      });
    setTableColumns([...tableColumns]);
    setSelectAllOptionalColumns(isAllColumnOptionsVisible(tableColumns));
  };

  if (!isEmpty(tableColumns)) {
    columns = columns.filter((column) => {
      const compareItem = tableColumns.find(
        (tableColumn) => tableColumn.key === camelCase(column.id)
      );
      return compareItem && compareItem.isSelected;
    });
  }
  // get row data
  const fetchData = (
    start: number,
    size: number,
    sorting: SortingState
  ): {
    data: TableColumnConfigs[];
    meta: {
      totalRowCount: number;
    };
  } => {
    const dbData = [...tableData];
    const sort = sorting[0] as ColumnSort;
    const { id, desc } = sort as { id: string; desc: boolean };
    if (id) {
      dbData.sort((a, b) => {
        if (desc) {
          return a[id] < b[id] ? 1 : -1;
        }
        return a[id] > b[id] ? 1 : -1;
      });
    }
    return {
      data: dbData.slice(start, start + size),
      meta: {
        totalRowCount: dbData?.length
      }
    };
  };
  //react-query has an useInfiniteQuery hook just for this situation!
  const { data, fetchNextPage, isFetching } = useInfiniteQuery<RowDataResponse>(
    ['table-data', sorting, globalFilter, newTableData], //adding sorting state as key causes table to reset and fetch from new beginning upon sort
    async ({ pageParam = 0 }) => {
      const start = pageParam * fetchSize;
      if (sorting.length === 0) {
        const fetchedData = fetchData(start, fetchSize, [sortState]); //pretend api call
        return fetchedData;
      } else {
        const fetchedData = fetchData(start, fetchSize, sorting); //pretend api call
        return fetchedData;
      }
    },
    {
      getNextPageParam: (_lastGroup, groups) => groups.length,
      keepPreviousData: true,
      refetchOnWindowFocus: false
    }
  );

  //we must flatten the array of arrays from the useInfiniteQuery hook
  const flatData = useMemo(() => data?.pages?.flatMap((page) => page.data) ?? [], [data]);

  const totalDBRowCount = data?.pages?.[0]?.meta?.totalRowCount ?? 0;
  const totalFetched = flatData.length;
  //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        //once the user has scrolled within 300px of the bottom of the table, fetch more data if there is any
        if (
          scrollHeight - scrollTop - clientHeight < 300 &&
          !isFetching &&
          totalFetched < totalDBRowCount
        ) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched, totalDBRowCount]
  );

  //a check on mount and after a fetch to see if the table is already scrolled to the bottom and immediately needs to fetch more data
  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  const table = useReactTable({
    data: flatData,
    columns,
    state: {
      sorting,
      columnVisibility,
      columnOrder,
      globalFilter,
      expanded
    },
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows,
    onSortingChange: setSorting,
    onColumnOrderChange: setColumnOrder,
    getCoreRowModel: getCoreRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    meta: {
      editedRows,
      setEditedRows,
      revertData: (rowIndex: number, revert: boolean) => {
        if (revert) {
          setDataTable((old) =>
            old.map((row, index) => (index === rowIndex ? originalData[rowIndex] : row))
          );
        } else {
          setOriginalData((old) =>
            old.map((row, index) => (index === rowIndex ? dataTable[rowIndex] : row))
          );
        }
      },
      updateData: (rowIndex: number, columnId: string, value: string) => {
        setDataTable((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value
              };
            }
            return row;
          })
        );
      }
    }
    //debugTable: true,
    //debugHeaders: true,
    //debugColumns: true
  });
  const handleFilterOptionChange = (val: string): void => {
    /* eslint-disable  @typescript-eslint/no-non-null-assertion */
    handleFilterRequest!(val);
    setShowFilter(!showFilter);
    setShowClearFilter(true);
  };

  const handleAdvanceFilterOptionChange = (val: AdvanceFilterOptionType[]): void => {
    /* eslint-disable  @typescript-eslint/no-non-null-assertion */
    handleAdvanceFilterRequest!(val);
  };

  const handleFilterSelect = (option?: OptionType) => {
    handleFilterOptionChange(option?.value || '');
  };

  const handleAdvanceFilterSelect = (option: AdvanceFilterOptionType[]) => {
    handleAdvanceFilterOptionChange(option);
  };

  const handleClearFilterClick = () => {
    setShowFilter(!showFilter);
    setShowClearFilter(false);
    /* eslint-disable  @typescript-eslint/no-non-null-assertion */
    handleFilterRequest!('All');
  };

  const handleClearAdvanceFilterClick = () => {
    setShowAdvanceFilter(!showAdvanceFilter);
    setShowClearAdvanceFilter(false);
    /* eslint-disable  @typescript-eslint/no-non-null-assertion */
    handleAdvanceFilterRequest!([]);
  };

  // const handleSearchValueChange = (value: string[]): void => {
  //   // should be removed when method is implemented
  //   console.log('Not yet implemented:...', value);
  // };
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const { rows } = customClassName === 'userTable' ? table.getCoreRowModel() : table.getRowModel();

  //Virtualizing is optional, but might be necessary if we are going to potentially have hundreds or thousands of rows
  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: !disableVirtualize ? 10 : rows.length
  });
  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;
  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;

  return (
    <StyledRootContainer className={customClassName}>
      {showTableCoverRule && <TableCoverRule />}
      {removeColumnOption ? null : (
        <ColumnOption shadow={shadow} className="customcss">
          {tableHeadBtn}
          <OptionWrapper>
            <TableFilterOption>
              {showFilter && (
                <DropdownOption
                  searchable
                  icon={Filter}
                  handleOptionSelect={handleFilterSelect}
                  options={filterOptions as { value: string; label: string }[]}
                  searchInputValue={searchInputValue}
                />
              )}
              {showClearFilter && (
                <ImageOptionWrapper>
                  <TooltipWrapper Tooltip="Clear Filter">
                    <ImageOption
                      src={ClearFilter}
                      onClick={handleClearFilterClick}
                      marginTop="-0.25rem"
                      marginLeft="-0.1rem"
                    />
                  </TooltipWrapper>
                </ImageOptionWrapper>
              )}

              {showAdvanceFilter && (
                <AdvanceFilterPopUp
                  searchable
                  handleOptionSelect={handleAdvanceFilterSelect}
                  options={filterOptions as tanStackTableMultiFilterOption[]}
                  searchInputValue={searchInputValue}
                />
              )}
              {showClearAdvanceFilter && (
                <ImageOptionWrapper>
                  <TooltipWrapper Tooltip="Clear Filter">
                    <ImageOption
                      src={ClearFilter}
                      onClick={handleClearAdvanceFilterClick}
                      marginTop="-0.25rem"
                      marginLeft="-0.1rem"
                    />
                  </TooltipWrapper>
                </ImageOptionWrapper>
              )}
            </TableFilterOption>
          </OptionWrapper>
          <Wrapper>
            {isShowColumnOptions && (
              <OptionWrapper
                onClick={() => {
                  /* eslint-disable  @typescript-eslint/no-non-null-assertion */
                  triggerShowColumnOptions!();
                }}
              >
                <ColumnOptionWrapper>
                  <IcoTableColumnOption width="16" />
                  <span>
                    {t('Column options')} (
                    {
                      table.getHeaderGroups()[0].headers.filter((head) => head.id !== 'action')
                        .length
                    }{' '}
                    {t('Columns')})
                  </span>
                </ColumnOptionWrapper>
              </OptionWrapper>
            )}

            {isShowGlobalSearch && (
              <OptionWrapper>
                <SearchIconWrapper>
                  <IconSearch width="15" />
                </SearchIconWrapper>
                <DebounceInput
                  value={globalFilter ?? ''}
                  restrictSpaceFromStart={true}
                  onChange={(value) => {
                    searchInputValue?.(String(value), 'global');
                    setGlobalFilter(String(value));
                  }}
                  placeholder={searchPlaceholder || (t('search') as string)}
                  style={{
                    paddingLeft: '1.4rem'
                  }}
                  className="globalSearchInput"
                />
              </OptionWrapper>
            )}
          </Wrapper>
        </ColumnOption>
      )}
      {showTableHeaderRule && <TableHeaderRule />}
      <Modal
        onClose={onCloseCommentModal}
        size={ModalSize.XXSMALL_AUTO_HEIGHT}
        title="Column Options"
        visible={showColumnOptions as boolean}
        widthOverride="25rem"
      >
        <OptionsModalContainer>
          <SelectionHeader>
            <Typography variant="body2" color={theme.colors.darkGrey}>
              Select columns to show
            </Typography>
          </SelectionHeader>

          <OptionalColumnContainer>
            {table.getHeaderGroups().map((headerGroup) => (
              <div key={headerGroup.id}>
                {headerGroup.headers.map(
                  (header) =>
                    header.id !== 'action' && (
                      <DraggableColumnHeader
                        key={header.id}
                        header={header}
                        table={table}
                        tableColumns={tableColumns}
                        handleOptionChange={handleOptionChange}
                      />
                    )
                )}
              </div>
            ))}

            {tableColumns.map((opt, index) => {
              const headerGroup = table.getHeaderGroups()[0];
              const headers = headerGroup?.headers;
              const header = headers?.find((header) => header.id === opt.name);

              return header ? (
                ''
              ) : (
                <StyledNonDraggableColumnRefContainer key={index}>
                  <OptionColumnCheckboxWrapper>
                    <Checkbox
                      key={index}
                      id={`${opt.name}-${index}`}
                      width={20}
                      height={20}
                      checked={opt.isSelected}
                      onChange={() => {
                        handleOptionChange(opt.name, opt.isSelected);
                      }}
                    />
                    <OptionalColumnName>
                      {convertCamelCaseintoTitleCase(t(opt.name))}
                    </OptionalColumnName>
                  </OptionColumnCheckboxWrapper>
                </StyledNonDraggableColumnRefContainer>
              );
            })}
          </OptionalColumnContainer>
          <SelectAllOptionalColumn>
            <Checkbox
              id={`opt-all`}
              width={20}
              height={20}
              checked={selectAllOptionalColumns}
              onChange={() => {
                handleSelectAllOptionsChange();
              }}
            />
            <OptionalColumnName htmlFor="checkbox-opt-all">Select All</OptionalColumnName>
          </SelectAllOptionalColumn>
        </OptionsModalContainer>
        <ModalButtonsContainer>
          <Button onClick={onCloseCommentModal} bgColor={theme.colors.primaryBlue4}>
            Close
          </Button>
        </ModalButtonsContainer>
      </Modal>
      <TableContainer
        style={
          !disableVirtualize
            ? {
                maxHeight: maxTableHeight ? `${maxTableHeight}vh` : '100vh',
                overflow: 'auto'
              }
            : { maxHeight: '100%', overflow: 'auto' }
        }
        onScroll={(e) => fetchMoreOnBottomReached(e.target as HTMLDivElement)}
        ref={tableContainerRef}
      >
        <StyledTable padding={padding} textAlign={textAlign} tdMarginLeft={tdMarginLeft}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : 'disabled-sort',
                            onClick: header.column.getToggleSortingHandler()
                          }}
                          style={{
                            whiteSpace: 'nowrap'
                          }}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {defaultSortIcons(header.column.getIsSorted() as string)}
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <p style={{ visibility: 'hidden', height: '0', margin: '0' }}>
            {isFetching && !disableVirtualize
              ? (document.body.style.overflow = 'hidden')
              : (document.body.style.overflow = 'auto')}
          </p>
          {isDataLoading ? (
            <Loader margin={'2rem 0rem'} size={40} />
          ) : (
            <tbody>
              {paddingTop > 0 ? (
                <tr>
                  <td style={{ height: `${paddingTop}px` }} />
                </tr>
              ) : null}
              {virtualRows.length === 0 ? (
                <tr
                  style={{
                    width: '85%',
                    wordBreak: 'break-all'
                  }}
                >
                  <td
                    colSpan={999}
                    style={{
                      wordBreak: 'break-all',
                      marginTop: '.5rem',
                      fontSize: '1.2rem',
                      border: 'none'
                    }}
                  >
                    <DataNotFound textMsg={t('nothing_to_show') || ''} />
                  </td>
                </tr>
              ) : null}
              {virtualRows.map((virtualRow) => {
                const row = rows[virtualRow.index] as Row<TableColumnConfigs>;

                return (
                  <tr
                    key={row.id}
                    className={`${row.subRows.length ? 'row' : 'row-hasChildren'} ${
                      row.getIsExpanded() ? 'open' : ''
                    }`}
                    style={typeof getRowStyles === 'function' ? getRowStyles(row) : {}}
                  >
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td key={cell.id}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
              {paddingBottom > 0 ? (
                <tr>
                  <td style={{ height: `${paddingBottom}px` }} />
                </tr>
              ) : null}
            </tbody>
          )}

          <StyledTFoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.footer, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </StyledTFoot>
        </StyledTable>
      </TableContainer>
      {virtualRows.length !== 0 && (
        <FetchDetail className="footer-total-count">
          {!hideTotalFetchCount
            ? globalFilter.length > 0
              ? `Showing ${rows.length} of ${totalDBRowCount} Rows`
              : `Showing ${flatData.length} of ${totalDBRowCount} Rows`
            : null}
        </FetchDetail>
      )}
    </StyledRootContainer>
  );
};
const NewBaseTable = (props: NewBaseTableProps): JSX.Element => {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <TanstackTable {...props} />
    </QueryClientProvider>
  );
};

export default NewBaseTable;
