import React from 'react';
import { RouterTabsNav } from 'common/components';

export const MachineHealthViewTabs = (): JSX.Element => {
  const tabsNavSettings: Record<string, unknown> = {
    tabs: [
      {
        label: 'Overview',
        slug: 'overview'
        // the slug for this tab, this will be used to populate url
        // path: `/fleet/machine/${id}/machine-health/overview`,
      },
      {
        label: 'Historical production',
        slug: 'historical-production'
        // the slug for this tab, this will be used to populate url
        // path: `/fleet/machine/${id}/machine-health/production`
      },
      {
        label: 'Alarms',
        slug: 'alarms'
        // path: `/fleet/machine/${id}/machine-health/alarms`
      },
      {
        label: 'Data Analysis',
        slug: 'data-analysis'
        // path: `/fleet/machine/${id}/machine-health/data-analysis`
      }
    ],
    // the route for this page, you can use custom as long as you're the value
    // of the current tab from the url
    //pageRoute: fleetPageRoute,
    // this is the key to get the position of the tab value from the url to compare if current tab is active
    // and for the link
    pathKey: 'tab'
    // basePath
  };

  return <RouterTabsNav {...tabsNavSettings} />;
};
