import theme from 'common/theme';
import styled from 'styled-components';

export const FooterContainer = styled.div`
  &.footer--timerange-stamp {
    font-size: 0.8em;
    color: ${theme.colors.label};
    text-align: center;
    text-transform: capitalize;
    padding: 1em;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.3em;
    line-height: 1;

    svg {
      height: 1.1em;
    }
  }
`;
