// Color and Icon mappping for Proseal
import { getUserTimeZone, isUSTimeZone } from 'helpers';
import { differenceInHours, format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export const useMockData = false;

// This should be coming from API for Proseal
export const recipeMapping = {
  k62: '#0076CC',
  k37: '#013153'
} as Record<string, string>;

export const API_INTERVAL = 10000; //10seconds
export const ZOOMEDINTERVAL = 10; //minutes

export const displayTooltip = (tooltipData: Record<string, unknown>): string => {
  const data = tooltipData;

  const { timestamp, status_category, ppm, ideal_ppm, recipe, color } = data as {
    timestamp: Date;
    status_category: string;
    status: string;
    ppm: number;
    ideal_ppm: number;
    recipe: string;
    color: string;
  };

  const isUSA = isUSTimeZone(getUserTimeZone()); //Affects time formatting AM/PM vs 14:00
  const startDate = format(timestamp, 'MMM do, y'); //todo update to use parseISO
  const startTime = format(timestamp, isUSA ? 'hh:mm:ss a' : 'HH:mm:ss'); //todo update to use parseISO

  return `<div class="tooltip--inner">
              <div class='tooltip--line-item'>
              <div class="tooltip--inner">
                        <div class='tooltip--header'>${startDate} - ${startTime}</div>
                        <div class='key'>
                          <span class='indicator' style="background-color: ${recipeMapping[recipe]}"></span>
                            Recipe:<span class="value">${recipe}</span>
                        </div>
                        <div class='key'>
                          <span class='indicator' style="background-color: ${color}"></span>
                              Status:<span class="value">${status_category}</span>
                          </div>
                        </div>
                        <small>Target: <b>${ideal_ppm}</b>  Actual: <b>${ppm}</b></small>
                </div>
              </div>
          </div>`;
};

function getRandomNumber(min: number, max: number): number {
  // Math.random() generates a number between 0 (inclusive) and 1 (exclusive)
  // Multiply by (max - min + 1) to scale the range
  // Add min to shift the range
  // Use Math.floor to get an integer
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function generateFakeLiveData(date: string): Record<string, unknown>[] {
  const itemDate = new Date(date);

  const testItem = {
    timestamp: new Date(itemDate.setMinutes(itemDate.getMinutes() + 1)).toISOString(),
    status: 'Machine Running',
    status_category: 'Machine Running',
    color: '#00FF00',
    ppm: getRandomNumber(70, 102),
    ideal_ppm: 123,
    recipe: 'k37'
  };
  return [testItem];
}

export function calcModulo(data: Record<string, unknown>): number {
  if (!data) return 0;
  let modulo = 30;
  const dataLength = (data?.length as number) || 0;
  //console.log('dataLength: ', dataLength)
  if (0 < dataLength && dataLength < 10) {
    return (modulo = 1);
  } else if (10 < dataLength && dataLength < 50) {
    return (modulo = 5);
  } else if (dataLength < 50) {
    return (modulo = 10);
  } else if (50 < dataLength && dataLength < 500) {
    return modulo;
  } else if (500 < dataLength && dataLength < 1000) {
    return (modulo = 100);
  } else if (1000 < dataLength && dataLength < 2000) {
    return (modulo = 120);
  } else if (2000 < dataLength) {
    return (modulo = 150);
  }
  return modulo;
}

export function zonedTimeToUTC(timeString: string): string {
  // Create a new Date object using the input time string
  const date = new Date(timeString);

  // Convert the date to an ISO string in UTC
  const utcString = date.toISOString();

  return utcString;
}

export function convertToUTC(dateString: string | Date, timeZone: string): string {
  const date = typeof dateString === 'string' ? new Date(dateString) : dateString;
  // Get the time zone offset in milliseconds
  const timeZoneOffset =
    new Date(date.toLocaleString('en-US', { timeZone })).getTime() - date.getTime();
  //console.log('timeZoneOffset: ', timeZoneOffset) //18000000 14400000
  // Convert to UTC by subtracting the time zone offset
  const utcDate = new Date(date.getTime() + timeZoneOffset); // + or - depends on positive or negative offset
  // Format the date as an ISO string
  const utcString = utcDate.toISOString();
  return utcString;
}

export interface GeneratedChartDataProps {
  ppm_data?: Record<string, unknown>[];
  ideal_ppm_data?: Record<string, unknown>[][];
  recipe_line_data?: Record<string, unknown>[][];
  machine_states_icons_data?: Record<string, unknown>[];
}

export const generateChartData = (
  propsData: Record<string, unknown>[]
): GeneratedChartDataProps => {
  if (!propsData || propsData.length === 0)
    return {
      ideal_ppm_data: undefined,
      recipe_line_data: undefined,
      machine_states_icons_data: undefined
    };

  //Data transformation for our chart
  const filteredArray = [];
  const recipe_line_data = [];
  const ideal_ppm_data = [];

  let recipe_line_datastartIndex = 0;
  let recipe_line_dataendIndex = 1;

  let ideal_ppm_datastartIndex = 0;
  let ideal_ppm_dataendIndex = 1;

  const data = propsData; //in ascending order
  //console.log('DEBUG ---> generateChartData -> ', data)

  for (let i = 0; i < data.length; i++) {
    //This array is for machine state icons
    if (data[i].status != 'Machine Running') {
      filteredArray.push(data[i]);
    } else if (i == data.length - 1 && data[i].status == 'Machine Running') {
      filteredArray.push(data[i]);
    }

    //logic for recipe lines
    const currItem = data[i];
    const nextItem = data[i + 1];

    //Add first index
    // if (i === 0) {
    //   recipe_line_data.push(data.slice(0, 1));
    //   ideal_ppm_data.push(data.slice(0, 1));
    // }

    // //Add last index
    // if (i === data.length - 1) {
    //   recipe_line_data.push(data.slice(data.length - 1));
    //   ideal_ppm_data.push(data.slice(data.length - 1));
    // }

    if (nextItem && currItem.recipe === nextItem.recipe) {
      //do nothing
    } else {
      if (!nextItem) {
        recipe_line_data.push(data.slice(recipe_line_datastartIndex));
      } else {
        recipe_line_dataendIndex = i + 1;
        recipe_line_data.push(data.slice(recipe_line_datastartIndex, recipe_line_dataendIndex));
        recipe_line_datastartIndex = i + 1;
      }
    }

    //logic for ideal_ppm lines
    if (nextItem && currItem.ideal_ppm === nextItem.ideal_ppm) {
      //do nothing
    } else {
      if (!nextItem) {
        ideal_ppm_data.push(data.slice(ideal_ppm_datastartIndex));
      } else {
        ideal_ppm_dataendIndex = i + 1;
        ideal_ppm_data.push(data.slice(ideal_ppm_datastartIndex, ideal_ppm_dataendIndex));
        ideal_ppm_datastartIndex = i + 1;
      }
    }
  }

  return {
    ppm_data: data,
    ideal_ppm_data: ideal_ppm_data,
    recipe_line_data: recipe_line_data,
    machine_states_icons_data: filteredArray //this should inclue calculations for the icons location
  };
};

export const isDateNow = (date: Date | string | number, timezone: string): boolean => {
  //check if zoom end date is in the past
  const now = utcToZonedTime(new Date().toISOString(), timezone);
  const zoomEnd = utcToZonedTime(date, timezone);
  const difference = Math.abs(now.valueOf() - zoomEnd.getTime()) > 5 * 60 * 1000; //5min is grace period
  return difference ? false : true;
};

export const calculateTimeDisplay = (start?: Date, end?: Date): string => {
  if (!start || !end) return '';

  let timeDisplay = '24 Hours';

  const hoursDifference = differenceInHours(end, start);

  if (hoursDifference <= 24) {
    timeDisplay = `Last ${hoursDifference} hours`;
  }

  return timeDisplay;
};
