import * as d3 from 'd3';
import { styledTheme } from 'common/theme';
import backgroundImageUrl from './images/ChartBG.png';
import { renderToString } from 'react-dom/server';
import { secondsToHms } from 'common/pages/fleetV2/machine/aseptic/views/MachineHealth/components/ActualDurationWidget/utils';

/* eslint-disable */
export class ZoomedBrush {
  constructor(
    element,
    dataSettings,
    { margins, rangeX, domainY, width, height, zoomedRangeX, updateZoomedRangeX, defaultBackground }
  ) {
    this.element = element;
    this.brush = null;
    this.customHandles = null;
    this.defaultSelection = null;

    this.width = width;
    this.height = height;

    this.rangeX = rangeX;
    this.domainY = domainY;
    this.updateZoomedRangeX = updateZoomedRangeX;

    const { top, right, bottom, left } = margins;

    this.top = top;
    this.right = right;
    this.bottom = bottom;
    this.left = left;

    const { data, xAxisKey, colorMapKey, colorMap, type } = dataSettings;

    this.xAxisKey = xAxisKey;
    this.colorMapKey = colorMapKey;
    this.colorMap = colorMap;
    this.type = type;
    this.defaultBackground = defaultBackground;

    // Delete svg if already exists
    d3.select(this.element).selectAll('svg').remove();

    // Create and style svg
    this.svg = this.createSVG(this.element, 'zoom-bigbrush');
    if (this.defaultBackground) this.createSVGbackground(this.svg);

    // Create group for X axis
    this.xAxisGroup = this.svg
      .append('g')
      .attr('transform', `translate(0,${this.height - this.bottom})`);

    //if we have Y domain, create a group for it
    if (this.domainY)
      this.yAxisGroup = this.svg.append('g').attr('transform', `translate(${this.left}, 0)`);

    // Create brush
    this.brush = this.activateBrush();

    // Create separate svg for the smaller zoom brush
    this.svgSmall = this.createSVG(this.element, 'zoom-smallbrush', 10);
    // Smaller brush for second svg
    this.brushSecond = this.activateBrush(8);

    this.brushSelection = zoomedRangeX ? zoomedRangeX : this.rangeX;

    //Create X axis
    this.x = this.createXAxis(this.rangeX);
    //Attach and style X axis to svg
    this.createXGroup(this.x);

    this.y = undefined;
    if (this.domainY) {
      //Create Y axis
      this.y = this.createYaxis(this.domainY);
      //Attach and style Y axis to svg
      this.createYGroup(this.y);
    }

    //Draw the flags here
    data && this.type === 'flags' && this.populateIcons(data);
    data && this.type === 'line' && this.populateLine(data);

    //Brush selection
    this.brushSelection = this.brushSelection
      ? [this.x(this.brushSelection[0]), this.x(this.brushSelection[1])]
      : this.rangeX;

    // Main zoom brush
    this.drawMainBrush(this.svg, this.x);

    // Small Zoom brush
    this.drawSecondBrush(this.svgSmall);

    // Define a custom drag behavior
    this.customDrag = d3
      .drag()
      .on('drag', (event) => this.customHandleDragging(event, this.brushSelection, this.x))
      .on('end', (event) => this.customHandleDragEnded(event, this.brushSelection, this.x));
  }

  activateBrush(height) {
    const extent = this.getExtentX(height);
    return d3.brushX().extent(extent);
  }

  createSVG(element, className, height) {
    return d3
      .select(element)
      .append('svg')
      .attr('width', this.width)
      .attr('height', height ?? this.height)
      .attr('class', `${className}`);
  }

  createSVGbackground(svg) {
    // Define the pattern
    const pattern = svg
      .append('defs')
      .append('pattern')
      .attr('id', 'backgroundPattern')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('patternUnits', 'userSpaceOnUse');

    // Embed the PNG image as an <image> element within the pattern
    pattern
      .append('image')
      .attr('xlink:href', backgroundImageUrl) // Replace with the URL to your PNG image
      .attr('width', 1) // Set the width and height to 1 to cover the entire pattern area
      .attr('height', 1);

    svg
      .append('rect')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('fill', 'url(#backgroundPattern)');
  }

  createXAxis(range) {
    // Create X axis
    return d3
      .scaleTime()
      .domain(range)
      .range([this.left, this.width - this.right]);
  }

  createYaxis(range) {
    // Create Y axis
    return d3
      .scaleLinear()
      .domain(range)
      .range([this.height - this.bottom, 0]);
  }

  createXGroup(x) {
    // Call X axis
    const xAxisCall = d3.axisBottom(x).ticks(10);

    return this.xAxisGroup
      .call(xAxisCall)
      .call((g) => g.select('.domain').remove())
      .call((g) =>
        g
          .selectAll('.tick line')
          .clone()
          .attr('y1', 0)
          .attr('y2', 10)
          .transition()
          .duration(500)
          .attr('stroke-opacity', 0.1)
      )
      .call((g) =>
        g
          .selectAll('.tick text')
          .attr('fill', styledTheme.colors.gray)
          .attr('transform', `translate(0, 10)`)
      )
      .call((g) => g.selectAll('.tick line:first-child').remove());
  }

  createYGroup(y) {
    // Call Y axis
    const yAxisCall = d3.axisLeft(y).ticks(2);
    this.yAxisGroup
      .call(yAxisCall)
      .attr('fill', styledTheme.colors.gray)
      .call((g) => g.select('.domain').remove())
      .call((g) => g.selectAll('.tick line').remove())
      .call((g) => g.selectAll('.tick text').remove());
  }

  customHandleDragging(event, brushSelection, x) {
    let selection = brushSelection;

    const allSvg = d3.select(this.element).selectAll('svg');
    const svgWidth = parseInt(allSvg.attr('width'));
    const handleWidth = 4;

    //Checking so X doesn't go beyond svg boundaries
    let wCoord; //handle
    let eCoord; //handle

    let wSelection;
    let eSelection;
    if (event.x < 4) {
      wCoord = 1;
      wSelection = handleWidth + 1;
    } else if (svgWidth - 4 < event.x) {
      eCoord = svgWidth - handleWidth - 5;
      eSelection = svgWidth - handleWidth - 1;
    } else {
      wCoord = event.x - handleWidth;
      eCoord = event.x - handleWidth;
      wSelection = event.x;
      eSelection = event.x;
    }

    //Update position of handles
    if (event.subject.type == 'w') {
      allSvg.selectAll('.custom-west-handle').attr('x', wCoord);
      selection[0] = wSelection;
    } else if (event.subject.type == 'e') {
      allSvg.selectAll('.custom-east-handle').attr('x', eCoord);
      selection[1] = eSelection;
    }

    brushSelection = selection;
    this.updateBrushSelection(brushSelection);

    this.brush &&
      d3
        .select(this.element)
        .selectAll('svg')
        .select('.brush')
        .call(this.brush.move, brushSelection);

    //Position pill container
    allSvg
      .selectAll('.handle-pill')
      .attr('x', () => brushSelection[0] + ((brushSelection[1] - brushSelection[0]) / 2 - 40)); //-40 is a half of the width of a pill

    //Position pill text
    allSvg
      .selectAll('.handle-pill-text')
      .attr('x', () => {
        const start = brushSelection[0];
        const end = brushSelection[1];
        //we need to calculate duration, sp that we place it correctly on x axis
        const duration =
          Number(
            secondsToHms((x.invert(end).getTime() - x.invert(start).getTime()) / 1000).length
          ) * 2.8;
        const position = (brushSelection[1] - brushSelection[0]) / 2 - duration;
        return brushSelection[0] + position;
      })
      .text(() => {
        const start = x.invert(brushSelection[0]);
        const end = x.invert(brushSelection[1]);
        const duration = secondsToHms((end.getTime() - start.getTime()) / 1000);
        return duration;
      });
  }

  customHandleDragEnded(event, brushSelection, x) {
    const range = [x.invert(brushSelection[0]), x.invert(brushSelection[1])];
    this.updateZoomedRangeX(range);
  }

  drawMainBrush(svg, x) {
    const selection = this.getCurrentSelection();

    // Append the brush to the chart
    const brushGroup = svg
      .append('g')
      .attr('class', 'brush')
      .call(this.brush)
      .call(this.brush.move, selection);

    // Below prevents default brush actions and let us use only custom handle actions
    brushGroup
      .selectAll('.selection')
      .attr('class', 'selection brush-selection')
      .style('pointer-events', 'none');
    brushGroup.select('.overlay').style('pointer-events', 'none');
    brushGroup.selectAll('.handle').style('pointer-events', 'none');

    // Append a custom handle as a rectangle
    const customHandleHeight = 28; // Set your desired handle height
    brushGroup
      .selectAll('.handle-custom')
      .data([{ type: 'w' }, { type: 'e' }])
      .enter()
      .append('rect')
      .attr('class', 'handle-custom')
      .attr('width', 8) // Set the width of the handle
      .attr('y', 18)
      .attr('height', customHandleHeight) // Set the height of the custom handle
      .attr('cursor', 'ew-resize');

    //Assign an individual class to each brush handle
    brushGroup.selectAll('.handle-custom').each(function (d, i) {
      // Here you can use i or d to differentiate handles and assign classes
      if (i === 0) {
        // Assuming the first handle is the "west" handle
        d3.select(this).classed('custom-west-handle', true);
      } else if (i === 1) {
        // Assuming the second handle is the "east" handle
        d3.select(this).classed('custom-east-handle', true);
      }
    });

    //Append time counter pill to selection
    svg
      .append('rect')
      .attr('class', 'handle-pill')
      .attr('height', 18)
      .attr('width', 80)
      .attr('y', -1);

    //Text inside pill
    svg
      .append('text')
      .attr('class', 'handle-pill-text')
      .attr('font-size', 12)
      .attr('fill', '#ffffff')
      .attr('y', 12);

    //Position handles
    svg
      .selectAll('.handle-custom')
      .attr('x', (d, i) => (i === 0 ? selection[0] - 4 : selection[1] - 4));

    //Position pill container
    svg
      .selectAll('.handle-pill')
      .attr('x', () => selection[0] + ((selection[1] - selection[0]) / 2 - 40)); //-40 is a half of the width of a blue "pill"

    //Position pill text
    svg
      .selectAll('.handle-pill-text')
      .text(() => {
        const start = x.invert(selection[0]);
        const end = x.invert(selection[1]);
        const duration = secondsToHms((end.getTime() - start.getTime()) / 1000);
        return duration;
      })
      .attr('x', () => {
        const start = x.invert(selection[0]);
        const end = x.invert(selection[1]);
        const duration =
          Number(secondsToHms((end.getTime() - start.getTime()) / 1000).length) * 2.8;
        const position = (selection[1] - selection[0]) / 2 - duration;
        return selection[0] + position;
      });
  }

  drawSecondBrush(svg) {
    const selection = this.getCurrentSelection();

    // Append the brush to the chart
    const brushGroup = svg
      .append('g')
      .attr('class', 'brush')
      .call(this.brushSecond)
      .call(this.brushSecond.move, selection);

    // Below prevents default brush actions and let us use only custom handle actions
    brushGroup
      .selectAll('.selection')
      .attr('class', 'selection brush-selection')
      .style('pointer-events', 'none');
    brushGroup.select('.overlay').style('pointer-events', 'none');
    brushGroup.selectAll('.handle').style('pointer-events', 'none');
  }

  getCurrentSelection() {
    return this.brushSelection;
  }

  getExtentX(height) {
    let extendBrush;
    !height
      ? (extendBrush = [
          [this.left, 7],
          [this.width - this.right, this.height - this.bottom + 10]
        ])
      : (extendBrush = [
          [0, 0],
          [this.width - this.right, height]
        ]);

    return extendBrush;
  }

  populateIcons(data) {
    if (!data) return;
    // Add icons
    this.svg
      .append('g')
      .attr('class', 'group--label-container')
      .attr('font-family', 'sans-serif')
      .attr('font-size', 12)
      .selectAll()
      .data(data)
      .join('foreignObject')
      .attr('x', (d) => this.x(d[this.xAxisKey]))
      .attr('y', '5')
      .attr('class', 'icon')
      .attr('width', 28)
      .attr('height', 28)
      .append('xhtml:div')
      .style('position', 'absolute')
      .html((d) => {
        const icon = renderToString(this.colorMap[d[this.colorMapKey]]);
        return `<div class="icon--inner">${icon}</div>`;
      });
  }

  populateLine(data) {
    if (!data) return;

    const color = d3
      .scaleSequential(d3.interpolate('white', 'rgba(134, 134, 134, 0.8)'))
      .domain([0, 1]);
    const defs = this.svg.append('defs');

    const gradient = defs
      .append('linearGradient')
      .attr('id', 'area-gradient')
      .attr('gradientUnits', 'userSpaceOnUse')
      .attr('x1', 0)
      .attr('y1', 50) // Starts at the bottom of the SVG
      .attr('x2', 0)
      .attr('y2', 0); // Ends at the top of the SVG

    // Set up the gradient stops based on your data or preferences
    gradient
      .append('stop')
      .attr('offset', '0%') // Bottom of the SVG
      .attr('stop-color', color(0)); // Color at the lowest data value

    gradient
      .append('stop')
      .attr('offset', '100%') // Top of the SVG
      .attr('stop-color', color(1)); // Color at the highest data value

    // Define an area shape generator.
    const area = d3
      .area()
      .curve(d3.curveNatural)
      .x((d) => this.x(d['timestamp']))
      .y0(this.y(0))
      .y1((d) => this.y(d['ppm']));

    data.map((item) => {
      return this.svg
        .append('path')
        .datum(item)
        .attr('d', area)
        .attr('fill', 'url(#area-gradient)');
    });
  }

  removeOldData() {
    if (!this.data) return;

    this.svg
      .selectAll('.group--label-container')
      .data(this.data)
      .exit()
      .transition()
      .duration(500)
      .attr('height', 0)
      .attr('y', this.height)
      .remove();
  }

  updateChart() {
    // Apply the custom drag behavior to brush handles
    this.svg.selectAll('.brush .handle-custom').call(this.customDrag);
  }

  updateBrushSelection(selection) {
    this.brushSelection = selection;
  }

  updateZoomedRangeX(range) {
    return this.updateZoomedRangeX(range);
  }
}
/* eslint-enable */
