import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useGetLiveDataQuery } from 'api';
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz';
import { LiveDataRecord } from '../types';
import { cloneDeep, isEqual } from 'lodash';
import { useDateRangeV2 } from 'components/StyledUi/DateRange/hooks/useDateRangeV2';
import { API_INTERVAL, isDateNow, useMockData } from '../utils';
import { useMockGetDataQuery } from './useMockGetDataQuery';

const LiveChartAPIProviderContext = createContext<LiveChartProps>({
  isLoading: true
});

interface LiveChartProps {
  isLoading?: boolean;
  data?: LiveDataRecord[];
  hasData?: boolean;
  hasError?: string;
  hasMessage?: string;
  end_time?: string;
  start_time?: string;
}

interface Props {
  children?: ReactNode | ReactNode[];
  timeZone: string;
  machineId: string;
}

type IntervalID = ReturnType<typeof setInterval>;

export const useLiveChartAPIProvider = (): LiveChartProps =>
  useContext(LiveChartAPIProviderContext);

export const LiveChartAPIProvider = ({ children, timeZone, machineId }: Props): JSX.Element => {
  const { startMoment: start_time, endMoment: end_time } = useDateRangeV2().machineDateRange;
  const { setDateRange } = useDateRangeV2();

  const [cached, setCachedData] = useState<LiveDataRecord[] | undefined>(undefined);
  const [key, setKey] = useState<number>(0); //This state is to retrigger api call after interval

  const [dates, setDates] = useState({
    startDate: start_time,
    endDate: end_time
  });

  const timezone = timeZone || 'UTC';

  const lastTimestampRef = useRef<string>(start_time); //This ref keeps reference to the last time stamp recieved from api data
  const intervalRef = useRef<IntervalID>();
  const hasEndDateApiCallRef = useRef<boolean>(true); //This ref indicates if we pass end date to api call or not.

  const isNOW = isDateNow(end_time, timezone);
  const {
    data: liveData,
    isLoading,
    error: isError
  } = useMockData
    ? useMockGetDataQuery(
        key,
        dates.startDate,
        Math.floor(Math.random() * 3),
        hasEndDateApiCallRef.current
      )
    : useGetLiveDataQuery({
        machineId: machineId,
        include_full_period_data: isNOW ? 'false' : 'true',
        start_datetime_utc: dates.startDate,
        end_datetime_utc: hasEndDateApiCallRef.current ? dates.endDate : undefined,
        key: key
      });

  useEffect(() => {
    if (!liveData || liveData.length === 0) {
      return;
    }

    if (!cached) {
      //if no cache - set new one
      const processedData = liveData?.map((item) => {
        const zonedTimestamp = utcToZonedTime(item.timestamp, timeZone);
        return {
          ...item,
          timestamp: zonedTimestamp
        };
      }) as unknown as LiveDataRecord[];
      const lastItemTimestamp = (liveData?.[liveData.length - 1].timestamp).split('+')[0] + '.000Z';
      lastTimestampRef.current = lastItemTimestamp;
      setCachedData(processedData);
    } else {
      //add to cache
      const newData = liveData?.map((item) => {
        const zonedTimestamp = utcToZonedTime(item.timestamp, timeZone);
        return {
          ...item,
          timestamp: zonedTimestamp
        };
      }) as unknown as LiveDataRecord[];

      const isEqualData = isEqual(newData, cached);
      if (isEqualData) return;

      const copyState = cloneDeep(cached);
      copyState.push(...newData); //add as last item in array because items are in asd order with lastest at the end

      const lastItemTimestamp = (liveData?.[liveData.length - 1].timestamp).split('+')[0] + '.000Z';
      lastTimestampRef.current = lastItemTimestamp;

      setCachedData(copyState);
    }
  }, [liveData]);

  const checkAndUpdateDates = () => {
    const now = utcToZonedTime(new Date().toISOString(), timezone);
    setDates((prevDates) => {
      //const prevZoned = utcToZonedTime(lastTimestampRef.current, timezone);
      const datesEndDate = utcToZonedTime(prevDates.endDate, timezone);

      //We should compare the difference between now and end date of the datepicker and not api item,
      //BUT timestamp of api item should be saves as start date
      const difference = Math.abs(now.valueOf() - datesEndDate.getTime()) > 10 * 60 * 1000;
      if (!difference || useMockData) {
        //why end date is the last timestamp?
        //it needs to be a real time end
        const newStartDate2 = lastTimestampRef.current;
        const newEndDate2 = formatInTimeZone(
          new Date(prevDates.endDate).getTime() + API_INTERVAL, //change back after mockup
          'UTC',
          "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
        );
        return { startDate: newStartDate2, endDate: newEndDate2 };
      } else {
        clearInterval(intervalRef.current);
        return prevDates;
      }
    });
    setKey((c) => c + 1);
  };

  useEffect(() => {
    //When we select a new date range, we need to reset the cache
    setCachedData(undefined);
  }, [setDateRange]);

  useEffect(() => {
    const isCurrent = isDateNow(end_time, timezone); //is end date now?
    hasEndDateApiCallRef.current = isCurrent ? false : true; //if end date is in the past, hasEndDateApiCallRef is true

    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    const startDateObj = start_time;
    const endDateObj = end_time;
    setDates({ startDate: startDateObj, endDate: endDateObj });

    const now = utcToZonedTime(new Date().toISOString(), timezone);
    const end = utcToZonedTime(endDateObj, timezone);
    const difference = Math.abs(now.valueOf() - end.getTime()) > 10 * 60 * 1000;
    if (!difference) {
      intervalRef.current = setInterval(checkAndUpdateDates, API_INTERVAL);
    }
    return () => clearInterval(intervalRef.current);
  }, [start_time, end_time]);

  const cachedData = useMemo(() => cached, [cached]);

  const hasError = isError ? 'error getting data' : undefined;
  let hasMessage = undefined;

  if (hasError) console.log('error getting data in useMachineUtilizationAPIProvider');

  if ((!isLoading && !hasError) || liveData?.length === 0) {
    hasMessage = 'no data';
  }

  return (
    <LiveChartAPIProviderContext.Provider
      value={{
        data: cachedData,
        start_time: dates.startDate,
        end_time: dates.endDate,
        hasError,
        hasMessage,
        isLoading
      }}
    >
      {children}
    </LiveChartAPIProviderContext.Provider>
  );
};
