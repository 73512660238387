import React, { createContext, useContext, ReactNode, useMemo } from 'react';

import { calculateGroupKeyTotals } from '../../helpers/calculators';

import { ChartGeneratorKeysProps, ChartsBaseProps } from '../../ChartGenerator.types';
import { UseChartSelectionsProvider } from '../useChartSelections';
import { getMaxValue, getMinValue } from './helpers/getMaxValue';
import { generateChartDataFromObjectArrayWithGroupIDKeys } from './helpers/generateChartData';
import { generateLegendItems } from './helpers/generateLegendItems';

export interface ChartGeneratorDataProviderDataTypesProps {
  data?: Record<string, unknown>[];
  groupedData?: Record<string, Record<string, unknown>[]>;
  chartData?: Record<string, unknown>[];
  dataType?: 'groupArray' | 'categoryArray' | 'groupObject' | string;
}

export interface ChartGeneratorDataProviderProps {
  data?: Record<string, unknown>[];
  groupedData?: Record<string, Record<string, unknown>[]>;
  chartData?: Record<string, unknown>[];
}

export interface ChartGeneratorDataProviderDataGetterOptionsProps
  extends ChartGeneratorKeysProps,
    ChartsBaseProps {
  key?: string;
  [key: string]: unknown;
}

export type UseChartGeneratorDataGetterProps = (
  type: string,
  options?: ChartGeneratorDataProviderDataGetterOptionsProps
) =>
  | string[]
  | string
  | Record<string, unknown>
  | Record<string, unknown>[]
  | Record<string, Record<string, unknown>[]>
  | ReactNode
  | undefined;

export interface GeneratorProps extends ChartsBaseProps, ChartGeneratorDataProviderProps {}

export interface UseChartGeneratorDataProviderReturnProps extends ChartGeneratorDataProviderProps {
  getData: UseChartGeneratorDataGetterProps;
  isInitialized?: boolean;
  [key: string]: unknown;
}

const ChartGeneratorDataContext = createContext<UseChartGeneratorDataProviderReturnProps>({
  getData: (type: string) => {
    console.log(type, 'not set');
    return undefined;
  },
  isInitialized: false
});

export const useChartGeneratorData = (): UseChartGeneratorDataProviderReturnProps =>
  useContext(ChartGeneratorDataContext);

interface Props extends GeneratorProps {
  children?: ReactNode;
  [key: string]: unknown;
}

export const UseChartGeneratorDataProvider = ({
  chartType,

  data,
  groupedData,

  groupKeys,
  categoryKeys,
  valueKeys,

  groupKey,
  categoryKey,
  valueKey,

  colorKey,
  colors,

  mapKeys,
  keysToUse,

  children
}: Props): JSX.Element => {
  groupKey = groupKey || ((valueKeys || valueKey) && 'group');
  valueKey = valueKey || (valueKeys && 'value');

  let dataToIndex = data || groupedData;

  // flatten data if it's an object
  if (typeof dataToIndex === 'object') dataToIndex = Object.values(dataToIndex).flat();

  if (!dataToIndex) {
    return <></>;
  }

  const indexedData = useMemo(
    () =>
      !valueKeys
        ? // this is a regular array of objects with key value pairs
          dataToIndex
            ?.map((item, dataIndex) => ({ ...item, dataIndex }))
            ?.sort((a: Record<string, unknown>, b: Record<string, unknown>) => {
              categoryKey = (categoryKey as string) || undefined;
              if (!categoryKey) return 0;
              if (Number(a?.[categoryKey as string] || 0) < Number(b?.[categoryKey]) || 0)
                return -1;
              if (Number(a?.[categoryKey] || 0) > Number(b?.[categoryKey] || 0)) return 1;
              return 0;
            })
        : // this is an array of objects where the keys are ids (or labels) and values are the values.  each valueKey will add that entry as a separate item in the array
          generateChartDataFromObjectArrayWithGroupIDKeys(valueKeys, {
            categoryKey,
            colors,
            data: dataToIndex,
            groupKey,
            valueKey
          }),
    [dataToIndex, valueKeys, categoryKey, colors, groupKey, valueKey]
  );

  const getData = (
    type: string,
    options?: ChartGeneratorDataProviderDataGetterOptionsProps | undefined
  ): Record<string, unknown> | Record<string, unknown>[] | ReactNode | undefined | string[] => {
    const dataToUse = (options?.data || indexedData) as Record<string, unknown>[] | undefined;

    if (!dataToUse) return undefined;

    const groupKeyToUse = (options?.groupKey || groupKey) as string;
    const categoryKeyToUse = (options?.categoryKey || categoryKey) as string;
    const valueKeyToUse = (options?.valueKey || valueKey) as string;
    const valueKeysToUse = (options?.valueKeys || valueKeys) as string[];

    switch (type) {
      case 'legendItems':
        return generateLegendItems({ data: dataToUse, colors, groupKey: groupKeyToUse });

      // gets a string array of categories for the bottom axis, each category is a tick mark on the axis and is the value of category in converted chart data
      case 'categories': {
        const key = options?.key || categoryKeyToUse;
        if (!key) return;
        return dataToUse?.reduce((acc: string[], item: Record<string, unknown>) => {
          const category = item?.[key] as string;
          if (!acc.includes(category)) acc = [category, ...acc];
          return acc;
        }, []);
      }

      case 'maxValue': {
        return getMaxValue(valueKeysToUse || valueKeyToUse, dataToUse);
      }

      case 'minValue': {
        return getMinValue(valueKeysToUse || valueKeyToUse, dataToUse);
      }

      case 'groupByKey':
      case 'groupedIndexedData': {
        let keys: string | string[] | undefined = groupKeyToUse || options?.key || valueKeysToUse;

        if (typeof keys === 'string') keys = [keys];
        if (!keys) keys = valueKeysToUse;

        if (!keys) return undefined;

        return dataToUse?.reduce(
          (acc: Record<string, Record<string, unknown>[]>, item: Record<string, unknown>) => {
            const groupID = item?.[groupKeyToUse] as string;
            if (!acc?.[groupID]) acc[groupID] = [];
            acc[groupID].push(item);
            return acc;
          },
          {}
        );
      }

      case 'indexedDataTotals':
      case 'pieChartData':
      case 'categoryTotals':
      case 'groupTotals': {
        let key = options?.key || groupKeyToUse || options?.categoryKey;
        if (type === 'categoryTotals') key = categoryKeyToUse;
        if (!key) return undefined;

        const totals = calculateGroupKeyTotals(key, {
          data: dataToUse as Record<string, unknown>[],
          valueKey: valueKeyToUse,
          categoryKey,
          groupKey,
          colors,
          mapKeys: mapKeys as Record<string, string>,
          isCategory: type === 'categoryTotals' ? true : false
        });

        if (!totals) return undefined;

        if (type === 'groupTotals' || type === 'categoryTotals') return totals;

        const flatData = Object.values(totals).flat();

        // return legend items
        return flatData;
      }

      case 'indexedData':
        return indexedData;

      default:
        break;
    }

    return;
  };

  const legendItems = (getData('legendItems') as Record<string, unknown>[]) || undefined;

  const returnValues = {
    getData,
    colors,
    chartType,

    colorKey,

    groupKey,
    categoryKey,
    valueKey,
    valueKeys,

    categoryKeys,
    groupKeys,

    keysToUse,

    data,
    indexedData,
    dataToIndex,

    legendItems,
    isInitialized: true
  };

  return (
    <ChartGeneratorDataContext.Provider value={returnValues}>
      <UseChartSelectionsProvider>{children}</UseChartSelectionsProvider>
    </ChartGeneratorDataContext.Provider>
  );
};

export default UseChartGeneratorDataProvider;
