import React, { ReactNode, createContext, useContext, useMemo } from 'react';

import { useGetAsepticMachineHealthChangeoverDetailsQuery } from 'api';
import { useBuSettings, useMachineInfo } from 'common/pages/fleetV2/providers';
import { processApiData } from 'common/helpers/processApiData';
import { useWidgetDateRange } from 'common/ui';
import { calculateByCategoryByGroup, calculateDataGroupTotals } from 'common/helpers/dataCounters';
import { convertObjectArrayToLegendItems } from 'common/components';

export interface UseChangeoverStepDetailsApiProviderProps {
  isLoading?: boolean;
  hasMessage?: ReactNode;
  hasError?: ReactNode;
  data?: Record<string, unknown>[];
  [key: string]: unknown;
}

const ChangeoverStepDetailsApiProviderContext =
  createContext<UseChangeoverStepDetailsApiProviderProps>({
    isLoading: true
  });

export const useChangeoverStepDetailsApiProvider = (): UseChangeoverStepDetailsApiProviderProps =>
  useContext(ChangeoverStepDetailsApiProviderContext);

interface Props extends UseChangeoverStepDetailsApiProviderProps {
  children?: ReactNode | ReactNode[];
}

const GetData = (): Record<string, unknown> => {
  const { startTime, endTime } = useWidgetDateRange().utcDateRange;
  const { timeZone, machineId } = useMachineInfo();
  const { mapKeys, colors } = useBuSettings();

  const { data, error, isLoading } = useGetAsepticMachineHealthChangeoverDetailsQuery({
    machineId: machineId as string,
    end_datetime: endTime,
    start_datetime: startTime
  });

  const hasError = error ? true : undefined;
  const hasMessage = !isLoading && !data?.length ? true : undefined;

  if (error) console.log('error getting data in useChangeoverStepDetailsApiProvider', { error });

  const dataCache = useMemo(() => {
    if (!data) return;
    // process data to normalize it
    const processedData = processApiData(data, {
      timeZone,
      startTimeKey: 'startTime',
      endTimeKey: 'endTime',
      formatTimestamps: 'M/dd',
      renameValues: {
        name: mapKeys as Record<string, string>
      },
      colors,
      colorKey: 'name'
    });

    if (!processedData) return { data };

    // get totals for each group
    const changeoverCategoriesTotals = calculateDataGroupTotals(processedData, {
      groupKey: 'name',
      valueKey: 'duration'
    });

    // get totals for each group by category
    const changeoverSessionStepsDurationTotals = calculateByCategoryByGroup('sessionId', 'name', {
      data: processedData,
      valueKey: 'duration'
    });

    const changeoverSessionsStartDates = Object.entries(
      processedData.reduce((acc: Record<string, { start: string[]; end: string[] }>, item) => {
        // session id
        const id = item?.sessionId as string;
        // session start time
        const newStartTime = item?.startTime as string;

        // if there is no id, return the accumulator
        if (!id) return acc;
        // add session id and start time to the accumulator
        if (!acc?.[id]) acc = { ...acc, [id]: { start: [], end: [] } };
        // add the start and end dates to the accumulator
        acc[id].start = [newStartTime, ...acc[id].start];
        acc[id].end = [newStartTime, ...acc[id].end];
        // sort the start and end dates
        acc[id].start.sort((a, b) => (a < b ? -1 : 1));
        acc[id].end.sort((a, b) => (a > b ? -1 : 1));
        return acc;
      }, {})
    ).reduce((acc: Record<string, { start: string; end: string }>, [id, dates]) => {
      if (!acc?.[id])
        acc = {
          ...acc,
          [id]: {
            start: dates.start[0],
            end: dates.end[0]
          }
        };
      return acc;
    }, {});

    const changeoverSessionIdsByDate = Object.entries(changeoverSessionsStartDates)
      .map(([category]) => category)
      .sort((a, b) => (a.start < b.start ? -1 : 1));

    const legendItems = convertObjectArrayToLegendItems(
      Object.values(changeoverCategoriesTotals) as Record<string, unknown>[],
      {
        valueKey: 'total',
        groupKey: 'group',
        colors
      }
    );

    return {
      // totals counter object for each group (or machine mode/category in this case) for all sessions
      // example { cleaning: { count: 10, total: 1000, average: 10, valueKey: 'duration', groupKey: 'name' }, ... }
      changeoverCategoriesTotals,
      // totals counter for each category in each session
      changeoverSessionStepsDurationTotals,
      // start and end dates for each session
      changeoverSessionsStartDates,
      // session ids sorted by date from earliest to latest
      changeoverSessionIdsByDate,
      // the processed api data
      data: processedData,
      // the raw api data
      rawData: data,
      legendItems
    };
  }, [data]);

  return { ...dataCache, isLoading, hasError, hasMessage };
};

export const ChangeoverStepDetailsApiProvider = ({ children }: Props): JSX.Element => {
  const { timeZone, machineId } = useMachineInfo();

  if (!machineId) {
    console.log('no machine id in useChangeoverStepDetailsApiProvider');
    return <></>;
  }

  if (!timeZone) {
    console.log('machine timeZone is missing');
    return <></>;
  }

  const { isLoading, hasError, hasMessage, ...rest } = GetData() as {
    isLoading?: boolean;
    data?: Record<string, unknown>[];
    [key: string]: unknown;
  };

  if (isLoading || hasError || hasMessage) {
    return (
      <ChangeoverStepDetailsApiProviderContext.Provider value={{ hasError, isLoading, hasMessage }}>
        {children}
      </ChangeoverStepDetailsApiProviderContext.Provider>
    );
  }

  return (
    <ChangeoverStepDetailsApiProviderContext.Provider
      value={{
        ...rest
      }}
    >
      {children}
    </ChangeoverStepDetailsApiProviderContext.Provider>
  );
};
