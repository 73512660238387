import styled from 'styled-components';

export const Downtime24hChartContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;

  .bars {
    margin: 1.1em 1.3em 1em;
  }

  &.has-error {
    border-width: 0;
  }
`;
