import React, { FC } from 'react';

import { useProductionOutputWidget } from './hooks/useProductionOutputWidget';
import { useBuSettings, useMachineInfo } from 'common/pages/fleetV2/providers';
import { formatAsZonedTimetamp } from 'sandbox/joey/ChartGenerator/helpers/dateHelpers';

import { formatNumber } from 'sandbox/joey/ChartGenerator/helpers/numberFormatters';
import { TooltipIcon } from 'common/ui/TooltipIcon/TooltipIcon';
import { convertSecondsToTime } from 'common/helpers/dateAndTimeHelpersV2';
import { ChartGenerator } from 'sandbox/joey/ChartGenerator/ChartGenerator';
import { WidgetUi } from 'common/ui';
import {
  ProductionOutputWidgetContainer,
  TotalsColumnContainer,
  ValueGroupContainer
} from './ProductionOutputWidget.elements';
import { useTranslation } from 'react-i18next';
/**
 * this is just for a custom widget template,
 * if you want a pie chart or bar chart one of the existing global charts, please choose one from common/components/WidgetUi
 */

const ValueGroup = ({ weight, count, title }: Record<string, unknown>) => {
  const { t } = useTranslation(['mh']);
  const { colors, mapKeys } = useBuSettings();

  const clr = colors?.[title as string];

  title = mapKeys?.[title as string] || title;

  const className = `value-group-container ${title}`;

  if (typeof weight === 'number') weight = Math.round(weight as number);
  if (typeof count === 'number') count = Math.round(count as number);

  weight = !weight ? 'n/a' : weight.toLocaleString();
  count = !count ? 'n/a' : count.toLocaleString();

  title = t(title as string);

  return (
    <ValueGroupContainer {...{ className, clr }}>
      <div className="value-group-title">{title}</div>
      <div className="group group-weight">
        <div className="group-value is-colored">{weight} Lbs</div>
        <div className="group-label">{t('weight')}</div>
      </div>
      <div className="group group-count">
        <div className="group-value is-colored">{count} Pcs</div>
        <div className="group-label">{t('piece count')}</div>
      </div>
    </ValueGroupContainer>
  );
};

const TotalsColumn = () => {
  const { totalWeight, totalCount } = useProductionOutputWidget() as {
    totalWeight: Record<string, Record<string, unknown>>;
    totalCount: Record<string, Record<string, unknown>>;
  };

  return (
    <TotalsColumnContainer className="totals-column-container">
      <div className="group-spacer">
        <ValueGroup
          title="portions"
          weight={totalWeight?.portions?.total}
          count={totalCount?.portions?.total}
        />
      </div>
      <div className="group-spacer">
        <ValueGroup
          title="nuggets"
          weight={totalWeight?.nuggets?.total}
          count={totalCount?.nuggets?.total}
        />
      </div>
    </TotalsColumnContainer>
  );
};

const Tooltips = (x?: Record<string, unknown>) => {
  const { t } = useTranslation(['mh']);
  const { colors } = useBuSettings();
  const { timeZone } = useMachineInfo();

  const indexedItem = x?.indexedItem as Record<string, unknown>;
  const ColoredGroupLabel = x?.ColoredGroupLabel as FC<Record<string, unknown>>;

  if (!indexedItem) return <></>;

  const groups = [
    {
      group: t('cut weight scan'),
      value: indexedItem?.['cut weight scan'],
      color: colors?.['cut weight scan']
    },
    {
      group: t('product output'),
      value: indexedItem?.['product output'],
      color: colors?.['product output']
    }
  ].sort((a, b) => (b.value as number) - (a.value as number));

  const title = x?.title;

  const dailyDurations = [
    {
      group: t('running'),
      value: indexedItem?.['running'],
      color: colors?.['running']
    },
    {
      group: t('no product'),
      value: indexedItem?.['no product'],
      color: colors?.['no product']
    }
  ];

  return (
    <>
      {title && (
        <div className="tooltip__title">
          {formatAsZonedTimetamp(title as number, timeZone || 'UTC', 'MMMM d, yyyy')}
        </div>
      )}
      <div className="tooltip-group">
        {groups?.map(({ group, color, value }) => {
          const newVal = typeof value === 'number' ? Math.round(value) : value;
          return (
            <ColoredGroupLabel key={group} clr={color}>
              {group} - <strong>{formatNumber(newVal as number)} LB</strong>
            </ColoredGroupLabel>
          );
        })}
        {dailyDurations?.map(({ group, color, value }) => {
          const newVal = typeof value === 'number' ? Math.round(value) : value;
          return (
            <ColoredGroupLabel key={group} clr={color}>
              {group} - <strong>{convertSecondsToTime(newVal as number, 'h:m')}</strong>
            </ColoredGroupLabel>
          );
        })}
      </div>
    </>
  );
};

export const ProductionOutputWidget = (): JSX.Element => {
  const { timeZone } = useMachineInfo();

  const { isLoading, hasMessage, hasError, dailyDetails } = useProductionOutputWidget();

  const { colors, mapKeys } = useBuSettings();

  const dataSettings = {
    data: Object.values(dailyDetails || {}).flat(),
    mapKeys,
    colors
  };

  const widgetSettings = {
    title: 'production output',
    className: 'production-output-widget',
    isLoading,
    hasMessage,
    hasError
  };

  const productionChartSettings = {
    title: 'production output',
    className: 'production-output-widget',

    chartType: 'lines',
    valueKeys: ['product output', 'cut weight scan'],
    categoryKey: 'timestamp',
    leftTickCount: 5,
    hasHoverColumns: true,
    Components: {
      Tooltips
    },
    format: {
      leftTicks: (x?: Record<string, unknown>) => {
        const { label } = x || {};
        return formatNumber(label as number);
      },
      bottomTicks: (x?: Record<string, unknown>) => {
        const { label } = x || {};
        if (typeof label === 'number')
          return (
            <>
              {formatAsZonedTimetamp(label, timeZone || 'UTC', 'MMM d')}
              {<TooltipIcon>{formatAsZonedTimetamp(label, 'UTC')}</TooltipIcon>}
            </>
          );
      }
    }
  };

  return (
    <WidgetUi
      {...widgetSettings}
      Main={
        <ProductionOutputWidgetContainer className="widget-ui__main has-overflow">
          <ChartGenerator {...dataSettings} {...productionChartSettings} />
          <TotalsColumn />
        </ProductionOutputWidgetContainer>
      }
    />
  );
};

/*
 // data settings
  const dataSettings = {
    data: dailyDetails as Record<string, unknown>[],
    groupKey: 'productionRunId',
    valueKey: 'count',
    categoryKey: 'timestamp',
    valueKeys: ['cut weight scan', 'product output'],
    mapKeys,
    colors
  };

  <ChartGeneratorWidget {...widgetSettings} {...durationChartSettings} />

<UseChartGeneratorDataProvider {...dataSettings}>
       <Provided {...{ isLoading, hasMessage, hasError }} />
    </UseChartGeneratorDataProvider>

    */
