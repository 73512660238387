import React from 'react';
import { useTranslation } from 'react-i18next';
import { SessionSelectButtonContainer } from './SelectSessionButton.elements';
import { generateZonedTimestamp } from 'common/helpers/dateAndTimeHelpersV2';
import { useMachineInfo } from 'common/pages/fleetV2/providers';
import { CalendarButton } from 'common/ui';
import { SelectSessionFlyout } from './SelectSessionFlyout';
// if this is being reused, it needs to be a global component that accepts handlers, and a date range or children.
export const SelectSessionButton = (): JSX.Element => {
  const [show, setShow] = React.useState(false); // [TODO: remove this state, and use the router to determine if the flyout is open or not.
  const { timeZone } = useMachineInfo();
  const { t } = useTranslation(['mh']);

  const handleClick = () => setShow(true);

  return (
    <>
      <SessionSelectButtonContainer className="session-select-button-area">
        <span className="label">{t('select session')}:</span>
        <CalendarButton {...{ handleClick }}>
          {`${
            generateZonedTimestamp(timeZone || 'UTC', { subtractDays: 30, format: 'P' }).formatted
          } - ${generateZonedTimestamp(timeZone || 'UTC', { format: 'P' }).formatted || ''}`}
        </CalendarButton>
      </SessionSelectButtonContainer>
      <SelectSessionFlyout show={show} setShow={setShow} />
    </>
  );
};
