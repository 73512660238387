// import { styledTheme } from 'components';
import { styledTheme } from 'common/theme';
import styled from 'styled-components';
import { CHART_MARGINS } from '../ui/Chart/LiveChartWrapper';

export const Widget = styled.div`
  &.live-graph-widget {
    .widget-ui__header {
      display: flex;
      border-width: 1px 1px 0 1px;
      border-style: solid;
      gap: 0.5em;
      align-items: center;
      border-color: #c2c2c6;
      padding: 1.3em;
      border-top-left-radius: 0.625rem;
      border-top-right-radius: 0.625rem;

      h2 {
        font-weight: 500;
        margin: 0;
        font-size: 1.1em;
        flex-grow: 1;
        padding: 0;
        text-transform: capitalize;
        margin: 0;
      }
    }

    .widget-ui__main {
      border-width: 0px 1px 1px 1px;
      border-style: solid;
      border-color: #c2c2c6;
      border-bottom-left-radius: 0.625rem;
      border-bottom-right-radius: 0.625rem;

      .widget-ui.live-graph-widget__stats {
        .widget-ui__header {
          width: 100%;
        }

        .widget-ui__main.has-error {
          border-width: 0;
        }
      }
    }
  }
`;

export const LiveGraphWidgetContainer = styled.div`
  min-height: 150px;
  grid-template-columns: 25em 1fr;
  grid-auto-rows: auto;
  grid-auto-flow: row;
  grid-gap: 0.7em;
  grid-template-areas:
    'time-bar time-bar'
    'zoom-bar zoom-bar'
    'stats    line-chart';

  display: grid !important;
  padding: 0.7em;
  padding-top: 0 !important;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'time-bar'
      'zoom-bar'
      'stats'
      'line-chart';
  }

  .widget__time-bar {
    .reset-button {
      position: relative;
      top: 1px;
      font-size: 1rem;
      color: #303e47;
    }
  }

  .live-graph-widget__zoom-bar {
    grid-area: zoom-bar;
    text-align: center;
    height: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .live-graph-widget__stats {
    grid-area: stats;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    height: 100%;
    min-height: 480px;
    border-right: 1px solid ${styledTheme.colors.border.main};

    &.has-message {
      .widget-ui__header {
        width: 100%;
      }
    }

    footer {
      border-width: 0px;
    }

    .widget-ui__header {
      border: 0;

      h2 {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }

    & > p {
      margin: 0 1.3em;
    }

    & > header.widget-ui__header {
      padding: 1.3em 1.3em 0.5em 1.3em;

      .bars-header--switch {
        display: flex;
        align-items: baseline;
        border: 0;
        background: none;
        cursor: pointer;
      }
    }

    .widget-ui__main.data-loader.has-message {
      border-width: 0;
    }
  }

  .tooltip-container {
    position: relative;
    width: 235px;
    height: 108px;
    font-size: 0.85rem;

    .tooltip--line-item .tooltip--inner {
      margin: 0 auto;
      max-width: 220px;
    }

    & > .tooltip--inner {
      position: relative;
      display: block;
      padding: 0.35rem 0.25rem 0.35rem 0.35rem;
      background: ${styledTheme.colors.neg};
      border-width: 1px;
      border-style: solid;
      border-color: ${styledTheme.colors.border.main};
      border-radius: 15px;
      box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.3);
      height: 100%;

      .tooltip--header {
        color: #323130;
        font-weight: 600;
        text-align: center;
        border-bottom: 1px solid #e5e9ed;
        padding-top: 5px;
        padding-bottom: 2px;
        margin-bottom: 2px;
      }

      small {
        display: block;
        color: #666;
        text-align: center;
        padding-top: 0.3em;
        font-size: 0.85rem;

        b {
          font-weight: 600;
        }
      }

      .key {
        display: inline-block;
        padding-left: 30px;
        font-size: 0.85rem;
        line-height: 1.8;

        span {
          padding: 0 3px;
        }

        .indicator {
          display: block;
          position: relative;
          width: 10px;
          height: 0px;
          margin-right: 5px;

          &::before {
            content: '';
            position: absolute;
            left: -25px;
            top: 4px;
            height: 18px;
            width: 18px;
            background-color: inherit;
            border-radius: 50%;
            display: inline-block;
          }
        }
      }

      .value {
        font-weight: 600;
      }
    }
  }

  .live-graph-widget__line-chart {
    grid-area: line-chart;
    height: 560px;
    display: flex;
    flex-direction: column;
    padding: 0 1.25rem;
    overflow: visible;

    &.no_data,
    &.has-error {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }

    .tooltip--header {
      font-size: 0.85rem;
    }

    .tooltip--line-item .tooltip--inner {
      box-shadow: none;
      border-width: 0;
    }

    .tooltip--container {
      position absolute;
      top: ${CHART_MARGINS.top}px;
      left: calc(${CHART_MARGINS.left}px);
      right: ${CHART_MARGINS.right}px;
      bottom: ${CHART_MARGINS.bottom}px;
      width: calc(100% - ${CHART_MARGINS.left}px - ${CHART_MARGINS.right}px); /* 100% width of the outer element minus left and right padding */
      height: calc(100% - ${CHART_MARGINS.top}px - ${CHART_MARGINS.bottom}px);
      z-index: 1;
    }

    .container_no_data {
      width: 100%;
      min-height: 500px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        display: block;
        max-height: 200px;
        width: auto;
        height: 100%;
      }
    }
  }
`;
