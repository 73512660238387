import React, { useEffect, useMemo, useRef, useState } from 'react';
import { StatesD3V2 } from '../../../../../../../../../../ui/StatesHorizontalBarChart/StatesD3-V3';
import { MachineStateContainer } from '../../elements';
import { COLORMAPPING, displayTooltip } from '../../utils';
import { createBars } from 'common/ui/StatesHorizontalBarChart/utils';
import { useDataManagementProvider } from '../../provider/useDataManagement';
import { NoData } from 'common/ui/StatesHorizontalBarChart/NoData';

interface MachineModesBarChartProps {
  width: number;
  height: number;
  zoomedRangeX?: Date[];
  chartData?: Record<string, unknown>[];
  linesData?: Record<string, unknown>[];
  triggerResize?: () => void;
  divRef: React.RefObject<HTMLDivElement> | undefined;
  expandedStatus: boolean;
}

export const MachineModesBarChart = ({
  width,
  height,
  triggerResize,
  divRef,
  expandedStatus
}: MachineModesBarChartProps): JSX.Element => {
  const chartArea = useRef<HTMLDivElement | null>(null);
  const { data, chartDateRange, zoomDaterange } = useDataManagementProvider();

  const chart = useRef<StatesD3V2>();
  const [chartWidth, setChartWidth] = useState(width);
  const isExpanded = useRef(true);

  const expandedRows = {
    status: expandedStatus
  };

  const handleExpandedRowUpdate = () => {
    isExpanded.current = !isExpanded.current;
    chart.current && chart.current.onExpand(isExpanded.current);
  };

  const row = useMemo(() => {
    if (!data || !chartDateRange) return;

    const rows: Record<string, unknown>[] = [];
    const chartData = [
      {
        data: data,
        label: 'Status',
        parentProperty: 'status'
      }
    ];

    // Add the top level button rows
    chartData?.forEach((parentRow) => {
      const topLevelRow = {
        parentRowProperty: parentRow.parentProperty,
        label: parentRow.label,
        isLabel: parentRow.isLabel,
        isExpanded: expandedRows[parentRow.parentProperty],
        state: parentRow.parentProperty,
        bars: createBars(parentRow.data, false),
        isTopLevel: true
      };

      if (Object.prototype.hasOwnProperty.call(expandedRows, parentRow.parentProperty))
        topLevelRow['isExpandable'] = true;

      rows.push(topLevelRow);

      // Add the child rows, if this row is expanded
      if (expandedRows[parentRow.parentProperty]) {
        const nameMap = {};

        // Add mid level rows as non-expandable rows, similar to top level rows
        parentRow.data.forEach(({ mode_number, mode_descr }) => {
          // Combine the parent property with the child state code to make it a unique state
          const uniqueStateCode = `${parentRow.parentProperty}-${mode_descr}`;

          nameMap[uniqueStateCode] = {
            stateName: mode_descr,
            stateCode: mode_number,
            uniqueStateCode
          };
        });

        Object.values(nameMap).forEach((val) => {
          rows.push({
            bars: createBars(
              parentRow.data.filter((bar) => bar.mode_number === val.stateCode),
              true
            ),
            label: nameMap[val.uniqueStateCode].stateName,
            state: val.uniqueStateCode,
            stateNameLabel: val.stateName
          });
        });
      }
    });

    return rows;
  }, [data]);

  const settings = {
    margins: {
      top: -3,
      right: 40,
      bottom: 20,
      left: 95
    },
    colorMap: COLORMAPPING,
    connectedLinesData: undefined,
    rangeX: chartDateRange,
    width: chartWidth,
    height,
    className: 'machine-mode-container',
    stackKey: 'duration',
    onTickClick: handleExpandedRowUpdate
  };

  useEffect(() => {
    setChartWidth(width);
  }, [width, triggerResize, divRef]);

  useEffect(() => {
    if (!chartDateRange || !row) return;
    chart.current = new StatesD3V2(chartArea.current, row, { ...settings });
    chart.current && chart.current.draw(displayTooltip, zoomDaterange, row, undefined);
    chart.current && chart.current.updateXAxis(zoomDaterange, undefined);
    chart.current && chart.current.reDrawBars(row, displayTooltip);
  }, [chartWidth]);

  useEffect(() => {
    if (!data || !chartDateRange || !zoomDaterange) return;

    if (!chart.current) {
      chart.current = new StatesD3V2(chartArea.current, row, { ...settings });
      chart.current && chart.current.draw(displayTooltip, zoomDaterange, row, undefined);
      chart.current && chart.current.updateXAxis(zoomDaterange, undefined);
      chart.current && chart.current.reDrawBars(row, displayTooltip);
    } else {
      chart.current && chart.current.updateXAxis(zoomDaterange, undefined);
      chart.current && chart.current.reDrawBars(row, displayTooltip);
    }
  }, [row, zoomDaterange]);

  if (!data || !chartDateRange) return <NoData />;

  return (
    <MachineStateContainer
      className={`chart-area machine-state`}
      ref={chartArea}
    ></MachineStateContainer>
  );
};
