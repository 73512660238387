import React from 'react';

import { WidgetUi } from 'common/ui';
import { DowntimeWidgetContainer } from './DowntimeWidget.elements';
import { useDowntimeWidget } from './hooks/useDowntimeWidget';

/**
 * this is just for a custom widget template,
 * if you want a pie chart or bar chart one of the existing global charts, please choose one from common/components/WidgetUi
 */

export const DowntimeWidget = (): JSX.Element => {
  const { isLoading, hasMessage, hasError, data } = useDowntimeWidget();

  const widgetSettings = {
    title: 'Downtime',
    className: 'downtime-widget',
    hasError,
    isLoading,
    hasMessage
  };

  return (
    <WidgetUi
      {...widgetSettings}
      Main={
        <DowntimeWidgetContainer className="widget-ui__main">
          {JSON.stringify(data)}
        </DowntimeWidgetContainer>
      }
    />
  );
};
