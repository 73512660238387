import React from 'react';
import { RouterTabsNav } from 'common/components';
import { useRouter } from 'common/hooks/useRouter';
import { fleetPageRoute } from 'common/pages/fleetV2/settings/fleetPageRoutes';

export const MachineViewTabs = (): JSX.Element => {
  const { id } = useRouter(fleetPageRoute);

  const tabsNavSettings: Record<string, unknown> = {
    tabs: [
      {
        label: 'Machine Health',
        // the slug for this tab, this will be used to populate url
        slug: 'machine-health',
        path: `/fleet/machine/${id}/machine-health/overview`
      },
      {
        label: 'Alerts',
        slug: 'alerts',
        path: `/fleet/machine/${id}/alerts`
      }
    ],
    //basePath,
    // the route for this page, you can use custom as long as you're the value
    // of the current tab from the url
    pageRoute: fleetPageRoute,
    // this is the key to get the position of the tab value from the url to compare if current tab is active
    // and for the link
    pathKey: 'view',
    style: 'full-width'
  };

  return <RouterTabsNav {...tabsNavSettings} />;
};
