// 3rd party libs
import React, { ReactElement, useMemo, useState } from 'react';
import { UserFormTypes, UserManagementTableType, UserTypes } from 'types/user-management';
import styled, { useTheme } from 'styled-components';

// Components

import { Button, Flyout, FlyoutHeader, Modal, Typography } from 'components';
import AlertIcon from 'components/WarningPrompt/AlertIcon';
import UserForm, {
  FormSection,
  InputContainer,
  StyledInputLabel
} from 'pages/UserManagementTwo/components/User/AddEditUser/UserForm';
import AsyncSelect from 'react-select/async';

// Types
import { Id, ModalSize } from 'types';
import {
  useCreateUserMutation,
  useGetUserManagementV2Query,
  useLazyGetUserManagementV2Query
} from 'api';
import { PAGE_LENGTH } from 'constants/search';
import { DropDownItem } from 'pages/ProteinMachine/MachineConfig/Common/Alerts/FormElementsTypes';
import { ActionMeta, MultiValue } from 'react-select';
import { toSnakeCase } from 'helpers';
import { ToastMsg } from 'common/components/Toast/Toast';
import { GroupHeaderPropt } from '../../Group/AddEditGroup/GroupInfo';
import {
  ClearValues,
  DropdownIndicator,
  Option
} from 'pages/ProteinMachine/MachineConfig/Common/Alerts/DropdownWithCheckboxes';
import { useTranslation } from 'react-i18next';
const Container = styled.div`
  background: ${({ theme }) => theme.colors.white};
  height: 100%;
`;
const UserFormContainer = styled.div`
  padding: 1rem;
  height: 100%;
  position: relative;
`;
const Footer = styled.div`
  position: absolute;
  bottom: 2rem;
  width: 90%;
`;
const BtnContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-left: 3rem;
`;
const StyledModalHeaderContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 1.25rem;
  padding: 1.25rem 1.5rem;
  width: 100%;
`;
const StyledButtonRow = styled.div`
  align-item: center;
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  padding-top: 1.25rem;
`;
const StyledTitle = styled.div`
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0rem;
  line-height: 1.3125rem;
`;
const StyledContentContainer = styled.div`
  padding: 0 1.5rem 1.25rem;
  width: 100%;
`;

// Types
interface Props {
  tableType: UserManagementTableType;
  tableItemId?: Id;
  open: boolean;
  setIsOpenModal: (isOpenModal?: boolean) => void;
  selectedItem?: UserTypes;
  setSelectedItem?: React.Dispatch<React.SetStateAction<UserTypes | undefined>>;
}
const defaultUserField = {
  email: '',
  firstName: '',
  lastName: '',
  textAlert: false,
  emailAlert: false,
  phone: '',
  groups: []
};
const UserManagementModal = ({
  tableType,
  tableItemId,
  open,
  setIsOpenModal,
  selectedItem = defaultUserField,
  setSelectedItem
}: Props): ReactElement => {
  const { t } = useTranslation(['common']);
  const theme = useTheme();
  const [isViewConfirmCancel, setIsViewConfirmCancel] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const selectedGroups =
    selectedItem?.groups?.map((group) => ({ value: group.id, label: group.name || '' })) || [];

  const [multiValue, setMultiValue] = useState<DropDownItem[]>(selectedGroups);

  const [createUser] = useCreateUserMutation();

  const [getGroupList] = useLazyGetUserManagementV2Query();
  const { data: groups } = useGetUserManagementV2Query({
    type: 'group',
    limit: 50,
    offset: 0
  });
  const [userFormField, setUserFormField] = useState<UserTypes>(selectedItem);

  const groupList = useMemo(
    () => groups?.items.map((group) => ({ label: group.name, value: group.id })),
    [groups]
  );

  useMemo(() => {
    setMultiValue(selectedGroups);
    setUserFormField(selectedItem);
  }, [selectedItem]);

  const handleSaveData = (data: UserFormTypes) => {
    console.log(data);
  };

  const handleInputOnChange = (e: MultiValue<DropDownItem>, option: ActionMeta<DropDownItem>) => {
    if (option.removedValue && option.removedValue.isFixed) return;
    setMultiValue([...e]);
    const selectedGroups = e.map((group) => ({ id: String(group.value) }));
    setUserFormField({ ...userFormField, groups: selectedGroups });
    // handleMultiSelect([...e]);
  };

  const onClose = () => {
    // setIsChanged(false);
    setIsOpenModal(false);
  };
  const loadOptions = async (inputValue: string) => {
    const groups = await getGroupList({
      type: 'group',
      nameSearchQuery: inputValue.trim(),
      limit: PAGE_LENGTH.SMALL,
      offset: 0
    });
    const groupList = groups.data?.items.map((group) => ({ label: group.name, value: group.id }));
    return Promise.resolve(groupList || []);
  };

  const handleUserSave = () => {
    const params = toSnakeCase(userFormField);
    const { groups } = params;
    const groupParams = groups?.map((group) => ({ id: group.id }));

    createUser({ ...params, groups: groupParams })
      .unwrap()
      .then(() => {
        ToastMsg({
          heading: 'Success',
          message: tableItemId ? 'Data updated successfully' : 'A user has been created.',
          type: 'success',
          position: 'top-center'
        });
        setIsOpenModal();
        setSelectedItem?.(undefined);
        setUserFormField(defaultUserField);
      });
  };

  const handleFormChange = (data: UserTypes, isValidForm?: boolean) => {
    if (isValidForm) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
    setUserFormField(data);
  };
  const onCloseVerifyData = () => {
    // if (isChanged) {
    //  setIsViewConfirmCancel(true);
    // } else {
    //   setIsChanged(false);
    setUserFormField({});
    setIsOpenModal(false);
    //  }
  };

  const flyoutTitle = useMemo(() => {
    const tableTypeName = tableType === UserManagementTableType.USER ? 'user' : 'Group';
    return tableItemId ? `Edit ${tableTypeName}` : `Add ${tableTypeName}`;
  }, [tableType, tableItemId]);

  const helperText = `Are you sure you want to go back to users? You have unsaved changes.`;
  const title = 'Unsaved Changes';
  return (
    <>
      <Modal
        title={
          <StyledModalHeaderContainer>
            <AlertIcon />
            <StyledTitle>{title}</StyledTitle>
          </StyledModalHeaderContainer>
        }
        allowContentScroll
        onClose={onCloseVerifyData}
        showCloseButton={false}
        size={ModalSize.XSMALL}
        visible={isViewConfirmCancel}
      >
        <StyledContentContainer>
          <Typography color={theme.colors.mediumGrey3} variant="stepheading">
            {helperText}
          </Typography>
          <StyledButtonRow>
            <Button
              onClick={() => {
                setIsViewConfirmCancel(false);
              }}
              width="auto"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                setIsOpenModal(false);
                setIsViewConfirmCancel(false);
              }}
              variant="danger"
              width="auto"
            >
              Yes
            </Button>
          </StyledButtonRow>
        </StyledContentContainer>
      </Modal>
      <Flyout width="28.125rem" visible={open} onClose={onCloseVerifyData}>
        <FlyoutHeader
          heading={flyoutTitle}
          onClose={onCloseVerifyData}
          bgColor={theme.colors.white}
        />
        <Container tabIndex={0}>
          <UserFormContainer>
            <GroupHeaderPropt>{t('by_adding_a_new_user')}</GroupHeaderPropt>
            <UserForm
              isUserPage
              handleFormChange={handleFormChange}
              onUserSave={handleSaveData}
              userData={{ user: userFormField, validationState: {} }}
            />

            <FormSection margin={'1.5rem 0 0'}>
              <InputContainer>
                <StyledInputLabel mandatory={true}>Group Selection</StyledInputLabel>
                <AsyncSelect
                  className="groupSelect"
                  placeholder="Select Group(s)"
                  value={multiValue}
                  onChange={(e, opt) => {
                    handleInputOnChange(e, opt);
                  }}
                  isMulti
                  isSearchable
                  loadOptions={loadOptions}
                  defaultOptions={groupList}
                  components={{
                    Option,
                    DropdownIndicator
                  }}
                  styles={{
                    clearIndicator: ClearValues,
                    multiValue: (styles) => {
                      return {
                        ...styles,
                        backgroundColor: 'white',
                        borderRadius: '1.25rem',
                        border: '0.063rem solid  #0076CC',
                        padding: '0.25rem 0.5rem 0.25rem 0.5rem;'
                      };
                    },
                    multiValueLabel: (styles) => ({
                      ...styles,
                      fontWeight: '400'
                    }),
                    multiValueRemove: (styles) => ({
                      ...styles,
                      ':hover': {
                        cursor: 'pointer'
                      }
                    })
                  }}
                />
              </InputContainer>
            </FormSection>
            <Footer>
              <BtnContainer>
                <Button
                  variant="link"
                  onClick={onClose}
                  borderRadius="3rem"
                  borderColor={theme.colors.primaryBlue5}
                  marginLeft="3rem"
                >
                  Cancel
                </Button>

                <Button
                  disabled={!(isValid && multiValue.length)}
                  variant="hover-blue"
                  borderRadius="3rem"
                  bgColor={theme.colors.primaryBlue5}
                  color={theme.colors.lightGrey3}
                  onClick={handleUserSave}
                >
                  Save
                </Button>
              </BtnContainer>
            </Footer>
          </UserFormContainer>
          {/* <AddEditUser
            userId={tableItemId}
            onClose={onClose}
            onCloseVerifyData={onCloseVerifyData}
            isActiveUserPromptMode={isViewConfirmCancel}
          /> */}
        </Container>
      </Flyout>
    </>
  );
};

export default UserManagementModal;
