import React from 'react';
import { ViewContainer } from './View.elements';
import { MachineModes, OEEMetricsWidget, ProductionKPIsWidget } from '../../components';
import { LatestIssuesWidget } from '../../components/LatestIssuesWidget';
import { MachineOverviewWidget } from '../../components/MachineOverviewWidget/MachineOverviewWidget';

export const OverviewView = (): JSX.Element => {
  return (
    <ViewContainer className="overview view-container">
      <MachineOverviewWidget />
      <LatestIssuesWidget />
      <OEEMetricsWidget />
      <ProductionKPIsWidget />
      <MachineModes />
    </ViewContainer>
  );
};
