import React from 'react';
import { ChartLegendContainer, ChartLegendItemContainer } from './ChartLegend.elements';
import { useChartSelections } from '../../hooks/useChartSelections';

export const ChartLegend = ({
  legendItems
}: {
  legendItems?: Record<string, unknown>[];
}): JSX.Element => {
  const handleSelect = useChartSelections()[1];

  const handleClick = (props?: Record<string, unknown>) => {
    const groupKeyToUse = props?.groupKey as string;
    if (!groupKeyToUse) return;
    const groupToUse = props?.[groupKeyToUse] as string;
    return handleSelect('toggle', { [groupKeyToUse]: groupToUse });
  };

  const checkIfFiltered = (props?: Record<string, unknown>) => {
    const groupKeyToUse = props?.groupKey as string;
    if (!groupKeyToUse) return;
    const groupToUse = props?.[groupKeyToUse] as string;
    return handleSelect('checkIfFiltered', { [groupKeyToUse]: groupToUse });
  };

  if (!legendItems) return <></>;

  return (
    <ChartLegendContainer className="chart-legend-container">
      {legendItems?.map((props: Record<string, unknown>, i: number) => {
        const groupKeyToUse = props?.groupKey as string;
        const groupToUse = (props?.[groupKeyToUse] as string) || props?.group;

        let className = 'legend-item';

        if (checkIfFiltered(props)) className += ' is-filtered';

        return (
          <ChartLegendItemContainer
            key={i}
            onClick={() => handleClick(props)}
            className={className}
            color={props?.color as string}
          >
            {groupToUse}
          </ChartLegendItemContainer>
        );
      })}
    </ChartLegendContainer>
  );
};

//<ChartLegendItemContainer key={i} color={props?.color as string}>{props?.label as string|| ''}</ChartLegendItemContainer>
