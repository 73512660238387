import React from 'react';
import { WidgetUi } from 'common/ui';
import { RecipesFormContainer } from './RecipesForm.elements';
import { useRecipesForm } from './hooks/useRecipesForm';
import { RecipesTable } from '../RecipesTable/RecipesTable';

export const RecipesForm = (): JSX.Element => {
  const { isLoading, hasMessage, hasError, data } = useRecipesForm();

  const widgetSettings = {
    className: 'recipes-form',
    hasError,
    isLoading,
    hasMessage
  };

  return (
    <WidgetUi
      {...widgetSettings}
      Main={
        <RecipesFormContainer className="widget-ui__main">
          <RecipesTable {...{ data }} />
        </RecipesFormContainer>
      }
    />
  );
};
