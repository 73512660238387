export const formatNumber = (num: number | string): string | number | undefined => {
  let numToUse = `${num}`;
  if (typeof num === 'string') num = Number(num);
  if (!num) return undefined;
  if (typeof num === 'number') {
    if (num > 999) {
      numToUse = `${String(num).slice(0, -3)}K`;
    }
    if (num > 999999) {
      numToUse = `${String(num).slice(0, -5)}M`.replace(/,/g, '.');
    }
  }
  return numToUse;
};
