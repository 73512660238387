import { ReactNode } from 'react';

import { useChangeoverSessionsByDateRangeAPIProvider } from '../providers/useChangeoverSessionsByDateRangeAPIProvider';
import { useFleetRouter } from 'common/pages/fleetV2/hooks/useFleetRouter';

export const useSelectedChangeoverSessionData = (): {
  data?: Record<string, unknown>[];
  currentSession?: Record<string, unknown>;
  isLoading?: boolean;
  hasError?: ReactNode;
  hasMessage?: ReactNode;
} => {
  // get the session selected session id from the url via the router hook
  const { selection: sessionId } = useFleetRouter()[0];

  // check the route tomake sure it's value
  const selectedSessionId = sessionId !== 'select-session' ? sessionId : undefined;

  const {
    data,
    hasMessage: apiMessage,
    isLoading,
    hasError
  } = useChangeoverSessionsByDateRangeAPIProvider() as {
    data?: Record<string, unknown>[] | undefined;
    isLoading?: boolean;
    hasError?: ReactNode;
    hasMessage?: ReactNode;
  };

  // get the latest session from the session array
  let currentSession = data?.[0];

  if (selectedSessionId) {
    currentSession = data?.find((session) => session.id === selectedSessionId);
  }

  const hasMessage =
    apiMessage || (!isLoading && !hasError && !currentSession) ? 'no data available' : undefined;

  return { currentSession, hasError, hasMessage, isLoading };
};
