import React from 'react';
import { DimensionsContainer } from 'components';
import { D3Wrapper } from './LiveChartWrapper';

interface Props {
  className?: string;
}

export const LiveGraphChart = ({ className }: Props): JSX.Element => {
  return (
    <DimensionsContainer
      className={className}
      Component={({ width, height }) => {
        return (
          <D3Wrapper
            width={width}
            height={height - 20} //20 is a height of a button above the chart
          />
        );
      }}
    />
  );
};
