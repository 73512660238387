import React from 'react';
import theme from 'themes';

interface IcoLimitProps {
  color?: string;
}
export const IcoLimit = ({ color }: IcoLimitProps): JSX.Element => {
  color = color || theme.colors.primaryBlue5;
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4225_2406)">
        <path
          d="M11.6045 13.3333C11.6045 13.687 11.745 14.0261 11.995 14.2761C12.2451 14.5262 12.5842 14.6667 12.9378 14.6667C13.2914 14.6667 13.6306 14.5262 13.8806 14.2761C14.1307 14.0261 14.2712 13.687 14.2712 13.3333C14.2712 12.9797 14.1307 12.6406 13.8806 12.3905C13.6306 12.1405 13.2914 12 12.9378 12C12.5842 12 12.2451 12.1405 11.995 12.3905C11.745 12.6406 11.6045 12.9797 11.6045 13.3333Z"
          stroke={color}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.6048 13.3333H10.2715"
          stroke={color}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.93815 2.66659C4.93815 3.02021 4.79768 3.35935 4.54763 3.60939C4.29758 3.85944 3.95844 3.99992 3.60482 3.99992C3.2512 3.99992 2.91206 3.85944 2.66201 3.60939C2.41196 3.35935 2.27148 3.02021 2.27148 2.66659C2.27148 2.31296 2.41196 1.97382 2.66201 1.72378C2.91206 1.47373 3.2512 1.33325 3.60482 1.33325C3.95844 1.33325 4.29758 1.47373 4.54763 1.72378C4.79768 1.97382 4.93815 2.31296 4.93815 2.66659Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.93848 2.66675H6.27181"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.60482 2.66675H8.27148"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.27181 13.3333H6.93848"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.27148 13.3334C7.60482 13.3334 8.93815 2.66675 14.2715 2.66675"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4225_2406">
          <rect width="16" height="16" fill="white" transform="translate(0.271484)" />
        </clipPath>
      </defs>
    </svg>
  );
};
