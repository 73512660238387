import { useChangeoverStepDetailsApiProvider } from '../../../views/Changeover/views/SessionsOverTime/providers/ChangeoverStepDetailsApiProvider';

export const useChangeoverStepsCategoriesWidget = (): Record<string, unknown> => {
  const { changeoverCategoriesTotals, ...rest } = useChangeoverStepDetailsApiProvider();

  if (!changeoverCategoriesTotals) return rest;

  const chartData = Object.values(changeoverCategoriesTotals).sort((a, b) => b.total - a.total);

  return {
    chartData,
    ...rest
  };
};
