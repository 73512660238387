import { calculateGroupTotals } from 'common/helpers/dataCounters';
import { useChangeoverSessionDetailsAPI } from 'common/pages/fleetV2/machine/aseptic/providers/useChangeoverSessionDetailsAPIPovider';
import { useBuSettings } from 'common/pages/fleetV2/providers';

export const useChangeoverMachineModesWidget = (): Record<string, unknown> => {
  const { colors, mapKeys } = useBuSettings();
  const { machineModes, isLoading, ...api } = useChangeoverSessionDetailsAPI();

  console.log({ machineModes });
  const chartData = calculateGroupTotals('modeDescr', {
    colors,
    data: machineModes as Record<string, unknown>[],
    mapTags: mapKeys,
    valueKey: 'duration',
    includeKeys: ['runLoginId', 'modeNumber', 'recipe']
  });
  const hasMessage = isLoading ? false : !chartData || !chartData.length ? true : false;
  return {
    ...api,
    isLoading,
    machineModes,
    hasMessage,
    chartData: chartData && Object.values(chartData)
  };
};
