import React from 'react';
import { ViewContainer } from './View.elements';

import { useRouterWithHandler } from 'common/hooks/useRouter';
import { fleetPageRoute, fleetPageVersionPath } from '../../../../../../settings/fleetPageRoutes';
import { SingleSessionView } from './views/SingleSession/SingleSessionView';
import { SessionsOverTimeView } from './views/SessionsOverTime/SessionsOverTimeView';
import { RouterTabsNav, WidgetDateRangeProvider } from 'common/components';
import { SelectSessionButton } from '../../components/Flyouts/SelectSessionFlyout/SelectSessionButton';
import { useMachineInfo } from 'common/pages/fleetV2/providers';
import { ChangeoverSessionsByDateRangeAPIProvider } from '../../../../providers/useChangeoverSessionsByDateRangeAPIProvider';

export const ChangeoverView = (): JSX.Element => {
  const [{ id }] = useRouterWithHandler(fleetPageRoute);

  const basePath = `${fleetPageVersionPath}/${id}/machine-health/changeover`;

  const tabsNavSettings: Record<string, unknown> = {
    tabs: [
      {
        label: 'Single Session',
        // the slug for this tab, this will be used to populate url
        path: `/${basePath}/single-session`,
        slug: 'single-session'
      },
      {
        label: 'Sessions Over Time',
        path: `/${basePath}/sessions-over-time`,
        slug: 'sessions-over-time'
      }
    ],
    // the route for this page, you can use custom as long as you're the value
    // of the current tab from the url
    pageRoute: fleetPageRoute,
    // this is the key to get the position of the tab value from the url to compare if current tab is active
    // and for the link
    pathKey: 'tab',
    size: 'sm'
  };

  const { timeZone } = useMachineInfo();
  return (
    <ViewContainer className="changeover view-container">
      <WidgetDateRangeProvider subtractDays={29} timeZone={timeZone}>
        <ChangeoverSessionsByDateRangeAPIProvider>
          <div className="sub-tabs-wrapper">
            <span>Show Me:</span> <RouterTabsNav {...tabsNavSettings} />
            <SelectSessionButton />
          </div>

          {
            // wrapp all the subtabs in the sessions over date range provider.  This will be used
            // to get current info from the latest session, also gets the date range of the selected session
            // to pas to a new DateRange provider to get the session data for charts and table
            <Routes />
          }
        </ChangeoverSessionsByDateRangeAPIProvider>
      </WidgetDateRangeProvider>
    </ViewContainer>
  );
};

const Routes = () => {
  const [{ id, subTab }, handle] = useRouterWithHandler(fleetPageRoute);

  if (!subTab)
    handle(`setPath`, {
      path: `/${fleetPageVersionPath}/${id}/machine-health/changeover/single-session`
    });

  const isSingleSession = subTab === 'single-session' || subTab === 'session-alarms';

  if (isSingleSession) return <SingleSessionView />;

  if (
    // sessions over time view
    subTab === 'sessions-over-time'
  )
    return <SessionsOverTimeView />;

  // 404 page
  return <div>Changeover 404</div>;
};
