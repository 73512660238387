import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';

interface DataPoint {
  value: number;
  category: Date;
}

export interface D3LineChartProp {
  name: string;
  data: DataPoint[];
  color?: string;
}

function D3LineChart({ groupData }: { groupData: D3LineChartProp[] }): JSX.Element {
  const [data, setData] = useState([]);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const chartRef = useRef<HTMLDivElement>(null);
  const margin = { top: 20, right: 20, bottom: 50, left: 70 };

  useEffect(() => {
    const formattedData = groupData.map((dataset) => ({
      name: dataset?.name,
      data: dataset?.data?.map((d) => ({
        date: d?.category,
        value: +d?.value,
        color: dataset?.color
      }))
    }));

    setData(formattedData);
  }, [groupData]);

  useEffect(() => {
    const handleResize = () => {
      if (chartRef.current) {
        const { width, height } = chartRef.current.getBoundingClientRect();
        setDimensions({ width, height });
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const svgWidth = dimensions.width - margin.left - margin.right;
  const svgHeight = dimensions.height - margin.top - margin.bottom;

  // Create scales and line generator
  const x = d3.scaleTime().range([0, svgWidth]);
  const y = d3.scaleLinear().range([svgHeight, 0]);

  if (data.length > 0) {
    x.domain(d3.extent(data.flatMap((dataset) => dataset?.data.map((d) => d?.date))));
    y.domain([0, d3.max(data, (dataset) => d3.max(dataset?.data, (d) => d?.value))]);
  }

  const valueLine = d3
    .line()
    .x((d) => x(d?.date))
    .y((d) => y(d?.value));

  return (
    <div ref={chartRef} style={{ width: '100%', height: '100%' }}>
      <svg width="100%" height="100%">
        <g transform={`translate(${margin.left}, ${margin.top})`}>
          {/* Y Axis */}
          <g
            ref={(node) => {
              if (data.length > 0) {
                const axis = d3.axisLeft(y).tickSizeOuter(0);
                d3.select(node).call(axis);
                // Style the y-axis line
                d3.select(node).select('.domain').attr('stroke', '#C8C8C8');
                // Style the y-axis ticks
                d3.select(node).selectAll('.tick line').attr('stroke', '#C8C8C8');
                // Style the y-axis tick text
                d3.select(node).selectAll('.tick text').attr('fill', '#666666');
                // Adding horizontal grid lines
                d3.select(node).selectAll('line').attr('x2', svgWidth).attr('stroke', '#E5E9ED');
              }
            }}
          />

          {/* X Axis */}
          <g
            transform={`translate(0, ${svgHeight})`}
            ref={(node) => {
              if (data.length > 0) {
                const axis = d3.axisBottom(x).tickSizeOuter(0).tickFormat(d3.timeFormat('%m/%d'));
                d3.select(node).call(axis);
                // Style the x-axis line
                d3.select(node).select('.domain').attr('stroke', '#C8C8C8');
                // Style the x-axis ticks
                d3.select(node).selectAll('.tick line').attr('stroke', '#C8C8C8');
                // Style the x-axis tick text
                d3.select(node).selectAll('.tick text').attr('fill', '#666666');
                // Adding vertical grid lines
                d3.select(node).selectAll('line').attr('y2', -svgHeight).attr('stroke', '#E5E9ED');
              }
            }}
          />

          {/* Lines */}
          {data.length > 0 &&
            data.map((dataset, index) => (
              <React.Fragment key={index}>
                <path
                  d={valueLine(dataset?.data)}
                  fill="none"
                  stroke={dataset?.data[0]?.color}
                  strokeWidth={1.5}
                />
              </React.Fragment>
            ))}
        </g>
      </svg>
    </div>
  );
}

export default D3LineChart;
