import React from 'react';
import { KPIWidget, KPIWidgetProps } from 'common/components';
import { useSelectedChangeoverSessionData } from '../../../../hooks/useSelectedChangeoverSessionData';
import { ChangeoverWidgetAcitveSessionLabel } from './ChangeoverWidget.elements';
import { useTranslation } from 'react-i18next';
import { convertSecondsToTime } from 'common/helpers/dateAndTimeHelpersV2';

/***
 * ChangeoverWidget
 */

export const ChangeoverWidget = (): JSX.Element => {
  // current session is the selected session based on the date button or the most recent session.
  // this info comes from all the session returned based on the date range

  const { t } = useTranslation(['mh']);
  const { currentSession, ...rest } = useSelectedChangeoverSessionData() as {
    currentSession?: {
      duration?: string;
      startTimestampFormatted?: string;
      previousRecipeDesc?: string;
      active?: boolean;
    };
    rest: Record<string, unknown>;
  };

  const active = currentSession?.active;

  let className = 'changeover-widget';

  if (active) className += ' is-active';

  const widgetSettings: KPIWidgetProps = {
    className,
    title: 'changeover',
    centerCell: {
      value: currentSession?.startTimestampFormatted,
      label: 'start time',
      color: 'rgba(24, 160, 60, 1)'
    },
    subCells: [
      {
        value: currentSession?.previousRecipeDesc,
        label: 'Previous Recipe'
      },
      active
        ? {
            value: (
              <ChangeoverWidgetAcitveSessionLabel>
                {t('session in progress')}
              </ChangeoverWidgetAcitveSessionLabel>
            )
          }
        : {
            value: convertSecondsToTime(currentSession?.duration || 0, 'h:m'),
            label: 'duration'
          }
    ],
    // this sets the center icon and formatting
    type: 'time',
    // change this based on column span
    textSize: 'large',
    ...rest
  };

  return <KPIWidget {...widgetSettings} />;
};
