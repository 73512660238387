import { changeoverTableColors } from 'common/pages/fleetV2/settings/aseptic';
import { fnt } from 'common/theme/standard.theme';
import styled from 'styled-components';

export const ProgrammedStepsContainer = styled.div`
  &.changeover-single-session--program-steps {
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-bottom-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    border-color: #c2c2c6;
    font-size: ${fnt.md};

    // overwriting styles
    .customcss {
      display: none;
    }

    .disabled-sort {
      margin-left: 0;
    }

    .changeover-program-steps {
      padding: 0.5em 1.5em;

      .select-none {
        margin: 0;
      }

      .row-hasChildren {
        background: #f9fafb;

        .child-cell {
          padding-left: 2rem;
        }
      }

      .row td {
        padding: 0.75rem 0.625rem;
      }

      .target-diff {
        color: #b62c10;
        font-weight: 400;

        &.red {
          color: ${changeoverTableColors.negativeValue};
        }

        &.green {
          color: ${changeoverTableColors.positiveValue};
        }
      }

      tbody {
        tr {
          td:first-child {
            width: 20%;
          }

          td:nth-child(2) {
            width: 20%;
          }

          td:nth-child(3) {
            width: 20%;
          }

          td:nth-child(4) {
            width: 20%;
          }

          td:nth-child(4) {
            width: 20%;
          }
        }
      }

      tbody tr.open + .row-hasChildren {
        box-shadow: inset 0px 5px 5px 0px #e9e9e9;
      }

      tbody tr:last-child td {
        border-bottom: 0;
      }

      tbody tr td:first-child {
        padding-left: 0;
      }

      tr td span {
        margin-left: 0;
        div {
          font-weight: bold;
        }
      }

      .cell-status {
        display: flex;
        align-items: center;
        font-weight: normal;

        .button-issues {
          background: none;
          border: 0;
          cursor: pointer;
        }
      }

      .parent-cell {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 0.9rem;
      }

      .child-cell {
        display: flex;
        align-items: center;
        font-weight: 400;
      }

      .button-expand {
        background: none;
        border: 0;
      }
    }
  }
`;
