import { styledTheme } from 'common/theme';
import styled from 'styled-components';

export const D3WrapperContainer = styled.div`
  .chart-area {
    position: relative;

    &.hidden {
      display: none;
    }
  }

  .label-container {
    cursor: pointer;

    .label--inner-wrapper {
      height: 24px;
    }

    & > div {
      padding: 5px;
      top: 50%;
      transform: translateY(-50%);
      background-color: #f1f7ff;
      border-width: 0px;
      border-style: solid;
      border-radius: 30px;
    }

    .label--inner {
      svg {
        min-width: 16px;
        minheight: 16px;
      }

      .label--description {
        display: none;
      }
    }

    &.visible {
      display: block;
      width: 155px;

      .label--inner {
        display: flex;
        align-items: center;
      }

      .label--description {
        min-width: 120px;
        font-size: 0.75rem;
        display: block;
        padding: 0 0.25em;
        width: 100%;
      }
    }
  }

  .svg-content-responsive {
    display: inline-block;
    position: absolute;
    top: 10px;
    left: 0;
  }

  .linechart--label {
    background: ${styledTheme.colors.secondaryLight};
    border: 0;
    border-radius: 12px;
    padding: 3px 5px;

    .label--inner {
      display: flex;
      flex-direction: row;
      align-items: center;

      .label--description {
        display: none;
      }
    }

    p {
      margin: 0;
    }
  }
`;
