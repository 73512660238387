import React from 'react';
import { SwatchList } from './SwatchList';
import { chartColors } from 'common/theme/light.theme';

const colors = Object.values(chartColors);

export const EditableField = (
  value: string | number,
  //onSelectChange: (e: ChangeEvent<HTMLSelectElement>) => void,
  onBlur: () => void,
  type?: string
): JSX.Element => {
  switch (type) {
    case 'select':
      // TODO select component here
      return <></>;
    case 'string':
      return (
        <input
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onBlur={onBlur}
          type="text"
        />
      );
    case 'number' | 'number-input':
      return (
        <input
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onBlur={onBlur}
          type="number"
        />
      );
    case 'color':
      return <SwatchList colors={colors} onColorClick={(e) => setValue(e.target.value)} />;
    default:
      return (
        <input
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onBlur={onBlur}
          type="text"
        />
      );
  }
};
