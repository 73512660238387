import { StyledUiContainerProps } from 'components';
import styled from 'styled-components';

export const ViewContainer = styled.div<StyledUiContainerProps>`
  grid-gap: 1em;
  display: flex;
  flex-wrap: wrap;
  margin-top: -3rem;
  .charts-and-filters-page__search-bar-area {
    width: 100%;
  }
  .widget-ui.pie-chart-widget.cleaning-step-categories-widget.has-sub-header.has-footer {
    width: calc(40% - 0.5rem);
  }
  .widget-ui.cleaning-step-duration-widget.has-sub-header.has-footer {
    width: calc(60% - 0.5rem);
  }
  .sate-history-buttons {
    row-gap: 0.3rem;
  }

  @media (max-width: 1300px) {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }

  .cleaning-step-categories-widget {
    grid-area: cleaning-step-categories;
    min-height: 10rem;
  }

  .cleaning-step-duration-widget {
    grid-area: cleaning-step-duration;
  }

  .has-message {
    min-height: 10rem;
  }

  .cleaning-step-categories-widget .cell-value__label {
    width: 7rem;
    margin: 0.85em auto 0 auto;
  }

  .cleaning-step-categories-widget .cell-value__value {
    font-size: 1.5em;
  }
`;
