import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { uniqueId } from 'lodash';

const IconContainer = styled.div`
  .grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    width: 100%;
    max-width: 1000px;
    margin: 2em auto;

    .grid-item {
      border: 1px solid #dedede;
      border-radius: 5px;
      color: #666666;
      padding: 10px;
      text-align: center;
      font-size: 1.5em;
      height: 100px;
      max-width: 250px;
      width: 100%;

      p {
        font-size: 0.85rem;
        margin: 0.2em 0;
      }

      svg,
      img,
      ong {
        max-height: 50px;
      }
    }
  }
`;

export const DisplayAllIconsContainer = (): JSX.Element => {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    axios
      .get('http://localhost:3001/files')
      .then((response) => {
        console.log('response', response);
        setFiles(response.data);
      })
      .catch((error) => {
        console.error('Error fetching files:', error);
      });
  }, []);

  return (
    <IconContainer className="icons-container">
      <h1>Icons: </h1>
      <div className="grid-container">
        {files.map((file) => (
          <div key={uniqueId()} className="grid-item">
            {file.type === '.svg' || file.type === '.png' || file.type === '.jpg' ? (
              <>
                <img src={`http://localhost:3001/root/${file.name}`} alt={file.name} width={100} />
                <p>{file.name}</p>
              </>
            ) : file.type === '.tsx' ? (
              <React.Suspense fallback={<div>Loading...</div>}>
                <DelayedDynamicComponent file={`${file.name}`} />
                <p>{file.name}</p>
              </React.Suspense>
            ) : (
              <p>{file.name}</p>
            )}
          </div>
        ))}
      </div>
    </IconContainer>
  );
};

const dynamicImport = async (name: string) => {
  try {
    // Adjust the path as needed to correctly resolve .tsx files
    const fileNameWithoutExtendtion = name.split('.')[0];
    const moduleNameString = name.split('.')[0];
    const test = await import(`../../../icons/${fileNameWithoutExtendtion}`);

    return test[moduleNameString];
  } catch (error) {
    console.error('Error loading component:', error);
    throw error;
  }
};

interface DelayedDynamicComponentProps {
  file: string;
  index: number;
}

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const DelayedDynamicComponent: React.FC<DelayedDynamicComponentProps> = ({ file, index }) => {
  const [Component, setComponent] = useState<React.FC | null>(null);

  useEffect(() => {
    const loadComponent = async () => {
      await delay(index * 100); // Add delay based on index to stagger the imports
      try {
        const LoadedComponent = await dynamicImport(file);
        setComponent(() => LoadedComponent);
      } catch (error) {
        console.error('Error loading component:', error);
      }
    };

    loadComponent();
  }, [file, index]);

  return Component ? <Component /> : null;
};
