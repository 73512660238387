import React from 'react';

import { WidgetUi } from 'common/ui';
import { ConfigurationFormContainer } from './ConfigurationForm.elements';
import { useConfigurationForm } from './hooks/useConfigurationForm';

/**
 * this is just for a custom widget template,
 * if you want a pie chart or bar chart one of the existing global charts, please choose one from common/components/WidgetUi
 */

export const ConfigurationForm = (): JSX.Element => {
  const { isLoading, hasMessage, hasError, data } = useConfigurationForm();

  const widgetSettings = {
    title: 'Configuration',
    className: 'configuration-form',
    hasError,
    isLoading,
    hasMessage
  };

  return (
    <WidgetUi
      {...widgetSettings}
      Main={
        <ConfigurationFormContainer className="widget-ui__main">
          {JSON.stringify(data)}
        </ConfigurationFormContainer>
      }
    />
  );
};
