import React, { Dispatch, ReactElement, SetStateAction, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { KetchPreferences } from 'components';
import { IcoExternal } from 'icons/IcoExternal';
import theme from 'themes';
import { useLocation } from 'react-router-dom';
import breakpoint from 'constants/breakpoints';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface FooterContainerProps {
  toggleContent: boolean;
  isAccessDeniedPage: boolean;
}

const Textbox = styled.div`
  font-family: ${(props) => props.theme.typography.family};
  font-size: 13px;
  line-height: 10px;
  font-weight: ${(props) => props.theme.typography.text.bodySmall.weight};
  color: ${(props) => props.theme.colors.darkGrey};
  @media (max-width: ${breakpoint.size.sm}px) {
    font-size: 12px;
  }
`;

const Link = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.colors.primaryBlue5};
  cursor: pointer;
`;

const FooterContainer = styled.div<FooterContainerProps>`
  height: 36px;
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0.125rem 0.0625rem 0.125rem 0.125rem rgba(0, 0, 0, 0.09);
  @media (min-width: 540px) {
    ${(props) =>
      props.isAccessDeniedPage
        ? css`
            margin-left: 0;
          `
        : props.toggleContent
        ? css`
            margin-left: 4.325rem;
          `
        : css`
            margin-left: 16.325rem;
          `}
  }
`;

const FooterOuterContainer = styled.div`
  position: fixed;
  bottom: 0px;
  z-index: 99;
  width: 100%;
  background-color: #ffffff;
`;

const FooterInnerBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  padding: 12px;
  @media (max-width: ${breakpoint.size.sm}px) {
    padding: 0;
    margin-left: 0.5rem;
  }
`;

const FooterLinks = styled.div`
  @media (max-width: ${breakpoint.size.lg}px) {
    display: none;
  }
`;

const FooterComplianceLinks = styled.div`
  margin-right: 1rem;
  @media (min-width: ${breakpoint.size.lg + 1}px) {
    display: none;
  }
  @media (max-width: ${breakpoint.size.sm}px) {
    margin-right: 0.5rem;
  }
`;

const MenuTextbox = styled.span`
  margin-right: 0.5rem;
  font-size: 13px;
  @media (max-width: ${breakpoint.size.sm}px) {
    font-size: 12px;
  }
`;

const MenuItem = styled.div`
  padding: 1rem 0 0 0.625rem;
  &:hover {
    background-color: ${theme.colors.lightGrey2};
  }
`;

const MenuFooter = styled.div`
  border-top: 0.063rem solid ${theme.colors.lightGrey4};
  margin: 0.625rem;
  padding-top: 1rem;
`;

const PopupContainer = styled.ul`
  position: absolute;
  bottom: 2.3rem;
  right: 0.2rem;
  background-color: white;
  min-width: 10rem;
  padding: 0.625rem;
  border: 0.063rem solid ${theme.colors.mediumBlue2};
  border-bottom: none;
  list-style: none;
  margin: 0px;
  border-radius: 0.625rem 0.625rem 0 0 ;
}
`;

interface Props {
  toggleContent?: boolean;
}

interface ComplianceLinksProps {
  setshowKetchPreferences: Dispatch<SetStateAction<boolean>>;
}

const ComplianceLinks: React.FC<ComplianceLinksProps> = ({ setshowKetchPreferences }) => {
  const { t } = useTranslation(['common']);

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <FooterComplianceLinks>
      <div ref={dropdownRef}>
        <MenuTextbox>{t('jbt_compliance_links')}</MenuTextbox>
        {isOpen ? (
          <FontAwesomeIcon
            icon={faChevronUp}
            style={{ color: `${theme.colors.primaryBlue5}`, cursor: 'pointer', fontSize: '12px' }}
            onClick={handleToggle}
          />
        ) : (
          <FontAwesomeIcon
            icon={faChevronDown}
            style={{ color: `${theme.colors.primaryBlue5}`, cursor: 'pointer', fontSize: '12px' }}
            onClick={handleToggle}
          />
        )}
        {isOpen && (
          <PopupContainer>
            <MenuItem>
              <Textbox>
                <Link href="accessibility-policy">{t('accessibility_policy')}</Link>
              </Textbox>
            </MenuItem>
            <MenuItem>
              <Textbox>
                <Link href="https://www.jbtc.com/terms-of-use/" target="_blank">
                  {t('terms')} <IcoExternal color={`${theme.colors.primaryBlue5}`} />
                </Link>
              </Textbox>
            </MenuItem>
            <MenuItem>
              <Textbox>
                <Link href="https://www.jbtc.com/privacy-and-cookie-policy/" target="_blank">
                  {t('privacy_policy')} <IcoExternal color={`${theme.colors.primaryBlue5}`} />
                </Link>
              </Textbox>
            </MenuItem>
            <MenuItem>
              <Textbox>
                <Link onClick={() => setshowKetchPreferences((prevState) => !prevState)}>
                  {t('cookie_preferences')}
                </Link>
              </Textbox>
            </MenuItem>
            <MenuFooter>
              <Textbox>{t('version')} - V1.0</Textbox>
            </MenuFooter>
          </PopupContainer>
        )}
      </div>
    </FooterComplianceLinks>
  );
};

const Footer = ({ toggleContent }: Props): ReactElement => {
  const { t } = useTranslation(['common']);
  const [showKetchPreferences, setshowKetchPreferences] = useState(false);
  const location = useLocation();
  const isAccessDeniedPage = location.pathname === '/access-denied';

  return (
    <>
      {showKetchPreferences && <KetchPreferences />}
      <FooterOuterContainer>
        <FooterContainer toggleContent={toggleContent} isAccessDeniedPage={isAccessDeniedPage}>
          <FooterInnerBox>
            <Textbox>
              &copy; {new Date().getFullYear()}
              {t('all_rights_reserved')}
            </Textbox>
          </FooterInnerBox>
          <FooterLinks>
            <FooterInnerBox>
              <Textbox>
                <Link href="accessibility-policy">{t('accessibility_policy')}</Link>
              </Textbox>
              <Textbox>
                <Link href="https://www.jbtc.com/terms-of-use/" target="_blank">
                  {t('terms')} <IcoExternal color={`${theme.colors.primaryBlue5}`} />
                </Link>
              </Textbox>
              <Textbox>
                <Link href="https://www.jbtc.com/privacy-and-cookie-policy/" target="_blank">
                  {t('privacy_policy')} <IcoExternal color={`${theme.colors.primaryBlue5}`} />
                </Link>
              </Textbox>
              <Textbox>
                <Link onClick={() => setshowKetchPreferences((prevState) => !prevState)}>
                  {t('cookie_preferences')}
                </Link>
              </Textbox>
              <Textbox>V1.0</Textbox>
            </FooterInnerBox>
          </FooterLinks>
          <ComplianceLinks setshowKetchPreferences={setshowKetchPreferences} />
        </FooterContainer>
      </FooterOuterContainer>
    </>
  );
};

export default Footer;
