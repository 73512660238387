import React from 'react';

import { SelectSessionFlyoutContainer } from '../SelectSessionFlyout/SelectSessionFlyout.elements';

import { FilterButtonWithDoropdownItemProps, FilterButtonWithDropdown } from 'common/ui';
import { Flyout } from 'common/ui';

import { FlyoutItem } from './FlyoutItem';
import { useSelectSessionFlyout } from './hooks/useSelectSessionFlyout';
import { useFleetRouter } from 'common/pages/fleetV2/hooks/useFleetRouter';

const dropdownItems: Record<string, FilterButtonWithDoropdownItemProps> = {
  'date by descending': {
    label: 'date by descending'
  },
  'date by ascending': {
    label: 'date by ascending'
  },
  'duration by descending': {
    label: 'duration by descending'
  },
  'duration by ascending': {
    label: 'duration by ascending'
  }
};

export const SelectSessionFlyout = ({ show, setShow }: Record<string, unknown>): JSX.Element => {
  const { data, order, setOrder } = useSelectSessionFlyout();

  const handle = useFleetRouter()[1];
  const isOpen = show;

  const flyoutSettings = {
    handleClose: () => {
      if (typeof setShow === 'function') setShow?.(false);
    },
    title: (
      <>
        <h2>Select Session</h2>
        <FilterButtonWithDropdown
          {...{
            selected: order as string,
            handleSelect: ({ id }: { id?: string }) =>
              typeof setOrder === 'function' && setOrder(id),
            items: dropdownItems
          }}
        />
      </>
    ),
    isOpen
  };

  const handleSelect = (sessionId?: string) => {
    if (typeof setShow === 'function') setShow?.(false);
    handle('updatePath', { subTab: `single-session/${sessionId}` });
  };

  return (
    <Flyout {...flyoutSettings}>
      <SelectSessionFlyoutContainer className="flyout-select-sessions">
        {data?.map((session: Record<string, unknown>, i: number) => {
          return (
            <FlyoutItem
              key={`session${i}`}
              {...{
                handleClick: () => handleSelect(session.id as string),
                ...session
              }}
            />
          );
        })}
      </SelectSessionFlyoutContainer>
    </Flyout>
  );
};
