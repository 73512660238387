import styled from 'styled-components';
import { StyledUiContainerProps } from 'components';

/* add grid styles after widdget place holders have been added

grid-gap: 1em;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  grid-auto-flow: row;

  @media (max-width: 1300px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
*/

export const ViewContainer = styled.div<StyledUiContainerProps>`
  grid-gap: 1em;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: auto;
  grid-auto-flow: row;

  .production-kpis-widget {
    grid-column-start: span 2;
  }

  .machine-overview-widget {
    grid-column-start: span 2;
  }

  .latest-issues-widget {
    grid-column-start: span 2;
  }

  .oee-metrics-widget {
    grid-column-start: span 2;
  }

  .quality-perfomance-widget {
    grid-column-start: span 4;
  }

  .machine-mode-container {
    order: 8;
  }

  // .machine-state {
  //   grid-column: span 4;
  //   position: static;
  // }

  // .machine-modes-widget {
  //   grid-column-end: span 4;
  //   position: static;

  //   @media (min-width: 1125px) {
  //     grid-column: 1 / span 4;
  //     height: 570px;
  //   }

  //   .widget-ui__header {
  //     padding-bottom: 0;
  //   }
  // }
`;
