import { WidgetUiBorderStyles } from 'common/ui/WidgetUi/WidgetUi.elements';
import styled from 'styled-components';
import theme from 'common/theme';

export const FlyoutItemContainer = styled.a`
  ${WidgetUiBorderStyles};

  color: inherit;
  text-decoration: none;
  padding: 1em;
  gap: 0.5em;

  .excess-time {
    &.has-excess {
      color: green;
    }
    &.no-excess {
      color: red;
    }
  }

  .col-group {
    display: grid;
    grid-gap: 1em;

    &.underlined {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding-bottom: 0.5em;
      margin-bottom: 0.5em;
    }

    &.col-group--2-col {
      grid-template-columns: 1fr 1fr;

      &.alternate-alignment .item-group:nth-child(even) {
        text-align: right;
      }
    }

    &.col-group--3-col {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .split {
    grid-row: 1;
    &:first-child {
      grid-column: 1;
    }
    &:last-child {
      grid-column: 2;
    }
  }

  .cta-cell {
    margin-top: 0.5em;
  }

  .item-group {
    font-weight: 500;

    span {
      margin-top: 10px;
      display: block;
      text-transform: capitalize;
      color: ${theme.colors.grayDark};
      opacity: 0.8;
    }
  }
`;
