import React, { ReactNode } from 'react';
import { useChartGenerator } from '../../hooks';
import { TickMarkContainer } from './GridAndAxisLayer.elements';
import { useTranslation } from 'react-i18next';

export const TickMark = ({
  x,
  y,
  isFirst,
  isLast
}: {
  y?: string | number;
  x?: string | number;
  isFirst?: boolean;
  isLast?: boolean;
}): JSX.Element => {
  const { t } = useTranslation(['mh']);

  if (!x && !y) return <></>;

  const { getScale, format } = useChartGenerator() || {};

  let position = 0;

  let label: ReactNode = x || y;

  if (x) {
    position = getScale('x', x) as number;
    label = format?.bottomTicks?.({ label }) || label;
  }

  if (y) {
    position = getScale('y', y) as number;
    label = format?.leftTicks?.({ label }) || label;
  }

  if (typeof label === 'string') label = t(label);

  const orient = x ? `vertical` : `horizontal`;
  const className = `tick-mark ${orient}${isFirst ? ' is-first' : isLast ? ' is-last' : ''}`.trim();

  const settings = {
    left: x ? `${position}px` : undefined,
    top: y ? `${position}px` : undefined,
    className
  };

  return <TickMarkContainer {...settings}>{label}</TickMarkContainer>;
};
