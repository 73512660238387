import { convertSecondsToTime } from 'common/helpers/dateAndTimeHelpersV2';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlyoutItemContainer } from './FlyoutItem.elements';

export const FlyoutItem = (props: Record<string, unknown>): JSX.Element => {
  const { startTimestampFormatted, endTimestampFormatted, active, ...rest } = props;

  const { t } = useTranslation(['mh']);

  let { targetDuration, excessTime, duration } = rest;

  let className = 'item-wrapper';
  let hasExcess = undefined;

  const end = active ? t('in progress') : endTimestampFormatted;

  const handleClick = props?.handleClick as (x?: Record<string, unknown>) => void;
  const path = props?.path as string;

  if (active) className += ' is-active';

  if (!handleClick) {
    console.log('session item is missing handleClick or startTime');
    return <div className={`${className} has-error`}>error</div>;
  }

  if (typeof targetDuration === 'number') targetDuration = convertSecondsToTime(targetDuration);
  if (typeof excessTime === 'number') {
    if (excessTime < 0) hasExcess = true;
    excessTime = convertSecondsToTime(excessTime);
  }
  if (typeof duration === 'number') duration = convertSecondsToTime(duration);

  return (
    <FlyoutItemContainer
      className={className}
      href={path || `#`}
      onClick={(e) => {
        e.preventDefault();
        return handleClick?.();
      }}
    >
      <div className="underlined col-group col-group--2-col alternate-alignment">
        <div className="item-group start-time split">
          <span>{t('start time')}</span>
          {startTimestampFormatted || '-'}
        </div>

        <div className="item-group end-time split">
          <span>{t('end time')}</span>
          {end || '-'}
        </div>
      </div>

      <div className="col-group col-group--3-col">
        {targetDuration && (
          <div className="item-group target-duration">
            <span>{t('target duration')}</span>
            {targetDuration}
          </div>
        )}

        {excessTime && (
          <div className={`item-group excess-time ${hasExcess ? `has-excess` : `no-excess`}`}>
            <span>{t('time result')}</span>
            {excessTime}
          </div>
        )}

        {duration && (
          <div className="item-group duration">
            <span>{t('duration')}</span>
            {duration}
          </div>
        )}
      </div>

      <div className="item-group cta-cell">View {` >`}</div>
    </FlyoutItemContainer>
  );
};
