import { ReactNode } from 'react';
import { useLiveTimeFrame } from 'common/ui';
import { useBuSettings, useMachineInfo } from 'common/pages/fleetV2/providers';
import { useGetProsealFeedFactorQuery } from 'api';
import { useApiDataCacher } from 'common/hooks/useApiDataCacher';

export const useFeedFactorWidget = (
  refreshRate?: number
): {
  isLoading?: boolean;
  hasError?: ReactNode;
  hasMessage?: ReactNode;
  data?: Record<string, unknown>;
  chartData?: {
    label?: string;
    value?: unknown;
    color?: string;
  }[];
  avg_feed_factor?: number;
  [key: string]: unknown;
} => {
  refreshRate = refreshRate || 60000;
  const { startTime, endTime } = useLiveTimeFrame(refreshRate, { hours: 24 });
  const { machineId } = useMachineInfo();
  const { colors, mapKeys } = useBuSettings();

  const { data, isLoading, error } = useApiDataCacher(useGetProsealFeedFactorQuery, {
    machineId: String(machineId),
    start_datetime_utc: startTime,
    end_datetime_utc: endTime
  });
  console.log({ data });
  const hasMessage = data && Object.values(data)?.every((x) => x === 0);
  console.log({ hasMessage });
  // remove avg_feed_factor key and save for later from data object
  const { avg_feed_factor, ...rest } = data || {};

  const chartData = !rest
    ? undefined
    : Object.entries(rest)
        .map(([key, value]) => ({
          label: mapKeys?.[key] || key,
          group: mapKeys?.[key] || key,
          value,
          color: (colors?.[mapKeys?.[key] || key] || 'red').toLowerCase()
        }))
        .sort((a, b) => Number(b.value) - Number(a.value));

  const hasError = error ? 'error getting feed factor data' : undefined;

  return {
    isLoading: isLoading as boolean,
    hasError,
    hasMessage,
    data: data as Record<string, unknown>,
    chartData,
    avg_feed_factor: avg_feed_factor as number
  };
};
