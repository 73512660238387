import { styledTheme } from 'common/theme';
import styled from 'styled-components';

export const LaneStatesViewContainer = styled.div`
  .lane-states-main {
    display: grid;
    padding: 0.5em 1.5em;
    grid-template-columns: 300px 1fr;
    grid-auto-rows: auto;
    grid-auto-flow: row;
    grid-template-areas:
      'legend time-bar'
      'border-line border-line'
      'zoom-bar zoom-bar'
      'lane-states-duration lane-states-zoomed';
    gap: 1rem;
    width: 100%;
    box-sizing: border-box;

    @media (max-width: 1325px) {
      grid-template-areas:
        'legend time-bar'
        'border-line border-line'
        'zoom-bar zoom-bar'
        'lane-states-zoomed lane-states-zoomed'
        'lane-states-duration lane-states-duration';
    }

    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-bottom-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    border-color: ${styledTheme.colors.border.main};

    .chart-legend {
      height: 17px;
    }

    .widget__time-bar {
      grid-area: time-bar;
      width: 100%;
      box-sizing: border-box;
    }

    .legend {
      grid-area: legend;
    }

    .border-line {
      grid-area: border-line;
      margin: 0;
      width: 100%;
      color: ${styledTheme.colors.main};
      opacity: 0.5;
      box-sizing: border-box;
    }

    .lane-states--zoom {
      grid-area: zoom-bar;
      height: 120px;
      max-width: 100%;
      box-sizing: border-box;
    }

    .lane-states-zoomed {
      min-width: 100%;

      .dim-container {
        position: inherit;
      }
    }

    .lane-states-duration {
      grid-area: lane-states-duration;
      box-sizing: border-box;

      @media (min-width: 1025px) {
        height: 424px;
      }
    }

    .lane-states-barchart {
      grid-area: lane-states-zoomed;
      padding-top: 1.25rem;
      width: 100%;
      box-sizing: border-box;
    }

    &.no_data {
      grid-template-columns: auto 300px auto;
      grid-template-areas:
        'time-bar time-bar time-bar'
        'no_data no_data no_data';
      gap: 1rem;
      padding-bottom: 2rem;

      .container_no_data {
        padding: 1rem;
        max-width: 250px;
        grid-column: 2;
        justify-self: center;
        align-self: center;
        text-align: center;

        img {
          width: 100%;
        }
      }
    }
  }

  .d3-tooltip {
    position: relative;
    padding: 0.25rem;
    font-size: 0.75rem;
    padding: 0.35rem 0.25rem 0.35rem 0.35rem;
    background: ${styledTheme.colors.neg};
    border-width: 1px;
    border-style: solid;
    border-color: ${styledTheme.colors.border.main};
    border-radius: 5px;
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.3);
    width: 310px;

    .tooltip--header {
      font-weight: bold;
    }

    .tooltip--subheader {
      display: flex;
      justify-content: space-between;
      padding: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      &.time,
      &.duration {
        color: ${styledTheme.colors.gray700};
        padding: 0.15rem 0;
        border-bottom: 0;

        b {
          color: ${styledTheme.colors.black};
        }
      }

      &.time {
        padding-right: 1rem;
      }
    }

    .tooltip--line-item {
      margin: 0.75rem 0 0.25rem;
    }

    .tooltip--value {
      font-weight: 500;
    }

    .color-indicator {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      margin-right: 3px;
    }
  }
`;

export const MachineStateContainer = styled.div`
  .bar-parent,
  .bar-child {
    rx: 5px; /* Horizontal corner radius */
    ry: 5px; /* Vertical corner radius */
    cursor: pointer;
  }

  .custom-tick {
    text-align: right;

    &.top-level p,
    &.top-level button {
      font-size: 0.85rem;
      color: ${styledTheme.colors.black};
    }

    button {
      display: flex;
      border: 0;
      background: none;
      cursor: pointer;
      font-weight: 600;
      padding: 0;
      justify-content: space-between;
      width: 100%;

      img {
        padding-right: 5px;
      }
    }

    p {
      color: ${styledTheme.colors.gray700};
      font-size: 0.75rem;
      font-weight: 600;
      margin: 0;
    }
  }
`;
