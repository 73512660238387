import { Button, Modal, Typography } from 'components';
import React, { SetStateAction } from 'react';
import {
  ErrorMsg,
  FileArea,
  FileNameContainer,
  FilePreviewContent,
  FooterButton,
  PreviewFileContainer,
  PreviewHeader
} from './File.element';
import theme from 'themes';
import { CustomFile } from 'types/machine-management';
import downloadPdf from 'img/pdf-download-icon.png';
import CustomLoader from 'components/CustomLoader';
import { useGetMachineDocFileQuery } from 'api';
import { getFileIcon } from './FileItem';
import { useTranslation } from 'react-i18next';
import { CenterAlign } from 'common/components/common-styled';

export interface FileObj extends Partial<File> {
  id?: string;
}
const getFileExtension = (url: string): string => {
  const match = url ? url.match(/\.([a-zA-Z0-9]+)(?:\?|$)/) : '';
  return match ? match[1] : '';
};

interface Props {
  file: CustomFile | FileObj;
  setSelectedFilePreview: React.Dispatch<SetStateAction<CustomFile | FileObj | undefined>>;
  type: 'fps' | 'on' | 'dg'; //type is used to get the file from different repo of BE service.
}
const FilePreviewModal = ({
  file: selectedFilePreview,
  setSelectedFilePreview,
  type = 'on'
}: Props): JSX.Element => {
  const { t } = useTranslation(['fpns']);
  const { data: filePreviewData, isLoading } = useGetMachineDocFileQuery({
    document_id: selectedFilePreview.id,
    type
  });

  return (
    <Modal
      visible={!!selectedFilePreview}
      onClose={() => setSelectedFilePreview(undefined)}
      size={'small_auto_height'}
      widthOverride="50rem"
    >
      <PreviewFileContainer>
        <PreviewHeader>
          <FileArea>
            {/* <img width={32} src={PdfIcon} /> */}
            {getFileIcon(selectedFilePreview.name || '')}
            {!isLoading ? (
              <FileNameContainer>
                <Typography size={'1.3rem'} weight="medium" mb={'.4rem'}>
                  {selectedFilePreview.name}
                </Typography>
                <Typography size={'.8rem'}>
                  <a
                    download
                    className="link"
                    target="_blank"
                    rel="noreferrer"
                    href={filePreviewData?.signedUrl}
                  >
                    {t('download_document')}
                  </a>
                </Typography>
              </FileNameContainer>
            ) : (
              ''
            )}
          </FileArea>
        </PreviewHeader>
        <FilePreviewContent>
          {isLoading ? (
            <CustomLoader size="3rem" />
          ) : filePreviewData &&
            ['xls', 'xlsx', 'doc'].includes(getFileExtension(filePreviewData.signedUrl || '')) ? (
            <>
              <ErrorMsg>
                <Typography style={{ lineHeight: '1.5rem' }} color={theme.colors.text.error}>
                  The browser does not support previewing this file. <br />
                  <CenterAlign>Click on download to access it.</CenterAlign>
                </Typography>
              </ErrorMsg>
              {/* <iframe
                src={`${filePreviewData.signedUrl}`}
                width="100%"
                height="100"
                style={{ border: 0 }}
                allowTransparency
              /> */}
            </>
          ) : filePreviewData ? (
            <iframe
              src={`${filePreviewData.signedUrl}`}
              width="100%"
              height="500"
              style={{ border: 0 }}
              allowTransparency
            />
          ) : (
            <ErrorMsg>
              <Typography color={theme.colors.text.error}>{t('file_not_loaded')}</Typography>
            </ErrorMsg>
          )}
        </FilePreviewContent>
        <FooterButton>
          <Button
            width="8rem"
            variant="text"
            color={theme.colors.primaryBlue5}
            style={{ gap: '.3rem' }}
          >
            <img src={downloadPdf} />{' '}
            <a
              className="link"
              target="_blank"
              rel="noreferrer"
              download
              href={filePreviewData?.signedUrl}
            >
              {t('download_document')}
            </a>
          </Button>
          <Button
            width="8rem"
            variant="hover-blue"
            borderRadius="3rem"
            onClick={() => setSelectedFilePreview(undefined)}
          >
            {t('close')}
          </Button>
        </FooterButton>
      </PreviewFileContainer>
    </Modal>
  );
};

export default FilePreviewModal;
