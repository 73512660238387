import React from 'react';
import { DimensionsContainer } from 'components';
import { MachineModesBarChart } from './MachineModesBarChart';

interface MachineModeContainerProps {
  className?: string;
}

export const MachineModeContainer = ({ className }: MachineModeContainerProps): JSX.Element => {
  return (
    <DimensionsContainer
      className={`${className} new-machine-states-zoomed`}
      Component={({ width, triggerResize, divRef }) => {
        const settings = {
          height: 320,
          width,
          triggerResize,
          divRef,
          expandedStatus: true
        };

        return <MachineModesBarChart {...settings} />;
      }}
    />
  );
};
