// 3rd party libs
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

// Components
import { DashboardWidget, PermissionWrapper } from 'components';

// Types
import { MachineHealthSubTabs, WidgetType } from 'types/protein';
import { PermissionScopeName } from 'types/user-management';
import { Role, UserScopes } from 'types';
import { KpiRow, WidgetTableDropdownItem } from 'types/machine-health';

// Helpers
import { AdminCardEditButton, DataRenderer } from '..';
import { useLanguage } from 'providers';
import { KpiContainer } from '../MachineKpi';
import RowRenderer from '../RowRenderer';
import { useContainerSize } from 'hooks';
import {
  useCreateMachineConfigurationDataMutation,
  useGetMachineConfiguratorDataQuery,
  useGetMachineTagsHistoryQuery,
  useGetMachineWidgetDataQuery
} from 'api';
import { calculateAccumulatedValue } from '../MachineKpi/utils';

interface CleaningSessionSummaryCardProps {
  machineId: string;
  startDatetime: string;
  endDatetime: string;
}

const CleaningSessionSummaryCard = ({
  machineId,
  startDatetime,
  endDatetime
}: CleaningSessionSummaryCardProps): JSX.Element | null => {
  const { t } = useTranslation(['mh']);
  const { languageId } = useLanguage();
  const { height, containerRef: kpisContainerRef } = useContainerSize();
  const [createWidgetError, setCreateWidgetError] = useState<string | undefined>(undefined);

  const { data } = useGetMachineConfiguratorDataQuery({
    machineId,
    labels: [MachineHealthSubTabs.CLESingleSessionCSS],
    languageId: languageId
  });

  const [isEditAdminPopupOpen, setIsEditAdminPopupOpen] = useState<boolean>(false);
  const handleIsEditAdminPopupOpen = () => {
    setIsEditAdminPopupOpen(!isEditAdminPopupOpen);
  };

  const {
    data: widgetData,
    isFetching: isFetchingWidgetData,
    error: errorWidgetData
  } = useGetMachineWidgetDataQuery({
    machineId,
    widgetId: (data && data[0]?.id) || '',
    includeTagValues: true,
    languageId: languageId,
    pollingInterval: 5000,
    startDatetime,
    endDatetime
  });

  const {
    data: overviewWidgetData,
    isFetching: overviewWidgetDataIsFetching,
    error: overviewWidgetDataError
  } = useGetMachineConfiguratorDataQuery({
    machineId,
    labels: [MachineHealthSubTabs.CLESingleSession],
    languageId: languageId
  });

  const { data: activeRecipeData } = useGetMachineTagsHistoryQuery({
    machineId,
    startDatetime,
    endDatetime,
    tagCodes: ['ActReciepeString'],
    is_machine_state: true
  });

  const [createMachineConfigurationData, createMachineConfigStatus] =
    useCreateMachineConfigurationDataMutation();

  const createWidgetConfiguration = async (parent_id: string) => {
    await createMachineConfigurationData({
      machineId,
      configuration: {
        id: uuidv4(),
        name: 'Cleaning Session Summary',
        names: {
          en: 'Cleaning Session Summary'
        },
        label: MachineHealthSubTabs.CLESingleSessionCSS,
        widgetClass: 'widget',
        widgetType: WidgetType.KpiCard,
        editable: false,
        deletable: false,
        active: true,
        toggleActive: true,
        reorder: false,
        parent_id,
        tags: []
      },
      languageId: languageId
    })
      .unwrap()
      .catch((error) => {
        setCreateWidgetError('error');
        console.error(error?.data?.detail);
      });
  };

  useEffect(() => {
    if (overviewWidgetData && overviewWidgetData.length > 0) {
      const targetItem = overviewWidgetData[0].members?.find(
        (item) => item.label === MachineHealthSubTabs.CLESingleSessionCSS
      );
      if (!targetItem) {
        // POST call to add widget data
        createWidgetConfiguration(overviewWidgetData[0].id);
      }
    }
  }, [overviewWidgetData]);

  const accumulatedWidgetTags = [] as KpiRow[];
  accumulatedWidgetTags[0] = {
    label: 'Active Recipe',
    value: {
      key: 'ActReciepeString',
      content:
        activeRecipeData &&
        activeRecipeData[activeRecipeData.length - 1]?.values.slice(-1)[0]?.machineState ===
          'cleaning'
          ? (activeRecipeData[activeRecipeData.length - 1]?.values.slice(-1)[0]?.value as string)
          : (t('not_available', { ns: 'common' }) as string)
    }
  };
  widgetData?.tags?.forEach(function (item: WidgetTableDropdownItem) {
    accumulatedWidgetTags.push({
      label: (item.name as string) || (item.id as string),
      value: {
        key: item.id as string,
        content:
          Array.isArray(item.values) && item.values.length > 0
            ? calculateAccumulatedValue(item, item?.meta?.dataType || '', item?.unit || '')
            : (t('not_available', { ns: 'common' }) as string)
      }
    });
  });

  const widgetSettings = {
    title: t('cleaning_session_summary'),
    isLoading:
      isFetchingWidgetData || overviewWidgetDataIsFetching || createMachineConfigStatus.isLoading,
    hasError:
      (errorWidgetData || overviewWidgetDataError || createWidgetError) &&
      t('cleaning_session_summary_failed'),
    hasMenu: true,
    isEditAdminPopupOpen: isEditAdminPopupOpen,
    noPad: true,
    hasCustomizer: true
  };

  return (
    <DashboardWidget
      onAdminButtonClickCallback={() => setIsEditAdminPopupOpen(!isEditAdminPopupOpen)}
      {...widgetSettings}
    >
      {data?.[0]?.id && (
        <PermissionWrapper
          page={PermissionScopeName.FLEET}
          scope={UserScopes.Write}
          role={Role.Admin}
        >
          <AdminCardEditButton
            hideInactive
            machineId={machineId}
            widgetId={data[0].id}
            isEditAdminPopupOpen={isEditAdminPopupOpen}
            setIsEditAdminPopupOpen={handleIsEditAdminPopupOpen}
          />
        </PermissionWrapper>
      )}
      <KpiContainer ref={kpisContainerRef}>
        <DataRenderer
          isLoading={
            isFetchingWidgetData ||
            overviewWidgetDataIsFetching ||
            createMachineConfigStatus.isLoading
          }
          error={
            (errorWidgetData || overviewWidgetDataError || createWidgetError) &&
            (t('failed_to_load_data', { ns: 'common' }) as string)
          }
        >
          <RowRenderer
            isCustomRowHeight={true}
            customRowHeight="2.5rem"
            rows={accumulatedWidgetTags}
            containerHeight={height}
          />
        </DataRenderer>
      </KpiContainer>
    </DashboardWidget>
  );
};

export default CleaningSessionSummaryCard;
