import styled from 'styled-components';

export const ChartLegendContainer = styled.div`
  display: inline-flex;
  gap: 1em;
  font-size: 0.9em;
`;

export const ChartLegendItemContainer = styled.button<{ color?: string }>`
  background-color: transparent;
  padding: 0;
  font-family: inherit;
  font-weight: 400;
  border: none;
  width: max-content;
  text-transform: capitalize;
  font-size: 1em;
  cursor: pointer;

  ${({ color }) =>
    color
      ? `
    display: grid;
    grid-template-columns: auto auto;
    gap: 0.3em; 
    align-items: center;

    &:before {
      content: '';
      width: .8em;
      height: .8em;
      border-radius: 50%;
      background-color: ${color};
    }

  `
      : ``}

  &.is-filtered {
    opacity: 0.7;
  }
`;
