import React from 'react';
import { KPIProgressTile } from 'common/components/KPIProgressTile';
import { useKPIApi } from './hooks/useKPIApi';

export const ThroughputRateKPIProgressTile = (): JSX.Element => {
  const { current, ...rest } = useKPIApi();

  const progressTileSettings = {
    progress: current as number,
    title: 'Throughput Rate',
    className: 'throughput-rate',
    ...rest
  };

  return <KPIProgressTile {...progressTileSettings} />;
};
