import { ReactNode } from 'react';
import { filterSelectedData, useFilterSelected } from 'components';

import { data } from '../../Flyouts/SelectSessionFlyout/demo/demoData';
import { useChangeoverStepDetailsApiProvider } from '../../../views/Changeover/views/SessionsOverTime/providers/ChangeoverStepDetailsApiProvider';

export const useChangeoverStepsDurationWidget = (): {
  data?: Record<string, unknown>[];
  isLoading?: boolean;
  hasError?: ReactNode;
  hasMessage?: ReactNode;
  [key: string]: unknown;
} => {
  const {
    changeoverSessionStepsDurationTotals,
    isLoading,
    hasError,
    hasMessage,
    changeoverSessionsStartDates,
    legendItems,
    changeoverCategoriesTotals
  } = useChangeoverStepDetailsApiProvider();

  const [selected] = useFilterSelected();

  const allChartData =
    changeoverSessionStepsDurationTotals &&
    Object.values(changeoverSessionStepsDurationTotals)
      .map((items) => {
        return Object.values(items);
      })
      .flat();

  const chartData =
    allChartData &&
    filterSelectedData({ data: allChartData as Record<string, unknown>[], selected });

  return {
    isLoading,
    hasError,
    hasMessage: !isLoading && !data ? 'No data within range' : hasMessage,
    //data,
    //groupedData,
    //chartData,
    chartData,
    allChartData,
    changeoverSessionsStartDates,
    legendItems,
    changeoverCategoriesTotals,
    changeoverSessionStepsDurationTotals
    //sessionStartDates,
    //filteredTooltipData
  };
};
