import styled from 'styled-components';
import theme from 'common/theme';

export const CenterValuesContainer = styled.div<{ sizeToUse?: number; color?: string }>`
  position: absolute;
  left: 0;
  top: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 1.3em;
  line-height: 1em;
  gap: 0.5em;

  height: ${({ sizeToUse }) => sizeToUse}px;

  ${({ color }) =>
    !color
      ? ``
      : `
    .has-color-circle {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 0.3em;
      align-items: center;
      justify-content: center;

      &:before {
        content: '';
        width: .8em;
        height: .8em;
        border-radius: 50%;
        background-color: ${color};
      }
    }
  `}

  .center-values {
    display: grid;
    grid-template-rows: 1fr auto 1fr;
    grid-template-columns: 1fr;
    justify-content: center;
    text-align: center;
    row-gap: 0.2em;

    width: ${({ sizeToUse }) => sizeToUse}px;

    .center-values-title,
    .center-values-label {
      font-weight: 500;
      justify-self: center;
      text-transform: capitalize;

      color: ${theme.colors.label};
    }

    &div:first-child {
      align-self: flex-end;
    }

    &div:last-child {
      align-self: flex-start;
    }

    .center-values-title {
      font-size: 0.8em;
    }

    .center-values-value {
      font-size: 1.3em;
      justify-self: center;
      width: 70%;
      font-weight: 800;
    }

    .center-values-label {
      font-size: 0.7em;
    }
  }
`;
