import React, { useMemo } from 'react';
import { ViewContainer } from './View.elements';
import ChartsOverTimeContainer from './ChartsOverTimeContainer';
import ProductTypeContainer from './ProductTypeContainer';
import { ProgressTileWidget } from '../Overview/ProgressTileWidget';
import { BeltSpeedWidget } from './BeltSpeedWidget/BeltSpeedWidget';
import { LoadingWidget } from './LoadingWidget/LoadingWidget';
import { ProductionValuesV1 } from './ProductValuesWidget/ProductValuesWidget';

export const ProductionView = (): JSX.Element => {
  const cachedProdTiles = useMemo(() => <ProductionValuesV1 />, [ProductionValuesV1]);

  return (
    <ViewContainer className="view-container view-container--overview">
      <div className="product-row">{cachedProdTiles}</div>
      <BeltSpeedWidget />
      <LoadingWidget />
      <ProgressTileWidget />
      <div className="product-type-wrapper-dont-use-after-we-move-to-v2">
        <ProductTypeContainer />
      </div>
      <div className="charts-over-time-row">
        <ChartsOverTimeContainer />
      </div>
    </ViewContainer>
  );
};
