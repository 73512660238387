// 3rd party
import React, { ReactElement } from 'react';
import styled, { useTheme } from 'styled-components';
import { useWizard } from 'react-use-wizard';
import { useTranslation } from 'react-i18next';
// Components
import { Typography } from 'components';
import MultiStepIndicator from 'common/components/MultiStepIndicator';
import { IcoNotes } from 'icons/IcoNotes';
import { IcoBilling } from 'icons/IcoBilling';
import { IcoFormDownload } from 'icons/IcoFormDownload';

// Styling
const Header = styled.div`
  width: 100%;
  padding: 1rem 2rem 0;
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;

  h2 {
    font-weight: bold;
    line-height: 1.5625rem;
    margin-bottom: 0.625rem;
  }
`;
const WarnMessage = styled.div``;
interface StepProps {
  header: string;
  description: string;
}

interface Props {
  stepHeaders: StepProps[];
  displayItemLossWarning: boolean;
  displayDraftQuoteWarning: boolean;
  isCartsEmpty?: boolean;
}
const CartHeader = ({
  stepHeaders,
  displayItemLossWarning,
  displayDraftQuoteWarning,
  isCartsEmpty
}: Props): ReactElement => {
  const theme = useTheme();
  const { activeStep, goToStep } = useWizard();
  const { t } = useTranslation(['fpns']);
  const handlePress = (index: number) => {
    if (index < activeStep) {
      goToStep(index);
    }
  };

  const steps = [
    { name: 'step_verify_quoting', icon: IcoNotes },
    { name: 'step_shipping_billing', icon: IcoBilling },
    { name: 'step_download_form', icon: IcoFormDownload }
  ];

  return (
    <>
      <Header>
        <Typography variant="h2">
          {t(stepHeaders[activeStep].header, { ns: ['fpns', 'common'] })}
        </Typography>
        <Typography color={theme.colors.lightGrey8} variant="body2" mb="0.0625rem">
          {t(stepHeaders[activeStep].description, { ns: ['fpns', 'common'] })}
        </Typography>
        {displayItemLossWarning && (
          <WarnMessage>
            <Typography color={theme.colors.darkRed} weight="medium" size="0.625rem" as="span">
              {t('cart_warning_message')}
            </Typography>
          </WarnMessage>
        )}
        {displayDraftQuoteWarning && (
          <WarnMessage>
            <Typography color={theme.colors.darkRed} weight="medium" size="0.625rem" as="span">
              {t('cart_warning_message_draft')}
            </Typography>
          </WarnMessage>
        )}
      </Header>
      <MultiStepIndicator
        handlePress={handlePress}
        steps={steps}
        currentStep={activeStep}
        isEmpty={isCartsEmpty}
      />
    </>
  );
};

export default CartHeader;
