import styled from 'styled-components';

export const ChangeoverWidgetAcitveSessionLabel = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-start;
  font-size: 0.8em;
  text-transform: capitalize;
  text-align: center;
  opacity: 0.8;
`;
