import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SavedProductList, UserPrompt } from 'components';
import { useAuth, useAuthB2C } from 'hooks';
import { useUser } from 'selectors';
import { User } from 'types';
import { useTranslation } from 'react-i18next';
import { themeColors } from 'themes';
import LogoutIcon from '../../img/Logout.svg';
import DropdownSelect from 'components/DropdownMenu/DropdownSelect';

const MainContainer = styled.div`
  height: 40px;
  width: 72px;
  position: relative;
`;

const OuterBadgeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

const InnerBadgeContainer = styled.div`
  width: 40px;
  height: 36px;
  border-radius: 50%;
  background-color: #e5e9ed;
  color: #475467;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  font-weight: 500;
`;

interface PersonBadgeProps {
  name: string;
}

const MenuItem = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding-left: 0.625rem;
  padding-top: 0.313rem;
  padding-bottom: 0.313rem;
  > div {
    cursor: pointer;
  }

  &:hover {
    background-color: ${themeColors.lightGrey2};
  }
`;
const MenuItemIcon = styled.div`
  margin-right: 0.063rem;
`;
const MenuItemText = styled.div`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.026rem;
`;
const MenuHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 0.063rem solid ${themeColors.lightGrey4};
  margin: 0.625rem;
  padding-bottom: 1.125rem;
`;
const MenuHeaderNameId = styled.div`
  font-weight: 700;
`;
const MenuHeaderEmailId = styled.div`
  color: ${themeColors.lightGrey8};
`;
const PopupContainer = styled.ul`
  position: absolute;
  top: 125%;
  right: 0.2rem;
  background-color: white;
  min-width: 13.375rem;
  padding: 0.625rem;
  border: 0.063rem solid ${themeColors.mediumBlue2};
  list-style: none;
  margin: 0px;
  border-radius: 0 0 0.625rem 0.625rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}
`;

const MenuItemsMobile = styled.div`
  @media (min-width: 541px) {
    display: none;
  }
  border-bottom: 0.063rem solid #dedede;
  margin: 0.625rem;
  padding-bottom: 1.125rem;
`;

const TextBadge: React.FC<PersonBadgeProps> = ({ name }) => {
  const auth = useAuth();
  const authB2C = useAuthB2C();
  const user = useUser() as User;
  const { t } = useTranslation(['common']);
  const b2cflag: boolean = process.env.REACT_APP_ENABLE_B2C == 'true';
  const [isLogoutModalOpen, setLogoutModalOpen] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null); // Ref for the dropdown container

  const handleCancelLogout = () => {
    setLogoutModalOpen(false);
  };

  const handleLogout = () => {
    b2cflag ? authB2C.signout() : auth.signout();
  };

  const getInitials = (name: string): string => {
    const names = name.split(' ');
    const initials = names.map((n) => n.charAt(0));
    return initials.join('');
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = () => {
    setLogoutModalOpen(!isLogoutModalOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    // Add event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <MainContainer>
      <OuterBadgeContainer ref={dropdownRef}>
        <InnerBadgeContainer>{getInitials(name)}</InnerBadgeContainer>
        <div>
          <FontAwesomeIcon
            icon={faChevronDown}
            style={{ color: '#E5E9ED', cursor: 'pointer' }}
            onClick={handleToggle}
          />
          {isOpen && (
            <PopupContainer>
              <MenuHeader>
                <MenuHeaderNameId>
                  {user.firstName} {user.lastName}
                </MenuHeaderNameId>
                <MenuHeaderEmailId>{user.email}</MenuHeaderEmailId>
              </MenuHeader>
              <MenuItemsMobile>
                <MenuItem>
                  <SavedProductList />
                </MenuItem>
                <MenuItem>
                  <DropdownSelect />
                </MenuItem>
              </MenuItemsMobile>
              <MenuItem>
                <MenuItemIcon>
                  <img src={LogoutIcon} />
                </MenuItemIcon>
                <MenuItemText onClick={handleOptionSelect}> {t('log_out')}</MenuItemText>
              </MenuItem>
            </PopupContainer>
          )}
        </div>
      </OuterBadgeContainer>
      {isLogoutModalOpen && (
        <UserPrompt
          visible={isLogoutModalOpen}
          message={t('logout_prompt')}
          subMessage={t('you_will_be_logged_out_in') as string}
          primaryActionLabel={t('continue_session')}
          secondaryActionLabel={t('log_out')}
          handleCancel={handleCancelLogout}
          handlePrimaryAction={handleCancelLogout}
          handleSecondaryAction={handleLogout}
          timeoutAction="secondary-action"
        />
      )}
    </MainContainer>
  );
};

export default TextBadge;
