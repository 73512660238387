import React from 'react';
import { PieChartWidget } from 'common/components';
import { useFilterSelected } from 'components';
import { convertSecondsToTime } from 'common/helpers/dateAndTimeHelpers';
import { useChangeoverStepsCategoriesWidget } from './hooks/useChangeoverStepsCategoriesWidget';

const groupKey = 'group';

export const ChangeoverStepCategoriesWidget = (): JSX.Element => {
  const { chartData, isLoading, hasError, hasMessage, legendItems } =
    useChangeoverStepsCategoriesWidget() as {
      chartData: Record<string, unknown>[];
      isLoading: boolean;
      hasError: boolean;
      hasMessage: boolean;
      [key: string]: unknown;
    };

  const [selected, handle] = useFilterSelected();
  // chartData = chartData.slice().sort((a, b) => b.count - a.count);
  const handleClick = (slice: Record<string, unknown>) => {
    const isSelected = selected?.[groupKey]?.includes(slice?.[groupKey] as string) ? true : false;
    return handle(isSelected ? 'toggle' : 'set', { group: slice.group as string });
  };

  // the function that helps determins if the slice is selected
  const checkIfSelected = (slice: Record<string, unknown>) => {
    if (!selected) return true;
    const group = (slice.label as string) || (slice.group as string);
    const selectedVals = selected?.group || [];
    if (selectedVals.length && selectedVals.includes(group)) return true;
    return false;
  };

  const centerCellValues = chartData
    ? {
        label: chartData?.[0]?.group as string,
        value: convertSecondsToTime(Number(chartData?.[0]?.total), 'h:m')
      }
    : undefined;

  if (selected && centerCellValues) {
    const cur = selected?.group?.[0];
    chartData?.forEach((x) => {
      if (x.group === cur && centerCellValues) {
        centerCellValues.label = x.group as string;
        centerCellValues.value = convertSecondsToTime(x.total as number);
      }
    });
  }

  //const filteredData = chartData && filterSelectedData({ data: chartData, selected });

  const widgetUiSettings = {
    isLoading,
    hasMessage,
    hasError,
    className: 'changeover-step-categories-widget',
    title: 'changeover step categories',
    hasDateFooter: true,
    //handleClick,
    legendItems
  };

  const pieChartWidgetSettings = {
    ...widgetUiSettings,
    type: 'doughnut',
    handleClick,
    checkIfSelected,
    selected,
    data: chartData,
    centerCellValues,
    groupKey: 'group',
    valueKey: 'total',
    //disableLegend: true,
    format: {
      legendItem: (props: Record<string, unknown>) => {
        const label = props?.label as string;
        return <>{label}</>;
      }
      /*
      tooltip: (props?: Record<string, unknown>) => {
        const { group, total, count, color, average } = props || {};
        console.log({props})
        return <>
          <ChartTooltipColorItem {...{ color}}>
            {group}
          </ChartTooltipColorItem>
          
          <div>
            Total: {convertSecondsToTime(total as number)}
          </div>
          <div>
            Count: {count}
          </div>
          <div>
            Average: {convertSecondsToTime(average as number)}
          </div>
        </>
      }
      */
    }
  };
  return <PieChartWidget {...pieChartWidgetSettings} />;
};
