import styled from 'styled-components';

export const HoverAreaContainer = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 2;
`;

export const HoverColumnContainer = styled.div<{
  className?: string;
  orientation?: 'vertical' | 'horizontal';
  left?: string;
  top?: string;
  bottom?: string;
  right?: string;
  width?: string;
  height?: string;
}>`
  display: flex;
  justify-content: center;
  position: absolute;
  transform: translateX(-50%);
  cursor: pointer;

  left: ${({ left }) => left};
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  bottom: ${({ bottom }) => bottom};
  top: ${({ top }) => top};
  width: ${({ width }) => width || `3%`};
  height: ${({ height }) => height || `100%`};
`;

export const DefaultHoverColumnComponentContainer = styled.div<{ className?: string }>`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  left: 0;
  top: 0;

  &:hover {
    &:after {
      display: block;
      position: absolute;
      left: 50%;
      top: 0;
      width: 0;
      height: 100%;
      transform: translateX(-50%);
      border-left: 2px dashed red;
      content: '';
      z-index: -1;
    }
  }
`;

export const DefaultHoverColumnTooltipInnerArea = styled.div<{ color?: string }>`
  text-align: center;
  font-size: 1em;

  .tooltip__group {
    align-items: center;
    text-transform: capitalize;
    font-size: 1.1em;
    font-weight: 500;
  }

  .tooltip__value {
    font-size: 1.5em;
    font-weight: 600;
  }

  ${({ color }) =>
    !color
      ? ``
      : `
    .tooltip__group {
      display: flex;
      flex-direction: column; 
      gap: .5em;
      justify-content: center;

      &:before {
        content: '';
        width: 0.7em;
        height: 0.7em;
        border-radius: 50%;
        background-color: ${color};
      }
    }
  `}
`;
