import React from 'react';
import { useChartGenerator } from '../../hooks';
import {
  DefaultHoverColumnComponentContainer,
  HoverAreaContainer,
  HoverColumnContainer
} from './HoverArea.elements';
import { useChartGeneratorTooltip } from '../../hooks/useChartGeneratorToolip/useChartGeneratorTooltip';

const DefaultHoverColumn = ({
  //setTooltip: tooltipsetter,
  ...props
}: Record<string, unknown>): JSX.Element => {
  const { id } = props || {};

  const { getScale } = useChartGenerator();
  const { setTooltip } = useChartGeneratorTooltip();

  /*const allColumnsTotals = useMemo(
    () => getData('categoryKeyTotals') as Record<string, unknown> | undefined,
    [getData]
  );*/

  /*const currentColumnTotals =
    (allColumnsTotals?.[String(id as string | 0)] as Record<string, unknown>) || {};*/

  const tooltipPosition = {
    left: getScale('x', id as number | string),
    top: '0'
  };

  const Component = (
    <>
      <div className="tooltip__title">hover col</div>
    </>
  );

  const hoverColumnSettings = {
    className: 'hover-column-container',
    onMouseEnter: () => setTooltip({ Component, ...tooltipPosition }),
    onMouseLeave: () => setTooltip(undefined)
  };

  return (
    <>
      <DefaultHoverColumnComponentContainer {...hoverColumnSettings} />
    </>
  );
};

const HoverColumn = (props?: Record<string, unknown>) => {
  const { getScale, Components } = useChartGenerator();
  const { tick, i, count } = props || {};
  const { setTooltip } = useChartGeneratorTooltip();

  const HoverColumnComponent = Components?.HoverColumns || DefaultHoverColumn;

  let className = `hover-column hover-column--${i} hover-column-${tick}`;

  if (i === 0) className += ' is-first';
  if (i === Number(count || 0) - 1) className += ' is-last';

  const columnSettings = {
    className,
    left: `${getScale('x', tick as string | number) || 0}px`,
    bottom: '0px'
  };

  return (
    <HoverColumnContainer {...columnSettings}>
      <HoverColumnComponent {...{ id: tick, setTooltip }} />
    </HoverColumnContainer>
  );
};

export const HoverArea = (): JSX.Element => {
  const { getScale } = useChartGenerator();

  const bottomTicks = (getScale('bottomTicks') as (string | number)[])?.reverse();

  if (bottomTicks) return <></>;

  return (
    <HoverAreaContainer className="hover-columns-container">
      <>
        {bottomTicks?.map((tick, i) => (
          <HoverColumn key={tick} {...{ tick, i, count: bottomTicks?.length }} />
        ))}
      </>
    </HoverAreaContainer>
  );
};
