import React from 'react';

import { ActiveIssuesWidget } from 'common/components/WidgetUi/ActiveIssuesWidget/ActiveIssuesWidget';

import { useCurrentIssuesWidget } from './hooks/useCurrentIssuesWidget';
import { WidgetDateRangeProvider } from 'common/ui';
import { useMachineInfo } from '../../../../../../providers/useMachineInfoProvider';
import { ActiveIssuesTableDataItemProps } from 'common/ui/ActiveIssuesTable/ActiveIssuesTable';

export const CurrentIssuesWidget = (): JSX.Element => {
  const { timeZone } = useMachineInfo();
  return (
    <WidgetDateRangeProvider
      //debugLabel="aseptic current issues"
      subtractDays={1}
      {...{ timeZone }}
    >
      <Provided />
    </WidgetDateRangeProvider>
  );
};

export const Provided = (): JSX.Element => {
  const { isLoading, hasError, hasMessage, currentIssues } = useCurrentIssuesWidget();

  const widgetSettings = {
    className: 'current-issues-widget',
    title: 'current issues',
    isLoading,
    hasError,
    hasMessage,
    tableData: currentIssues as ActiveIssuesTableDataItemProps[]
  };

  return <ActiveIssuesWidget {...widgetSettings} />;
};
