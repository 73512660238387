import React from 'react';
import styled from 'styled-components';

interface SwatchProps {
  color: string;
  onClick?: (color: string) => void;
}

interface SwatchContainerProps {
  color: string;
}

const SwatchContainer = styled.div<SwatchContainerProps>`
  background-color: ${({ color }) => color};
  width: 28px;
  height: 28px;
  display: inline-block;
  margin: 5px;
  border-radius: 50%;
`;

export const Swatch = ({ color, onClick }: SwatchProps): JSX.Element => {
  return <SwatchContainer color={color} onClick={() => onClick?.(color)}></SwatchContainer>;
};
