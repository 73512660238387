import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from 'api';
import { addArgsToPath, defaultTransformResponse } from 'helpers';

const TAGS = ['RecipiesConfigs'];

export const prosealApi = createApi({
  reducerPath: 'prosealApi', //Key in the Redux store where the API slice's reducer state will be stored//prevents conflicts with other slices or reducers
  baseQuery: baseQueryWithReauth, // Replace '/api' with your actual base URL
  tagTypes: TAGS, //Tags are used to group endpoints together in the generated hooks
  endpoints: (builder) => ({
    getDowntimeData: builder.query({
      query: ({ machineId, ...rest }) => {
        return `/mh/v1/proseal/machines/${machineId ?? ''}/downtime${addArgsToPath(rest)}`;
      }
    }),
    getRecipes: builder.query({
      query: ({ machineId }) => {
        return `/mh/v1/machines/${machineId ?? ''}/proseal-recipes`;
      },
      transformResponse: defaultTransformResponse
    })
    //getRecipes update row call here
  })
});

export const { useGetDowntimeDataQuery, useGetRecipesQuery } = prosealApi;
