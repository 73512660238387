import { ChartsBaseProps } from '../../ChartGenerator/ChartGenerator.types';

export interface D3ChartGeneratorDataProviderProps extends ChartsBaseProps {
  includeKeys?: string[];
}

export interface CalculateGroupKeyTotalsReturnProps {
  [key: string]: {
    count?: number;
    total?: number;
    average?: number;
    groupKey?: string;
    valueKey?: string;
    [key: string]: unknown;
  };
}

export interface CalculatorsOptionsProps {
  data?: Record<string, unknown>[];
  valueKey?: string;
  colorKey?: string;
  colors?: Record<string, string>;
  mapKeys?: Record<string, string>;
  includeKeys?: string[];
  categoryKey?: string;
  isCategory?: boolean;
  [key: string]: unknown;
}

export const calculateGroupKeyTotals = (
  groupKey: string,
  {
    data,
    valueKey,
    colorKey,
    colors,
    mapKeys,
    includeKeys,
    categoryKey,
    isCategory
  }: CalculatorsOptionsProps
): CalculateGroupKeyTotalsReturnProps | undefined => {
  //if (!data) return undefined;
  colorKey = (colorKey || groupKey || 'group') as string;

  // Group the data by the specified key and count the number of occurrences of each group
  const reducedData = data?.reduce((acc: CalculateGroupKeyTotalsReturnProps | undefined, obj) => {
    let keyId = undefined;

    let newItem: Record<string, unknown> = {
      count: 0, // the number of occurrences of this group
      total: 0, // the total amount of the values of valueKeys (if provided)
      average: 0 // the average amount of the values of valueKeys (if provided)
    };

    if (valueKey) newItem.valueKey = valueKey;

    // this is for grouping by groupKey
    if (!isCategory) {
      keyId = obj?.[groupKey as string] as string;
      keyId = (mapKeys?.[keyId] as string) || keyId;

      // look for possible color to add to data item
      const colorId = obj?.[colorKey as string] as string;
      const color = obj?.color || (colors?.[colorId] as string);

      newItem = {
        ...newItem,
        group: keyId,
        groupKey,
        [groupKey]: keyId,
        color,
        colorKey
      };

      if (categoryKey)
        newItem = {
          ...newItem,
          category: obj?.[categoryKey as string] as string,
          [categoryKey]: obj?.[categoryKey as string] as string,
          categoryKey
        };
    } else {
      if (!categoryKey) return acc;

      let category = obj?.[categoryKey as string] as string;
      category = (mapKeys?.[category] as string) || category;

      keyId = category;

      newItem = {
        ...newItem,
        category,
        categoryKey,
        [categoryKey]: category,
        group: obj?.[groupKey],
        [groupKey]: obj?.[groupKey as string] as string,
        groupKey
      };
    }

    if (!keyId) return acc;

    // start new counter object
    if (!acc?.[keyId]) {
      acc = {
        [keyId]: newItem,
        ...acc
      };
    }

    // setup a new group object.  this was done to pass TS errors
    const newG = acc[keyId] as Record<string, unknown>;
    // add to the counter
    newG.count = Number(newG.count) + 1;
    //newG[counterValueKey] = newG.count;

    // check if there is a value or we're just couting the occurrences of this group
    // add value key for reference if provided
    if (valueKey) {
      newItem = {
        ...newItem,
        [valueKey as string]: obj?.[valueKey as string] as number
      };
      // get value if we're given a valueKey to total up the amounts
      const val = obj?.[valueKey as string] as number;
      newG.total = Number(newG.total) + val;
      newG.average = Number(newG.total) / Number(newG.count);
    } else {
      delete newG.average;
      delete newG.total;
    }

    // replace with new totals
    acc[keyId] = newG;

    if (includeKeys) {
      includeKeys?.forEach((key) => {
        acc[keyId][key] = obj?.[key];
      });
    }
    // return the new data object array
    return acc;
  }, {});

  return reducedData;
};

export const calculateValueKeyTotals = (
  valueKeys: string | string[],
  { data }: CalculatorsOptionsProps
): Record<string, Record<string, unknown>> | undefined => {
  if (typeof valueKeys === 'string') valueKeys = [valueKeys];

  if (!data) return undefined;
  /*
  const totals: Record<string, unknown> = data.reduce(
    (acc: Record<string, Record<string, unknown>>, obj: Record<string, unknown>) => {
      valueKeys.forEach((valueKey) => {
        let group = valueKey as string;
        group = (mapKeys?.[group] as string) || group;

        const color = colors?.[group];

        // start new counter object
        if (!acc[group]) {
          acc = {
            [group]: {
              // the number of occurrences of this group
              count: 0,
              // the total amount of the values of valueKeys (if provided)
              total: 0,
              // the average amount of the values of valueKeys (if provided)
              average: 0,
              valueKey
            },
            ...acc
          };

          if (color) acc[group].color = colors?.[group];
        }

        // setup a new group object.  this was done to pass TS errors
        const newG = acc[group] as Record<string, unknown>;

        // get value if we're given a valueKey to total up the amounts
        const val = obj?.[valueKey as string] as number;

        // add to the counter
        newG.count = Number(newG.count) + 1;

        if (val) {
          newG.valueKey = keys.valueKey;
          newG.total = Number(newG.total) + val;
          newG.average = Number(newG.total) / Number(newG.count);
        } else {
          delete newG.average;
          delete newG.total;
        }
        // replace with new totals
        acc[group] = newG;
      });

      // return the new data object array
      return acc;
    },
    {}
  );
*/
  return;
};
