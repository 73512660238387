import { DowntimeItem, DowntimeItemWidget } from 'common/pages/fleetV2/machine/proseal/api/types';
import { CategoryLineProps } from '../elements/Chart.types';
import { Icons } from 'common/pages/fleetV2/settings/proseal';

export const generateWidgetData = (
  data: DowntimeItem[]
): { processedData: DowntimeItemWidget[]; categories: CategoryLineProps[] } => {
  // There are 2500 statusses that are grouped to 7 categories.

  // For example:
  // The machine is in an Internal Error
  // The Category is Process error
  // The actual error (status) = Film sesnor Error

  // Mapping
  //   {
  //     "status":
  //     "total_time_seconds":
  //     "counts":
  //     "production_percentage":
  //     "machine_state":
  //     "status_category":
  //     "short_name":
  //     "color":

  //     category_label -> status_category,
  //     bar_label -> short_name,
  //     icon -> status_category
  // },

  const totals = data?.reduce(
    (
      acc: { tot_time: number; tot_count: number },
      curr: { total_time_seconds: number; counts: number }
    ) => {
      acc.tot_time += curr.total_time_seconds;
      acc.tot_count += curr.counts;
      return acc;
    },
    { tot_time: 0, tot_count: 0 }
  ) as {
    tot_time: number;
    tot_count: number;
  };

  const processedData =
    data &&
    (
      [...data]
        .map((item) => ({
          ...item,
          icon: Icons[item.status_category || ''],
          label: item.short_name,
          percentage_time: Math.floor(
            (Math.floor(item.total_time_seconds as number) * 100) / totals.tot_time
          ) as unknown as number,
          percentage_count: Math.floor((item.counts * 100) / totals.tot_count) as unknown as number
        }))
        .sort(
          (a, b) => b.total_time_seconds - a.total_time_seconds
        ) as unknown as DowntimeItemWidget[]
    ).slice(0, 8);

  const categories = data?.reduce((acc: CategoryLineProps[], current: DowntimeItem) => {
    if (!acc.some((item: CategoryLineProps) => item.category === current.status_category)) {
      const icon = Icons[current.status_category || ''];
      const label = current.status_category;
      acc.push({ icon, label, color: current.color, category: current.status_category });
    }
    return acc;
  }, []);

  return { processedData, categories };
};
