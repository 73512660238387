import React, { createContext, useContext, ReactNode, useMemo } from 'react';

import { ChartsBaseProps } from '../../ChartGenerator.types';
import { useChartGeneratorData } from '../useChartGeneratorData/useChartGeneratorData';
import { line, scaleLinear, scalePoint, pie, arc, PieArcDatum } from 'd3';
import { generateChartDataFromObjectArrayWithGroupIDKeys } from '../useChartGeneratorData/helpers/generateChartData';
import { generateLegendItems } from '../useChartGeneratorData/helpers/generateLegendItems';
import { getMaxValue } from '../useChartGeneratorData/helpers/getMaxValue';

export type UseChartGeneratorGetterProps = (
  type: string,
  options?: Record<string, unknown> | number | string
) =>
  | string[]
  | number[]
  | string
  | number
  | Record<string, unknown>
  | Record<string, unknown>[]
  | Record<string, Record<string, unknown>[]>
  | ReactNode
  | undefined;

export interface UseChartGeneratorProviderReturnProps extends ChartsBaseProps {
  getScale: UseChartGeneratorGetterProps;
  [key: string]: unknown;
}

const ChartGeneratorDataContext = createContext<UseChartGeneratorProviderReturnProps>({
  getScale: (type, options) => {
    console.log({ type, options }, 'not set');
    return undefined;
  }
});

export const useChartGenerator = (): UseChartGeneratorProviderReturnProps =>
  useContext(ChartGeneratorDataContext);

interface Props extends ChartsBaseProps {
  children?: ReactNode;
  legendItems?: Record<string, unknown>[];
  setLegendItems?: (legendItems?: Record<string, unknown>[] | undefined) => void;
  //[key: string]: unknown;
}

export const UseChartGeneratorProvider = ({
  children,
  width,
  height,
  leftTickCount,
  size,
  legendItems,
  setLegendItems,
  ...incomingOverrides
}: Props): JSX.Element => {
  const { isInitialized, getData, ...providerSettings } = useChartGeneratorData();

  if (!isInitialized) {
    return <>useChartGeneratorData not initialized</>;
  }

  const {
    //data,
    chartType,
    keysToUse,
    mapKeys,
    groupKeys,
    valueKeys,
    categoryKey,
    categoryKeys,
    colorKey,
    colors,
    dataToIndex,
    ...props
  } = {
    // this will use the useChartData props first and overwrite with incoming props that get sent to ChartGenerator
    ...providerSettings,
    ...incomingOverrides
  } as Props;

  let { groupKey, valueKey } = props as Props;

  if (valueKeys || valueKey) {
    groupKey = groupKey || 'group';
    valueKey = valueKey || 'value';
  }

  width = width || 0;
  height = height || 0;

  leftTickCount = leftTickCount || 3;
  size = size || 2;

  //const [indexedData, setIndexedData] = useState<Record<string, unknown>[] | undefined>(props?.indexedData as Record<string, unknown>[] || undefined);

  const indexedData = useMemo(() => {
    if (!dataToIndex) return undefined;

    const data = valueKeys
      ? generateChartDataFromObjectArrayWithGroupIDKeys(valueKeys as string[], {
          categoryKey,
          colors,
          data: dataToIndex as Record<string, unknown>[],
          groupKey,
          valueKey
        })
      : (dataToIndex as Record<string, unknown>[])
          ?.map((item, dataIndex) => ({ ...item, dataIndex }))
          ?.sort((a: Record<string, unknown>, b: Record<string, unknown>) => {
            if (!categoryKey) return 0;
            if (Number(a?.[categoryKey as string] || 0) < Number(b?.[categoryKey]) || 0) return -1;
            if (Number(a?.[categoryKey] || 0) > Number(b?.[categoryKey] || 0)) return 1;
            return 0;
          });

    if (!legendItems) {
      const newLegendItems = generateLegendItems({ data, colors, groupKey });
      setLegendItems?.(newLegendItems);
    }
    return data;
  }, [dataToIndex, valueKeys, categoryKey, colors, groupKey, valueKey]);

  const { categories, domainX, minMaxDomainY } = useMemo(() => {
    const categoriesToUse =
      props?.categories || (getData('categories', { categoryKey, data: indexedData }) as string[]);

    const maxValue = (valueKeys || valueKey) && getMaxValue(valueKey as string, indexedData);

    return {
      categories: categoriesToUse as string[],
      domainX: (props?.domainX || categoriesToUse) as string[],
      minMaxDomainY: maxValue //getData('maxValue', { valueKey, valueKeys, data: indexedData })
    };
  }, [indexedData, incomingOverrides]) as Record<string, unknown>;

  const getScale = (
    type: string,
    options?: Record<string, unknown> | number | string | Record<string, unknown>[]
  ):
    | string[]
    | number[]
    | string
    | number
    | Record<string, unknown>
    | Record<string, unknown>[]
    | Record<string, Record<string, unknown>[]>
    | ReactNode
    | undefined => {
    const optionsToUse = (
      typeof options === 'object' ? options : { leftTickCount: undefined }
    ) as Record<string, unknown>;

    const categoryKeyToUse = (optionsToUse?.categoryKey || categoryKey) as string;
    const groupKeyToUse = (optionsToUse?.groupKey || groupKey) as string;
    const valueKeyToUse = (optionsToUse?.valueKey || valueKey) as string;

    const dataToUse = (optionsToUse?.data || indexedData) as Record<string, unknown>[];
    const leftTickCountToUse = (optionsToUse?.leftTickCount as number) || leftTickCount;

    const minMax = [minMaxDomainY || 0, 0] as number[];

    const scalerX = scalePoint()
      .domain(domainX as string[])
      .rangeRound([(width as number) || 0, 0]);

    const scalerY = scaleLinear()
      .domain(minMax as number[])
      .nice(leftTickCountToUse)
      .rangeRound([0, (height as number) || 0]);

    switch (type) {
      case 'categories':
        return getData('categories', { categoryKey: categoryKeyToUse, data: dataToUse });
      case 'legendItems':
        return generateLegendItems({ data: dataToUse, colors, groupKey: groupKeyToUse });
      case 'groupedData':
        return getData('groupByKey', { groupKey: groupKeyToUse, data: dataToUse });
      case 'bottomTicks':
        return scalerX.domain();
      case 'leftTicks': {
        return scalerY.ticks(Number(leftTickCountToUse));
      }

      case 'y':
      case 'left':
      case 'yLinear': {
        const val =
          typeof options !== 'object'
            ? options
            : optionsToUse?.value ||
              optionsToUse?.left ||
              optionsToUse?.y ||
              optionsToUse?.[valueKeyToUse];

        return scalerY(Number(val));
      }

      case 'x':
      case 'bottom':
      case 'xPoint': {
        if (!domainX || typeof scalerX !== 'function') return undefined;

        const val =
          typeof options !== 'object'
            ? options
            : optionsToUse?.value ||
              optionsToUse?.bottom ||
              optionsToUse?.x ||
              optionsToUse?.[categoryKeyToUse];

        return scalerX(val as string);
      }

      case 'linePath': {
        const categoryKeyToUse = (optionsToUse?.categoryKey || categoryKey) as string;
        const valueKeyToUse = optionsToUse?.valueKey || valueKey;

        if (typeof scalerX !== 'function' || typeof scalerY !== 'function') return undefined;

        // Build the line
        const lineBuilder = line<Record<string, unknown>>()
          .x((d: Record<string, unknown>) => scalerX(d?.[categoryKeyToUse] as string) || 0)
          .y((d) => scalerY(Number(d?.[valueKeyToUse as string]) || 0) as number);

        const path = lineBuilder(dataToUse as Record<string, unknown>[]);

        return path;
      }

      case 'pieChartTotals':
      case 'valueKeyTotal':
      case 'pieChartData':
      case 'pieSlices': {
        const valueKeyToUse = optionsToUse?.valueKey || keysToUse?.valueKey || valueKey;

        const pieChartData = getData('pieChartData');
        const pieChartTotals = getData('groupKeyTotals');

        if (type === 'pieChartData') return pieChartData;
        if (type === 'pieChartTotals') return pieChartTotals;
        if (type === 'valueKeyTotal') {
          return (pieChartData as Record<string, unknown>[])?.reduce((acc, item) => {
            const valueToUse = item?.[valueKeyToUse as string] as number;
            if (typeof valueToUse === 'number') acc = acc + valueToUse;
            return acc;
          }, 0);
        }

        const isHoverLayer = optionsToUse?.isHoverLayer || false;
        let outerRadius = (optionsToUse?.outerRadius || 0) as number;
        let innerRadius = optionsToUse?.innerRadius as number;
        let padding = 4;
        size = size || 0.78;

        if (isHoverLayer) padding = 0;
        else padding = 4;

        width = width || 200;
        height = height || 200;

        // this just picks if it's taller or wider to make it square
        const sizer = Number(width || 0) < Number(height || 0) ? width : height;

        outerRadius = outerRadius || Number(sizer) / 2 - padding / 2;
        // this gets set for ring style charts
        innerRadius = innerRadius || Number(outerRadius || 0) * size + padding / 2;
        // Define the pie generator
        const pieSvg = pie<Record<string, unknown>>().value((d) =>
          typeof d?.[valueKeyToUse as string] === 'number' ? +Number(d[valueKeyToUse as string]) : 0
        );

        const arcSvg = arc<PieArcDatum<Record<string, unknown>>>()
          .innerRadius(innerRadius)
          .outerRadius(outerRadius);

        if (!pieChartData) return undefined;

        const arcs = pieSvg(pieChartData as Record<string, unknown>[]);

        if (!arcs) return undefined;

        const pieData = arcs.map((slice) => {
          const center = arcSvg.centroid(slice);

          let [left, top] = center;

          left = left + (width as number) / 2;
          top = top + Number(height) / 2;

          return {
            ...slice,
            d: arcSvg(slice),
            centerLeft: left,
            centerTop: top,
            hasOffset: { width: Number(width || 0) / 2, height: Number(height || 0) / 2 }
          };
        });

        return pieData as Record<string, unknown>[];
      }

      default:
        break;
    }

    return;
  };

  return (
    <ChartGeneratorDataContext.Provider
      value={{
        ...props,

        getScale,
        chartType,

        width,
        height,
        leftTickCount,
        size,
        categories: categories as string[],

        groupKey,
        groupKeys,
        categoryKey,
        categoryKeys,
        colorKey,
        valueKey,
        valueKeys,

        keysToUse,
        mapKeys,
        colors,

        dataToIndex,
        indexedData
      }}
    >
      <>{children}</>
    </ChartGeneratorDataContext.Provider>
  );
};

/*
export const generateStackedBarChart = (
  data: Record<string, unknown>[],
  {
    width,
    height,
    categoryKey,
    groupKey,
    valueKey,
    marginLeft,
    marginBottom,
    marginTop,
    marginRight,
    colors,
    domainX,
    //domainY,
    barSpacing,
    leftTickFormatType,
    categories
  }: {
    categories?: string[];
    width?: number;
    height?: number;
    categoryKey?: string;
    groupKey?: string;
    valueKey?: string;
    marginLeft?: number;
    marginRight?: number;
    marginTop?: number;
    marginBottom?: number;
    colors?: Record<string, string>;
    domainY?: string[] | number[];
    domainX?: string[] | number[];
    barSpacing?: number;
    leftTickFormatType?: 'seconds-to-hours';
  }
): [
  Record<string, BarChartBarProps[]>,
  BarChartBarProps[],
  BarChartBarProps[],
  { x?: number[]; y?: number[] }
] => {
  width = width || 0;
  height = height || 0;

  if (width === 0 || height === 0) return [{}, [], [], {}];

  marginLeft = marginLeft || 0;
  marginBottom = marginBottom || 0;
  marginTop = marginTop || 0;
  marginRight = marginRight || 0;

  groupKey = groupKey || 'group';
  categoryKey = categoryKey || 'category';
  valueKey = valueKey || 'value';

  const chartHeight = height - (marginBottom + marginTop);
  const chartWidth = width - (marginLeft + marginRight);

  categories = categories || generateChartKeys(data, categoryKey);

  const series = d3
    .stack()
    .keys(d3.union(data.map((d) => d?.[groupKey as string] as string))) // distinct series keys, in input order
    .value(([, D], key) => D.get(key)?.[valueKey as string] || 0)(
    d3.index(
      data,
      (d) => d?.[categoryKey as string] as string,
      (d) => d?.[groupKey as string] as string
    )
  );

  const maxTotal = d3.max(series, (d) => d3.max(d, (d) => d[1]));
  //const maxTotalRound = Math.ceil((maxTotal as number) / 10000) * 10000;

  const generateLeftKeys = (maxTotal: number, count: number) => {
    // get the total hours based on the amount of seconds
    const totalHours = maxTotal / 3600;

    // first round to nearest 10 as default
    let totalHoursRound = Math.ceil(totalHours / 0.5) * 0.5;
    // if the total is greater than 10, round to nearest 100 and keep moving up
    //if (totalHours > 5) totalHoursRound = Math.ceil(totalHours / 5) * 10;
    if (totalHours > 10) totalHoursRound = Math.ceil(totalHours / 10) * 10;
    else if (totalHours > 100) totalHoursRound = Math.ceil(totalHours / 100) * 100;
    else if (totalHours > 1000) totalHoursRound = Math.ceil(totalHours / 1000) * 1000;
    else if (totalHours > 10000) totalHoursRound = Math.ceil(totalHours / 10000) * 10000;
    // no convert that back to seconds

    const maxTotalSeconds = totalHoursRound * 3600;
    // how many ticks you want to display
    const chunk = maxTotalSeconds / count;
    const keys = [];
    // generate an array of seconds based on a rounded hour value
    for (let i = 0; i - 1 < count; i++) {
      ``;
      keys.push(chunk * i);
    }

    return keys;
  };
  const leftTickMarks =
    leftTickFormatType === 'seconds-to-hours' ? generateLeftKeys(maxTotal as number, 5) : undefined;
  const x = d3
    .scaleBand()
    .domain((domainX as string[]) || categories)
    .rangeRound([0, chartWidth])
    .padding((barSpacing as number) || 0.3);

  const y = d3
    .scaleLinear()
    .domain([0, leftTickMarks?.[leftTickMarks.length - 1] || maxTotal] as number[])
    .rangeRound([chartHeight, 0]);
  const color = d3
.scaleOrdinal()
.domain(series.map((d) => d.key))
.range(d3.schemeSpectral[series.length])
.unknown('#ccc');

  const leftTicks = leftTickMarks || y.ticks(5);
  const bottomTicks = x.domain();

  const gridX: number[] = [];
  const gridY: number[] = [];

  let bars: Record<string, Record<string, unknown>[]> = {};

  const axisLeft: BarChartBarProps[] = leftTicks.map((val) => {
    gridY.push(y(val as number));
    return {
      x: marginLeft,
      y: y(val as number),
      label: `${val}`,
      width: marginLeft
    };
  });

  const axisBottom: BarChartBarProps[] = bottomTicks.map((val) => {
    gridX.push((x(val) as number) + x.bandwidth() / 2);
    return {
      x: (x(val as string) as number) + x.bandwidth() / 2,
      y: 0,
      label: `${val}`,
      width: x.bandwidth(),
      center: x
    };
  });

  series.forEach((D) => {
    const group = D.key;
    D.forEach((d) => {
      const category = d.data[0];
      const height = y(d[0]) - y(d[1]);
      // for now i'm searching the chart data for the item and adding it to the bar data
      // ideally, this should be done a different way.  i'm trying to figure out how to get more info
      // from the data set that D3 is using for the stacks
      const item = data?.find(
        (item: Record<string, unknown>) =>
          item[groupKey as string] === group && item[categoryKey as string] === category && item
      );
      if (!bars || !bars?.[category]) bars = { [category]: [], ...bars };

      const xpos = (x(`${d.data[0]}`) as number) + Number(marginLeft);
      const ypos = (y(d[1]) as number) + Number(marginTop);
      const width = x.bandwidth() as number;

      const newBar = {
        ...item,
        x: xpos,
        y: ypos,
        width,
        height,
        color: item?.color || colors?.[group] || 'red',
        group,
        category,
        center: xpos + width / 2
      };

      if (height > 0) bars[category] = [newBar, ...bars[category]];
    });
  });
  return [bars, axisLeft, axisBottom, { x: gridX, y: gridY }];
};
*/
