import React from 'react';
import { ViewContainer } from './View.elements';
import { ChangeoverWidget } from '../../../../components/ChangeoverWidget/ChangeoverWidget';
import { WaitTimeWidget } from '../../../../components/WaitTimeWidget/WaitTimeWidget';
import { AsepticMachineUtilizationTiles } from '../../../../components/MachineUtilizationTiles/AsepticMachineUtilizationTiles';
import { LaneStatesView } from '../../../../components/LaneStatesWidget/LaneStatesView';
import { SignelSessionProgramStepsTable } from '../../../../components/SingleSessionSteps/SingleSessionProgramStepsTable';
import { ChangeoverSessionDetailsAPIPovider } from 'common/pages/fleetV2/machine/aseptic/providers/useChangeoverSessionDetailsAPIPovider';
import { SessionAlarmsFlyout } from '../../../../components/Flyouts/SessionAlarmsFlyout/SessionAlarmsFlyout';
import { ChangeoverMachineModesWidget } from '../../../../components/ChangeoverMachineModes';
import { showCounters } from 'common/pages/fleetV2/testing_settings';
// you can delete this when it's enables, this is just for TS and lint check
const enableTiles = false;

const UsingChangeoverSessionDetails = (
  <ChangeoverSessionDetailsAPIPovider>
    <WaitTimeWidget />
    <ChangeoverMachineModesWidget />
    <LaneStatesView />
    <SignelSessionProgramStepsTable />
    <SessionAlarmsFlyout />
  </ChangeoverSessionDetailsAPIPovider>
);

let SingleSessionView_conter = 0;
// add widgets that don't need provided date here

export const SingleSessionView = (): JSX.Element => {
  ++SingleSessionView_conter;
  if (showCounters) console.log({ SingleSessionView_conter });
  return (
    <ViewContainer className="changeover single-session view-container">
      <ChangeoverWidget />
      {enableTiles && <AsepticMachineUtilizationTiles />}
      {UsingChangeoverSessionDetails}
    </ViewContainer>
  );
};
