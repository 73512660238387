import React from 'react';
import { Typography } from 'components';
import { Value, Row, Cell } from './CardComponents';
import theme from 'themes';
import { themeColors } from 'themes';
import styled from 'styled-components';
import { CleaningSession, CleaningSessionsKpi } from 'types/protein';
import { formatDuration, getDurationBetweenTimestamps } from 'helpers';
import { IcoCleaningGroup } from 'icons/IcoCleaningGroup';
import { IcoCleaningAvgSession } from 'icons/IcoCleaningAvgSession';
import { IcoCleaningClock } from 'icons/IcoCleaningClock';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  flex-direction: column;
  position: relative;
  padding: 1rem 3.125rem;
  display: flex;
  gap: 0.5rem;
  justify-content: space-around;
`;

const SubContainer = styled.div`
  position: relative;
  display: flex;
  gap: 1rem;
  justify-content: space-around;
`;

const ChildContainer = styled.div`
  position: relative;
  display: flex;
  gap: 0.75rem;
`;

const IconContainer = styled.div``;

interface Props {
  value: Value;
  selectedSession?: CleaningSession;
  cleaningSessionKpiData?: CleaningSessionsKpi;
  isLoading?: boolean;
}

const NewCleaningCard = ({
  value,
  selectedSession,
  cleaningSessionKpiData
}: Props): JSX.Element => {
  const { t } = useTranslation(['mh']);
  return (
    <>
      <Row>
        <Cell>
          <IconContainer>
            <IcoCleaningGroup color={theme.colors.icons.toast.warning} />
          </IconContainer>
          {value.valueTitle && (
            <Typography mb={value.mb ?? '0.625rem'} color="mediumGrey2" size="0.8125rem">
              {value.valueTitle}
            </Typography>
          )}
          <Typography
            mb={value.mb ?? 0}
            size={value.size ?? '2.25rem'}
            color={theme.colors.icons.toast.warning}
            className="kpi-card__value"
            style={{ fontWeight: 300 }}
          >
            {value.value}
          </Typography>
          <Typography
            mb={0}
            color={themeColors.lightGrey8}
            size={value.unitSize ?? '0.8125rem'}
            className={'kpi-card__unit'}
          >
            {value.unit}
          </Typography>
          <Container>
            <SubContainer>
              <Typography mb={0} style={{ fontWeight: 600 }} size="2rem" weight="bold">
                {selectedSession
                  ? getDurationBetweenTimestamps(
                      selectedSession.startTimestamp,
                      selectedSession?.endTimestamp,
                      'hours:mins'
                    )
                  : '-'}
              </Typography>
              <Typography mb={0} style={{ fontWeight: 600 }} size="2rem" weight="bold">
                {cleaningSessionKpiData
                  ? formatDuration(cleaningSessionKpiData.avgDuration, 'hours:mins')
                  : '-'}
              </Typography>
            </SubContainer>
            <SubContainer>
              <ChildContainer>
                <IcoCleaningClock />
                <Typography color={theme.colors.text.lightBlack}>{t('Hours/ Min')}</Typography>
              </ChildContainer>
              <ChildContainer>
                <IcoCleaningAvgSession />
                <Typography color={theme.colors.text.lightBlack} mb={0}>
                  {t('Avg/ Session')}
                </Typography>
              </ChildContainer>
            </SubContainer>
          </Container>
        </Cell>
      </Row>
    </>
  );
};

export default NewCleaningCard;
