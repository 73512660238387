// 3rd party libs
import React, { useEffect } from 'react';
import { Switch, Route, useRouteMatch, Redirect, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

// Components
import { PageTabView, DateButtonWithDropdown, useDateRange } from 'components';
import { ConfiguredSubNav, DataRenderer, GenericWidgetPage } from 'components/machine-health';

// SubViews
import { HistoricRecipes } from './HistoricRecipes/HistoricRecipesV2';
import Consumables from './Consumables/ConsumablesV2';

// Providers
import { SyncZoomProvider, useLanguage } from 'providers';

// Types
import {
  MachinePerformanceSubTabs,
  MachinePerformanceTabs,
  ProteinMachineRouteQueryParams,
  TopLevelTabs,
  WidgetClass,
  WidgetType
} from 'types/protein';

// Routes
import { proteinMachinePerformanceSlugs } from 'constants/routes';

// Hooks
import { useConfiguredSubNav } from 'hooks';
import { DATA_DATE_LIMIT_DAYS } from 'constants/machineConfig';
import { useCreateMachineConfigurationDataMutation, useGetMachineConfiguratorDataQuery } from 'api';

// Define the slugs used for top level nav within Machine Health
export const SUB_ROUTES = {
  current: proteinMachinePerformanceSlugs[MachinePerformanceTabs.Current],
  historicRecipes: proteinMachinePerformanceSlugs[MachinePerformanceTabs.HistoricRecipes],
  consumables: proteinMachinePerformanceSlugs[MachinePerformanceTabs.Consumables],
  hmi: proteinMachinePerformanceSlugs[MachinePerformanceTabs.Hmi]
};

const MachinePerformance = (): JSX.Element => {
  const match = useRouteMatch();

  const { dateRange, setDateRange } = useDateRange();
  const { machineId } = useParams<ProteinMachineRouteQueryParams>();
  const { languageId } = useLanguage();
  const [createMachineConfigurationData] = useCreateMachineConfigurationDataMutation();

  const { isLoading, error, tabs } = useConfiguredSubNav(
    undefined,
    [TopLevelTabs.MachinePerformance],
    proteinMachinePerformanceSlugs
  );
  const MPTabs = true;

  const hasGoBackDateLimit = DATA_DATE_LIMIT_DAYS;

  const { data: overviewWidgetData } = useGetMachineConfiguratorDataQuery({
    machineId,
    labels: [TopLevelTabs.MachinePerformance],
    languageId: languageId
  });

  useEffect(() => {
    if (overviewWidgetData && overviewWidgetData.length > 0) {
      const targetItem = overviewWidgetData[0].members?.find(
        (item) => item.label === MachinePerformanceTabs.Consumables
      );
      if (!targetItem) {
        // POST call to add widget data
        createWidgetConfiguration(overviewWidgetData[0].id);
      }
    }
  }, [overviewWidgetData]);

  const createWidgetConfiguration = async (parent_id: string) => {
    await createMachineConfigurationData({
      machineId,
      configuration: {
        id: uuidv4(),
        name: 'Consumables',
        names: {
          en: 'Consumables'
        },
        label: MachinePerformanceTabs.Consumables,
        widgetClass: WidgetClass.Structure,
        widgetType: WidgetType.Menu,
        editable: true,
        deletable: false,
        active: true,
        toggleActive: true,
        reorder: false,
        parent_id
      },
      languageId: languageId
    })
      .unwrap()
      .catch((error) => {
        console.error(error?.data?.detail);
      });
  };

  return (
    <DataRenderer isLoading={isLoading} error={error && 'Failed to load navigation'}>
      <PageTabView
        TabSubNav={
          tabs && (
            <ConfiguredSubNav
              baseUrl={match.url}
              labels={[TopLevelTabs.MachinePerformance]}
              slugMap={proteinMachinePerformanceSlugs}
              tabMember={MPTabs}
            />
          )
        }
        HeaderRight={
          <DateButtonWithDropdown {...{ dateRange, setDateRange, hasGoBackDateLimit }} />
        }
      >
        <SyncZoomProvider>
          <Switch>
            <Route exact path={`${match.path}/`}>
              {/* Redirect to the first active tab - this may not necessarily be Overview */}
              <Redirect to={`${match.url}/${tabs && tabs[0].slug}`} />
            </Route>
            <Route path={`${match.path}/${SUB_ROUTES.current}`}>
              {/* MP is one nav-level collapsed; we are rendering the sub-tabs directly */}
              <GenericWidgetPage
                pageTemplateId={MachinePerformanceSubTabs.Current}
                {...dateRange}
              />
            </Route>
            <Route path={`${match.path}/${SUB_ROUTES.historicRecipes}`}>
              <HistoricRecipes />
            </Route>
            <Route path={`${match.path}/${SUB_ROUTES.consumables}`}>
              <Consumables />
            </Route>
            <Route path={`${match.path}/${SUB_ROUTES.hmi}`}>
              <GenericWidgetPage
                pageTemplateId={MachinePerformanceSubTabs.HmiMain}
                {...dateRange}
              />
            </Route>
          </Switch>
        </SyncZoomProvider>
      </PageTabView>
    </DataRenderer>
  );
};

export default MachinePerformance;
