import React from 'react';
import { KPIProgressTile } from 'common/components/KPIProgressTile';
import { useKPIApi } from './hooks/useKPIApi';

export const ThroughputPieceCountKPIProgressTile = (): JSX.Element => {
  const { current, ...rest } = useKPIApi();

  const progressTileSettings = {
    progress: current as number,
    title: 'throughput piece count',
    className: 'throughput-piece-count',
    ...rest
  };

  console.log({ rest });
  return <KPIProgressTile {...progressTileSettings} />;
};
